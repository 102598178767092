import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { bool, func, object, string } from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { sendFeedEvent } from 'services/amplitude/events';
import t from 'utils/translateFunc';
import { useGetNewsAllCount } from 'storage/queryHooks';
import { setCopilotUnread, setLastViewed } from 'storage/actions';
import { useCopilotCreate, useCopilotUpdate, useCopilotDetail, useCopilotNewQuestion } from 'storage/copilot/requestsHooks';
import { openNotification } from 'Components';
import { Vector, Send, NotDisturb, ThumpUpNDown, Copy } from 'icons';
import BodyHtml from 'dangerously-set-html-content';
import colors from 'styles/_colors.scss';
import classes from './CopilotMessages.module.scss';

function Messages({ chat = null, set_chat = () => null, questionText = '', setQuestionText = () => null, load_limits = () => null, select_mode = () => null, is_full_screen = false }) {
  const [canSand, setCanSend] = useState(true);
  const [updateWithVerification, setUpdateWithVerification] = useState(true);
  const [isFiltersWasChanged, setIsFiltersWasChanged] = useState(false);
  const [lastQuestionText, setLastQuestionText] = useState(null);
  const in_process = useSelector(state => state?.copilot?.in_process);
  const unread = useSelector(state => state?.copilot?.unread);
  const limits = useSelector(state => state?.copilot?.limits);
  const { language } = useSelector(state => state?.userInfo);
  const chekedArticles = useSelector(state => state?.news?.chekedArticles);
  const filtersQuery = useSelector(state => state?.filters?.filtersQuery);
  const dispatch = useDispatch();
  const endOfChatRef = useRef(null);
  const { mutate: copilotDetail, isLoading: isLoadingDetail } = useCopilotDetail();
  const { mutate: copilotCreate, isLoading: isCreating } = useCopilotCreate();
  const { mutate: copilotNewQuestion, isLoading: isCreatingNew } = useCopilotNewQuestion();
  const { mutate: copilotUpdate } = useCopilotUpdate();
  useGetNewsAllCount();

  useEffect(() => {
    if (in_process || isCreating || isCreatingNew) {
      setCanSend(false);
    } else {
      setCanSend(true);
    }
  }, [in_process, isCreatingNew, isCreating]);

  useEffect(() => {
    if ((!chat?.messages && chat?.id) || (chat?.id && chat?.id === unread)) {
      copilotDetail({ chat_id: chat.id }, {
        onSuccess: (resp) => {
          set_chat(resp);
          if (chat?.id === unread) dispatch(setCopilotUnread({ unread: null }));
        },
        onError: () => { console.log('error'); }
      });
    }
    endOfChatRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    dispatch(setLastViewed({ article_id: chat?.id ? chat?.id : null }));
  }, [chat, unread, isFiltersWasChanged]);

  useEffect(() => {
    !updateWithVerification && setUpdateWithVerification(true);
  }, [filtersQuery, chekedArticles]);

  function onClickLike(msg_num) {
    if (is_full_screen) {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Like_Answer_In_Copilot_Chat_In_Full_Screen_Mode' });
    } else {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Like_Answer_In_Copilot_Chat' });
    }

    copilotUpdate({ chat_id: chat.id, liked_messages_ids: [msg_num] }, {
      onSuccess: resp => set_chat(resp)
    });
  }

  function onClickDislike(msg_num) {
    if (is_full_screen) {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Dislike_Answer_In_Copilot_Chat_In_Full_Screen_Mode' });
    } else {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Dislike_Answer_In_Copilot_Chat' });
    }

    copilotUpdate({ chat_id: chat.id, disliked_messages_ids: [msg_num] }, {
      onSuccess: resp => set_chat(resp)
    });
  }

  async function onClickCopy(msg_num) {
    if (is_full_screen) {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Copy_Answer_In_Copilot_Chat_In_Full_Screen_Mode' });
    } else {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Copy_Answer_In_Copilot_Chat' });
    }
    let copied_text = chat?.messages?.length > msg_num ? (chat?.messages[msg_num]?.content || '') : '';

    copied_text = /^```html(?<content>[\s\S]*)```$/g.exec(copied_text)?.groups?.content || copied_text;
    copied_text = copied_text.replaceAll(/<\/?(\w+)(\s[\w\W]*?)?>/g, '');

    await navigator.clipboard.writeText(copied_text);
    openNotification({ message: 'Copied' });
  }

  function sendQuestionEvent() {
    if (is_full_screen) {
      const event_name = chat?.id ? 'Clicked_Send_New_Question_In_Existed_Copilot_Chat_In_Full_Screen_Mode' : 'Clicked_Start_New_Copilot_Chat_In_Full_Screen_Mode';
      sendFeedEvent({ category: 'copilot', event: event_name });
    } else {
      const event_name = chat?.id ? 'Clicked_Send_New_Question_In_Existed_Copilot_Chat' : 'Clicked_Start_New_Copilot_Chat';
      sendFeedEvent({ category: 'copilot', event: event_name });
    }
  }

  function sendQuestion(force = null) {
    // check limits
    if (limits?.usage >= limits?.limit) {
      select_mode('using');
      return;
    }

    const q = force?.text || questionText;

    isFiltersWasChanged && setIsFiltersWasChanged(false);

    const body = { question: force?.text || questionText };
    if (chekedArticles) body.selected_articles_ids = chekedArticles.map(item => item.article_id);

    const messages = chat?.messages || [];

    if (force) {
      force?.is_new && set_chat({ messages: [{ content: q, role: 'human' }] });
      setLastQuestionText(null);
    } else {
      messages.push({ content: q, role: 'human' });
      set_chat({ ...chat, messages });
    }

    if (chat?.id && !force?.is_new) {
      body.with_verification = updateWithVerification;
      copilotNewQuestion({ chat_id: chat?.id, body }, {
        onSuccess: (resp) => {
          if (resp?.was_articles_changed) {
            setIsFiltersWasChanged(true);
            setLastQuestionText(q);
          } else {
            set_chat({ ...resp });
          }
          setUpdateWithVerification(false);
          load_limits();
        },
        onError: () => {
          const err_text = t('COPILOT', 'ERROR_MESSAGE', language);
          set_chat({ ...chat, messages, error: err_text, remote_task_status: 'FAILED' });
          load_limits();
        }
      });
    } else {
      copilotCreate({ body }, {
        onSuccess: (resp) => {
          set_chat({ ...resp });
          load_limits();
        },
        onError: (err) => {
          const err_text = err?.response?.data?.error || t('COPILOT', 'ERROR_MESSAGE', language);
          set_chat({ messages, error: err_text, remote_task_status: 'FAILED' });
          load_limits();
        }
      });
    }
    setQuestionText('');
  }

  function filterHTML(text) {
    if (!text) return null;
    // clean HTML mark
    text = /^```html(?<content>[\s\S]*)```$/g.exec(text)?.groups?.content || text;
    // clean forbidden tags
    const allowed_tags = ['a', 'b', 'i', 'p', 'br', 'ul', 'ol', 'li'];
    const tags = new Set([...text.matchAll(/<\/?(\w+)(\s[\w\W]*?)?>/g)].map(item => item[1]));
    const not_allowed_tags = [...tags].filter(item => !allowed_tags.includes(item));

    if (not_allowed_tags?.length) {
      const re = new RegExp(`</?(?:${not_allowed_tags.join('|')})(\\s[\\w\\W]*?)?>`, 'g');
      text = text.replaceAll(re, '');
    }
    // modify 'a' tags
    text = text.replaceAll(/<a(\s[\w\W]*?)?>/g, '<a$1 target="_blank">');

    return text;
  }

  return (
    <>
      <main className={classes.messages}>
        <div className={classes.copilotUser}>
          <Vector />
          <span>Q Insight</span>
        </div>
        <div className={classes.answer}>
          <div className={classes.message}>
            <BodyHtml html={t('COPILOT', 'INFO_MESSAGE_1', language)} />
          </div>
        </div>
        <div className={classes.answer}>
          <div className={classes.message}>
            <BodyHtml html={t('COPILOT', 'INFO_MESSAGE_2', language)} />
          </div>
        </div>
        <div className={classes.answer}>
          <div className={classes.message}>
            <BodyHtml html={t('COPILOT', 'INFO_MESSAGE_3', language)} />
          </div>
        </div>
        <div className={classes.answer}>
          <div className={classes.message}>
            <BodyHtml html={t('COPILOT', 'INFO_MESSAGE_4', language)} />
          </div>
        </div>
        {isLoadingDetail && (
        <>
          <div className={classes.question}>
            <div className={classes.message}>
              <Skeleton className={classes.skeleton} baseColor={colors.purple500} borderRadius="7px" count={3} />
            </div>
          </div>
          <div className={classes.answer}>
            <div className={classes.message}>
              <Skeleton className={classes.skeleton} baseColor={colors.purpleA150} borderRadius="7px" count={3} />
            </div>
          </div>
        </>
        )}
        {chat?.messages && (
          chat.messages.map((item, num) => (
            <>
              {item?.role === 'human' && (
                <div key={`${item?.role}-${item?.id}-${num}`} className={classes.question}>
                  <div className={classes.message}>
                    <BodyHtml html={(item?.content || ' ')?.replaceAll('\n', '<br />')} />
                  </div>
                </div>
              )}
              {item?.role === 'ai' && (
                <>
                  <div className={classes.copilotUser}>
                    <Vector />
                    <span>Q Insight</span>
                  </div>
                  <div key={`${item?.role}-${item?.id}-${num}`} className={classes.answer}>
                    <BodyHtml
                      allowRerender
                      className={classes.message}
                      html={`${filterHTML(item?.content) || t('COPILOT', 'ERROR_MESSAGE', language)}`}
                    />
                  </div>
                  <div className={classes.actions}>
                    <div className={classes.action} onClick={() => (item?.id ? onClickLike(item?.id) : null)}>
                      <ThumpUpNDown
                        is_active={item?.is_liked === true}
                        color="green"
                      />
                    </div>
                    <div className={classes.action} onClick={() => (item?.id ? onClickDislike(item?.id) : null)}>
                      <ThumpUpNDown
                        is_active={item?.is_liked === false}
                        dislike
                        color="red"
                      />
                    </div>
                    <div className={classes.action} onClick={() => onClickCopy(num)}>
                      <Copy />
                    </div>
                  </div>
                </>
              )}
            </>
          ))
        )}
        {(chat?.id && isFiltersWasChanged) && (
          <>
            <div className={classes.copilotUser}>
              <Vector />
              <span>Q Insight</span>
            </div>
            <div className={classes.system}>
              <div className={classes.message}>
                {t('COPILOT', 'FILTER_WAS_CHANGED', language)}
              </div>
            </div>
            <div
              className={classes.systemBtn}
              onClick={() => {
                if (is_full_screen) {
                  sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Сontinue_In_New_Chat_In_Full_Screen_Mode' });
                } else {
                  sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Сontinue_In_New_Chat' });
                }
                sendQuestion({ text: lastQuestionText, is_new: true });
              }}
            >
              {t('COPILOT', 'START_NEW_DIALOG', language)}
            </div>
            <div
              className={classes.systemBtn}
              onClick={() => {
                if (is_full_screen) {
                  sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Continue_In_Current_Chat_In_Full_Screen_Mode' });
                } else {
                  sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Continue_In_Current_Chat' });
                }
                sendQuestion({ text: lastQuestionText, is_new: false });
              }}
            >
              {t('COPILOT', 'CONTINUE_CURRENT_DIALOG', language)}
            </div>
          </>
        )}
        {chat?.remote_task_status === 'FAILED' && (
          <>
            <div className={classes.copilotUser}>
              <Vector />
              <span>Q Insight</span>
            </div>
            <div className={classes.answer}>
              <div className={classes.message}>
                {chat?.error || t('COPILOT', 'ERROR_MESSAGE', language)}
              </div>
            </div>
          </>
        )}
        {(chat?.remote_task_status === 'SCHEDULED' || chat?.remote_task_status === 'IN_PROGRESS') && (
          <>
            <div className={classes.copilotUser}>
              <Vector />
              <span>Q Insight</span>
            </div>
            <div className={classes.answer}>
              <div className={classes.message}>
                {t('COPILOT', 'WAITING_ANSWER', language)}
              </div>
            </div>
          </>
        )}
        <div ref={endOfChatRef} />
      </main>
      <footer className={classes.sendForm}>
        <form>
          <div className={classes.inputQuestion}>
            <textarea
              onChange={e => setQuestionText(e.target.value)}
              value={questionText}
              name="copilot_msg"
              placeholder={t('COPILOT', 'INPUT_TEXT', language)}
              tabIndex="0"
              disabled={!canSand}
              onKeyDown={
                (e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    sendQuestionEvent();
                    sendQuestion();
                  }
                }
              }
            />
          </div>
          {canSand && (
            <div
              className={classes.sendButton}
              onClick={() => {
                sendQuestionEvent();
                sendQuestion();
              }}
            >
              <Send />
            </div>
          )}
          {!canSand && (
            <div className={classes.sendButton}>
              <NotDisturb />
            </div>
          )}
        </form>
        <div className={classes.footerText}>
          {t('COPILOT', 'CHATGPT_WARNING', language)}
        </div>
      </footer>
    </>
  );
}

Messages.propTypes = {
  chat: object,
  set_chat: func,
  questionText: string,
  setQuestionText: func,
  load_limits: func,
  select_mode: func,
  is_full_screen: bool
};

export default Messages;
