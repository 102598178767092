import { useRef, useMemo } from 'react';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { InfoBtn, Loader, ArticlesListPreview } from 'Components';
import { CSSTransition } from 'react-transition-group';
import { bool, string, func, shape } from 'prop-types';
import colors from 'styles/_colors.scss';
import { useParams } from 'react-router-dom';
import transitionFiltrationPanel from './transitionFiltrationPanel.module.scss';
import classes from './PreviewSide.module.scss';

function PreviewSide({ visible = false,
  language = 'uk',
  isLoading = true,
  onChangePeriod = () => null,
  period = { value: '', label: '' },
  previewData = null,
  name = '',
  query = '',
  error = false
}) {
  const nodeRef = useRef(null);
  const { filterName } = useParams();
  const optionsPeriod = [
    { value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) },
    { value: 'last2Weeks', label: t('bodyAddTagModal', 'LAST_2WEEK', language) }
  ];
  const highlights = useMemo(() => {
    const higlightName = name ? [name] : [];
    return [...higlightName]?.join(',').replaceAll(',', '|');
  }, [name, query]);

  return (
    <CSSTransition
      in={visible}
      timeout={350}
      classNames={transitionFiltrationPanel}
      unmountOnExit
      nodeRef={nodeRef}
    >
      <div className={cn(classes.previewSideWrap)} ref={nodeRef}>
        <div className={cn(classes.previewWrapp, visible && classes.previewWrapp_opened)}>
          <div className={classes.previewSideHeader}>{t('advancedBuilder', 'PREVIEW', language)}</div>
          <Loader size={65} fetching={isLoading} color={colors.font35}>
            {visible && (
            <div className={classes.previewBody}>
              <div className={classes.previewHeader}>
                {t('bodyAddTagModal', 'RESULT_HEADER', language)}
                <InfoBtn
                  className={cn(classes.infoButton)}
                  text={t('bodyAddTagModal', 'RESULT_KEYWORD_HEADER_INFO', language)}
                  icon="error_outline"
                />
              </div>
              <ArticlesListPreview
                previewData={previewData}
                highlights={highlights || ''}
                language={language}
                name={name}
                className={classes.builderPreviewWrap}
                onChangePeriod={onChangePeriod}
                optionsPeriod={optionsPeriod}
                period={period}
                error={error}
                type="advanced"
                searchType={filterName}
              />
            </div>
            )}
          </Loader>
        </div>

      </div>
    </CSSTransition>
  );
}

PreviewSide.propTypes = {
  visible: bool,
  language: string,
  isLoading: bool,
  onChangePeriod: func,
  period: shape({ value: string, label: string }),
  previewData: shape({}),
  name: string,
  query: string,
  error: bool
};
export default PreviewSide;
