import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export const KidStarOutlined = ({ width = 20, height = 20, color = colors.font50, className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <mask id="mask0_171_5921" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill={colors.iconMainColor} />
    </mask>
    <g mask="url(#mask0_171_5921)">
      <path d="M6.354 5.33329L8.68734 2.31246C8.854 2.09024 9.05192 1.92704 9.28109 1.82288C9.51025 1.71871 9.74984 1.66663 9.99984 1.66663C10.2498 1.66663 10.4894 1.71871 10.7186 1.82288C10.9478 1.92704 11.1457 2.09024 11.3123 2.31246L13.6457 5.33329L17.1873 6.52079C17.5484 6.6319 17.8332 6.83677 18.0415 7.13538C18.2498 7.43399 18.354 7.76385 18.354 8.12496C18.354 8.29163 18.3297 8.45829 18.2811 8.62496C18.2325 8.79163 18.1526 8.95135 18.0415 9.10413L15.7498 12.3541L15.8332 15.7708C15.8471 16.2569 15.6873 16.6666 15.354 17C15.0207 17.3333 14.6318 17.5 14.1873 17.5C14.1596 17.5 14.0068 17.4791 13.729 17.4375L9.99984 16.3958L6.27067 17.4375C6.20123 17.4652 6.12484 17.4826 6.0415 17.4895C5.95817 17.4965 5.88178 17.5 5.81234 17.5C5.36789 17.5 4.979 17.3333 4.64567 17C4.31234 16.6666 4.15262 16.2569 4.1665 15.7708L4.24984 12.3333L1.979 9.10413C1.86789 8.95135 1.78803 8.79163 1.73942 8.62496C1.69081 8.45829 1.6665 8.29163 1.6665 8.12496C1.6665 7.77774 1.7672 7.45482 1.96859 7.15621C2.16998 6.8576 2.45123 6.64579 2.81234 6.52079L6.354 5.33329ZM7.37484 6.77079L3.33317 8.10413L5.9165 11.8333L5.83317 15.8125L9.99984 14.6666L14.1665 15.8333L14.0832 11.8333L16.6665 8.14579L12.6248 6.77079L9.99984 3.33329L7.37484 6.77079Z" fill={color || colors.font50} />
    </g>
  </svg>
);
KidStarOutlined.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
