import { useState, useCallback, useEffect } from 'react';
import { array, shape, oneOfType, string } from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { ErrorBoundarySentry, IconSVG, ChooseIndicator } from 'Components';
import moment from 'moment';
import { roundFnString } from 'utils/common';
import { linearGradientDef } from '@nivo/core';
import { mediaTypeOption } from 'data/selectOptions';
import cn from 'classnames';
import colors from 'styles/_colors.scss';
import classes from './LineChart.module.scss';

// const colorsCustom = {
//   tv: colors.pink800,
//   radio: colors.purpleA300,
//   press: colors.yellow400,
//   internet: colors.blue350,
//   socialnetwork: colors.green350,
//   forum: colors.font300,
//   review: colors.orange500,
//   agency: colors.green475,
//   total: colors.totalColor,
//   default: colors.blue350
// };

const colorsCustom = {
  tv: '#7A0E63',
  radio: '#B193DC',
  press: '#FFBF00',
  internet: '#5F60FF',
  socialnetwork: '#26D789',
  forum: '#2A206A',
  review: '#EB8034',
  blog: '#2627D2',
  agency: '#F77A99',
  total: '#2ec7c9',
  default: '#5F60FF'
};

const LineChart = ({ data = [], period = '' }) => {
  const [selectedData, setSelectedData] = useState([]);
  useEffect(() => {
    setSelectedData(data);
  }, [data]);

  function onClickLegend(legend) {
    const hasLegend = selectedData?.some(item => item?.id === legend?.id);
    setSelectedData(hasLegend ? selectedData?.filter(({ id }) => id !== legend?.id) : selectedData.concat(legend));
  }

  const legendRestList = useCallback(
    (point) => {
      const legendRestListArr = data?.filter(legend => legend?.id !== point?.serieId && !!selectedData?.some(selectItem => selectItem?.id === legend?.id));
      return !!legendRestListArr?.length && (
      <div className={classes.tooltipList}>
        {legendRestListArr.map(legend => (
          <div key={legend?.id} className={cn(classes.tooltipItem)} onClick={() => { onClickLegend(legend); }}>
            <IconSVG type={legend?.id} colored iconWrapClass={classes.tooltipIconWrapClass} />
            <div>{mediaTypeOption(legend?.id)?.label}</div>
            <span>
              {legend?.data?.find(item => item?.x === point?.data?.x)?.y || '0'}
            </span>
          </div>
        ))}
      </div>
      );
    },
    [data, selectedData]
  );

  const toolTipElement = ({ point }) => (
    <div className={classes.tooltipWrap}>
      <div className={classes.tooltipItem}>
        <IconSVG type={point?.serieId} colored iconWrapClass={classes.tooltipIconWrapClass} />
        <div>{mediaTypeOption(point?.serieId)?.label}</div>
        <span>
          {point?.data?.y}
        </span>
      </div>
      {legendRestList(point)}
    </div>
  );

  return (
    <div className={classes.lineChart}>
      <ChooseIndicator data={selectedData}>
        <ErrorBoundarySentry>
          <ResponsiveLine
            data={selectedData}
            indexBy="date"
            curve="cardinal"
            enableArea
            areaOpacity={0.3}
            lineWidth={3}
            pointSize={6}
            enableLabel={false}
            enableGridY={false}
            enableGridX={false}
            animate={false}
            margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              format(value) { return moment(value).format(period === 'day' ? 'DD.MM HH:mm' : 'DD.MM'); },
              tickValues: 6,
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 15
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: 6,
              format(value) { return roundFnString(+value); }
            }}
            theme={{
              textColor: colors.grey300,
              fontSize: 11,
              lineHeight: 16,
              fontFamily: 'Roboto',
              dots: { text: { fontSize: 12, fill: colors.font60 } },
              axis: {
                ticks: {
                  line: { stroke: colors.grey300, strokeWidth: 1 },
                  text: { fontSize: '10px' }
                }
              },
              grid: { line: { stroke: colors.grey300, strokeWidth: '0.3px' } }
            }}
            defs={[
              linearGradientDef('gradientA', [
                { offset: 0, color: 'inherit' },
                { offset: 100, color: 'inherit', opacity: 0 }
              ])
            ]}
            fill={[{ match: '*', id: 'gradientA' }]}
            tooltip={tooltip => toolTipElement(tooltip)}
            pointColor={{ from: 'color' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            colors={({ id }) => colorsCustom[id] || colorsCustom.default}
            enableTouchCrosshair
            useMesh
          />
        </ErrorBoundarySentry>
      </ChooseIndicator>
      <div className={classes.legend}>
        {data.map(legend => (
          <div
            key={legend?.id}
            className={cn(
              classes.legendItem,
              !selectedData?.some(({ id }) => id === legend?.id) && classes.legendItem_disabled
            )}
            onClick={() => { onClickLegend(legend); }}
          >
            <IconSVG type={legend?.id} colored />
            <div>{mediaTypeOption(legend?.id)?.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

LineChart.propTypes = { data: oneOfType([array, shape({})]), period: string };

export default LineChart;
