import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function GoBack({ className = '', width = 16, height = 16, color = colors.textPrimary }) {
  return (
    <svg width={width || '16'} height={height || '16'} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_378_8521" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
        <rect y="0.5" width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_378_8521)">
        <path
          d="M5.33325 11.833L1.33325 7.83301L5.33325 3.83301L6.28325 4.76634L3.21659 7.83301L6.28325 10.8997L5.33325 11.833ZM13.3333 13.1663V10.4997C13.3333 9.94412 13.1388 9.4719 12.7499 9.08301C12.361 8.69412 11.8888 8.49967 11.3333 8.49967H7.21659L9.61659 10.8997L8.66659 11.833L4.66659 7.83301L8.66659 3.83301L9.61659 4.76634L7.21659 7.16634H11.3333C12.2555 7.16634 13.0416 7.49134 13.6916 8.14134C14.3416 8.79134 14.6666 9.57745 14.6666 10.4997V13.1663H13.3333Z"
          fill={color || colors.textPrimary}
        />
      </g>
    </svg>
  );
}

GoBack.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
