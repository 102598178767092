import { useMemo } from 'react';
import { oneOfType, func, bool, string, shape, arrayOf } from 'prop-types';
import { SelectCustom } from 'Components';
import t from 'utils/translateFunc';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import classes from '../ReportDetails.module.scss';

const ObjectComponent = ({ dataObject = null, keywords = [], pages = [], error = false, onChange = () => null }) => {
  const allKeywords = { id: null, name: t('reports', 'ALL_OBJECTS') };
  const keywordsOptions = useMemo(() => (keywords?.length ? [allKeywords, ...keywords]?.map(kw => ({ value: kw.id, label: kw.name })) : null), [keywords]);
  const pagesOptions = useMemo(() => (pages?.length ? pages?.map(kw => ({ value: kw.id, label: kw.name })) : null), [pages]);
  const groupedOptions = [
    { label: t('reports', 'OBJECTS'), options: keywordsOptions || [] },
    { label: t('reports', 'PAGES'), options: pagesOptions || [] }
  ];

  const styles = {
    container: provided => ({
      ...provided,
      border: colors.borderWhite,
      borderRadius: '4px',
      ...(error ? {
        borderColor: colors.red500
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: error ? colors.red500 : colors.grey600,
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    }),
    option: (provided, state) => ({
      ...provided,
      marginBottom: '1px',
      padding: '10px 16px',
      height: '40px',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: state.isSelected ? '500' : '400',
      cursor: 'pointer',
      color: colors.font200,
      backgroundColor: state.isSelected ? colors.purpleA10 : colors.white,
      ':active': {
        backgroundColor: colors.purpleA10
      },
      paddingLeft: '24px',
      '&:hover': {
        backgroundColor: PROJECT === 'metricom' ? colors.green50 : colors.purpleA10
      }
    }),
    groupHeading: provided => ({
      ...provided,
      backgroundColor: colors.purple25
    }),
    group: provided => ({
      ...provided,
      paddingTop: 0
    })
  };

  const formatGroupLabel = data => (
    <div className={classes.group}>
      <div>{data.label}</div>
    </div>
  );

  return (
    <SelectCustom
      value={dataObject ? { value: dataObject?.id, label: dataObject?.name } : null}
      placeholder={t('reports', 'CHOOSE_MAILING_OBJECT')}
      onChange={({ value, label }) => { onChange({ id: value, name: label }); }}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      styles={styles}
    />
  );
};

ObjectComponent.propTypes = {
  dataObject: shape({}),
  keywords: arrayOf(shape({})),
  pages: arrayOf(shape({})),
  error: oneOfType([bool, string]),
  onChange: func
};

export default ObjectComponent;
