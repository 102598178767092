import colors from 'styles/_colors.scss';

export const stylesPeriod = {
  control: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '165px',
    minHeight: '24px',
    maxHeight: '24px',
    backgroundColor: colors.fontBody,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '30px',
    opacity: state.isDisabled ? '0.8' : '1',
    cursor: 'pointer',
    margin: 0
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: colors.boxShadow,
    width: 'fit-content',
    maxWidth: '250px',
    height: 'auto',
    right: 0
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px',
    maxHeight: '335px'
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '400',
    color: colors.font50
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      fill: `${colors.font50} !important`
    }
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '10px 16px',
    height: '39px',
    fontSize: ' 14px',
    lineHeight: '24px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: colors.font200,
    ':nth-of-type(3)': {
      borderBottom: `1px solid ${colors.purpleA100}`,
      height: '43px'
    },
    ':nth-of-type(5)': {
      borderBottom: `1px solid ${colors.purpleA100}`,
      height: '43px'
    },
    borderBottom: '1px solid transparent',
    backgroundColor:
      state.isFocused || state.isSelected ? colors.purpleA10 : colors.white,
    ':active': {
      backgroundColor: colors.purpleA10
    }
  })
};
