import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import * as requests from 'storage/alerts/requests';
import * as actions from 'storage/actions';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';
import { useDispatch, useSelector } from 'react-redux';
import hasOwn from 'lodash/has';

function showErrorMessage(errorData) {
  try {
    return errorData
      ? Object.values(errorData)?.flat()?.join(', ')
      : t('notif', 'SOMETHING_WRONG');
  } catch (err) {
    return t('notif', 'SOMETHING_WRONG');
  }
}

export const useGetSimpleAlerts = (query) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getSimpleAlerts', query],
    queryFn: () => requests.getSimpleAlerts(query),
    onSuccess: (resp) => { dispatch(actions.addAlertsSimple(resp)); },
    onError: () => { dispatch(actions.addAlertsSimple([])); }
  });
};

export const useGetSimpleAlertById = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    enabled: !!id,
    queryKey: ['getSimpleAlertById', id],
    queryFn: () => requests.getSimpleAlertById(id),
    onSuccess: (resp) => { dispatch(actions.editAlertDetails(resp)); },
    onError: ({ response }) => {
      dispatch(actions.editAlertDetails(null));
      openNotification({ message: showErrorMessage(response?.data) });
    }
  });
};

export const useCreateSimpleAlert = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useMutation({
    mutationKey: 'createSimpleAlert',
    mutationFn: ({ body }) => requests.createSimpleAlert(body),
    onSuccess: (resp) => {
      if (resp?.id) {
        !hasOwn(resp, 'editable') && (resp.editable = true);
        dispatch(actions.createAlertSimle(resp));
        dispatch(actions.closeAlertDetails());
        isOpenModal && dispatch(actions.closeModal());
        openNotification({ message: t('notif', 'ALERT_CREATE_SUCCESS') });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useChangeSimpleAlert = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  const alertId = useSelector(state => state?.alerts?.alertDetails?.id);
  return useMutation({
    mutationKey: 'changeSimpleAlert',
    mutationFn: ({ id, body }) => requests.changeSimpleAlert({ body, id }),
    onSuccess: (resp, req) => {
      if (resp?.id) {
        dispatch(actions.editAlertSimple(resp));
        alertId === resp?.id && dispatch(actions.closeAlertDetails());
        isOpenModal && dispatch(actions.closeModal());
        const switcher = Object.keys(req.body)?.[0] === 'is_enabled';
        openNotification({
          message: t('notif', !switcher
            ? 'ALERT_EDITED_SUCCESS'
            : resp.is_enabled ? 'ALERT_ON' : 'ALERT_OFF')
        });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useDeleteSimpleAlert = () => {
  const dispatch = useDispatch();
  const alertsSimple = useSelector(state => state?.alerts?.alertsSimple);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'deleteSimpleAlert',
    mutationFn: id => requests.deleteSimpleAlert(id),
    onSuccess: (_, id) => {
      dispatch(actions.deleteAlertSimple({ id }));
      dispatch(actions.closeAlertDetails());
      openNotification({ message: t('notif', 'ALERT_DELETED_SUCCESS') });
      const leftAlerts = alertsSimple.filter(alert => alert?.id !== id);
      !leftAlerts?.length && queryClient.refetchQueries({ queryKey: ['getSimpleAlerts'] });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};
// NEGATIVE ALERTS

export const useGetNegativeAlerts = (query) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getNegativeAlerts', query],
    queryFn: () => requests.getNegativeAlerts(query),
    onSuccess: (resp) => { dispatch(actions.addAlertsNegative(resp)); },
    onError: () => { dispatch(actions.addAlertsNegative([])); }
  });
};

export const useGetNegativeAlertById = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    enabled: !!id,
    queryKey: ['getNegativeAlertById', id],
    queryFn: () => requests.getNegativeAlertById(id),
    onSuccess: (resp) => { dispatch(actions.editAlertDetails(resp)); },
    onError: ({ response }) => {
      dispatch(actions.editAlertDetails(null));
      openNotification({ message: showErrorMessage(response?.data) });
    }
  });
};

export const useCreateNegativeAlert = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useMutation({
    mutationKey: 'createNegativeAlert',
    mutationFn: ({ body }) => requests.createNegativeAlert(body),
    onSuccess: (resp) => {
      if (resp?.id) {
        !hasOwn(resp, 'editable') && (resp.editable = true);
        dispatch(actions.createAlertNegative(resp));
        dispatch(actions.closeAlertDetails());
        isOpenModal && dispatch(actions.closeModal());
        openNotification({ message: t('notif', 'ALERT_CREATE_SUCCESS') });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useChangeNegativeAlert = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  const alertId = useSelector(state => state?.alerts?.alertDetails?.id);
  return useMutation({
    mutationKey: 'changeNegativeAlert',
    mutationFn: ({ id, body }) => requests.changeNegativeAlert({ body, id }),
    onSuccess: (resp, req) => {
      if (resp?.id) {
        dispatch(actions.editAlertNegative(resp));
        alertId === resp?.id && dispatch(actions.closeAlertDetails());
        isOpenModal && dispatch(actions.closeModal());
        const switcher = Object.keys(req.body)?.[0] === 'is_enabled';
        openNotification({
          message: t('notif', !switcher
            ? 'ALERT_EDITED_SUCCESS'
            : resp.is_enabled ? 'ALERT_ON' : 'ALERT_OFF')
        });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useDeleteNegativeAlert = () => {
  const dispatch = useDispatch();
  const alertsNegative = useSelector(state => state?.alerts?.alertsNegative);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'deleteNegativeAlert',
    mutationFn: id => requests.deleteNegativeAlert(id),
    onSuccess: (_, id) => {
      dispatch(actions.deleteAlertNegative({ id }));
      dispatch(actions.closeAlertDetails());
      openNotification({ message: t('notif', 'ALERT_DELETED_SUCCESS') });
      const leftAlerts = alertsNegative.filter(alert => alert?.id !== id);
      !leftAlerts?.length && queryClient.refetchQueries({ queryKey: ['getNegativeAlerts'] });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

//  ACTIVITY ALERTS

export const useGetActivityAlerts = (query) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getActivityAlerts', query],
    queryFn: () => requests.getActivityAlerts(query),
    onSuccess: (resp) => { dispatch(actions.addAlertsActivity(resp)); },
    onError: () => { dispatch(actions.addAlertsActivity([])); }
  });
};

export const useGetActivityAlertById = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    enabled: !!id,
    queryKey: ['getActivityAlertById', id],
    queryFn: () => requests.getActivityAlertById(id),
    onSuccess: (resp) => { dispatch(actions.editAlertDetails(resp)); },
    onError: (err) => {
      dispatch(actions.editAlertDetails(null));
      openNotification({ message: err?.response?.data?.name?.[0] || t('notif', 'SOMETHING_WRONG') });
    }
  });
};

export const useCreateActivityAlert = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useMutation({
    mutationKey: 'createActivityAlert',
    mutationFn: ({ body }) => requests.createActivityAlert(body),
    onSuccess: (resp) => {
      if (resp?.id) {
        !hasOwn(resp, 'editable') && (resp.editable = true);
        dispatch(actions.createAlertActivity(resp));
        dispatch(actions.closeAlertDetails());
        isOpenModal && dispatch(actions.closeModal());
        openNotification({ message: t('notif', 'ALERT_CREATE_SUCCESS') });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useChangeActivityAlert = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  const alertId = useSelector(state => state?.alerts?.alertDetails?.id);
  return useMutation({
    mutationKey: 'changeActivityAlert',
    mutationFn: ({ id, body }) => requests.changeActivityAlert({ body, id }),
    onSuccess: (resp, req) => {
      if (resp?.id) {
        dispatch(actions.editAlertActivity(resp));
        alertId === resp?.id && dispatch(actions.closeAlertDetails());
        isOpenModal && dispatch(actions.closeModal());
        const switcher = Object.keys(req.body)?.[0] === 'is_enabled';
        openNotification({
          message: t('notif', !switcher
            ? 'ALERT_EDITED_SUCCESS'
            : resp.is_enabled ? 'ALERT_ON' : 'ALERT_OFF')
        });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useDeleteActivityAlert = () => {
  const dispatch = useDispatch();
  const alertsActivity = useSelector(state => state?.alerts?.alertsActivity);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'deleteActivityAlert',
    mutationFn: id => requests.deleteActivityAlert(id),
    onSuccess: (_, id) => {
      dispatch(actions.deleteAlertActivity({ id }));
      dispatch(actions.closeAlertDetails());
      openNotification({ message: t('notif', 'ALERT_DELETED_SUCCESS') });
      const leftAlerts = alertsActivity.filter(alert => alert?.id !== id);
      !leftAlerts?.length && queryClient.refetchQueries({ queryKey: ['getActivityAlerts'] });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useHideAlertCard = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'hideAlertCard',
    mutationFn: ({ id }) => requests.hideAlertCard(id),
    onSuccess: (_, data) => {
      dispatch(actions.hideAlertCard(data));
      openNotification({ message: t('notif', 'ALERT_DELETED_SUCCESS') });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useGetReports = () => {
  const dispatch = useDispatch();
  const reportsPortal = useSelector(state => state?.alerts?.reportsPortal);
  return useQuery({
    queryKey: ['getReports', reportsPortal],
    queryFn: () => requests.getReports({ portal_id: reportsPortal?.id }),
    onSuccess: (resp) => { dispatch(actions.addReports(resp)); },
    onError: ({ response }) => {
      dispatch(actions.addReports([]));
      openNotification({ message: showErrorMessage(response?.data) });
    }
  });
};

export const useGetReportById = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    enabled: !!id,
    queryKey: ['getReportById', id],
    queryFn: () => requests.getReportById(id),
    onSuccess: (resp) => { dispatch(actions.editReportDetails(resp)); },
    onError: ({ response }) => {
      dispatch(actions.editReportDetails(null));
      openNotification({ message: showErrorMessage(response?.data) });
    }
  });
};

export const useCreateReport = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  return useMutation({
    mutationKey: 'createReport',
    mutationFn: ({ body }) => requests.createReport(body),
    onSuccess: (resp) => {
      if (resp?.id) {
        !hasOwn(resp, 'editable') && (resp.editable = true);
        dispatch(actions.createReport(resp));
        dispatch(actions.closeReportDetails());
        isOpenModal && dispatch(actions.closeModal());
        openNotification({ message: t('notif', 'REPORT_CREATE_SUCCESS') });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useChangeReport = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state?.modals?.isOpen);
  const reportId = useSelector(state => state?.alerts?.reportDetails?.id);
  return useMutation({
    mutationKey: 'changeReport',
    mutationFn: ({ id, body }) => requests.changeReport({ body, id }),
    onSuccess: (resp, req) => {
      if (resp?.id) {
        dispatch(actions.editReport(resp));
        isOpenModal && dispatch(actions.closeModal());
        reportId === resp?.id && dispatch(actions.closeReportDetails());
        const switcher = Object.keys(req.body)?.[0] === 'is_enabled';
        openNotification({
          message: t('notif', !switcher
            ? 'REPORT_EDITED_SUCCESS'
            : resp.is_enabled ? 'REPORT_ON' : 'REPORT_OFF')
        });
      }
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useDeleteReport = () => {
  const dispatch = useDispatch();
  const reportsList = useSelector(state => state?.alerts?.reports);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'deleteReport',
    mutationFn: id => requests.deleteReport(id),
    onSuccess: (_, id) => {
      dispatch(actions.deleteReport({ id }));
      dispatch(actions.closeReportDetails());
      openNotification({ message: t('notif', 'REPORT_DELETED_SUCCESS') });
      const leftReports = reportsList.filter(report => report?.id !== id);
      !leftReports?.length && queryClient.refetchQueries({ queryKey: ['getReports'] });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useHideReportCard = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationKey: 'hideReportCard',
    mutationFn: ({ id }) => requests.hideAlertCard(id),
    onSuccess: (_, data) => {
      dispatch(actions.hideReportCard(data));
      openNotification({ message: t('notif', 'REPORT_DELETED_SUCCESS') });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useGetTemplates = () => {
  const portal_id = useSelector(state => state?.alerts?.reportsPortal)?.id;
  return useQuery({
    queryKey: ['getTemplates', portal_id],
    queryFn: () => requests.getTemplates({ portal_id })
  });
};

export const useGetUserKeywords = () => {
  const { alertsList } = useSelector(state => state?.alerts);
  const params = {};
  alertsList === 'alerts-negative' && (params.auto_tone_enabled = 'true');
  return useInfiniteQuery({
    queryKey: ['getUserKeywords', params],
    queryFn: ({ pageParam = {} }) => requests.getUserKeywords({ params: { ...pageParam, ...params } }),
    getNextPageParam: lastPage => (lastPage?.next ? Object.fromEntries(new URLSearchParams(lastPage?.next.split('?')[1])) : undefined)
  });
};

export const useGetKeywordsReports = () => {
  const reportsPortalSlug = useSelector(state => state?.alerts?.reportsPortal?.slug);
  return useQuery({
    queryKey: ['getUserKeywordsReports', reportsPortalSlug],
    enabled: !!reportsPortalSlug,
    queryFn: () => requests.getKeywords({ portal_slug: reportsPortalSlug })
  });
};

export const useGetPagesReports = () => {
  const reportsPortalSlug = useSelector(state => state?.alerts?.reportsPortal?.slug);
  return useQuery({
    queryKey: ['getUserPagesReports', reportsPortalSlug],
    enabled: !!reportsPortalSlug,
    queryFn: () => requests.getPages({ portal_slug: reportsPortalSlug })
  });
};

export const useGetTemplateById = ({ id, open }) => {
  const dispatch = useDispatch();
  return useQuery({
    enabled: !!id && !!open && id !== 'default',
    queryKey: ['getTemplateById', id],
    queryFn: () => requests.getTemplateById(id),
    onSuccess: (resp) => {
      dispatch(actions.changeReportTemlate(resp));
    },
    onError: ({ response }) => {
      dispatch(actions.changeReportTemlate(null));
      openNotification({ message: showErrorMessage(response?.data) });
    }
  });
};

export const useGetTemplateMeta = ({ enabled }) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['getTemplateMeta'],
    enabled: !!enabled,
    queryFn: () => requests.getTemplateMeta(),
    onSuccess: (resp) => {
      dispatch(actions.addTemlateMeta(resp));
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useGetTemplateDashboardCharts = ({ enabled }) => {
  const dispatch = useDispatch();
  const id = useSelector(state => state?.alerts?.reportsPortal?.id);
  return useQuery({
    queryKey: ['getTemplateDashboardCharts', id],
    enabled: !!id && !!enabled,
    queryFn: () => requests.getTemplateDashboardCharts({ portal_id: id }),
    onSuccess: (resp) => {
      dispatch(actions.addTemlateDashboardCharts(resp));
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useGetTemplatePreview = body => useQuery({
  enabled: !!body,
  queryKey: ['getTemplatePreview', body],
  queryFn: () => requests.getTemplatePreview(body),
  onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
});
export const useDeleteReportTemplate = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const reportsPortal = useSelector(state => state?.alerts?.reportsPortal);
  return useMutation({
    mutationKey: 'deleteReportTemplate',
    mutationFn: id => requests.deleteReportTemplate(id),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['getTemplates'] });
      queryClient.refetchQueries({ queryKey: ['getReports', reportsPortal] });
      dispatch(actions.closeReportTemplate());
      dispatch(actions.editReportDetails({ report_customization: null }));

      openNotification({ message: t('notif', 'REPORT_TEMPLATE_DELETED_SUCCESS') });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useCreateTemplate = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'createTemplate',
    mutationFn: body => requests.createTemplate(body),
    onSuccess: (resp) => {
      dispatch(actions.closeReportTemplate());
      resp?.id && dispatch(actions.editReportDetails({ report_customization: { id: resp?.id, name: resp?.title } }));
      queryClient.refetchQueries({ queryKey: ['getTemplates'] });
      openNotification({ message: t('notif', 'REPORT_TEMPLATE_CREATED_SUCCESS') });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};

export const useEditTemplate = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'editTemplate',
    mutationFn: ({ id, body }) => requests.editTemplate({ id, body }),
    onSuccess: (resp) => {
      dispatch(actions.closeReportTemplate());
      queryClient.refetchQueries({ queryKey: ['getTemplates'] });
      resp?.id && dispatch(actions.editReportDetails({ report_customization: { id: resp?.id, name: resp?.title } }));
      openNotification({ message: t('notif', 'REPORT_TEMPLATE_EDITED_SUCCESS') });
    },
    onError: ({ response }) => { openNotification({ message: showErrorMessage(response?.data) }); }
  });
};
