import colors from 'styles/_colors.scss';

export function Undo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="5" viewBox="0 0 12 5" fill="none">
      <path
        d="M6.27876 0C4.69412 0 3.25898 0.591998 2.15272 1.55474L1.02254 0.424564C0.645815 0.0478382 0 0.310948 0 0.843148V4.18584C0 4.51473 0.26909 4.78382 0.597977 4.78382H3.94067C4.47287 4.78382 4.74196 4.138 4.36523 3.76128L3.2231 2.61914C4.05428 1.92549 5.1127 1.49494 6.28474 1.49494C8.17435 1.49494 9.80682 2.59522 10.5842 4.18584C10.7456 4.52071 11.1284 4.68814 11.4812 4.56855C11.9057 4.43101 12.121 3.94665 11.9296 3.54002C10.9011 1.4471 8.76036 0 6.27876 0Z"
        opacity="0.3"
        fill={colors.white}
      />
    </svg>
  );
}
