import { memo } from 'react';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Icon } from 'Components';
import { bool, func } from 'prop-types';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import classes from './ExampleCard.module.scss';

const ExampleCard = memo(({ isBlur = false, onClick = () => null }) => (
  <div className={cn(classes.reportsCard, isBlur && classes.reportsCard_blur)} onClick={onClick}>
    <div className={classes.reportBody}>
      <div className={classes.textWrap}>
        <div className={classes.textTitle}>
          {t('reports', 'REPORT_LOOK')}
        </div>
        <div className={cn(classes.textSubTitle, classes.textSubTitle_black)}>
          Turkish Airlines
        </div>
        <div className={classes.textSmallSubTitle}>
          {t('reports', 'TEMPLATE')}
        </div>
        <div className={cn(classes.textSubTitle, classes.textSubTitle_purple)}>
          {t('reports', 'PERSONALIZED_NEEDS')}
        </div>
        <div className={classes.textSmallSubTitle}>
          {t('reports', 'PERIODICITY')}
          <span>{` - ${t('reports', 'EVERY_DAY')}`}</span>
        </div>
        <div className={classes.timeListWrap}>
          { t('reports', 'CERTAIN_DAYS') }
        </div>
      </div>
      <div className={cn(classes.switchWrapper)}>
        <div className={classes.formatLangWrapper}>
          <div className={classes.formatType}>
            <Icon type="html" />
            <span className={cn(classes.formatTypeLabel, classes.formatTypeLabel_html)}>
              HTML
            </span>
          </div>
          <div className={cn(classes.language, classes.language_ua)}>
            UA
          </div>
        </div>
        <label className={cn(classes.switch)}>
          <input
            type="checkbox"
            defaultChecked
            disabled
            onChange={() => null}
          />
          <span className={cn(classes.slider, classes.round)} />
        </label>
      </div>
    </div>
    <div className={classes.reportFooter}>
      <div className={classes.postItem}>
        <span className={cn(classes.postAvar)} style={{ backgroundColor: PROJECT === 'metricom' ? colors.pink500 : colors.pink300 }}>
          Q
        </span>
        <span className={classes.postText}>{PROJECT === 'metricom' ? 'support@metricom' : 'support@looqme.ua'}</span>
      </div>
    </div>
  </div>
));

ExampleCard.propTypes = {
  isBlur: bool,
  onClick: func
};

export default ExampleCard;
