import t from 'utils/translateFunc';
import { string, func, shape, number, oneOfType, arrayOf } from 'prop-types';
import * as Sentry from '@sentry/react';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './ArticlesList.module.scss';
import ArticleCard from './ArticleCard';

function ArticlesList({ articles = [], isSelect = () => null, onChange = () => null, onSubmit = () => null,
  onRemoveTag = () => null, onVisible = () => null, language = 'uk', keyword = null, detailsId = '' }) {
  const createEventName = (action, body) => {
    const baseName = 'article_link_';
    if (action === 'changeTone' && body?.tone) return `${baseName}${body.tone}_${action}`;
    if (action === 'changeProcessed') return `${baseName}${body.processed}_${action}`;
    return `${baseName}${action}`;
  };

  function onHandleSubmit({ action, body, info }) {
    const eventList = ['copyLink', 'showCard', 'reportProblem', 'changeTone', 'changeProcessed'];
    const eventWithoutBlockList = ['blockSource', 'filterMentions'];
    (eventList.includes(action) || (eventWithoutBlockList.includes(action) && !body)) && sendFeedEvent({ category: `article_link_${action}`, event: createEventName(action, body) });
    onSubmit({ action, body, info: { ...info, keyword_id: keyword?.id } });
  }

  function onRemove({ article_id, tag_id }) {
    onRemoveTag({ article_id, tag_id });
  }

  function onHandleChange(e, { action, keyword_id, keyword_name, article }) {
    const { tone, url, tags, deleted, processed, bookmarked } = article;
    const toggleInfo = { article_id: +e.target.value, checked: e.target.checked, keyword_name, keyword_id, tone, url, tags, deleted, processed, bookmarked };
    onChange({ action, info: toggleInfo });
  }

  function checkSingleHighlight(hl) {
    if (hl) {
      try {
        const reg = /^(\.|\*|\+|\^|\$|\(|\)|\{|\}|\|)$/g;
        const h2 = hl?.split(',')?.map(item => item?.trim().replace(reg, '\\$1')).join('|');
        return hl ? new RegExp(`${h2}`, 'gi') : '';
      } catch (e) {
        return '';
      }
    }
    return '';
  }

  const regex = checkSingleHighlight(keyword?.highlight);

  return (
    <>
      {articles?.length ? articles?.map(article => (
        <ArticleCard
          key={article?.id}
          article={article}
          isSelect={isSelect}
          onChange={onHandleChange}
          onSubmit={onHandleSubmit}
          onRemoveTag={onRemove}
          keyword={keyword}
          onVisible={onVisible}
          language={language}
          detailsId={detailsId}
          regex={regex}
        />
      )) : <span className={classes.noArticles}>{t('notFound', 'NO_ARTICLES', language)}</span>}
    </>
  );
}

ArticlesList.propTypes = {
  articles: arrayOf(shape({})),
  isSelect: func,
  onChange: func,
  onSubmit: func,
  onRemoveTag: func,
  onVisible: func,
  language: string,
  keyword: shape({}),
  detailsId: oneOfType([string, number])
};
export default Sentry.withProfiler(ArticlesList);
