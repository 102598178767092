import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function ExtendedRepost({ width = 16, height = 16, color = colors.font70, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
      <mask id="mask0_1398_8658" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={colors.white} />
      </mask>
      <g mask="url(#mask0_1398_8658)">
        <path d="M6.66667 11.3333H9.33333V10H6.66667V11.3333ZM6.66667 8.66667H11.3333V7.33333H6.66667V8.66667ZM4.66667 6H11.3333V4.66667H4.66667V6ZM4.41667 14.6667C3.53889 14.6667 2.79167 14.3583 2.175 13.7417C1.55833 13.125 1.25 12.3778 1.25 11.5C1.25 10.8667 1.41389 10.3 1.74167 9.8C2.06944 9.3 2.5 8.92222 3.03333 8.66667H1.33333V7.33333H5.33333V11.3333H4V9.71667C3.58889 9.80556 3.25 10.0167 2.98333 10.35C2.71667 10.6833 2.58333 11.0667 2.58333 11.5C2.58333 12.0111 2.76389 12.4444 3.125 12.8C3.48611 13.1556 3.91667 13.3333 4.41667 13.3333V14.6667ZM6.66667 14V12.6667H12.6667V3.33333H3.33333V6H2V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H6.66667Z" fill={color || colors.font70} />
      </g>
    </svg>

  );
}
ExtendedRepost.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
