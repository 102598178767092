import { useSelector } from 'react-redux';
import { BtnDropDown } from 'Components';
import t from 'utils/translateFunc';
import { string, func } from 'prop-types';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import cn from 'classnames';
import classes from './UserDropdown.module.scss';
import { userMenu } from '../helpers/navigationConfig';

function UserDropdown({ onChange = () => null, active = '' }) {
  const language = useSelector(state => state?.userInfo?.language);
  const navMenu = !!userMenu?.length && userMenu?.map(el => ({ ...el, name: t('userMenu', el.name, language) }));
  return (
    <div className={cn(classes.userWrapper, PROJECT === 'metricom' && classes.userWrapper_metricom)}>
      {!!navMenu?.length && (
      <BtnDropDown
        icon="profile"
        menuConfig={navMenu}
        selectOption={onChange}
        className={classes.btnMore}
        iconClass={classes.iconClass}
        selectClass={classes.selectClass}
        active={active}
        color={colors.font90}
        arrowRight
        amplitudeCategory="Header"
        amplitudeEvent="Clicked_Settings_Button"
      />
      )}
    </div>
  );
}

UserDropdown.propTypes = {
  onChange: func,
  active: string
};

export default UserDropdown;
