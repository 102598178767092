import { useState } from 'react';
import { Button, Phone } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { func, string } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import { PROJECT } from 'config';
import classes from './BodyConnectWhatsapp.module.scss';

const BodyConnectWhatsapp = ({
  onClickOk = () => null,
  onCancel = () => null,
  addWhatsappAccount = () => null,
  language = 'uk'
}) => {
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  function onChangePhone(phone_number) {
    const number = phone_number.replaceAll(/[\D]/g, '');
    setPhone(number);
  }
  function add_whatsapp_account() {
    if (phone.length > 15 || phone.length < 10) {
      setError('Wrong phone number');
    } else {
      setError('');
      setPhone('');
      onCancel();
      addWhatsappAccount(phone);
      onClickOk();
    }
  }
  return (
    <div className={cn(classes.bodyConnectWhatsapp)}>
      <div className={classes.imageWrapp}>
        <div className={classes.imageMessage} />
        <div className={classes.imagePhone} />
      </div>
      <div className={classes.text}>
        {t('alerts', PROJECT === 'metricom' ? 'WHATSAPP_RECEIVE_M' : 'WHATSAPP_RECEIVE', language)}
      </div>
      <Phone
        onChange={onChangePhone}
        error={error}
      />
      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL', language)}
          purpleTransparent32
        />
        <Button
          onClick={() => {
            sendAlertsEvent({ category: 'Recipient WA', event: 'Clicked_Connect_WhatsApp_Button_In_Connect_WhatsApp_Modal' });
            add_whatsapp_account();
          }}
          textButton={t('modalWindow', 'CONNECT_WHATSAPP', language)}
          purpleTransparent32
        />
      </div>
    </div>
  );
};

BodyConnectWhatsapp.propTypes = {
  onClickOk: func,
  onCancel: func,
  addWhatsappAccount: func,
  language: string
};

export default BodyConnectWhatsapp;
