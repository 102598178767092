import { api } from 'api/apiInstance';
import { checkQueryParams } from 'utils/common';
import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { postTypesOptions, mediaTypesOptions } from 'data/selectOptions';

export const getMediaTypes = language => Promise.resolve(mediaTypesOptions(language));

export const getPostTypes = language => Promise.resolve(postTypesOptions(language));

export const getCountries = async ({ query }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`filter/country/${queryString}`);
  return response?.data?.map((item) => {
    const code = item?.value?.toUpperCase();
    const flag = hasFlag(code) && getUnicodeFlagIcon(code);
    return ({ value: item?.id, label: item?.label, code: item?.value, flag: flag || '' });
  });
};

export const getSourcesOptions = async ({ query }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`quick_search/sources/${queryString}`);
  return response?.data?.results?.map(item => ({ label: item?.name, value: item?.id, icon: item?.icon }));
};

export const getSources = async ({ query, signal }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`quick_search/sources/${queryString}`, { signal });
  return response?.data;
};

export const getSourcesLevel = async ({ query }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`filter/levels/${queryString}`);
  return response?.data?.map(item => ({ value: item?.id, label: item?.title }));
};

export const getSourcesSubject = async ({ query }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`filter/subjects/${queryString}`);
  return response?.data?.map(item => ({ value: item?.id, label: item?.title }));
};

export const getSourcesLanguages = async ({ query }) => {
  const queryString = checkQueryParams(query);
  const response = await api.get(`filter/languages/${queryString}`);
  return response?.data?.map(item => ({ value: item?.id, label: item?.title, code: item?.code }));
};

export const getSearchHistory = async () => {
  const response = await api.get('quick_search/history/');
  return response?.data;
};

export const getSearchHistoryDay = async (day) => {
  const response = await api.get(`quick_search/history/${day}`);
  return response?.data;
};
export const getSearchHistoryInfinity = async () => {
  const response = await api.get('quick_search/history/');
  return response?.data;
};
export const translateSearchArticleText = async (body) => {
  const response = await api.post('quick_search/translate/', body);
  return response?.data;
};

export const getSearchAnalyticsCharts = async ({ body, signal }) => {
  const response = await api.post('quick_search/charts/', body, { signal });
  return response?.data;
};

export const getMediaArticles = async ({ body, signal }) => {
  const response = await api.post('quick_search/media/', body, { signal });
  return response?.data;
};
export const getSocialnetworkArticles = async ({ body, signal }) => {
  const response = await api.post('quick_search/socialnetwork/', body, { signal });
  return response?.data;
};
export const getSearchCounts = async ({ body, signal }) => {
  const response = await api.post('quick_search/count/', body, { signal });
  return response?.data;
};

export const getSearchFilters = async ({ signal }) => {
  const response = await api.get('quick_search/pages/', { signal });
  return response?.data?.results;
};

export const createSearchFilter = async (body) => {
  const response = await api.post('quick_search/pages/', body);
  return response?.data;
};

export const editSearchFilter = async (body) => {
  const response = await api.patch(`quick_search/pages/${body?.id || ''}`, body);
  return response?.data;
};

export const deleteSearchFilter = async (id) => {
  const response = await api.delete(`quick_search/pages/${id}`);
  return response?.data;
};

export const getFavoriteMediaArticles = async ({ body, signal }) => {
  const response = await api.post('quick_search/favorites/media/', body, { signal });
  return response?.data;
};

export const addToFavorite = async (body) => {
  const response = await api.post('quick_search/favorite/', body);
  return response?.data;
};
export const addToFavoriteMany = async (body) => {
  const response = await api.post('quick_search/favorite/create_bulk/', body);
  return response?.data;
};

export const addToSystem = async (body) => {
  const response = await api.post('quick_search/create-article/', body);
  return response?.data;
};

export const removeFromSystem = async (id) => {
  const response = await api.delete(`quick_search/create-article/${id}`);
  return response?.data;
};

export const removeFromFavorite = async (id) => {
  const response = await api.delete(`quick_search/favorite/${id}`);
  return response?.data;
};

export const removeFromFavoriteMany = async (body) => {
  const response = await api.post('quick_search/favorite/delete_bulk/', body);
  return response?.data;
};

export const getAllFavoriteSearchCounts = async ({ signal }) => {
  const response = await api.get('quick_search/favorite/count/', { signal });
  return response?.data;
};

export const getFavoriteSearchCounts = async ({ body, signal }) => {
  const response = await api.post('quick_search/favorites/counts/', body, { signal });
  return response?.data;
};
export const getFavoriteSocialnetworkArticles = async ({ body, signal }) => {
  const response = await api.post('quick_search/favorites/socialnetwork/', body, { signal });
  return response?.data;
};
export const dowloadSearchReport = async (body) => {
  const response = await api.post('quick_search/download/', body);
  return response?.data;
};

export const dowloadFavoriteSearchReport = async (body) => {
  const response = await api.post('quick_search/favorites/download/', body);
  return response?.data;
};
