import colors from 'styles/_colors.scss';

export function Polygon() {
  return (
    <svg width="35" height="46" viewBox="0 0 35 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1308_5788)">
        <path d="M24.2048 20.2654C24.6334 20.6614 24.6334 21.3386 24.2048 21.7346L11.6785 33.3058C11.0381 33.8975 10 33.4432 10 32.5713L10 9.42872C10 8.55679 11.0381 8.10252 11.6786 8.69417L24.2048 20.2654Z" fill={colors.purple400} />
      </g>
      <defs>
        <filter id="filter0_d_1308_5788" x="0" y="0.426758" width="34.5264" height="45.1465" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.867014 0 0 0 0 0.886719 0 0 0 0 0.945833 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1308_5788" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1308_5788" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
