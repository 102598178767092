import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function ArrowRightCircled({ className = '', width = 16, height = 16, color = colors.purple600 }) {
  return (
    <svg width={width || '16'} height={height || '16'} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_107_4064" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_107_4064)">
        <path
          d="M7.99998 10.6663L10.6666 7.99967L7.99998 5.33301L7.06665 6.26634L8.13331 7.33301H5.33331V8.66634H8.13331L7.06665 9.73301L7.99998 10.6663ZM7.99998 14.6663C7.07776 14.6663 6.21109 14.4913 5.39998 14.1413C4.58887 13.7913 3.88331 13.3163 3.28331 12.7163C2.68331 12.1163 2.20831 11.4108 1.85831 10.5997C1.50831 9.78856 1.33331 8.9219 1.33331 7.99967C1.33331 7.07745 1.50831 6.21079 1.85831 5.39967C2.20831 4.58856 2.68331 3.88301 3.28331 3.28301C3.88331 2.68301 4.58887 2.20801 5.39998 1.85801C6.21109 1.50801 7.07776 1.33301 7.99998 1.33301C8.9222 1.33301 9.78887 1.50801 10.6 1.85801C11.4111 2.20801 12.1166 2.68301 12.7166 3.28301C13.3166 3.88301 13.7916 4.58856 14.1416 5.39967C14.4916 6.21079 14.6666 7.07745 14.6666 7.99967C14.6666 8.9219 14.4916 9.78856 14.1416 10.5997C13.7916 11.4108 13.3166 12.1163 12.7166 12.7163C12.1166 13.3163 11.4111 13.7913 10.6 14.1413C9.78887 14.4913 8.9222 14.6663 7.99998 14.6663ZM7.99998 13.333C9.48887 13.333 10.75 12.8163 11.7833 11.783C12.8166 10.7497 13.3333 9.48856 13.3333 7.99967C13.3333 6.51079 12.8166 5.24967 11.7833 4.21634C10.75 3.18301 9.48887 2.66634 7.99998 2.66634C6.51109 2.66634 5.24998 3.18301 4.21665 4.21634C3.18331 5.24967 2.66665 6.51079 2.66665 7.99967C2.66665 9.48856 3.18331 10.7497 4.21665 11.783C5.24998 12.8163 6.51109 13.333 7.99998 13.333Z"
          fill={color || colors.purple600}
        />
      </g>
    </svg>
  );
}

ArrowRightCircled.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
