import React from 'react';
import colors from 'styles/_colors.scss';
import { string } from 'prop-types';

export function Mail({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_2562_33672)">
        <path d="M12.0043 24.0102C18.6344 24.0102 24.0093 18.6354 24.0093 12.0052C24.0093 5.37506 18.6344 0.000244141 12.0043 0.000244141C5.37409 0.000244141 -0.000732422 5.37506 -0.000732422 12.0052C-0.000732422 18.6354 5.37409 24.0102 12.0043 24.0102Z" fill={color || colors.pink500} />
        <mask id="mask0_2562_33672" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
          <rect x="4" y="4" width="16" height="16" fill={colors.iconMainColor} />
        </mask>
        <g mask="url(#mask0_2562_33672)">
          <path d="M6.66671 17.3333C6.30004 17.3333 5.98615 17.2027 5.72504 16.9416C5.46393 16.6805 5.33337 16.3666 5.33337 16V7.99996C5.33337 7.63329 5.46393 7.3194 5.72504 7.05829C5.98615 6.79718 6.30004 6.66663 6.66671 6.66663H17.3334C17.7 6.66663 18.0139 6.79718 18.275 7.05829C18.5362 7.3194 18.6667 7.63329 18.6667 7.99996V16C18.6667 16.3666 18.5362 16.6805 18.275 16.9416C18.0139 17.2027 17.7 17.3333 17.3334 17.3333H6.66671ZM12 12.55C12.0556 12.55 12.1139 12.5416 12.175 12.525C12.2362 12.5083 12.2945 12.4833 12.35 12.45L17.0667 9.49996C17.1556 9.4444 17.2223 9.37496 17.2667 9.29163C17.3112 9.20829 17.3334 9.11663 17.3334 9.01663C17.3334 8.7944 17.2389 8.62774 17.05 8.51663C16.8612 8.40551 16.6667 8.41107 16.4667 8.53329L12 11.3333L7.53337 8.53329C7.33337 8.41107 7.13893 8.40829 6.95004 8.52496C6.76115 8.64163 6.66671 8.80552 6.66671 9.01663C6.66671 9.12774 6.68893 9.22496 6.73337 9.30829C6.77782 9.39163 6.84449 9.45551 6.93337 9.49996L11.65 12.45C11.7056 12.4833 11.7639 12.5083 11.825 12.525C11.8862 12.5416 11.9445 12.55 12 12.55Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2562_33672">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
Mail.propTypes = {
  color: string
};
