import { string } from 'prop-types';
import colors from 'styles/_colors.scss';

export const Processed = ({ color = colors.green400 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
    <path d="M10.195 5.32299L6.50712 9.08676C6.25301 9.3461 5.84252 9.3461 5.58841 9.08676L3.74449 7.20487C3.49038 6.94553 3.49038 6.5266 3.74449 6.26726C3.9986 6.00792 4.40909 6.00792 4.6632 6.26726L6.04451 7.67701L9.26974 4.38538C9.52385 4.12604 9.93434 4.12604 10.1884 4.38538C10.4491 4.64472 10.4491 5.06365 10.195 5.32299ZM1.75723 6.5C1.75723 4.95061 2.42182 3.56081 3.46432 2.58994L4.46121 3.60736C4.6632 3.8135 5.01504 3.6672 5.01504 3.36796V0.515217C5.01504 0.329024 4.8717 0.182729 4.68926 0.182729H1.89405C1.60085 0.182729 1.45751 0.541816 1.66601 0.747959L2.5391 1.64568C1.26204 2.85593 0.454102 4.57822 0.454102 6.5C0.454102 9.65864 2.61729 12.3052 5.51023 12.9835C5.92071 13.0766 6.31816 12.7641 6.31816 12.3318C6.31816 12.0193 6.10315 11.7533 5.80343 11.6802C3.49038 11.1415 1.75723 9.02691 1.75723 6.5ZM13.4854 6.5C13.4854 3.34136 11.3222 0.694761 8.42923 0.0164855C8.01874 -0.0766111 7.62129 0.235928 7.62129 0.668162C7.62129 0.980701 7.8363 1.24669 8.13602 1.31984C10.4491 1.85847 12.1822 3.97309 12.1822 6.5C12.1822 8.04939 11.5176 9.43919 10.4751 10.4101L9.47824 9.39265C9.27626 9.1865 8.92441 9.3328 8.92441 9.63204V12.4848C8.92441 12.671 9.06776 12.8173 9.2502 12.8173H12.0454C12.3386 12.8173 12.4819 12.4582 12.2734 12.252L11.4004 11.3543C12.6774 10.1441 13.4854 8.42178 13.4854 6.5Z" fill={color || colors.green400} />
  </svg>
);
Processed.propTypes = {
  color: string
};
export const ProcessedOld = ({ color = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path d="M11.4708 6.14191L7.12803 10.4847C6.82879 10.784 6.3454 10.784 6.04617 10.4847L3.87476 8.31332C3.57552 8.01408 3.57552 7.53069 3.87476 7.23145C4.174 6.93221 4.65739 6.93221 4.95663 7.23145L6.58326 8.85809L10.3813 5.06005C10.6805 4.76081 11.1639 4.76081 11.4632 5.06005C11.7701 5.35929 11.7701 5.84268 11.4708 6.14191ZM1.53456 7.5C1.53456 5.71224 2.31719 4.10862 3.54483 2.98839L4.71877 4.16233C4.95663 4.40019 5.37096 4.23139 5.37096 3.88611V0.594482C5.37096 0.379643 5.20216 0.210842 4.98732 0.210842H1.69569C1.35041 0.210842 1.18161 0.625173 1.42714 0.863029L2.4553 1.89886C0.951427 3.29531 0 5.28256 0 7.5C0 11.1446 2.54737 14.1984 5.95409 14.981C6.43748 15.0884 6.90552 14.7278 6.90552 14.229C6.90552 13.8684 6.65232 13.5615 6.29937 13.4771C3.57552 12.8556 1.53456 10.4157 1.53456 7.5ZM15.3456 7.5C15.3456 3.85542 12.7982 0.801647 9.39151 0.0190217C8.90812 -0.0883975 8.44008 0.272224 8.44008 0.770956C8.44008 1.13158 8.69328 1.43849 9.04623 1.52289C11.7701 2.14439 13.811 4.58434 13.811 7.5C13.811 9.28776 13.0284 10.8914 11.8008 12.0116L10.6268 10.8377C10.389 10.5998 9.97464 10.7686 9.97464 11.1139V14.4055C9.97464 14.6204 10.1434 14.7892 10.3583 14.7892H13.6499C13.9952 14.7892 14.164 14.3748 13.9185 14.137L12.8903 13.1011C14.3942 11.7047 15.3456 9.71744 15.3456 7.5Z" fill={color || colors.font70} />
  </svg>
);

ProcessedOld.propTypes = {
  color: string
};
