import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export const Search = ({ width = 13, height = 13, color = colors.font50 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width || 13} height={height || 13} viewBox="0 0 13 13" fill="none">
    <path d="M9.29102 8.1761H8.70383L8.49571 7.97541C9.22413 7.12807 9.66266 6.02802 9.66266 4.83133C9.66266 2.16295 7.49971 0 4.83133 0C2.16295 0 0 2.16295 0 4.83133C0 7.49971 2.16295 9.66266 4.83133 9.66266C6.02802 9.66266 7.12807 9.22413 7.97541 8.49571L8.1761 8.70383V9.29102L11.8925 13L13 11.8925L9.29102 8.1761ZM4.83133 8.1761C2.98056 8.1761 1.48656 6.6821 1.48656 4.83133C1.48656 2.98056 2.98056 1.48656 4.83133 1.48656C6.6821 1.48656 8.1761 2.98056 8.1761 4.83133C8.1761 6.6821 6.6821 8.1761 4.83133 8.1761Z" fill={color || colors.font50} />
  </svg>
);
Search.propTypes = {
  width: number,
  height: number,
  color: string
};
