import { useEffect, useMemo, useRef, useState } from 'react';
import { sendFeedEvent } from 'services/amplitude/events';
import { SkeletonArticle } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { func, bool, object, string, number } from 'prop-types';
import classes from './ArticlesTree.module.scss';
import ArticleCard from './ArticleCard';
import ArticleEmptyCard from './ArticleEmptyCard';

function ArticlesTree({ article = null, load_descendants = () => null, current_types = 'comments', current_shift = 20, is_parent = false }) {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasEmptyArticles, setHasEmptyArticles] = useState(false);
  const [treeChildren, setTreeChildren] = useState(null);
  const [children, setChildren] = useState(null);
  const [articlesTypes, setArticlesTypes] = useState(null);
  const ref = useRef();

  function scrollToCurrent() {
    if (ref?.current) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  const showLoadPreviousBtn = useMemo(() => {
    if (children?.length) {
      if (children[0]?.cursor?.sn_position > 0) return true;
    }
    return false;
  }, [children, hasEmptyArticles, articlesTypes]);

  const showLoadNextBtn = useMemo(() => {
    if (hasEmptyArticles) return false;
    const types = is_parent ? current_types : articlesTypes;
    if (children?.length) {
      const cursor = (children[children.length - 1]?.cursor?.sn_position || 0) + 1;
      if (types === 'comments' && cursor < Number(article?.engagements?.comment_count || 0)) return true;
      if (types === 'reposts' && cursor < Number(article?.engagements?.repost_count || 0)) return true;
    }
    return false;
  }, [children, children?.length, hasEmptyArticles, articlesTypes]);

  const filteredChildren = useMemo(() => {
    if (treeChildren && children) {
      return children.filter(item => item?.id !== treeChildren[0]?.id);
    }
    return children;
  }, [children, children?.length]);

  useEffect(() => {
    if (article?.children?.length) {
      const children_articles = [...article.children];
      children_articles[0]?.is_tree ? setTreeChildren(children_articles.splice(0, 1)) : setTreeChildren(null);
      children_articles?.length ? setChildren(children_articles) : setChildren(null);
    }
  }, [article]);

  useEffect(() => {
    checkEmptyArticles(children || [], is_parent ? current_types : articlesTypes);
  }, [children, children?.length, current_types, articlesTypes]);

  function checkEmptyArticles(data, types) {
    const comments_count = Number(article?.engagements?.comment_count || 0);
    const reposts_count = Number(article?.engagements?.repost_count || 0);
    const cursor = data?.length ? (data[data.length - 1]?.cursor?.sn_position || 0) + 1 : 0;
    if (is_parent) {
      if (types === 'comments' && article?.children?.length < comments_count && article?.children?.length < current_shift) {
        setHasEmptyArticles(true);
      } else if (types === 'reposts' && article?.children?.length < reposts_count && article?.children?.length < current_shift) {
        setHasEmptyArticles(true);
      } else {
        setHasEmptyArticles(false);
      }
      return;
    }
    if (children?.length === 1 && treeChildren?.length) {
      if (children[0]?.id === treeChildren[0]?.id) {
        !hasEmptyArticles && setHasEmptyArticles(true);
        return;
      }
    }
    if (types === 'comments' && data?.length < current_shift && cursor < comments_count) {
      !hasEmptyArticles && setHasEmptyArticles(true);
      return;
    }
    if (types === 'reposts' && data?.length < current_shift && cursor < reposts_count) {
      !hasEmptyArticles && setHasEmptyArticles(true);
      return;
    }
    setHasEmptyArticles(false);
  }

  function getDescendants(types) {
    if (is_parent) return;
    if (types === 'comments' && Number(article?.engagements?.comment_count) === article?.children?.length) return;
    if (types === 'reposts' && Number(article?.engagements?.repost_count) === article?.children?.length) return;
    if (types === 'comments' && !Number(article?.engagements?.comment_count) > 0) return;
    if (types === 'reposts' && !Number(article?.engagements?.repost_count) > 0) return;

    setArticlesTypes(types);
    setIsLoadingMore(true);
    const variables = {
      body: {
        article_id: article?.id || article?.post_id,
        date: article?.date || article?.created_date,
        types,
        shift: current_shift
      }
    };
    load_descendants(variables, {
      onSuccess: (data) => {
        checkEmptyArticles(data, types);
        setChildren([...data]);
        if (data?.length) {
          setChildren([...data]);
        } else {
          setChildren(null);
        }
        setIsLoadingMore(false);
        scrollToCurrent();
      },
      onError: () => {
        setIsLoadingMore(false);
        scrollToCurrent();
      }
    });
    scrollToCurrent();
  }

  function hideDescendants() {
    sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Hide_Replies' });
    setChildren(null);
    setHasEmptyArticles(false);
    setArticlesTypes(null);
  }

  function loadMore(is_next = true) {
    if (children?.length) {
      setIsLoadingMore(true);
      const variables = {
        body: {
          article_id: article?.id || article?.post_id,
          date: article?.date || article?.created_date,
          types: is_parent ? current_types : articlesTypes,
          cursor: is_next ? children[children.length - 1]?.cursor : children[0]?.cursor,
          shift: is_next ? current_shift : -1 * current_shift
        }
      };
      load_descendants(variables, {
        onSuccess: (data) => {
          checkEmptyArticles(data, is_parent ? current_types : articlesTypes);
          if (data?.length) {
            setChildren([...data]);
          }
          setIsLoadingMore(false);
          scrollToCurrent();
        },
        onError: () => {
          setIsLoadingMore(false);
          scrollToCurrent();
        }
      });
      scrollToCurrent();
    }
  }

  return (
    <div className={classes.articlesTreeWrap}>
      {article && !is_parent && (
        <div ref={ref}>
          <ArticleCard
            key={article?.id}
            article={article}
            get_descendants={getDescendants}
            hide_descendants={hideDescendants}
            has_children={!!filteredChildren?.length || hasEmptyArticles}
            is_parent={is_parent}
          />
        </div>
      )}
      {!!treeChildren && treeChildren?.map((item, num) => (
        <div className={classes.innerArticle} key={num}>
          {!is_parent && (
            <div className={cn(classes.treeBranches, ((!children && !hasEmptyArticles) && !is_parent) && classes.treeBranches_last)}>
              <div className={cn(classes.branchHorizontal, ((!children && !hasEmptyArticles) && !is_parent) && classes.branchHorizontal_last)} />
            </div>
          )}
          <ArticlesTree
            key={`tree_child_article_${item?.id}`}
            article={item}
            load_descendants={load_descendants}
            current_types={is_parent ? current_types : articlesTypes}
            current_shift={10}
          />
        </div>
      ))}
      {isLoadingMore ? (
        <div className={classes.articleSkeletonWrap}>
          <SkeletonArticle />
        </div>
      ) : (
        <>
          {showLoadPreviousBtn && (
            <div className={cn(classes.loadMoreBtnWrapper, classes.loadMoreBtnWrapper_previous, classes.innerArticle, !is_parent && classes.loadMoreBtnWrapper_with_border)}>
              <div
                className={cn(classes.loadMoreBtn, classes.link)}
                onClick={() => {
                  sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Load_Previous_Button' });
                  loadMore(false);
                }}
              >
                {t('btn', 'DOWNLOAD_PREVIOUS')}
              </div>
            </div>
          )}
          {filteredChildren && filteredChildren?.map((item, num) => (
            <div className={classes.innerArticle} key={num}>
              {!is_parent && (
                <div className={cn(classes.treeBranches, (num + 1 === filteredChildren.length && !hasEmptyArticles) && classes.treeBranches_last)}>
                  <div className={cn(classes.branchHorizontal, (num + 1 === filteredChildren.length && !hasEmptyArticles) && classes.branchHorizontal_last)} />
                </div>
              )}
              <ArticlesTree
                key={`child_article_${item?.id}`}
                article={item}
                load_descendants={load_descendants}
                current_types={is_parent ? current_types : articlesTypes}
                current_shift={10}
              />
            </div>
          ))}
          {(hasEmptyArticles || (is_parent && article?.post_type !== 'post' && !article?.children?.length)) && (
            <div className={classes.innerArticle}>
              {!is_parent && (
                <div className={cn(classes.treeBranches, classes.treeBranches_last)}>
                  <div className={cn(classes.branchHorizontal, classes.branchHorizontal_last)} />
                </div>
              )}
              <ArticleEmptyCard />
            </div>
          )}
          {showLoadNextBtn && (
            <div className={cn(classes.loadMoreBtnWrapper, classes.innerArticle)}>
              <div
                className={cn(classes.loadMoreBtn, classes.link)}
                onClick={() => {
                  sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Load_Next_Button' });
                  loadMore(true);
                }}
              >
                {t('btn', 'DOWNLOAD_NEXT')}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

ArticlesTree.propTypes = {
  article: object,
  load_descendants: func,
  current_types: string,
  current_shift: number,
  is_parent: bool
};
export default ArticlesTree;
