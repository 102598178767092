import colors from 'styles/_colors.scss';

function regexFunc(match) {
  const numbersColor = !match.search(/\d+|~\d+/g) && colors.font60;
  const fieldText = !match.search(/title:|text:/g) && colors.purple400;
  const fieldAuthor = !match.search(/author_name:|author_url:|author_gender:|author_subscribers:/g) && colors.blue350;
  const fieldPublicationPlace = !match.search(/publication_place_name:|publication_place_url:|publication_place_subscribers:|post_type:/g) && colors.green600;
  const fieldReactions = !match.search(/likes_count:|comments_count:|shares_count:|views_count:|source_type:|country_name:|source_country:/g) && colors.pink400;
  const fieldCode = !match.search(/language_code:|url:/g) && colors.yellow800;
  const symbolsColor = !match.search(/[.{0,10}]|.{a,b}|[{}]|["]/g) && colors.blue500;

  const searchColor = fieldText || fieldAuthor || fieldPublicationPlace || fieldReactions || fieldCode || numbersColor || symbolsColor;

  const color = {
    'AND NOT': colors.red600,
    AND: colors.green500,
    OR: colors.yellow500,
    '?': colors.yellow600,
    ')': colors.pink500,
    '(': colors.pink500,
    '[': colors.pink100,
    ']': colors.pink100,
    '/': colors.blue800,
    '.': colors.pink500,
    '*': colors.yellow500,
    '~': colors.font60
  };

  return `<span style='color:${color[match] || searchColor || colors.textPrimary}'>${match}</span>`;
}
function regexBgColor(match) {
  const numbersColor = !match.search(/\d+|~\d+/g) && colors.font60;
  const fieldText = !match.search(/title:|text:/g) && colors.purple400;
  const fieldAuthor = !match.search(/author_name:|author_url:|author_gender:|author_subscribers:/g) && colors.blue350;
  const fieldPublicationPlace = !match.search(/publication_place_name:|publication_place_url:|publication_place_subscribers:|post_type:/g) && colors.green600;
  const fieldReactions = !match.search(/likes_count:|comments_count:|shares_count:|views_count:|source_type:|country_name:|source_country:/g) && colors.pink400;
  const fieldCode = !match.search(/language_code:|url:/g) && colors.yellow800;
  const symbolsColor = !match.search(/[.{0,10}]|.{a,b}|[{}]|["]/g) && colors.blue500;

  const searchColor = fieldText || fieldAuthor || fieldPublicationPlace || fieldReactions || fieldCode || numbersColor || symbolsColor;

  const color = {
    'AND NOT': colors.red600,
    AND: colors.green500,
    OR: colors.yellow500,
    '*': colors.yellow500,
    '?': colors.yellow600,
    ')': colors.pink500,
    '( )': colors.pink500,
    '(': colors.pink500,
    '[': colors.pink100,
    ']': colors.pink100,
    '/': colors.blue800,
    '.': colors.pink500,
    '~': colors.font60
  };
  return color[match] || searchColor || colors.white;
}

export const textReplaced = text => text
  .replaceAll(
    /\bAND\s+NOT\b|\bAND\b(?! NOT)|OR|["]|[~]|[?]|[:]|[-]|[*]|[()]|[/[\]]|[{}]|\d+|~\d+|.{a,b}|[.{0,10}]|language_code:|author_name:|publication_place_name:|publication_place_url:|publication_place_subscribers:|post_type:|source_type:|country_name:|source_country:|title:|text:|author_url:|author_gender:|author_subscribers:|likes_count:|comments_count:|shares_count:|views_count:|url:/g,
    match => regexFunc(match)
  );

export const getBgColor = text => regexBgColor(text);
