import { getIconName } from './sourceConfig';

export const isShowMetric = (info, metric) => {
  // telegram rules
  if (getIconName(info?.source?.name || info?.source) === 'telegram') {
    if (metric === 'reposts' && ['post', 'repost', 'comment', 'replyComment'].includes(info?.post_type)) return false;
    if (metric === 'views' && ['comment', 'replyComment'].includes(info?.post_type)) return false;
    if (metric === 'doubles' && ['repost', 'comment', 'replyComment'].includes(info?.post_type)) return false;
  }
  // linkedin rules
  if (getIconName(info?.source?.name || info?.source) === 'linkedin') {
    if (['reposts', 'doubles'].includes(metric) && ['comment', 'replyComment'].includes(info?.post_type)) return false;
    if (metric === 'views' && ['post', 'repost', 'comment', 'replyComment'].includes(info?.post_type)) return false;
  }
  // facebook rules
  if (getIconName(info?.source?.name || info?.source) === 'facebook') {
    if (['reposts', 'doubles'].includes(metric) && ['comment', 'replyComment'].includes(info?.post_type)) return false;
    if (metric === 'views' && ['post', 'repost', 'extendedRepost', 'comment', 'replyComment'].includes(info?.post_type)) return false;
  }
  // youtube + tiktok rules
  if (['tiktok', 'youtube'].includes(getIconName(info?.source?.name || info?.source))) {
    if (['reposts', 'views'].includes(metric) && ['comment', 'replyComment'].includes(info?.post_type)) return false;
    if (metric === 'doubles' && ['post', 'comment', 'replyComment'].includes(info?.post_type)) return false;
  }
  // instagram rules
  if (getIconName(info?.source?.name || info?.source) === 'instagram') {
    if (['reposts', 'doubles'].includes(metric) && ['post', 'comment', 'replyComment'].includes(info?.post_type)) return false;
    if (metric === 'views' && ['comment', 'replyComment'].includes(info?.post_type)) return false;
  }

  return true;
};
