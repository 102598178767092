import { func, shape, string } from 'prop-types';
import { sourcePopoversMenu } from 'data/dropDownConfig';
import t from 'utils/translateFunc';
import { BtnDropDown } from 'Components';
import classes from './Popovers.module.scss';

function PopoversList({ onSelect = () => null, info = null, language = 'uk' }) {
  const popoversMenuConfig = sourcePopoversMenu?.lenght && sourcePopoversMenu.map(el => ({ ...el, name: t('sourcePopover', el?.name, language) }));
  return (
    <div className={classes.chipsWrapper}>
      <a
        href={info?.internetarticle?.url || info?.socialnetworkarticle?.url || info?.forumarticle?.url
        || info?.reviewarticle?.url || info?.blogarticle?.url || info?.agencyarticle?.url || info?.url}
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        {info?.source?.name || info?.source}
        {!!info?.post_type && ` | ${t('postTypes', info?.post_type, language)}`}
      </a>
      {!!popoversMenuConfig?.length && (
      <BtnDropDown
        icon="arrow_drop_down"
        menuConfig={popoversMenuConfig}
        selectOption={onSelect}
        className={classes.btnMore}
        iconClass={classes.iconClass}
        selectClass={classes.selectClass}
      />
      )}
    </div>
  );
}

PopoversList.propTypes = {
  onSelect: func,
  info: shape({}),
  language: string
};
export default PopoversList;
