import cn from 'classnames';
import BodyHtml from 'dangerously-set-html-content';
import { sendFeedEvent } from 'services/amplitude/events';
import { Icon, IconSVG, Loader } from 'Components';
import moment from 'moment';
import { useTranslateSearchArticleText } from 'storage/queryHooks';
import { SOCIAL_NETWORKS } from 'data/sourceConfig';
import { getSourceColors } from 'data/colorsConfig';
import { isShowMetric } from 'data/metricsConfig';
import t from 'utils/translateFunc';
import { roundFnString } from 'utils/common';
import { shape, bool, func } from 'prop-types';
import { useMemo, useState } from 'react';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import classes from './ArticlesTree.module.scss';
import ArticleEmptyCard from './ArticleEmptyCard';

function ArticleCard({ article, get_descendants = () => null, hide_descendants = () => null, has_children = false, is_parent = false }) {
  const [showTranslation, setShowTranslation] = useState(false);
  const { data: translation, isLoading: translLoading, isRefetching: translRefetching } = useTranslateSearchArticleText({
    enabled: showTranslation && !!(article?.title || '') && !!(article?.text || ''), body: { title: article?.title || '', text: article?.text || '' } });

  const articleText = useMemo(() => {
    let title = article?.title || ' ';
    if (showTranslation && translation?.title) {
      title = translation?.title;
    }
    return title;
  }, [showTranslation, translation?.title, translLoading]);

  const sourceColor = useMemo(() => {
    const sourceName = article?.source?.name || article?.source_name || '';
    const mediaType = article?.source_type || article.media_type;
    const iconType = mediaType === 'socialnetwork' && SOCIAL_NETWORKS.includes(sourceName) ? sourceName : mediaType;
    return getSourceColors(iconType);
  }, []);

  function getDescendants(types) {
    if (types === 'comments') sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Comments_Button' });
    if (types === 'reposts') sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Reposts_Button' });
    get_descendants(types);
  }

  const iconsList = useMemo(() => {
    if (article?.source_type === 'socialnetwork') {
      return [
        { type: 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES'), text: `${roundFnString(article?.engagements?.like_count) || 0}`, handleClick: () => null },
        { type: 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS'), text: `${roundFnString(article?.engagements?.comment_count) || 0}`, handleClick: () => getDescendants('comments') },
        isShowMetric(article, 'reposts') && { type: 'reposts', role: 'button', tooltipText: t('tooltip', 'REPOSTS'), text: `${roundFnString(article?.engagements?.repost_count) || 0}`, handleClick: () => getDescendants('reposts') }
      ];
    }
    if (article?.source_type === 'review') {
      return [
        { type: 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES'), text: `${roundFnString(article?.engagements?.like_count) || 0}` },
        { type: 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS'), text: `${roundFnString(article?.engagements?.comment_count) || 0}`, handleClick: () => get_descendants('comments') }
      ];
    }
    if (article?.source_type === 'forums') {
      return [];
    }
    return [
      { type: 'audience', role: 'button', tooltipText: t('tooltip', 'AUDIENCE'), text: `${roundFnString(article?.source?.attendance || article?.attendance) || 0}` },
      { type: 'ave', role: 'button', tooltipText: 'AVE', text: `${roundFnString(article?.ave) || 0}` }
    ];
  }, []);

  const iconType = useMemo(() => {
    if (article?.source_type === 'socialnetwork') {
      return SOCIAL_NETWORKS.includes(article?.source?.name) ? article?.source?.name : article?.source_type;
    }
    return 'socialnetwork';
  }, [article]);

  const involvementCount = `${(Number(article?.engagements?.repost_count || 0) + Number(article?.engagements?.like_count || 0) + Number(article?.engagements?.comment_count || 0)) || 0}`;
  const showViews = article?.source_type === 'internet' || article?.source_type === 'socialnetwork' || article?.source_type === 'agency' || article?.source_type === 'press';

  function isClickable(type) {
    if (type === 'comments') {
      return !!Number(article?.engagements?.comment_count || 0) && article?.post_type !== 'post';
    }
    if (type === 'reposts') {
      return !!Number(article?.engagements?.repost_count || 0) && article?.post_type !== 'post';
    }
    return false;
  }

  if (article?.post_type === 'not_found') return <ArticleEmptyCard />;

  return (
    <div className={cn(classes.article, article?.is_main && classes.mainArticle)} key={article?.id}>
      <div className={cn(classes.contentWrapper)}>
        <div className={cn(classes.headerWrapper)}>
          <div className={cn(classes.titleWrapper)}>
            <span className={classes.textContentWrapper}>
              <BodyHtml
                allowRerender
                className={classes.textContentWrapper_body}
                html={`${articleText}`}
              />
            </span>
          </div>
          <div className={cn(classes.date)}>{moment(article?.date).format('DD MMM YYYY HH:mm')}</div>
        </div>
        <div className={cn(classes.footerWrapper)} id={`article-footer-${article?.id}`}>
          <div className={cn(classes.footerWrapper_container)}>
            <div>
              <IconSVG type={iconType} iconWrapClass={classes.iconSNWrap} />
            </div>
            <div className={cn(classes.source)}>
              <a
                onClick={() => sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Source_URL_In_Publication_Context_Modal' })}
                href={article?.url}
                target="_blank"
                className={cn(classes.sourceType, classes.link)}
                style={{
                  backgroundColor: sourceColor?.background,
                  color: sourceColor?.text,
                  '--background-active': sourceColor?.backgroundActive
                }}
                rel="noopener noreferrer"
              >
                {!article?.post_type
                  ? <b>{article?.source?.name || article?.source || ''}</b>
                  : `${t('postTypes', article?.post_type)}`}
              </a>
            </div>
            <div className={classes.creatorsPlaces}>
              {article?.author?.name && (
                <Icon
                  type=""
                  role="button"
                  color={colors.grey800}
                  tooltipText={t('creatorPlaceCard', 'CREATOR')}
                  text={article?.author?.name}
                  btnType={cn(classes.iconBtnWrap, classes.iconBtnWrap_reverse)}
                  onClick={() => {
                    sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Author_URL_In_Publication_Context_Modal' });
                    article.author?.url && window.open(article.author?.url, '_blank');
                  }}
                />
              )}
              {article?.publication_place?.name && (
              <>
                <Icon
                  type="next_arrow"
                  color={colors.grey800}
                  btnType={classes.iconBtnWrap}
                />
                <Icon
                  type=""
                  role="button"
                  color={colors.grey800}
                  tooltipText={t('creatorPlaceCard', 'PLACE_MENTION')}
                  text={article?.publication_place?.name}
                  onClick={() => {
                    sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Publications_Place_URL_In_Publication_Context_Modal' });
                    article.publication_place?.url && window.open(article.publication_place?.url, '_blank');
                  }}
                  btnType={cn(classes.iconBtnWrap, classes.iconBtnWrap_reverse)}
                />
              </>
              )}
            </div>
          </div>
          <div className={cn(classes.footerWrapper_container, classes.footerWrapper_icons, classes.footerWrapper_icon_text)}>
            {!!Number(article?.engagements?.comment_count) && Number(article?.engagements?.comment_count) !== article?.children?.length && !has_children && !is_parent && (
              <div
                className={classes.textLink}
                onClick={() => {
                  sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Show_Replies' });
                  get_descendants('comments');
                }}
              >
                {t('btn', 'SHOW_REPLIES')}
              </div>
            )}
            {has_children && !is_parent && (
              <div className={classes.textLink} onClick={() => hide_descendants()}>
                {t('btn', 'HIDE_REPLIES')}
              </div>
            )}
            {(article?.source_type === 'socialnetwork' || article?.source_type === 'review') && (
              <>
                {article?.source_type !== 'review' && (
                <div className={classes.iconWrap}>
                  <Icon
                    color={colors.grey450}
                    type="group"
                    role="button"
                    tooltipText={t('tooltip', 'POTENTIAL_REACH')}
                    text={roundFnString(article?.potential_reach) || '0'}
                    className={cn(classes.iconText)}
                    textClass={cn(classes.iconText)}
                    btnType={cn(classes.btnIcon)}
                  />
                </div>
                )}
                <div className={classes.iconWrap}>
                  <Icon
                    color={colors.grey450}
                    type="engagement"
                    role="button"
                    tooltipText={t('tooltip', 'ENGAGEMENT')}
                    text={roundFnString(involvementCount) || '0'}
                    className={cn(classes.iconText)}
                    textClass={cn(classes.iconText)}
                    btnType={cn(classes.btnIcon)}
                  />

                  <div className={classes.pipeChild} />
                  <div className={classes.iconGroupWrap}>
                    {iconsList.map((icon, i) => (
                      <Icon
                        {...icon}
                        color={colors.grey450}
                        key={i}
                        className={cn(classes.iconText, isClickable(icon.type) && classes.isClickable)}
                        textClass={cn(classes.iconText)}
                        btnType={cn(classes.btnIcon)}
                        onClick={icon.handleClick}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
            {showViews && isShowMetric(article, 'views') && (
            <div className={classes.iconDuplicates}>
              <Icon
                type="view"
                role="button"
                tooltipText={t('tooltip', article?.source_type === 'socialnetwork' ? 'VIEW' : 'VIEWING')}
                text={roundFnString(article?.source_type === 'socialnetwork' ? article?.engagements?.views_count : (article?.source?.average_attendance || article?.average_attendance)) || '0'}
                className={cn(classes.iconText)}
                textClass={cn(classes.iconText)}
                btnType={cn(classes.btnIcon)}
              />
            </div>
            )}
            {article?.source_type !== 'review' && isShowMetric(article, 'doubles') && (
              <div className={classes.iconDuplicates}>
                <Icon
                  type="doubles"
                  role="button"
                  color={colors.grey450}
                  tooltipText={t('tooltip', 'DOUBLES')}
                  text={roundFnString(article?.duplicates_count) || 0}
                  className={cn(classes.iconText)}
                  textClass={cn(classes.iconText)}
                  btnType={cn(classes.btnIcon)}
                />
              </div>
            )}
            <div className={classes.iconDuplicates}>
              <Loader type="spin" size={16} fetching={translLoading || translRefetching} color={colors.font35}>
                <Icon
                  type="language"
                  width={16}
                  height={16}
                  role="button"
                  color={colors.grey450}
                  tooltipText={!showTranslation ? t('details', 'TRANSLATE') : t('details', 'SHOW_ORIGINAL')}
                  onClick={() => {
                    sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Translate_In_Publication_Context_Modal' });
                    setShowTranslation(!showTranslation);
                  }}
                  className={cn(classes.iconText)}
                />
              </Loader>
            </div>
            {is_parent && (
              <div className={classes.iconDuplicates}>
                <Icon
                  type="descendents_tree"
                  role="button"
                  tooltipText={t('notif', 'ORIGINAL_POST')}
                  color={PROJECT === 'metricom' ? colors.green600m : colors.purple600}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ArticleCard.propTypes = {
  article: shape({}),
  get_descendants: func,
  hide_descendants: func,
  has_children: bool,
  is_parent: bool
};

export default ArticleCard;
