import cn from 'classnames';
import t from 'utils/translateFunc';
import { Icon } from 'Components';
import colors from 'styles/_colors.scss';
import classes from './ArticlesTree.module.scss';

function ArticleEmptyCard() {
  return (
    <div className={cn(classes.emptyArticle)}>
      <span className={classes.textContentWrapper}>
        {t('notif', 'NOT_IN_FILTER_PATTERN')}
      </span>
      <span className={classes.info}>
        <Icon
          type=""
          role="button"
          color={colors.grey800}
          tooltipText={t('notif', 'NOT_IN_FILTER_PATTERN_DETAIL')}
          text="ⓘ"
        />
      </span>
    </div>
  );
}

export default ArticleEmptyCard;
