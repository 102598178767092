import { string } from 'prop-types';
import colors from 'styles/_colors.scss';

export const Unprocessed = ({ color = colors.font70 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_127_14802" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill={colors.iconMainColor} />
    </mask>
    <g mask="url(#mask0_127_14802)">
      <path d="M8.00179 14.7203C7.06845 14.7203 6.19517 14.5456 5.38195 14.196C4.56862 13.8464 3.8564 13.3661 3.24529 12.7548C2.63406 12.1437 2.15367 11.4307 1.80412 10.6158C1.45456 9.80094 1.27979 8.92644 1.27979 7.99233C1.27979 7.05833 1.45456 6.18639 1.80412 5.3765C2.15367 4.56672 2.63406 3.85627 3.24529 3.24516C3.8564 2.63394 4.56862 2.15355 5.38195 1.804C6.19517 1.45444 7.0679 1.27966 8.00012 1.27966C8.70012 1.27966 9.36679 1.37894 10.0001 1.5775C10.6335 1.77605 11.2231 2.0635 11.769 2.43983L10.5726 3.63616C10.1992 3.39761 9.79523 3.21744 9.36079 3.09566C8.92623 2.97388 8.47267 2.913 8.00012 2.913C6.59056 2.913 5.39034 3.40727 4.39945 4.39583C3.40856 5.38439 2.91312 6.58705 2.91312 8.00383C2.91312 9.42061 3.40856 10.622 4.39945 11.608C5.39034 12.594 6.59056 13.087 8.00012 13.087C8.37479 13.087 8.73779 13.0472 9.08912 12.9677C9.44045 12.8882 9.77579 12.7746 10.0951 12.6268L11.3175 13.8493C10.8257 14.1286 10.3026 14.3435 9.74829 14.4942C9.19395 14.6449 8.61179 14.7203 8.00179 14.7203ZM12.1835 13.4167V11.4167H10.1835V9.78333H12.1835V7.78333H13.8168V9.78333H15.8168V11.4167H13.8168V13.4167H12.1835ZM7.05512 11.0717L4.23062 8.24716L5.35162 7.11666L7.07178 8.837L13.6066 2.30216L14.7205 3.416L7.05512 11.0717Z" fill={color || colors.font70} />
    </g>
  </svg>
);
Unprocessed.propTypes = {
  color: string
};
export const UnprocessedOld = ({ color = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path d="M1.53456 7.5C1.53456 5.71224 2.31719 4.10862 3.54483 2.98839L4.71877 4.16233C4.95663 4.40019 5.37096 4.23139 5.37096 3.88611V0.594482C5.37096 0.379643 5.20216 0.210842 4.98732 0.210842H1.69569C1.35041 0.210842 1.18161 0.625173 1.42714 0.863029L2.4553 1.89886C0.951427 3.29531 0 5.28256 0 7.5C0 11.1446 2.54737 14.1984 5.95409 14.981C6.43748 15.0884 6.90552 14.7278 6.90552 14.229C6.90552 13.8684 6.65232 13.5615 6.29937 13.4771C3.57552 12.8556 1.53456 10.4157 1.53456 7.5ZM15.3456 7.5C15.3456 3.85542 12.7982 0.801647 9.39151 0.0190217C8.90812 -0.0883975 8.44008 0.272224 8.44008 0.770956C8.44008 1.13158 8.69328 1.43849 9.04623 1.52289C11.7701 2.14439 13.811 4.58434 13.811 7.5C13.811 9.28776 13.0284 10.8914 11.8008 12.0116L10.6268 10.8377C10.389 10.5998 9.97464 10.7686 9.97464 11.1139V14.4055C9.97464 14.6204 10.1434 14.7892 10.3583 14.7892H13.6499C13.9952 14.7892 14.164 14.3748 13.9185 14.137L12.8903 13.1011C14.3942 11.7047 15.3456 9.71744 15.3456 7.5Z" fill={color || colors.font70} />
  </svg>
);

UnprocessedOld.propTypes = {
  color: string
};
