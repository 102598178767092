/* eslint-disable react/prop-types */
import { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Icon } from 'Components';
import colors from 'styles/_colors.scss';
import classes from './EditableLabel.module.scss';

const EditableLabel = ({ dataItem, operation, onChange, onRemove, onChangeError, error }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const operationLength = operation?.length;
    if (operationLength > 0 && inputRef.current) {
      const lastItem = operation[operationLength - 1];
      dataItem.value === lastItem.value && inputRef.current.focus();
    }
  }, [dataItem]);

  const handleEditChange = useCallback(
    (e) => {
      if (e.type === 'blur' || e?.key === 'Enter') {
        const inputValue = inputRef?.current?.value;
        if (!inputValue) return;
        if (inputValue.length < 2) {
          onChangeError('MIN_SYMBOLS');
          return;
        }
        const hasValue = operation.some(item => item?.value === inputValue);
        if (!hasValue) {
          const index = operation.findIndex(el => el.value === dataItem.value);
          const newOperations = [...operation];
          newOperations[index] = { label: inputValue, value: inputValue };
          error && onChangeError('');
          onChange(newOperations);
        } else {
          onChangeError('ALREADY_ADDED');
        }
      }
    },
    [dataItem, onChange, onChangeError, error]
  );

  const EditableLabelComponent = useCallback(
    () => (
      <div id={dataItem?.value} className={classes.selectedItem}>
        {dataItem?.value?.includes('example') ? (
          <input
            type="text"
            placeholder={dataItem?.label || ''}
            className={cn(classes.multiInput)}
            id={dataItem?.value}
            onBlur={handleEditChange}
            onKeyDown={(e) => {
              e?.key === 'Enter' && handleEditChange(e);
              error && onChangeError('');
              inputRef.current.style.width = inputRef?.current?.value ? `${(inputRef.current.value.length + 1.5) * 8}px` : '124px';
            }}
            onClick={() => inputRef.current.focus()}
            ref={inputRef}
          />
        ) : (
          <div
            id={dataItem?.value}
            className={cn(classes.multiLabel)}
          >
            {dataItem?.value}
          </div>
        )}
        <Icon
          type="close_arrow"
          color={colors.font400}
          width={8}
          height={8}
          className={classes.btnClose}
          onClick={onRemove}
        />
      </div>
    ),
    [handleEditChange]
  );
  return <EditableLabelComponent />;
};
export default EditableLabel;
