import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Review({ width = 14, height = 14, color = colors.orange500, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none">
      <svg className={className} width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.04998 9.49998L7.99998 8.31665L9.94998 9.49998L9.43331 7.28331L11.1666 5.78331L8.88331 5.59998L7.99998 3.49998L7.11665 5.59998L4.83331 5.78331L6.56665 7.28331L6.04998 9.49998ZM1.33331 14.6666V2.66665C1.33331 2.29998 1.46387 1.98609 1.72498 1.72498C1.98609 1.46387 2.29998 1.33331 2.66665 1.33331H13.3333C13.7 1.33331 14.0139 1.46387 14.275 1.72498C14.5361 1.98609 14.6666 2.29998 14.6666 2.66665V10.6666C14.6666 11.0333 14.5361 11.3472 14.275 11.6083C14.0139 11.8694 13.7 12 13.3333 12H3.99998L1.33331 14.6666ZM3.43331 10.6666H13.3333V2.66665H2.66665V11.4166L3.43331 10.6666Z" fill={color || colors.font300} />
      </svg>
    </svg>
  );
}

Review.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
