import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function DownloadIcon({ width = 16, height = 17, color = colors.darkIconColor, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '16'} height={height || '17'} viewBox="0 0 16 17" fill="none">
      <mask id="mask0_581_34948" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
        <rect y="0.5" width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_581_34948)">
        <path d="M3.69524 13.5866H12.2981C12.5244 13.5866 12.7179 13.6657 12.8786 13.824C13.0392 13.9823 13.1196 14.1742 13.1196 14.3998C13.1196 14.6252 13.0401 14.8183 12.8812 14.979C12.7224 15.1396 12.5297 15.22 12.3032 15.22H3.70041C3.47407 15.22 3.28057 15.1408 3.11991 14.9825C2.95924 14.8242 2.87891 14.6324 2.87891 14.4068C2.87891 14.1812 2.95835 13.9881 3.11724 13.8275C3.27613 13.6669 3.4688 13.5866 3.69524 13.5866ZM7.99757 11.8808C7.87835 11.8808 7.76513 11.8566 7.65791 11.8083C7.55068 11.76 7.45552 11.6844 7.37241 11.5815L4.00357 7.3293C3.78524 7.06019 3.74768 6.77374 3.89091 6.46996C4.03413 6.16607 4.28063 6.01413 4.63041 6.01413H5.56591V2.59596C5.56591 2.36952 5.64541 2.1768 5.80441 2.0178C5.9633 1.8588 6.15602 1.7793 6.38257 1.7793H9.61591C9.84246 1.7793 10.0352 1.8588 10.1941 2.0178C10.3531 2.1768 10.4326 2.36952 10.4326 2.59596V6.01413H11.3681C11.7179 6.01413 11.9644 6.16607 12.1076 6.46996C12.2508 6.77374 12.2132 7.06019 11.9949 7.3293L8.62607 11.5815C8.54241 11.6844 8.44657 11.76 8.33857 11.8083C8.23046 11.8566 8.1168 11.8808 7.99757 11.8808Z" fill={color || colors.darkIconColor} />
      </g>
    </svg>
  );
}

DownloadIcon.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
