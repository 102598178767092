import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import colors from 'styles/_colors.scss';
import classes from './SkeletonSelectedFiltersPanel.module.scss';

export default function SkeletonSelectedFiltersPanel() {
  return (
    <Skeleton
      count={7}
      baseColor={colors.fontBody}
      highlightColor={colors.purpleA100}
      className={classes.skeletonFilters}
      containerClassName={classes.skeletonFiltersWrapper}
      inline
    />
  );
}
