import { useGetCharts } from 'storage/queryHooks';
import { useDispatch, useSelector } from 'react-redux';
import { Icons } from 'Components';
import { number, string, func, bool, shape } from 'prop-types';
import t from 'utils/translateFunc';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { toggleMention } from 'storage/news/newsReducer';
import colors from 'styles/_colors.scss';
import ProgressBarMention from './ProgressBarMention';
import classes from './MentionsList.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';

export default function KeywordMentionHeader({ keyword = {}, language = 'uk', onToggle = () => null, isOpened = false }) {
  const id = keyword.id;
  const scale = { transform: 'scaleY(-1)' };
  const dispatch = useDispatch();
  const { newsList, chekedMentions } = useSelector(state => state?.news);

  const { data, isLoading, isFetching } = useGetCharts({ id });

  const isSelectMention = () => !!chekedMentions.length && chekedMentions?.some(el => Number(el.keyword_id) === Number(id));

  function onChangeMention({ target }) {
    const checkedArticles = !!newsList?.length && newsList.find(el => el.keyword.id === Number(target.id))?.articles
      .map(elem => ({ id: elem?.id, keyword_id: Number(target.id), keyword_name: target.value }));
    dispatch(toggleMention({ keyword_id: +target.id, keyword_name: target.value, checked: target.checked, articles: checkedArticles }));
  }

  return (
    <>
      <div className={cn(classes.articles_header)}>
        <label htmlFor={id} className={classes.checkboxLabel}>
          <input
            type="checkbox"
            className={classes.visually_hidden}
            onChange={onChangeMention}
            value={keyword.name}
            id={id}
          />
          <div
            className={cn(
              classes.checkbox,
              isSelectMention() && classes.checkbox_selected
            )}
          >
            {isSelectMention() && (<Icons icon="check" />)}
          </div>
        </label>
        <span className={classes.keywordName}>{keyword.name}</span>
        <div className={classes.counts}>
          {(!isLoading && !isFetching) ? (
            <>
              <span>
                {data?.all_count || 0}
                {t('mentions', 'TOTAL', language)}
                /
              </span>
              <span>
                {data?.originals_count || 0}
                {t('mentions', 'ORIGINALS', language)}
              </span>
            </>
          ) : <Skeleton className={classes.skeletonCounts} baseColor={colors.fontBody} highlightColor={colors.purpleA100} />}
          <button
            className={cn(classes.option, isOpened && classes.option_open)}
            onClick={() => onToggle(id, isOpened)}
            type="button"
          >
            <Icons
              icon="icn_Arrow-2_nofill"
              className={classes.iconArrow}
              style={isOpened && scale}
            />
          </button>
        </div>
      </div>

      <ProgressBarMention data={data} isLoading={isLoading} language={language} keyword={keyword} />
    </>
  );
}

KeywordMentionHeader.propTypes = {
  keyword: shape({
    id: number.isRequired,
    name: string.isRequired
  }),
  language: string,
  isOpened: bool,
  onToggle: func
};
