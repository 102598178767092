import { Suspense, lazy, useMemo, useEffect, useState } from 'react';
import { Animated, Checkbox, Button, SelectCustom, SkeletonSearchArticle } from 'Components';
import { array, bool, func, number, string } from 'prop-types';
import t from 'utils/translateFunc';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import classes from './SearchResultsArticles.module.scss';
import { stylesOrdering, stylesTabs } from './helpers';

const SearchArticleCard = lazy(() => import('../SearchArticleCard'));

function SearchResultsArticles({ eventFunc = () => null, show = false, isOperator = false, pageSize = 20, ordering = '-created_date',
  onToggleFavoriteMany = () => null, onChangePageSize = () => null, onChangeOrdering = () => null, onChangeCheckedArticles = () => null,
  articles = [], checkedArticles = [], isSocial = false, isFavoriteTab = false, isFetching = false, isScrollingUp = false }) {
  const checkedAll = !!articles?.length && !!checkedArticles?.length
  && isEqual(articles.map(({ id }) => ({ id })), checkedArticles.map(({ solr_id }) => ({ id: solr_id })));
  const isMobile = useCheckMobileScreen();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (isFetching && articles?.length > 2) {
      const el_id = isScrollingUp ? null : `search-${articles[articles.length - 1]?.id}`;
      const el = document.getElementById(el_id);
      if (el) el.scrollIntoView(isScrollingUp);
      setIsScrolled(true);
    } else if (isScrolled && articles?.length > 2) {
      const el_id_up = articles?.length >= pageSize ? `search-${articles[pageSize - 2]?.id}` : `search-${articles[0]?.id}`;
      const el_id_down = articles?.length >= pageSize ? `search-${articles[articles.length - pageSize]?.id}` : `search-${articles[articles.length - 1]?.id}`;
      const el_id = isScrollingUp ? el_id_up : el_id_down;
      const el = document.getElementById(el_id);
      if (el) el.scrollIntoView(isScrollingUp);
      setIsScrolled(false);
    } else {
      setIsScrolled(false);
    }
  }, [isFetching]);

  function isSelectedId(id) {
    if (!!checkedArticles.length && checkedArticles?.some(item => item.solr_id === id)) return true;
    return false;
  }

  const needRemove = useMemo(() => {
    const removIds = checkedArticles?.filter(item => !!item?.favorited);
    const addIds = checkedArticles.filter(item => !item?.favorited);
    return addIds?.length < removIds?.length;
  });

  function onToggleAll() {
    eventFunc('Click_Checkbox_All_Articles');
    onChangeCheckedArticles(checkedAll ? [] : articles.map(({ id, created, date, favorited }) => ({ solr_id: id, created_date: created, date, from_lsn: isSocial, favorited })));
  }

  function onToggleArticle({ id, created, date, favorited }) {
    const checkedItem = { solr_id: id, created_date: created, date, from_lsn: isSocial, favorited };
    eventFunc('Click_Checkbox_Article');
    if (checkedArticles?.length) {
      const isChecked = checkedArticles.some(item => item.solr_id === id);
      onChangeCheckedArticles(isChecked ? checkedArticles.filter(item => item?.solr_id !== id) : checkedArticles.concat(checkedItem));
    } else {
      onChangeCheckedArticles(checkedArticles.concat(checkedItem));
    }
  }

  const optionsOrdering = [
    { value: '-created_date', label: t('ordering', 'BY_DATE_NEWEST_FIRST') },
    { value: 'created_date', label: t('ordering', 'BY_DATE_OLDEST_FIRST') },
    { value: '-duplicates_count', label: t('ordering', 'DUBLICATES') },
    { value: '-source_attendance', label: t('ordering', 'SOURCE_ATTENDANCE') }
  ];
  const optionsOffset = [
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '200', label: '200' }
  ];
  const orderingValue = optionsOrdering.find(el => el.value === ordering);
  const offsetValue = optionsOffset.find(el => el.value === String(pageSize));

  function isShowHeader() {
    if (articles?.length) {
      return articles[0]?.cursor?.sn_position === 0 || articles[0]?.cursor?.cm_position === 0;
    }
    return false;
  }

  return (
    <Animated visible={!!show} duration={300} className={classes.searchArticlesWrap}>
      {isShowHeader() && (
        <div className={classes.searchArticlesHeader}>
          <div className={cn(classes.checkboxHeaderContainer)}>
            <Checkbox isSelected={checkedAll} size={20} isSelectedSome={!checkedAll && !!checkedArticles?.length} onChange={onToggleAll} />
            {!!checkedArticles?.length && (
              <div>
                {t('search', 'RESULTS_SELECTED')}
                &nbsp;&ndash;&nbsp;
                {checkedAll ? articles.length : checkedArticles.length}
              </div>
            )}
          </div>
          <div className={classes.buttonBox}>
            {!!checkedArticles?.length && (
              <Button
                onClick={() => {
                  onToggleFavoriteMany();
                  eventFunc((needRemove || isFavoriteTab) ? 'Click_Remove_All_From_Favorite' : 'Click_Add_All_To_Favorite');
                }}
                textButton={t('search', (needRemove || isFavoriteTab) ? 'REMOVE_FAVORITE' : 'ADD_FAVORITE')}
                purpleTransparent32
              />
            )}
            {/* {!!checkedArticles?.length && (
          <Button
            onClick={() => { }}
            textButton={t('btn', 'Завантажити')}
            purpleTransparent32
          />
          )} */}
            <SelectCustom
              value={offsetValue}
              onChange={({ value }) => {
                eventFunc('Change_Page_Size', { size: value });
                onChangePageSize(value);
              }}
              options={optionsOffset}
              className={classes.selectOffset}
              styles={stylesTabs(isMobile)}
              isRadio
            />
            <SelectCustom
              value={orderingValue}
              onChange={({ value }) => {
                eventFunc('Change_Page_Ordering', { ordering: value });
                onChangeOrdering(value);
              }}
              options={optionsOrdering}
              className={classes.select}
              styles={stylesOrdering(isMobile)}
              isRadio
            />
          </div>
        </div>
      )}
      <div className={classes.searchArticlesList}>
        {(isFetching && isScrollingUp) && <SkeletonSearchArticle />}
        {!!articles?.length && articles.map((article, i) => (
          <Suspense fallback={<SkeletonSearchArticle />} key={i}>
            <SearchArticleCard
              article={article}
              isOperator={isOperator}
              isSelectedId={isSelectedId}
              onToggleArticle={onToggleArticle}
              articleNumber={(article?.cursor?.cm_position || article?.cursor?.sn_position || 0) + 1}
              eventFunc={eventFunc}
            />
          </Suspense>
        ))}
        {(isFetching && !isScrollingUp) && <SkeletonSearchArticle />}
      </div>
    </Animated>
  );
}

SearchResultsArticles.propTypes = {
  show: bool,
  ordering: string,
  articles: array,
  checkedArticles: array,
  onChangePageSize: func,
  onChangeOrdering: func,
  onToggleFavoriteMany: func,
  onChangeCheckedArticles: func,
  pageSize: number,
  isSocial: bool,
  isOperator: bool,
  isFavoriteTab: bool,
  isFetching: bool,
  eventFunc: func,
  isScrollingUp: bool
};

export default SearchResultsArticles;
