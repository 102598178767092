import { useState, useMemo, useEffect } from 'react';
import { oneOfType, func, shape, bool, string, arrayOf } from 'prop-types';
import { Icon, SelectCustom, AnimatedTab } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import isEqual from 'lodash/isEqual';
import { sendAlertsEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import classes from '../ReportDetails.module.scss';

const WhatsappComponent = ({ dataWhatsappUsers = [], whatsappData = null, onOpenModal = () => null, error = false, onChange = () => null }) => {
  const [whatsappUsers, setWhatsappUsers] = useState([]);
  const [whatsappAccounts, setWhatsappAccounts] = useState(null);

  useEffect(() => {
    !isEqual(dataWhatsappUsers, whatsappUsers) && setWhatsappUsers(dataWhatsappUsers);
    !isEqual(whatsappAccounts?.accounts, whatsappAccounts) && setWhatsappAccounts(whatsappData?.accounts);
  }, [whatsappData, dataWhatsappUsers]);

  useEffect(() => {
    const handler = setTimeout(() => {
      !isEqual(whatsappUsers, dataWhatsappUsers) && onChange(whatsappUsers);
    }, 50);
    return () => { clearTimeout(handler); };
  }, [whatsappUsers]);

  const optionsWhatsapp = useMemo(() => (whatsappAccounts?.length
    ? whatsappAccounts.reduce((acc, curr) => (whatsappUsers.some(el => el?.id === curr?.id) ? acc : acc.concat({ value: curr?.id, label: curr?.phone })), [])
    : []), [whatsappAccounts, whatsappUsers]);

  function openSelectWhatsappModal() {
    sendAlertsEvent({ category: 'Recipient WA', event: 'Clicked_Add_Recipient_Button' });
    onOpenModal({ action: 'addWhatsappPhone', info: 'report' });
  }

  const styles = {
    container: provided => ({
      ...provided,
      border: colors.borderWhite,
      borderRadius: '4px',
      ...(error ? {
        borderColor: colors.red500
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: error ? colors.red500 : colors.grey600,
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    })
  };
  return (
    <AnimatedTab>
      <div className={cn(
        classes.deliveryWhatsapp,
        (!whatsappUsers?.length && !optionsWhatsapp?.length) && classes.deliveryWhatsapp_empty
      )}
      >
        {!!whatsappAccounts?.length && (
        <SelectCustom
          value={null}
          placeholder={t('reports', 'SELECT_ACCOUNTS_TELEGRAM')}
          onChange={({ value, label }) => { setWhatsappUsers([...whatsappUsers, { id: value, phone: label }]); }}
          options={optionsWhatsapp}
          styles={styles}
        />
        )}
        {(!whatsappUsers?.length && !whatsappAccounts?.length) && !optionsWhatsapp?.length && (
        <div className={cn(classes.whatsappNoUsers, error && classes.whatsappNoUsers_error)}>
          <div className={classes.whatsappBigImage} />
          <div className={classes.whatsappText}>
            {t('reports', PROJECT === 'metricom' ? 'GET_REPORTS_WHATSAPP_M' : 'GET_REPORTS_WHATSAPP') }
          </div>
        </div>
        )}
        {!!whatsappUsers?.length && whatsappUsers.map(itemWhatsapp => (
          <div className={classes.whatsappWrap} key={itemWhatsapp?.id}>
            <div className={classes.whatsappImage} />
            <div className={classes.whatsappNameWrap}>
              <div className={classes.whatsappTitle}>
                {t('btn', 'PRIVATE_MESSAGE')}
              </div>
              <div className={classes.whatsappName}>
                {itemWhatsapp?.phone}
              </div>
            </div>
            {!itemWhatsapp?.confirmed && <div className={classes.spinner} />}
            <div
              className={classes.deleteIcon}
              onClick={() => {
                sendAlertsEvent({ category: 'Recipient WA', event: 'Clicked_Delete_Recipient' });
                setWhatsappAccounts([...whatsappAccounts, itemWhatsapp]);
                setWhatsappUsers(whatsappUsers.filter(item => item?.id !== itemWhatsapp?.id));
              }}
            >
              <Icon type="delete" width={16} height={18} color={colors.font70} />
            </div>
          </div>
        ))}

        <div className={classes.whatsappButtonBox}>
          <div className={classes.purpleButton} onClick={openSelectWhatsappModal}>
            {t('btn', 'ADD_RECIPIENT')}
          </div>
        </div>
      </div>
    </AnimatedTab>
  );
};

WhatsappComponent.propTypes = {
  dataWhatsappUsers: arrayOf(shape({})),
  whatsappData: shape({}),
  error: oneOfType([bool, string]),
  onOpenModal: func,
  onChange: func
};

export default WhatsappComponent;
