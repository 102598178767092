import { useState, useMemo, useRef } from 'react';
import { CheckboxGroup, Icon } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { CSSTransition } from 'react-transition-group';
import { shape, func, arrayOf } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from '../TemplateDrawer.module.scss';

function ContentComponent({ onChange = () => null, data = null, customPages = [], dashboardPages = [] }) {
  const [isOpenCP, setIsOpenCP] = useState(true);
  const [isOpenDP, setIsOpenDP] = useState(true);
  const pagesRef = useRef();
  const dashboardsRef = useRef();
  const customPc = useMemo(() => customPages.map(({ charts }) => charts.map(({ id }) => id)).flat().toSorted(), [customPages]);
  const customPcSelected = data?.custom_chart || [];
  const customPcSelectedFilered = useMemo(() => ((customPcSelected?.length && customPc?.length)
    ? customPcSelected?.filter(selectId => customPc.find(id => id === selectId)).toSorted() : []), [customPcSelected, customPc]);
  const isSelectAllCustoms = isEqual(customPc, customPcSelectedFilered);
  const isSelectSomeCustoms = !!customPc?.length && !!customPcSelectedFilered?.length && customPc.length !== customPcSelectedFilered.length;
  const checkedCP = !!isSelectAllCustoms || !!isSelectSomeCustoms;

  const dashboardPc = useMemo(() => dashboardPages.map(({ charts }) => charts.map(({ id }) => id)).flat().toSorted(), [dashboardPages]);
  const dashboardPcSelected = data?.dashboard_chart || [];
  const dashboardPcSelectedFilered = useMemo(() => ((dashboardPcSelected?.length && dashboardPc?.length)
    ? dashboardPcSelected?.filter(selectId => dashboardPc.find(id => id === selectId)).toSorted() : []), [dashboardPcSelected, dashboardPc]);
  const isSelectAllDc = isEqual(dashboardPc, dashboardPcSelectedFilered);
  const isSelectSomeDc = !!dashboardPc?.length && !!dashboardPcSelectedFilered?.length && dashboardPc.length !== dashboardPcSelectedFilered.length;
  const checkedDp = !!isSelectAllDc || !!isSelectSomeDc;

  const onToggleAllCp = () => {
    onChange({ custom_chart: isSelectAllCustoms ? [] : customPc });
  };
  const onToggleAllDp = () => {
    onChange({ dashboard_chart: isSelectAllDc ? [] : dashboardPc });
  };

  return (
    <div className={classes.contentComponent}>
      {/* <div className={classes.contentHeader}>
        <div className={classes.contentTitle}>{t('reports', 'CHART_SELECTION')}</div>
        <div className={classes.contentSubtile}>{ t('reports', 'HOVER_AND_PREVIEW')}</div>
      </div> */}
      <div className={classes.pagesWrap}>
        <div className={classes.checkboxGroupName}>
          <button
            className={cn(
              classes.openButton,
              isOpenCP && classes.openButton_open,
              !customPc?.length && classes.openButton_disabled
            )}
            onClick={() => { !!customPc?.length && setIsOpenCP(!isOpenCP); }}
            type="button"
          >
            <Icon type="arrow_down" color={colors.font70} />
          </button>
          <label className={classes.checkboxLabel}>
            <input
              className={classes.visually_hidden}
              type="checkbox"
              disabled={!customPages?.length}
              checked={checkedCP}
              onChange={onToggleAllCp}
            />
            <div className={cn(
              classes.checkbox,
              checkedCP && classes.checkbox_selected,
              !customPc?.length && classes.checkbox_disabled
            )}
            >
              {customPcSelectedFilered && (<Icon type={isSelectAllCustoms ? 'checked' : 'checked_minus'} className={classes.iconCheck} />)}
            </div>
            {t('reports', 'CUSTOM_PAGES')}
          </label>
        </div>
        <div className={cn(classes.chartGroups, !isOpenCP && classes.chartGroups_close)}>
          <CSSTransition
            in={isOpenCP}
            timeout={350}
            unmountOnExit
            nodeRef={pagesRef}
          >
            <>
              {!!customPages?.length && customPages.map(page => (
                <CheckboxGroup
                  key={page?.id}
                  groupName={page?.name}
                  selectedList={data?.custom_chart || []}
                  dataList={page?.charts || []}
                  onEditData={(value) => { onChange({ custom_chart: value }); }}
                />
              ))}
            </>
          </CSSTransition>
        </div>
      </div>
      <div className={classes.pagesWrap}>
        <div className={classes.checkboxGroupName}>
          <button
            className={cn(
              classes.openButton,
              isOpenDP && classes.openButton_open,
              !dashboardPc?.length && classes.openButton_disabled
            )}
            onClick={() => { !!dashboardPc?.length && setIsOpenDP(!isOpenDP); }}
            type="button"
          >
            <Icon type="arrow_down" color={colors.font70} />
          </button>
          <label className={classes.checkboxLabel}>
            <input
              className={classes.visually_hidden}
              type="checkbox"
              disabled={!customPages?.length}
              checked={checkedDp}
              onChange={onToggleAllDp}
            />
            <div className={cn(
              classes.checkbox,
              checkedDp && classes.checkbox_selected,
              !dashboardPc?.length && classes.checkbox_disabled
            )}
            >
              {dashboardPcSelectedFilered && (
              <Icon
                type={isSelectAllDc ? 'checked' : 'checked_minus'}
                className={classes.iconCheck}
              />
              )}
            </div>
            {t('reports', 'DASHBOARDS')}
          </label>
        </div>
        <div className={cn(classes.chartGroups, !isOpenDP && classes.chartGroups_close)}>
          <CSSTransition
            in={isOpenDP}
            timeout={350}
            unmountOnExit
            nodeRef={dashboardsRef}
          >
            <>
              {!!dashboardPages?.length && dashboardPages.map(page => (
                <CheckboxGroup
                  key={page?.id}
                  groupName={page?.name}
                  selectedList={data?.dashboard_chart || []}
                  dataList={page?.charts || []}
                  onEditData={(value) => { onChange({ dashboard_chart: value }); }}
                />
              ))}
            </>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
}

ContentComponent.propTypes = {
  onChange: func,
  data: shape({}),
  customPages: arrayOf(shape({})),
  dashboardPages: arrayOf(shape({}))
};

export default ContentComponent;
