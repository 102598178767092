import { sendFeedInfo, sendAlertsInfo, sendDownloadsInfo, sendSearchInfo } from 'services/amplitude';

const feedEventList = {
  auth: 'Clicked_Cancel_Button_Profile_Authentication_Settings',
  personal: 'Clicked_Cancel_Button_Profile_Personal_Settings',
  source: 'Clicked_Remove_From_Blocked_Sources_Button',
  creator: 'Clicked_Remove_From_Blocked_Authors_Button',
  place: 'Clicked_Remove_From_Blocked_Places_Button',
  filter_keyword: 'Filtered_By_Keyword',
  filter_tag: 'Filtered_By_Tag',
  filter_country: 'Filtered_By_Country',
  filter_region: 'Filtered_By_Region',
  filter_city: 'Filtered_By_City',
  filter_level: 'Filtered_By_Level',
  filter_source: 'Filtered_By_Source',
  filter_subject: 'Filtered_By_Subject',
  filter_creator: 'Filtered_By_Authors',
  filter_language: 'Filtered_By_Language',
  filter_publication_place: 'Filtered_By_Publication_Place',
  filter_bookmarked: 'Filtered_By_Selected',
  filter_processed: 'Filtered_By_Processed',
  filter_unprocessed: 'Filtered_By_Unprocessed',
  filter_deleted: 'Filtered_By_Deleted',
  tab_dashboards: 'Clicked_Dashboards_Button_Header',
  tab_mentions: 'Clicked_Mentions_Button_Header',
  tab_search: 'Clicked_Search_Button_Header',
  tab_alerts: 'Clicked_Notifications_Button_Header',
  tab_downloads: 'Clicked_Downloads_Button_Header',
  help_help: 'Clicked_Knowledge_Base_In_Dropdown',
  help_blog: 'Clicked_Blog_In_Dropdown',
  help_chat: 'Clicked_Chat_In_Dropdown',
  profile_auth: 'Clicked_Save_Button_Profile_Authentication_Settings',
  profile_personal: 'Clicked_Save_Button_Profile_Personal_Settings',
  profile_close_auth: 'Clicked_Cancel_Button_Profile_Authentication_Settings',
  profile_close_personal: 'Clicked_Cancel_Button_Profile_Personal_Settings',
  language_en: 'Selected_English_Language_In_Profile_Settings',
  language_uk: 'Selected_Ukrainian_Language_In_Profile_Settings',
  language_ru: 'Selected_Russian_Language_In_Profile_Settings',
  language_ro: 'Selected_Romanian_Language_In_Profile_Settings',
  filter_audiences_audience_from: 'Filtered_By_Mentions_From',
  filter_audiences_audience_to: 'Filtered_By_Mentions_To',
  filter_media_type_tv: 'Filtered_By_TV_Source',
  filter_media_type_radio: 'Filtered_By_Radio_Source',
  filter_media_type_press: 'Filtered_By_Press_Source',
  filter_media_type_internet: 'Filtered_By_Internet_Source',
  filter_media_type_socialnetwork: 'Filtered_By_Social_Network_Source',
  filter_media_type_forum: 'Filtered_By_Forum_Source',
  filter_media_type_review: 'Filtered_By_Review_Source',
  filter_media_type_blog: 'Filtered_By_Blog_Source',
  filter_media_type_agency: 'Filtered_By_News_Agency_Source',
  filter_tone_neutral: 'Filtered_By_Neutral_Sentiment',
  filter_tone_negative: 'Filtered_By_Negative_Sentiment',
  filter_tone_positive: 'Filtered_By_Positive_Sentiment',
  filter_character_subject: 'Filtered_By_Main_Role',
  filter_character_secondary: 'Filtered_By_Secondary_Role',
  filter_character_object: 'Filtered_By_Episodic_Role',
  filter_post_type_post: 'Filtered_By_Mention_Type_Post',
  filter_post_type_repost: 'Filtered_By_Mention_Type_Repost',
  filter_post_type_extendedRepost: 'Filtered_By_Mention_Type_Repost_With_Addition',
  filter_post_type_comment: 'Filtered_By_Mention_Type_Comment',
  filter_post_type_replyComment: 'Filtered_By_Mention_Type_Reply_To_Comment',
  filter_post_type_not_defined: 'Filtered_By_Mention_Type_Not_Specified',
  order_date: 'Sorted_By_Date_Oldest_First',
  template_company_search: 'Clicked_Template_Search_Company',
  template_person_search: 'Clicked_Template_Search_Person',
  template_no_template: 'Clicked_No_Template',
  keyword_lastWeek: 'Selected_Keyword_Query_Preview_Last_Week',
  keyword_last2Weeks: 'Selected_Keyword_Query_Preview_Last_Two_Weeks',
  tag_lastWeek: 'Selected_Tag_Query_Preview_Last_Week',
  tag_last2Weeks: 'Selected_Tag_Query_Preview_Last_Two_Weeks',
  articles_basic: 'Add keyword Basic QB',
  articles_preview: 'Clicked_News_Title_in_Preview',
  article_link_copyLink: 'Clicked_Copy_Post_Link_Button',
  article_link_filterMentions: 'Filter_By_Source_Button_Clicked',
  article_link_blockSource: 'Block_Source_Button_Clicked',
  article_link_showCard: 'Show_Source_Card_Button_Clicked',
  article_link_reportProblem: 'Open_Report_A_Problem_Modal',
  article_link_positive_changeTone: 'Change_Mention_Tone_To_Positive_Button_Clicked',
  article_link_negative_changeTone: 'Change_Mention_Tone_To_Negative_Button_Clicked',
  article_link_neutral_changeTone: 'Change_Mention_Tone_To_Neutral_Button_Clicked',
  article_link_positive_changeToneDetails: 'Change_Mention_Tone_To_Positive_Button_Clicked',
  article_link_negative_changeToneDetails: 'Change_Mention_Tone_To_Negative_Button_Clicked',
  article_link_neutral_changeToneDetails: 'Change_Mention_Tone_To_Neutral_Button_Clicked',
  article_link_subject_changeRole: 'Change_Mention_Role_To_Main_Button_Clicked',
  article_link_secondary_changeRole: 'Change_Mention_Role_To_Secondary_Button_Clicked',
  article_link_object_changeRole: 'Change_Mention_Role_To_Episodic_Button_Clicked',
  article_link_true_changeProcessed: 'Add_To_Processed_Button_Clicked',
  article_link_false_changeProcessed: 'Add_To_Unprocessed_Button_Clicked',
  block_source_creators: 'Blocked_Author_Button_Clicked',
  block_source_publication_place: 'Blocked_Publication_Place_Button_Clicked',
  block_source_source: 'Block_Source_Button_Clicked_In_Source_Card',
  filter_mentions_creators: 'Show_Mentions_Button_Clicked',
  filter_mentions_publication_place: 'Show_Mentions_Button_Clicked',
  filter_mentions_source: 'Filter_By_Source_Button_Clicked_In_Source_Card',
  article_menu_creator: 'Clicked_On_Creator_In_Newsfeed',
  article_menu_publication_place: 'Clicked_On_Publication_Place_In_Newsfeed',
  article_count_creator: 'Clicked_Author_News_Count_Button_In_Modal',
  article_count_publication_place: 'Clicked_Place_Of_Publication_News_Count_Button_In_Modal',
  footer_true_changeFavorite: 'Add_To_Favorites_Button_Clicked_In_Fulltext',
  footer_false_changeFavorite: 'Remove_From_Favorites_Button_Clicked_In_Fulltext',
  footer_false_deleteArticle: 'Delete_Button_Clicked',
  footer_true_changeProcessed: 'Add_To_Processed_Button_Clicked_In_Fulltext',
  footer_false_changeProcessed: 'Add_To_Unprocessed_Button_Clicked_In_Fulltext',
  operator_AND: 'Clicked_AND_Operator_Button',
  subscribe_emails: 'Subscribe_Duplicate_Mentions_Click_By_Email',
  subscribe_telegram_users: 'Subscribe_Duplicate_Mentions_Click_By_Telegram',
  'order_-date': 'Sorted_By_Date_Newest_First',
  'order_-views_count': 'Sorted_By_Views_Count',
  'order_-duplicates': 'Sorted_By_Duplicates_Count',
  'order_-source__attendance': 'Sorted_By_Source_Audience',
  'order_-source__average_attendance': 'Sorted_By_Source_Average_Views',
  'order_-lsn__creators_subscribers_count': 'Sorted_By_Author_Audience',
  'order_-lsn__publication_place_subscribers_count': 'Sorted_By_Publication_Place_Audience',
  'order_-lsn__potential_reach': 'Sorted_By_Potential_Reach',
  'order_-lsn__engagement': 'Sorted_By_Engagement',
  default: ''
};

const downloadsEventList = {
  sort_by_sent_at: 'Clicked_Sorting_By_Date',
  sort_by_file_type: 'Clicked_Sorting_Formats_By_Alphabet',
  sort_by_status: 'Clicked_Sorting_Statuses_By_Alphabet',
  filter_file_: 'Filtered_By_All_File_Formats',
  filter_file_pdf: 'Filtered_By_PDF_Format',
  filter_file_html: 'Filtered_By_HTML_Format',
  filter_file_docx: 'Filtered_By_DOCX_Format',
  filter_file_xls: 'Filtered_By_XLS_Format',
  filter_status_: 'Filtered_By_All_Statuses',
  filter_status_finished: 'Filtered_By_Done_Status',
  filter_status_failed: 'Filtered_By_Error_Status',
  filter_status_processing: 'Filtered_By_Forming_Status',
  'filter_status_in queue': 'Filtered_By_Pending_Status',
  default: ''
};

const alertsEventList = {
  template_step_main: 'Clicked_Main_Section',
  template_step_content: 'Clicked_Chart_Selection_Section',
  template_step_display: 'Clicked_Display_Section',
  template_step_preview: 'Clicked_Preview_Section',
  delete_alert: 'Clicked_Delete_Button_In_Modal_Remove_Alert',
  delete_report: 'Clicked_Delete_Button_In_Modal_Edit_Report',
  delete_template: 'Clicked_Confirm_Button',
  builder_simple: 'Selected_Basic_Query_Builder_For_Add_Query',
  builder_advanced: 'Selected_Advanced_Query_Builder_For_Add_Search_Query',
  template_company_search: 'Clicked_Template_Search_Company',
  template_person_search: 'Clicked_Template_Search_Person',
  template_no_template: 'Clicked_No_Template',
  alert_lastWeek: 'Selected_Keyword_Query_Preview_Last_Week',
  alert_last2Weeks: 'Selected_Keyword_Query_Preview_Last_Two_Weeks',
  'edit_alert_alerts-simple': 'Clicked_Edit_Alert_Button',
  'edit_alert_alerts-negative': 'Clicked_Edit_Alert_By_Negative_Button',
  'edit_alert_alerts-activity': 'Clicked_Edit_Alert_By_Activity_Button',
  'add_alert_alerts-simple': 'Clicked_Create_An_Alert_Button_In_Alerts',
  'add_alert_alerts-negative': 'Clicked_Create_An_Alert_Button_In_Alerts_By_Negative',
  'add_alert_alerts-activity': 'Clicked_Create_An_Alert_Button_In_Alerts_By_Activity',
  'cancel_add_alert_alerts-simple': 'Clicked_Cancel_Button_In_Alerts',
  'cancel_add_alert_alerts-negative': 'Clicked_Cancel_Button_In_Alerts_By_Negative',
  'cancel_add_alert_alerts-activity': 'Clicked_Cancel_Button_In_Alerts_By_Activity',
  'cancel_edit_alert_alerts-simple': 'Clicked_Cancel_Button_In_Modal_Edit_Alert',
  'cancel_edit_alert_alerts-negative': 'Clicked_Cancel_Button_In_Modal_Edit_Alert_By_Negative',
  'cancel_edit_alert_alerts-activity': 'Clicked_Cancel_Button_In_Modal_Edit_Alert_By_Activity',
  'cancel_confirm_edit_alert_alerts-simple': 'Clicked_Cancel_Changes_Button_In_Modal_Edit_Alert',
  'cancel_confirm_edit_alert_alerts-negative': 'Clicked_Cancel_Changes_Button_In_Modal_Edit_Alert_By_Negative',
  'cancel_confirm_edit_alert_alerts-activity': 'Clicked_Cancel_Changes_Button_In_Modal_Edit_Alert_By_Activity',
  'confirm_edit_alert_alerts-simple': 'Clicked_Confirm_Changes_Button_In_Modal_Edit_Alert',
  'confirm_edit_alert_alerts-negative': 'Clicked_Confirm_Changes_Button_In_Modal_Edit_Alert_By_Negative',
  'confirm_edit_alert_alerts-activity': 'Clicked_Confirm_Changes_Button_In_Modal_Edit_Alert_By_Activity',
  'scroll_alerts-simple-cards': 'Clicked_Alerts_Button',
  'scroll_alerts-negative-cards': 'Clicked_By_Negative_Button',
  'scroll_alerts-activity-cards': 'Clicked_By_Activity_Button',
  default: ''
};
const searchEventList = { default: '' };

const categoryList = {
  article_link_copyLink: 'Article',
  article_link_filterMentions: 'Filters',
  article_link_blockSource: 'News feed settings',
  article_link_showCard: 'Article',
  article_link_reportProblem: 'Article',
  article_link_changeTone: 'Article',
  article_link_changeProcessed: 'Article',
  builder_simple: 'Add Query Basic QB',
  builder_advanced: 'Add Query Advanced QB',
  copilot: 'Copilot',
  descendants_tree: 'Publication Context',
  default: ''
};

function sendEvent({ category, event, properties, type }) {
  if (type) {
    if (type === 'alert') {
      sendAlertsEvent({ category, event, properties });
      return null;
    }

    sendFeedEvent({ category, event, properties });
  }

  return null;
}

function sendFeedEvent({ category, event, properties }) {
  category && event && sendFeedInfo({
    category: categoryList[category] || category,
    event: feedEventList[event] || event,
    properties
  });
}

function sendAlertsEvent({ category, event, properties }) {
  category && event && sendAlertsInfo({
    category: categoryList[category] || category,
    event: alertsEventList[event] || event,
    properties
  });
}

function sendDownloadsEvent({ category, event, properties }) {
  category && event && sendDownloadsInfo({
    category,
    event: downloadsEventList[event] || event,
    properties
  });
}

function sendSearchEvent({ category, event, properties }) {
  category && event && sendSearchInfo({
    category: categoryList[category] || category,
    event: searchEventList[event] || event,
    properties
  });
}

export { sendEvent, sendFeedEvent, sendAlertsEvent, sendDownloadsEvent, sendSearchEvent };
