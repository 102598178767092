import { memo, useMemo, useState } from 'react';
import { SelectCustom, Icon } from 'Components';
import { func, shape, oneOfType, array, bool, string } from 'prop-types';
import t from 'utils/translateFunc';
import hasOwn from 'lodash/has';
import useDebounceValue from 'hooks/useDebounce';
import { useSelector } from 'react-redux';
import { useGetSearchSources } from 'storage/queryHooks.js';
import colors from 'styles/_colors.scss';
import { sendSearchEvent } from 'services/amplitude/events';
import classes from '../FiltersList.module.scss';
import OptionPart from '../SelectParts/OptionPart';
import PlaceholderPart from '../SelectParts/PlaceholderPart';
import getOptions from '../options';

const Sources = memo(({ filterName = '', searchTab = 'search', list = [], label = '', placeholder = '', endingPlaceholder = '', onChange = () => null, onOpenModal = () => null, onSortSources = () => null }) => {
  const { customStyles } = getOptions();
  const searchSources = useSelector(state => state?.search?.searchSources);
  const [input, setInput] = useState('');
  const icontains = useDebounceValue(input, 500);
  const { isLoading: sourceLoading, isRefetching: sourceRefetching } = useGetSearchSources(icontains);

  function onChangeSources(newOptions) {
    onChange(newOptions);
  }

  const sourceOptions = useMemo(() => {
    if (searchSources && list) {
      const filteredOptions = searchSources?.filter(({ value }) => !list?.some(item => item?.value === value));
      return list?.concat(filteredOptions)?.toSorted((_, b) => (hasOwn(b, 'isExluse') ? -1 : 1));
    }
    return searchSources;
  }, [searchSources, list]);

  return (
    <div className={classes.selectWrapper}>
      <div className={classes.suggestBox}>
        <label className={classes.seletMenuTitle}>
          {label}
        </label>
        <Icon
          type="add_round"
          role="button"
          btnType={classes.addButton}
          color={colors.font60}
          width={24}
          height={24}
          onClick={() => {
            sendSearchEvent({ category: `Search_Filters_Tab_${searchTab}`, event: 'Open_Propose_Source_Modal' });
            onOpenModal({ action: 'proposeSource', info: null });
          }}
          tooltipText={t('search', 'SUGGEST_SOURCE')}
        />
      </div>
      <SelectCustom
        name={filterName}
        value={list}
        options={searchSources}
        placeholder={placeholder}
        onChange={onChangeSources}
        onMenuClose={() => { onSortSources(sourceOptions); }}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        styles={customStyles}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        endingPlaceholderPart={endingPlaceholder}
        components={{ Option: OptionPart, Placeholder: PlaceholderPart }}
        isLoading={sourceLoading || sourceRefetching}
        inputValue={input}
        onInputChange={setInput}
      />
    </div>
  );
});

Sources.propTypes = {
  searchTab: string,
  filterName: string,
  label: string,
  placeholder: string,
  endingPlaceholder: string,
  onChange: func,
  onOpenModal: func,
  onSortSources: func,
  list: oneOfType([array, bool, shape({})]) };

export default Sources;
