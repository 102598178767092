import { forwardRef, useMemo } from 'react';
import { string, func, shape, bool, number, oneOfType } from 'prop-types';
import { tags } from '@lezer/highlight';
import { languages } from '@codemirror/language-data';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { syntaxTree, syntaxHighlighting, HighlightStyle, StreamLanguage } from '@codemirror/language';
import { linter } from '@codemirror/lint';
import CodeMiror, { EditorView } from '@uiw/react-codemirror';
import isEqual from 'lodash/isEqual';
import elt from 'crelt';
import * as events from '@uiw/codemirror-extensions-events';
import colors from 'styles/_colors.scss';
import { solr } from './solr';
import classes from './CodeMirror.module.scss';

const CodeMirror = forwardRef(({
  onChange = () => null,
  onConfirmEditig = () => null,
  onPressEnter = () => null,
  editable = true,
  code = '',
  errorQuery = '',
  exampleTitle = '',
  assignValue = '',
  errorValidate = null,
  customProps = {},
  customStyles = {}
}, ref) => {
  const errorMessage = useMemo(() => errorValidate?.message || '', [errorValidate]);
  const errorPosition = useMemo(() => errorValidate?.position || 0, [errorValidate]);
  const errorExample = useMemo(() => errorValidate?.example || '', [errorValidate]);
  const containerMsg = useMemo(() => elt(
    'div',
    { class: classes.tooltipCusomWrapp },
    elt('div', { class: classes.messageError }, errorMessage),
    elt('div', { class: classes.exampleTitle }, exampleTitle),
    elt('div', { class: classes.exampleText }, errorExample)
  ), [errorMessage]);

  const newErrorPosition = useMemo(() => [{
    from: errorPosition > 0 ? errorPosition - 1 : errorPosition,
    to: errorPosition + 1,
    severity: 'error',
    message: errorMessage,
    renderMessage: () => containerMsg
  }], [errorMessage, errorPosition]);

  const linterExt = linter((view) => {
    let diagnostics = [];
    code && syntaxTree(view.state).cursor().iterate(() => {
      if (errorValidate) { !isEqual(diagnostics, newErrorPosition) && (diagnostics = newErrorPosition); }
    });
    return code ? diagnostics : [];
  }, { delay: 500 });

  const editorBaseTheme = EditorView.theme({
    '.cm-scroller': {
      fontFamily: "'Roboto Mono Regular', 'Roboto', monospace !important",
      fontWeight: 400
    },
    '&.cm-focused': { outline: 'none' },
    '.cm-matchingBracket': { color: `${colors.red600} !important`, backgroundColor: 'yellow !important' },
    '.cm-matchingBracket > span': { color: `${colors.red600} !important`, backgroundColor: 'yellow !important' },
    '&.cm-focused .cm-matchingBracket': { color: `${colors.red600} !important`, backgroundColor: 'yellow !important' },
    '&.cm-focused .cm-nonmatchingBracket': { color: `${colors.red600} !important`, backgroundColor: 'yellow !important' },
    '.cm-lintRange-error': { position: 'relative', cursor: 'default !important', backgroundImage: 'none !important', backgroundColor: colors.red500t30 },
    '.cm-tooltip-lint': { width: '100%' },
    '.cm-diagnostic.cm-diagnostic-error': {
      border: 'none',
      fontSize: '14px',
      padding: '12px',
      fontFamily: "'Roboto Mono Regular', 'Roboto', monospace !important",
      fontWeight: 400,
      color: colors.textPrimary
    },
    '.cm-tooltip.cm-tooltip-hover': {
      borderRadius: '6px',
      backgroundColor: 'white',
      border: 'none',
      width: '160px',
      overflow: 'hidden'
    }
  });

  const eventInput = events.content({
    beforeinput: () => { onConfirmEditig(); },
    click: () => { onConfirmEditig(); }
  });
  const eventDom = events.dom({
    keydown: (e) => {
      if (e.key === 'Enter' || e.code === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        onPressEnter();
      }
    }
  });

  const highlightStyle = HighlightStyle.define([
    { tag: tags.propertyName, class: classes.propertyName },
    { tag: tags.operator, class: classes.operator },
    { tag: tags.comment, class: classes.comment },
    { tag: tags.string, class: classes.string },
    { tag: tags.number, class: classes.number },
    { tag: tags.bool, class: classes.bool },
    { tag: tags.typeName, class: classes.typeNambrackete },
    { tag: tags.variableName, class: classes.variableName },
    { tag: tags.labelName, class: classes.labelName },
    { tag: tags.compareOperator, class: classes.compareOperator },
    { tag: tags.derefOperator, class: classes.derefOperator },
    { tag: tags.bracket, class: classes.bracket },
    { tag: tags.squareBracket, class: classes.squareBracket },
    { tag: tags.angleBracket, class: classes.angleBracket },
    { tag: tags.paren, class: classes.paren },
    { tag: tags.brace, class: classes.brace }
  ]);
  // function command(view) {
  //   return deleteLine(view);
  // }

  // const keys = [{ key: 'Enter', mac: 'Enter', run: command }];

  return (
    <CodeMiror
      value={code}
      ref={ref}
      style={{
        backgroundColor: colors.fontBody,
        fontSize: '14px',
        fontFamily: "'Roboto Mono Regular', 'Roboto', monospace !important",
        color: colors.blue350,
        borderRadius: '4px',
        outline: `1px solid ${colors.fontBody}`,
        fontWeight: 400,
        ...customStyles,
        ...((errorValidate || errorQuery) ? { outline: colors.borderRed } : {})
      }}
      theme={editorBaseTheme}
      height={`calc(100vh - ${assignValue ? 305 : 295}px)`}
      maxHeight={`calc(100vh - ${assignValue ? 305 : 295}px)`}
      width="calc(100% - 20px)"
      maxWidth="calc(100% - 20px)"
      autoFocus
      onChange={onChange}
      indentWithTab
      editable={editable}
      extensions={[
        StreamLanguage.define(solr),
        linterExt,
        eventInput,
        eventDom,
        EditorView.lineWrapping,
        syntaxHighlighting(highlightStyle),
        markdown({ base: markdownLanguage, codeLanguages: languages })
        // Prec.highest(keymap.of([...keys]))
      ]}
      basicSetup={{
        autoCloseTags: true,
        bracketMatching: true,
        closeBrackets: true,
        rectangularSelection: true,
        crosshairCursor: true,
        highlightSelectionMatches: true,
        closeBracketsKeymap: true,
        searchKeymap: true,
        foldKeymap: true,
        completionKeymap: true,
        lintKeymap: false,
        lineNumbers: false,
        foldGutter: false,
        dropCursor: false,
        allowMultipleSelections: false,
        indentOnInput: false,
        highlightActiveLine: false,
        autocompletion: false,
        highlightActiveLineGutter: false
      }}
      {...customProps}
    />
  );
});

CodeMirror.propTypes = {
  onChange: func,
  onPressEnter: func,
  onConfirmEditig: func,
  editable: bool,
  code: string,
  errorQuery: string,
  exampleTitle: string,
  assignValue: string,
  customProps: shape({}),
  customStyles: shape({}),
  errorValidate: oneOfType([shape({}), number])
};
export default CodeMirror;
