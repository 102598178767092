import { useState, useEffect, useRef, memo } from 'react';
import { bool, string, func, oneOfType, arrayOf, shape, number } from 'prop-types';
import cn from 'classnames';
import { BodyMenu, BodyLink, Icon, SelectCustom } from 'Components';
import { useSelector } from 'react-redux';
import t from 'utils/translateFunc';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './BtnDrop.module.scss';
import { styles } from './selectStyles';

const BtnDropDown = memo(({
  active = '',
  icon = '',
  menuConfig = [],
  body = '',
  tooltipText = '',
  className = '',
  bodyClassName = '',
  iconClass = '',
  btnType = '',
  arrowRight = false,
  text = '',
  selectOption = () => null,
  info = '',
  offset = false,
  color = '',
  colorBtn = '',
  selectClass = '',
  selectMenu = [],
  amplitudeCategory = '',
  amplitudeEvent = ''
}) => {
  const [windowVisible, setWindowVisible] = useState(false);
  const [optionValue, setOptionValue] = useState('default');
  const node = useRef();
  const buttonList = useRef();
  const language = useSelector(state => state?.userInfo?.language);
  useEffect(() => {
    if (windowVisible) {
      document.addEventListener('mousedown', handleClick);
      document.addEventListener('keydown', escAndTabFunction);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', escAndTabFunction);
    };
  }, [windowVisible]);

  function escAndTabFunction(event) {
    if (event.code === 'Escape' || event.code === 'Tab') {
      setWindowVisible(false);
    }
  }

  function handleClick(e) {
    if (node.current.contains(e.target) || buttonList.current.contains(e.target)) {
      return;
    }
    setWindowVisible(false);
  }

  const hahdleButton = (e) => {
    e.stopPropagation();
    !windowVisible && amplitudeCategory && amplitudeEvent && sendFeedEvent({
      category: amplitudeCategory,
      event: amplitudeEvent
    });

    const isSelectInput = e.target?.classList?.contains('select__input-container');
    const isSelectPart = e.target?.dataset?.name === 'select-option-part';
    const isSelect = [...e.target.classList]?.some(item => item?.includes('select'));
    const svg = e.target.tagName === 'svg' || e.target.tagName === 'path';
    (isSelectInput || isSelectPart || isSelect || svg) ? setWindowVisible(true) : setWindowVisible(!windowVisible);
  };

  function handleChangeOption({ value }) {
    setOptionValue(value);
  }

  function onClose() {
    setWindowVisible(false);
  }

  function onSelect(value) {
    selectMenu ? selectOption(value, optionValue) : selectOption(value);
  }

  return (
    <div
      className={cn(className)}
      onClick={hahdleButton}
      style={{ background: 'transparent' }}
    >
      <div
        ref={buttonList}
        className={cn(
          classes.container,
          offset && !windowVisible && classes.container_offset
        )}
      >
        <div className={classes.select}>
          {text && (<div className={classes.select_text}>{text}</div>)}
          <div className={cn(
            classes.select_icon,
            offset && windowVisible && classes.select_icon_offset
          )}
          >
            {icon && (
              <Icon
                type={icon}
                color={windowVisible ? color : colorBtn}
                {...(tooltipText && !windowVisible ? { role: 'button' } : null)}
                {...(tooltipText ? { tooltipText } : null)}
                btnType={btnType}
                className={cn(iconClass, windowVisible && selectClass)}
              />
            )}

            {windowVisible && (
              <div
                className={cn(
                  bodyClassName,
                  classes.windowWrap,
                  !arrowRight && classes.windowWrap_center,
                  arrowRight && classes.windowWrap_right
                )}
                ref={node}
                onClick={onClose}
              >
                {!!selectMenu?.length && (
                <div className={classes.selectWrapper} data-name="select-menu">
                  <div className={classes.seletMenuTitle}>{t('iconsBlock', 'CHOOSE_TEMPLATE', language)}</div>
                  <SelectCustom
                    value={selectMenu?.find(({ value }) => value === optionValue) || selectMenu[0]}
                    onChange={handleChangeOption}
                    options={selectMenu}
                    className={classes.selectDropDown}
                    styles={styles}
                  />
                </div>
                )}
                {!!menuConfig?.length && (
                <BodyMenu
                  menuConfig={menuConfig}
                  selectOption={onSelect}
                  active={active}
                  info={info}
                  color={color}
                />
                )}
                {body && <BodyLink selectOption={onSelect} />}
              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
});

BtnDropDown.propTypes = {
  icon: string,
  menuConfig: arrayOf(shape({ name: string, icon: string, info: oneOfType([bool, string]) })),
  selectMenu: arrayOf(shape({ value: oneOfType([number, string]) })),
  body: string,
  className: string,
  bodyClassName: string,
  iconClass: string,
  btnType: string,
  tooltipText: string,
  selectOption: func,
  arrowRight: bool,
  text: oneOfType([number, string]),
  active: string,
  info: oneOfType([number, string]),
  offset: bool,
  color: string,
  colorBtn: string,
  selectClass: string,
  amplitudeCategory: string,
  amplitudeEvent: string
};

export default BtnDropDown;
