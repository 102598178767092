import { useMemo } from 'react';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Icons } from 'Components';
import { addFilterItem, removeFilterItem } from 'storage/actions';
import colors from 'styles/_colors.scss';
import classes from './RateFilter.module.scss';

const RateFilter = () => {
  const dispatch = useDispatch();
  const { filterList } = useSelector(state => state?.filters);
  const { language } = useSelector(state => state?.userInfo);
  const itemTypeRate = t('filtrationPanel', 'RATE', language);

  const selectedDataRate = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'rate'), [filterList]);

  const rates = [
    { id: 5, title: '⭐⭐⭐⭐⭐' },
    { id: 4, title: '⭐⭐⭐⭐' },
    { id: 3, title: '⭐⭐⭐' },
    { id: 2, title: '⭐⭐' },
    { id: 1, title: '⭐' }
  ];

  const addItem = ({ id, name, type, filterName }) => { dispatch(addFilterItem({ id, name, type, filterName })); };
  const removeItem = ({ id, filterName }) => { dispatch(removeFilterItem({ id, filterName })); };

  const onToggle = (filter, isSelect) => {
    if (isSelect) {
      removeItem({ id: filter.id, filterName: 'rate' });
    } else {
      addItem({
        id: filter.id, name: filter?.title, type: itemTypeRate, filterName: 'rate'
      });
    }
  };

  return (
    <div className={classes.sourceFilter}>
      <section className={classes.box}>
        <ul className={cn(classes.filtersList)}>
          {rates.map((filter) => {
            const isSelect = selectedDataRate?.find(item => item.id === filter.id);
            return (
              <li key={`rate-${filter?.id}`} className={cn(classes.filter)}>
                <label className={classes.checkboxLabel}>
                  <input
                    className={classes.visually_hidden}
                    type="checkbox"
                    checked={!!isSelect}
                    onChange={() => onToggle(filter, isSelect)}
                  />
                  <div className={cn(classes.checkbox, !!isSelect && classes.checkbox_selected)}>
                    {!!isSelect && (<Icons icon="check" className={classes.iconCheck} />)}
                  </div>
                  <div className={classes.content}>
                    {Array(5).fill(null).map((_, i) => <Icon type="kid_star" height={16} width={16} key={i} color={i < filter.id ? colors.grey600 : colors.iconMainColor} />)}
                  </div>
                </label>
              </li>
            );
          })}
        </ul>

      </section>
    </div>
  );
};

export default RateFilter;
