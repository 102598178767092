import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';

export function OriginalBookmark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="34" viewBox="0 0 31 34" fill="none">
      <path d="M0 0L31 34L31 0L0 0Z" fill={PROJECT === 'metricom' ? colors.blueA350 : colors.green400} />
      <path d="M21.625 12.3747L24.353 13.9093C24.8526 14.1906 25.464 13.7748 25.3325 13.249L24.6094 10.3632L27.0219 8.41892C27.4624 8.0643 27.2257 7.39176 26.6472 7.34896L23.4722 7.09829L22.2298 4.37143C22.0063 3.87619 21.2437 3.87619 21.0202 4.37143L19.7778 7.09217L16.6028 7.34285C16.0243 7.38565 15.7876 8.05819 16.2281 8.4128L18.6406 10.3571L17.9175 13.2429C17.786 13.7687 18.3974 14.1845 18.897 13.9032L21.625 12.3747Z" fill="white" />
    </svg>
  );
}
