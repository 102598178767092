import colors from 'styles/_colors.scss';

export const styles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minHeight: '32px',
    backgroundColor: state?.sort ? colors.fontBody : colors.white,
    height: '30px',
    borderRadius: '4px',
    border: `1px solid ${colors.purpleA100} !important`,
    ':focus': {
      backgroundColor: colors.purpleA10
    },
    outline: 'none !important',
    boxShadow: 'none !important',
    opacity: state.isDisabled ? '0.8' : '1'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 12px'
  }),

  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    color: colors.font200
  }),

  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: colors.boxShadow,
    zIndex: 9999
  }),

  menuList: provided => ({
    ...provided,
    paddingBottom: '3px'
  }),

  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '10px 16px',
    height: '40px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: colors.font200,
    backgroundColor:
      state.isFocused || state.isSelected ? colors.purpleA10 : colors.white,
    ':active': {
      backgroundColor: colors.purpleA10
    }
  })
};
