import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { sendFeedEvent } from 'services/amplitude/events';
import { openCopilotWindow, openModal } from 'storage/actions';
import { useCopilotDelete } from 'storage/copilot/requestsHooks';
import t from 'utils/translateFunc';
import { Button } from 'Components';
import classes from './BodyCopilotDelete.module.scss';

function BodyCopilotDelete({ onClose = () => null }) {
  const dispatch = useDispatch();
  const modalInfo = useSelector(state => state?.modals?.modalInfo);
  const { language } = useSelector(state => state?.userInfo);
  const { mutate: copilotDelete } = useCopilotDelete();

  function deleteChat() {
    const chatID = modalInfo?.chatID;
    if (chatID) {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Confirm_Delete_Chat' });
      copilotDelete(chatID);
    }
    dispatch(openCopilotWindow({ is_open: false }));
    onClose();
  }

  function onHandleClose() {
    const openModalName = modalInfo?.openModalName;
    const chat_id = modalInfo?.chatID || null;
    if (openModalName) {
      dispatch(openModal({ action: openModalName, chat_id }));
    } else {
      onClose();
    }
  }

  return (
    <div className={classes.bodyCopilotDelete}>
      <div className={classes.text}>
        {t('COPILOT', 'DELETE_CHAT', language)}
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={onHandleClose}
          textButton={t('btn', 'CANCEL', language)}
          purpleTransparent32
        />
        <Button
          onClick={deleteChat}
          textButton={t('menuConfig', 'DELETE', language)}
          pink32
        />
      </div>
    </div>
  );
}

BodyCopilotDelete.propTypes = {
  onClose: func
};

export default BodyCopilotDelete;
