/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'hooks/useIntercom';
import { useWootric } from 'hooks/useWootric';
import 'normalize.css';
import classes from 'App.module.scss';
import Routes from 'routes';
import { updateToken, updateLanguage, apiToken } from 'api/apiInstance';
import { useGetPeriod } from 'storage/queryHooks';
import { loadAntdLang, changeFavicon, changeTheme } from 'utils/common';
import { ConfigProvider, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import colors from 'styles/_colors.scss';
import './styles/styles.scss';
import cn from 'classnames';
import { PROJECT } from 'config';

ConfigProvider.config({ holderRender: children => <ConfigProvider prefixCls="newsfeed">{children}</ConfigProvider> });
notification.config({ prefixCls: 'newsfeed', placement: 'bottomLeft', bottom: 10 });

function App() {
  const { language, user } = useSelector(state => state?.userInfo);
  const count = useSelector(state => state?.news?.count);
  const { accessToken, isAuthenticated } = useSelector(state => state?.auth);
  const { openIntercom } = useIntercom();
  const { openWootric } = useWootric();
  const { i18n } = useTranslation();
  const periodData = useGetPeriod();

  useEffect(() => {
    if (user?.id && PROJECT !== 'metricom') {
      openWootric();
      openIntercom();
    }
  }, [user?.id]);

  useEffect(() => {
    changeFavicon(count);
  }, [count]);

  useEffect(() => {
    changeTheme();
  }, [PROJECT]);

  useEffect(() => {
    if (isAuthenticated && language) {
      updateLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [isAuthenticated, language]);

  if (accessToken && !apiToken) {
    updateToken(accessToken);
  }

  return (
    <div className={cn(classes.app)}>
      <ConfigProvider
        locale={loadAntdLang(language)}
        prefixCls="newsfeed"
        theme={{
          components: {
            Table: {
              fontFamily: 'Roboto',
              headerBorderRadius: 8,
              headerColor: colors.grey600,
              headerBg: colors.grey100,
              borderColor: colors.grey200,
              rowHoverBg: PROJECT === 'metricom' ? colors.font10Green : colors.purpleA150,
              headerSortActiveBg: PROJECT === 'metricom' ? colors.font10Green : colors.purpleA150,
              headerSortHoverBg: PROJECT === 'metricom' ? colors.font10Green : colors.purpleA150,
              headerSplitColor: PROJECT === 'metricom' ? colors.font10Green : colors.font70
            },
            Pagination: {
              fontFamily: 'Roboto',
              fontSize: 14,
              colorBgContainer: colors.white,
              colorPrimary: undefined,
              colorText: colors.grey600,
              colorTextDisabled: colors.font50,
              colorBgTextActive: PROJECT === 'metricom' ? colors.font10Green : colors.purpleA150,
              colorBgTextHover: PROJECT === 'metricom' ? colors.font10GreenT70 : colors.purpleA150t70,
              colorBorder: PROJECT === 'metricom' ? colors.font50Green : colors.purpleA150,
              colorPrimaryBorder: PROJECT === 'metricom' ? colors.font400Green : colors.purple600t50,
              colorPrimaryHover: PROJECT === 'metricom' ? colors.font200Green : colors.purple350
            },
            Progress: {
              remainingColor: colors.white,
              defaultColor: colors.purple350
            },
            Radio: {
              colorPrimary: colors.green400
            }
          }
        }}
      >
        <Routes />
      </ConfigProvider>
    </div>
  );
}

export default App;
