import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSearchParams } from 'api/apiInstance';
import * as actions from 'storage/actions';
import { editPeriod } from 'storage/datePicker/requests';
import { AnimatePage } from 'Components';
import Modals from 'Containers/Modals';
import classes from './Search.module.scss';
import SearchFilters from './SearchFilters';
import SearchFeed from './SearchFeed';

function Search() {
  const dispatch = useDispatch();
  const searchParams = getSearchParams();

  useEffect(() => {
    if (searchParams?.length) {
      const filters = searchParams.reduce((acc, item) => {
        const [key, value] = Object.entries(item)[0];
        acc[key] = value;
        return acc;
      }, {});

      const { start, end, by_created, duplicates } = filters;
      duplicates && dispatch(actions.toggleSearchDuplicates(duplicates));
      if (start && end) {
        const periodBody = {
          start,
          end,
          by_created: [true, 'True', 'true'].includes(by_created)
        };
        editPeriod(periodBody).then(() => dispatch(actions.setDatePicker(periodBody)));
      }
    }
  }, [dispatch, searchParams]);

  return (
    <AnimatePage>
      <div className={classes.searchWrapper}>
        <SearchFilters />
        <SearchFeed />
      </div>
      <Modals />
    </AnimatePage>
  );
}

export default Search;
