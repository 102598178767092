import { string } from 'prop-types';
import colors from 'styles/_colors.scss';

export const Restore = ({ color = colors.font35 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15" fill="none">
    <path d="M0.785714 12.5714C0.785714 13.4357 1.49286 14.1429 2.35714 14.1429H8.64286C9.50714 14.1429 10.2143 13.4357 10.2143 12.5714V4.71429C10.2143 3.85 9.50714 3.14286 8.64286 3.14286H2.35714C1.49286 3.14286 0.785714 3.85 0.785714 4.71429V12.5714ZM5.225 5.775C5.38214 5.61786 5.62571 5.61786 5.78286 5.775L8.64286 8.64286H7.07143V11.7857H3.92857V8.64286H2.35714L5.225 5.775ZM8.25 0.785714L7.69214 0.227857C7.55071 0.0864285 7.34643 0 7.14214 0H3.85786C3.65357 0 3.44929 0.0864285 3.30786 0.227857L2.75 0.785714H0.785714C0.353571 0.785714 0 1.13929 0 1.57143C0 2.00357 0.353571 2.35714 0.785714 2.35714H10.2143C10.6464 2.35714 11 2.00357 11 1.57143C11 1.13929 10.6464 0.785714 10.2143 0.785714H8.25Z" fill={color} />
  </svg>
);

Restore.propTypes = {
  color: string
};
