import History from 'Containers/Mentions/Copilot/History';
import Messages from 'Containers/Mentions/Copilot/Messages';
import AIUsing from 'Containers/Mentions/Copilot/AIUsing';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendFeedEvent } from 'services/amplitude/events';
import { func } from 'prop-types';
import { PROJECT } from 'config';
import { More, Vector, AddCircle, History as HistoryIcon, DeleteType2, SmallScreen, Bolt, Info as InfoIcon } from 'icons';
import useClickOutside from 'hooks/clickOutside';
import { useCopilotGetLimits } from 'storage/copilot/requestsHooks';
import { openModal, setCopilotUnread, setLastViewed, openCopilotWindow, setInitText } from 'storage/actions';
import t from 'utils/translateFunc';
import colors from 'styles/_colors.scss';
import classes from './BodyCopilotFullScreen.module.scss';

function BodyCopilotFullScreen({ onClose = () => null }) {
  const infoPages = {
    looqme_uk: 'https://help.looqme.io/uk/articles/10038339-знайомтесь-з-q-insight-вашим-ai-помічником-для-бренд-моніторингу',
    looqme_en: 'https://help.looqme.io/uk/articles/10067520-meet-q-insight-your-ai-assistant-for-brand-monitoring',
    metricom_en: ''
  };
  const [questionText, setQuestionText] = useState('');
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isShowHistory, setIsShowHistory] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [menuMode, setMenuMode] = useState('messages');
  const history = useSelector(state => state?.copilot?.history);
  const unread = useSelector(state => state?.copilot?.unread);
  const last_viewed = useSelector(state => state?.copilot?.last_viewed);
  const limits = useSelector(state => state?.copilot?.limits);
  const modalInfo = useSelector(state => state?.modals?.modalInfo);
  const { language } = useSelector(state => state?.userInfo);
  const dispatch = useDispatch();
  const menuBtnRef = useRef(null);
  const { mutate: copilotLimitsData, isLoading: isLimitLoading } = useCopilotGetLimits();
  useClickOutside(menuBtnRef, () => (showMenu ? setShowMenu(false) : null));

  useEffect(() => {
    // Updating parent element style
    const current_element = document.getElementById('CopilotModal');
    const buttons = current_element.parentElement.parentElement.getElementsByTagName('button');
    if (buttons.length) buttons[0].remove();
    const modal_element = current_element.parentElement.parentElement;
    modal_element.style.borderRadius = '12px';
  }, []);

  useEffect(() => {
    if (activeQuestion?.id === unread) dispatch(setCopilotUnread({ unread: null }));
  }, [activeQuestion]);

  useEffect(() => {
    !isLimitLoading && copilotLimitsData();
    if (modalInfo) {
      const chat_id = modalInfo?.chat_id || null;
      const question = modalInfo?.question || '';
      if (chat_id) selectQuestion(chat_id);
      if (question) setQuestionText(question);
      return;
    }
    if (!activeQuestion && history?.length && unread) {
      selectQuestion(unread);
    }
    if (!activeQuestion && history?.length && !unread && last_viewed?.article_id) {
      if (Date.now() - (last_viewed?.timestamp || 0) < 30 * 60 * 1000) {
        selectQuestion(last_viewed?.article_id);
      } else {
        dispatch(setLastViewed({ article_id: null }));
      }
    }
  }, []);

  useEffect(() => {
    if (limits?.limit && limits?.usage >= limits?.limit) {
      menuMode !== 'using' && selectMode('using');
    }
  }, [limits?.usage, limits?.limit]);

  function selectMode(mode) {
    setMenuMode(mode);
    setShowMenu(false);
  }

  function newChat() {
    sendFeedEvent({ category: 'copilot', event: 'Clicked_New_Chat_Button_In_Full_Screen_Mode' });
    setActiveQuestion(null);
    selectMode('messages');
  }

  function selectQuestion(question_id) {
    menuMode !== 'messages' && selectMode('messages');
    const question = history.find(q => q?.id === question_id);
    setActiveQuestion(question);
  }

  function deleteChat() {
    sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Delete_Chat_In_Full_Screen_Mode' });
    dispatch(openModal({ action: 'copilotDelete', chatID: activeQuestion?.id, openModalName: 'copilotFullScreen' }));
  }

  function handleClose() {
    sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Close_Full_Screen' });
    !!questionText && dispatch(setInitText({ text: questionText }));
    dispatch(openCopilotWindow({ is_open: true }));
    onClose();
  }

  function openInfoPage() {
    sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Info_Page_In_Full_Screen_Mode' });
    !!showMenu && setShowMenu(false);
    if (PROJECT === 'metricom') {
      window.open(infoPages.metricom_en, '_blank');
    } else {
      language === 'uk' ? window.open(infoPages.looqme_uk, '_blank') : window.open(infoPages.looqme_en, '_blank');
    }
  }

  return (
    <div id="CopilotModal" className={classes.fullScreen}>
      <header className={classes.header}>
        <div className={classes.headerItem}>
          {menuMode === 'messages' && <div className={classes.headerIconRainbow}><Vector /></div>}
          {menuMode === 'using' && <div className={classes.headerIconPurple}><Bolt color="white" /></div>}
          <div className={classes.headerText}>
            <div className={classes.upperText}>Q Insight</div>
            <div className={classes.bottomText}>by ChatGPT</div>
          </div>
        </div>
        <div className={classes.actions}>
          <div className={classes.button} onClick={newChat}>
            <AddCircle color={colors.font70} />
          </div>
          <div
            className={classes.button}
            onClick={() => {
              sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_History_In_Full_Screen_Mode' });
              setIsShowHistory(!isShowHistory);
            }}
          >
            <HistoryIcon color={colors.font70} />
          </div>
          {(activeQuestion?.id && (activeQuestion?.remote_task_status === 'FAILED' || activeQuestion?.remote_task_status === 'FINISHED')) && (
            <div className={classes.button} onClick={deleteChat}>
              <DeleteType2 color={colors.font70} />
            </div>
          )}
          <div className={classes.button} onClick={handleClose}>
            <SmallScreen color={colors.font70} />
          </div>
          <div>
            <div className={classes.button} onClick={() => setShowMenu(!showMenu)}>
              <More color={colors.font70} />
            </div>
            <ul className={classes.dropdownMenu} style={{ visibility: showMenu ? 'visible' : 'hidden' }} ref={menuBtnRef}>
              <li>
                <div
                  className={classes.dropdownItem}
                  onClick={() => {
                    sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Limits_Page_In_Full_Screen_Mode' });
                    selectMode('using');
                  }}
                >
                  <Bolt color={colors.font70} />
                  {t('COPILOT', 'CHAT_USING', language)}
                </div>
              </li>
              {PROJECT !== 'metricom' && (
              <li>
                <div className={classes.dropdownItem} onClick={() => openInfoPage()}>
                  <InfoIcon color={colors.font70} />
                  {t('COPILOT', 'CHAT_INFO', language)}
                </div>
              </li>
              )}
            </ul>
          </div>
        </div>
      </header>
      <div className={classes.mainPart}>
        {isShowHistory && (
          <div className={classes.historyPart}>
            <History chats_list={history} select_chat={selectQuestion} is_full_screen />
          </div>
        )}
        <div className={classes.chatPart}>
          {menuMode === 'messages' && (
            <Messages
              chat={activeQuestion}
              set_chat={setActiveQuestion}
              questionText={questionText}
              setQuestionText={setQuestionText}
              load_limits={copilotLimitsData}
              select_mode={selectMode}
              is_full_screen
            />
          )}
          {menuMode === 'using' && <AIUsing load_limits={copilotLimitsData} is_loading_limits={isLimitLoading} is_full_screen />}
        </div>
      </div>
    </div>
  );
}

BodyCopilotFullScreen.propTypes = {
  onClose: func
};

export default BodyCopilotFullScreen;
