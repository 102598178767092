import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { clearState, changeServerUpdating, authIn, changeNotAvailable } from 'storage/actions';
import { checkQueryParams, loadMomentLocale } from 'utils/common';
import { useDispatch } from 'react-redux';
import { translateBase } from 'lang/translation';
import { API_URL } from 'config';
import moment from 'moment';

const baseURL = `${API_URL || process.env.REACT_APP_LOOQME_API_URL}`;

const instance = axios.create({
  baseURL: `${baseURL}/api/v1/`,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', 'Content-Language': 'en' }
});

const interceptor = (store) => {
  if (!store) { return; }
  instance.interceptors.response.use(interseptorsSuccessFunc, interseptorsErrorFunc);

  function interseptorsSuccessFunc(resp) {
    const { isAuthenticated, notAvailable, serverUpdating } = store.getState().auth;
    serverUpdating && store.dispatch(changeServerUpdating(false));
    notAvailable && store.dispatch(changeNotAvailable(false));

    if (!isAuthenticated && !resp?.data?.redirect_url) {
      store.dispatch(authIn());
    }
    if (resp?.data?.redirect_url) {
      window.location.replace(resp?.data?.redirect_url);
      store.dispatch(clearState());
    }
    return resp;
  }

  function interseptorsErrorFunc(err) {
    const responseStatus = err?.response?.status;
    const requestStatus = err?.request?.status;
    const canceled = err?.message === 'canceled';
    const isAuthenticated = store.getState().auth.isAuthenticated;
    if (responseStatus === 401) {
      const portal_slug = store.getState().portals.currentPortal?.slug || null;
      const queryString = checkQueryParams({ portal_slug });
      window.location.replace(`${baseURL}/remote-login-api${queryString}`);
      instance.defaults.headers.common.Authorization = null;
      store.dispatch(clearState());
    }
    if (!requestStatus && !canceled && window.location.pathname !== '/updating' && isAuthenticated) {
      const isNewsFeed = err?.config?.url?.includes('/articles/newsfeed/');
      const isNewsFeedCount = err?.config?.url?.includes('/articles/newsfeed-count/');
      !isNewsFeed && !isNewsFeedCount && store.dispatch(changeServerUpdating(true));
    }
    if (!requestStatus && window.location.pathname !== '/not_available' && !isAuthenticated) {
      store.dispatch(changeNotAvailable(true));
    }
    return Promise.reject(err);
  }
};

export const AxiosInterceptor = ({ isAuthenticated, children }) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const resInterceptor = response => response;

    const errInterceptor = (err) => {
      const requestStatus = err?.request?.status;
      if (!requestStatus && window.location.pathname !== '/updating' && isAuthenticated) {
        dispatch(changeServerUpdating(true));
        history('/updating');
      }
      if (!requestStatus && window.location.pathname !== '/not_available' && !isAuthenticated) {
        dispatch(changeNotAvailable(true));
        history('/not_available');
      }
      return Promise.reject(err);
    };
    const intercept = instance.interceptors.response.use(resInterceptor, errInterceptor);

    return () => instance.interceptors.response.eject(intercept);
  }, [history]);

  return children;
};

const getSearchParams = () => {
  const search = window.location.search;
  const mySearchParams = new URLSearchParams(search);
  const params = [];
  for (const [key, value] of mySearchParams.entries()) {
    params.push({ [key]: value });
  }
  return params;
};

function updateLanguage(lang) {
  instance.defaults.headers.common['Content-Language'] = lang;
  instance.defaults.headers['Content-Language'] = lang;
  translateBase.default = lang;
  loadMomentLocale(lang).then((language) => { moment.locale(language); });
}

function updateToken(token) {
  token ? instance.defaults.headers.common.Authorization = `Token ${token}` : instance.defaults.headers.common.Authorization = null;
}
const apiToken = instance?.headers?.common?.Authorization;
export {
  instance as api, updateToken, updateLanguage, apiToken, interceptor, getSearchParams
};
