import colors from 'styles/_colors.scss';

export const WhatsNew = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
    <path d="M12.7998 5.6875C12.7998 6.23188 12.7998 6.76812 12.7998 7.3125C13.7598 7.3125 15.0078 7.3125 15.9998 7.3125C15.9998 6.76812 15.9998 6.23188 15.9998 5.6875C15.0078 5.6875 13.7598 5.6875 12.7998 5.6875Z" fill={colors.font90} />
    <path d="M11.2002 11.0581C11.9682 11.635 12.9682 12.3988 13.7602 13C14.0802 12.5694 14.4002 12.1306 14.7202 11.7C13.9282 11.0987 12.9282 10.335 12.1602 9.75C11.8402 10.1888 11.5202 10.6275 11.2002 11.0581Z" fill={colors.font90} />
    <path d="M14.7202 1.3C14.4002 0.869375 14.0802 0.430625 13.7602 0C12.9682 0.60125 11.9682 1.365 11.2002 1.95C11.5202 2.38063 11.8402 2.81938 12.1602 3.25C12.9282 2.665 13.9282 1.90937 14.7202 1.3Z" fill={colors.font90} />
    <path d="M1.60002 4.0625C0.720009 4.0625 0 4.79375 0 5.6875V7.3125C0 8.20625 0.720009 8.9375 1.60002 8.9375H2.40003V12.1875H4.00005V8.9375H4.80006L8.80011 11.375V1.625L4.80006 4.0625H1.60002ZM5.62407 5.45187L7.20009 4.49312V8.50687L5.62407 7.54812L5.24006 7.3125H4.80006H1.60002V5.6875H4.80006H5.24006L5.62407 5.45187Z" fill={colors.font90} />
    <path d="M10.8006 6.50001C10.8006 5.41939 10.3366 4.44439 9.60059 3.77814V9.21376C10.3366 8.55564 10.8006 7.58064 10.8006 6.50001Z" fill={colors.font90} />
  </svg>
);
