import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Newspaper({ width = 16, height = 17, color = 'white', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '16'} height={height || '17'} viewBox="0 0 16 17" fill="none">
      <mask id="mask0_557_14276" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
        <rect y="0.5" width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_557_14276)">
        <path d="M2.66732 14.5C2.30065 14.5 1.98676 14.3695 1.72565 14.1084C1.46454 13.8473 1.33398 13.5334 1.33398 13.1667V2.90003C1.33398 2.82225 1.36732 2.76947 1.43398 2.7417C1.50065 2.71392 1.56176 2.72781 1.61732 2.78336L2.45065 3.6167L3.31732 2.73336C3.38398 2.6667 3.46176 2.63336 3.55065 2.63336C3.63954 2.63336 3.71732 2.6667 3.78398 2.73336L4.66732 3.6167L5.55065 2.73336C5.61732 2.6667 5.6951 2.63336 5.78398 2.63336C5.87287 2.63336 5.95065 2.6667 6.01732 2.73336L6.88398 3.6167L7.76732 2.73336C7.83398 2.6667 7.91176 2.63336 8.00065 2.63336C8.08954 2.63336 8.16732 2.6667 8.23398 2.73336L9.11732 3.6167L9.98398 2.73336C10.0507 2.6667 10.1284 2.63336 10.2173 2.63336C10.3062 2.63336 10.384 2.6667 10.4507 2.73336L11.334 3.6167L12.2173 2.73336C12.284 2.6667 12.3618 2.63336 12.4507 2.63336C12.5395 2.63336 12.6173 2.6667 12.684 2.73336L13.5507 3.6167L14.384 2.78336C14.4395 2.72781 14.5007 2.71392 14.5673 2.7417C14.634 2.76947 14.6673 2.82225 14.6673 2.90003V13.1667C14.6673 13.5334 14.5368 13.8473 14.2757 14.1084C14.0145 14.3695 13.7007 14.5 13.334 14.5H2.66732ZM2.66732 13.1667H7.33398V9.1667H2.66732V13.1667ZM8.66732 13.1667H13.334V11.8334H8.66732V13.1667ZM8.66732 10.5H13.334V9.1667H8.66732V10.5ZM2.66732 7.83336H13.334V5.83336H2.66732V7.83336Z" fill={color || 'white'} />
      </g>
    </svg>
  );
}

Newspaper.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
