import { string, func, bool, number, shape, node, oneOfType, arrayOf } from 'prop-types';
import cn from 'classnames';
import { Icons, Icon, InfoBtn } from 'Components';
import { Popconfirm } from 'antd';
import ReactModal from 'react-modal';
import { sendAlertsEvent, sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './ModalWindow.module.scss';

const ModalWindow = ({
  infoBtn = false,
  stylesModal = {},
  isOpen = false,
  closeModal = () => null,
  children = '',
  headerText = false,
  datePicker = false,
  dark = false,
  headerUrl = '',
  isCard = false,
  iconsList = [],
  popoverConfirmProps = false,
  shouldCloseOnOverlayClick = true
}) => (
  <ReactModal
    isOpen={isOpen}
    onAfterOpen={() => null}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    shouldCloseOnEsc
    onRequestClose={closeModal}
    ariaHideApp={false}
    className={cn(
      classes.modalBody,
      stylesModal?.body || '',
      datePicker && classes.modalBody_datePicker,
      dark && classes.modalBody_dark
    )}
    parentSelector={() => document.getElementById('root')}
    style={{
      overlay: {
        backgroundColor: datePicker ? 'transparent' : colors.modalBg,
        fontFamily: 'Roboto, sans-serif',
        zIndex: 9947483003
      }
    }}
  >
    <Popconfirm
      placement="bottomLeft"
      arrow={false}
      trigger="click"
      zIndex={21474836479}
      icon={false}
      title=""
      description=""
      destroyTooltipOnHide
      {...(popoverConfirmProps || {})}
    >
      <button
        className={classes.exitBtn}
        onClick={() => {
          popoverConfirmProps && sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Close_Button_In_Modal_Waiting_For_Connecting' });
          !popoverConfirmProps && closeModal();
        }}
        type="button"
      >
        <Icons
          icon="Icn_Clear"
          className={cn(classes.icon, stylesModal?.iconClear || '')}
        />
      </button>
    </Popconfirm>
    {headerText && (
      <div className={cn(
        classes.titleWrap,
        stylesModal?.titleWrap || '',
        isCard && classes.titleWrap_iconsList,
        !!iconsList?.length && classes.titleWrap_iconsList_high
      )}
      >
        <h2 className={cn(classes.title, stylesModal?.title || '', headerUrl && classes.title_hasLink)} size={2}>
          <a
            {...(headerUrl && { target: '_blank', rel: 'noopener noreferrer', href: headerUrl })}
            onClick={() => {
              headerUrl && sendFeedEvent({ category: 'Article', event: 'Clicked_Post_Link_Button' });
            }}
          >
            {headerText}
          </a>
        </h2>
        {infoBtn && (
        <InfoBtn className={classes.infoButton} text={infoBtn} icon="error_outline" />
        )}
        {!!iconsList?.length && (
        <div className={classes.iconsList}>
          {iconsList.map((icon, i) => (
            <Icon
              {...icon}
              key={i}
              className={cn(classes.iconsList_iconButton)}
              btnType={cn(classes.iconsList_icon)}
            />
          ))}
        </div>
        )}
      </div>
    )}
    <div
      className={cn(classes.children, stylesModal?.children || '')}
    >
      {children}
    </div>
  </ReactModal>
);

ModalWindow.propTypes = {
  isOpen: bool,
  closeModal: func,
  stylesModal: shape({
    bg: oneOfType([bool, string]),
    body: string,
    iconClear: string,
    titleWrap: string,
    title: string,
    children: oneOfType([bool, string])
  }),
  iconsList: oneOfType([bool, arrayOf(shape({ type: string, role: string, tooltipText: oneOfType([number, string]), text: oneOfType([number, string]) }))]),
  headerUrl: oneOfType([bool, string]),
  children: oneOfType([node, string]),
  headerText: oneOfType([bool, string]),
  infoBtn: oneOfType([bool, string]),
  datePicker: bool,
  dark: bool,
  isCard: bool,
  popoverConfirmProps: oneOfType([bool, shape({})]),
  shouldCloseOnOverlayClick: bool

};

export default ModalWindow;
