import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function DescendentsTree({ className = '', width = 16, height = 16, color = colors.purple600 }) {
  return (
    <svg width={width || '16'} height={height || '16'} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect y="0.5" width="16" height="16" fill={colors.font50} />
      </mask>
      <g mask="url(#mask0_367_3009)">
        <path
          d="M2.66669 15.8334V11.8334H4.33335V10.5001H2.66669V6.50008H4.33335V5.16675H2.66669V1.16675H7.33335V5.16675H5.66669V6.50008H7.33335V7.83342H9.33335V6.50008H14V10.5001H9.33335V9.16675H7.33335V10.5001H5.66669V11.8334H7.33335V15.8334H2.66669ZM4.00002 14.5001H6.00002V13.1667H4.00002V14.5001ZM4.00002 9.16675H6.00002V7.83342H4.00002V9.16675ZM10.6667 9.16675H12.6667V7.83342H10.6667V9.16675ZM4.00002 3.83341H6.00002V2.50008H4.00002V3.83341Z"
          fill={color || colors.purple600}
        />
      </g>
    </svg>
  );
}

DescendentsTree.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
