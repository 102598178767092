import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';

export const stylesOrdering = isMobile => ({
  control: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '224px',
    minHeight: '28px',
    maxHeight: '28px',
    backgroundColor: state.menuIsOpen ? (PROJECT === 'metricom' ? colors.green25 : colors.purple25) : colors.white,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    opacity: state.isDisabled ? '0.8' : '1',
    cursor: 'pointer',
    margin: 0n
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 8px'
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    minWidth: '280px',
    boxShadow: colors.boxShadow,
    width: 'auto',
    height: 'auto',
    zIndex: 9999,
    ...(isMobile ? { left: 0 } : { right: 0 })
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px',
    overflow: 'hidden'

  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: '400',
    color: colors.grey600
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      fill: `${colors.grey600} !important'`
    }
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '10px 16px',
    height: '39px',
    fontSize: ' 14px',
    lineHeight: '24px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: colors.font400,
    ':nth-of-type(2)': {
      borderBottom: `1px solid ${colors.purpleA100}`,
      height: '43px'
    },
    ':nth-of-type(3)': {
      borderBottom: `1px solid ${colors.purpleA100}`,
      height: '43px'
    },
    borderBottom: '1px solid transparent',
    backgroundColor:
      state.isFocused || state.isSelected ? colors.backgroundSecondary : colors.white,
    ':active': {
      backgroundColor: colors.backgroundSecondary
    }
  })
});

export const stylesTabs = isMobile => ({
  control: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '50px',
    minHeight: '28px',
    maxHeight: '28px',
    backgroundColor: colors.fontBody,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '4px',
    opacity: state.isDisabled ? '0.8' : '1',
    cursor: 'pointer',
    margin: 0
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 2px 0 8px'
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: colors.boxShadow,
    width: 'auto',
    height: 'auto',
    minWidth: '60px',
    zIndex: 9999,
    ...(isMobile ? { left: 0 } : { right: 0 })
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px'
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: '400',
    color: colors.textPrimary
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      fill: `${colors.grey600} !important'`
    }
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '6px 12px',
    height: '30px',
    fontSize: ' 12px',
    lineHeight: '28px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: colors.font400,
    borderBottom: '1px solid transparent',
    backgroundColor:
      state.isFocused || state.isSelected ? colors.backgroundSecondary : colors.white,
    ':active': {
      backgroundColor: colors.backgroundSecondary
    }
  })
});
