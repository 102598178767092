import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Settings({ width = 20, height = 20, color = colors.purple500, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
      <mask id="mask0_535_562" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_535_562)">
        <path d="M8.396 18L7.93766 15.625C7.61822 15.5 7.30919 15.3542 7.01058 15.1875C6.71197 15.0208 6.43072 14.8264 6.16683 14.6042L3.87516 15.375L2.271 12.6042L4.0835 11.0208C4.05572 10.8542 4.03488 10.6875 4.021 10.5208C4.00711 10.3542 4.00016 10.1806 4.00016 10C4.00016 9.81944 4.00711 9.64583 4.021 9.47917C4.03488 9.3125 4.05572 9.14583 4.0835 8.97917L2.271 7.39583L3.87516 4.625L6.16683 5.39583C6.43072 5.17361 6.71197 4.97917 7.01058 4.8125C7.30919 4.64583 7.61822 4.5 7.93766 4.375L8.396 2H11.6043L12.0627 4.375C12.3821 4.5 12.6911 4.64583 12.9897 4.8125C13.2884 4.97917 13.5696 5.17361 13.8335 5.39583L16.1252 4.625L17.7293 7.39583L15.9168 8.97917C15.9446 9.14583 15.9654 9.3125 15.9793 9.47917C15.9932 9.64583 16.0002 9.81944 16.0002 10C16.0002 10.1806 15.9932 10.3542 15.9793 10.5208C15.9654 10.6875 15.9446 10.8542 15.9168 11.0208L17.7293 12.6042L16.1252 15.375L13.8335 14.6042C13.5696 14.8264 13.2884 15.0208 12.9897 15.1875C12.6911 15.3542 12.3821 15.5 12.0627 15.625L11.6043 18H8.396ZM10.0002 13C10.8335 13 11.5418 12.7083 12.1252 12.125C12.7085 11.5417 13.0002 10.8333 13.0002 10C13.0002 9.16667 12.7085 8.45833 12.1252 7.875C11.5418 7.29167 10.8335 7 10.0002 7C9.16683 7 8.4585 7.29167 7.87516 7.875C7.29183 8.45833 7.00016 9.16667 7.00016 10C7.00016 10.8333 7.29183 11.5417 7.87516 12.125C8.4585 12.7083 9.16683 13 10.0002 13Z" fill={color || colors.purple500} />
      </g>
    </svg>
  );
}

Settings.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
