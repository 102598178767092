import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { node, bool } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from './SkeletonNavPage.module.scss';

function SkeletonNavPage({ fetching = false, children = null }) {
  return (
    fetching ?
      <Skeleton count={4} baseColor={colors.fontBody} highlightColor={colors.purpleA100} className={classes.skeletonNavs} containerClassName={classes.skeletonNavsWrapper} inline />
      : children
  );
}

SkeletonNavPage.propTypes = {
  fetching: bool,
  children: node
};
export default SkeletonNavPage;
