import { string, func, bool, shape, number, oneOfType, arrayOf } from 'prop-types';
import cn from 'classnames';
import { Icons, Icon, Tooltip } from 'Components';
import t from 'utils/translateFunc';
import { useNavigate } from 'react-router-dom';
import { closeFooter } from 'storage/actions';
import { useDispatch } from 'react-redux';
import { sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './FilterList.module.scss';

const FilterList = ({
  dataFilters = [],
  selectData = [],
  language = 'uk',
  filterName = '',
  fixedHeight = false,
  onEdit = () => null,
  onRemove = () => null,
  className = '',
  addItem = () => null,
  removeItem = () => null,
  filterType = '',
  setSelectData = () => null,
  filterSearch = ''

}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const onToggle = (filter, isSelect) => {
    if (filterType) {
      isSelect ? removeItem({ id: filter.id, filterName }) : addItem({
        ...filter, id: filter.id, name: filter?.name || filter?.title, type: filterType, filterName
      });
    } else {
      isSelect
        ? setSelectData(selectData?.filter(selectDataItem => selectDataItem.id !== filter.id))
        : setSelectData([...selectData, filter]);
    }
  };

  const searchData = (dataFiltersArr, filterSearchValue) => dataFiltersArr
    ?.filter(({ name, title }) => (name ? name.toLowerCase() : title.toLowerCase())?.includes(filterSearchValue?.toLowerCase()));

  const onInputChange = (filter, isSelect) => {
    const filtersEvents = ['keyword', 'tag', 'country', 'region', 'city', 'level', 'source', 'subject', 'creator', 'language', 'publication_place'];
    filtersEvents.includes(filterName) && !isSelect && sendFeedEvent({
      category: 'Filters',
      event: `filter_${filterName}`
    });
    onToggle(filter, !!isSelect);
  };

  return (
    <ul
      className={cn(
        classes.filtersList,
        !!dataFilters?.length && fixedHeight && classes.filtersList_fixedHeight,
        className
      )}
    >
      {(filterSearch
        ? searchData(dataFilters, filterSearch)
        : dataFilters)?.map((filter) => {
        const isSelect = selectData?.find(selectDataItem => selectDataItem.id === filter.id);
        const text = filter.name || filter.title || selectData?.title;
        return (
          <li
            key={filter?.id}
            className={cn(classes.filter)}
          >
            <label className={classes.checkboxLabel}>
              <input
                className={classes.visually_hidden}
                type="checkbox"
                checked={!!isSelect}
                onChange={() => onInputChange(filter, isSelect)}
              />
              <div className={cn(classes.checkbox, !!isSelect && classes.checkbox_selected)}>
                {!!isSelect && (<Icons icon="check" className={classes.iconCheck} />)}
              </div>
              {filterName === 'tagPopoup' && filter?.color && <div className={classes.colorDot} style={{ backgroundColor: `#${filter?.color}` }} />}
              <Tooltip
                text={String(text).length > 25 ? text : ''}
                className={classes.tooltip}
              >
                {text}
              </Tooltip>
              {!!isSelect?.count && (
                <span>
                  &nbsp;
                  (
                  {isSelect?.count}
                  )
                </span>
              )}
            </label>

            { filter?.id && (
              <div className={classes.btnWrapper}>
                {((filterName === 'tag' || filterName === 'tagPopoup') || (filterName === 'keyword' && !filter?.is_locked)) && (
                <button
                  type="button"
                  onClick={() => {
                    if (filter?.query) {
                      dispatch(closeFooter());
                      history(`/advanced_builder/${filterName === 'tagPopoup' ? 'tag' : filterName}/${filter.id}`);
                      return;
                    }
                    onEdit(filter);
                  }}
                  className={cn(classes.btn, classes.btnEdit)}
                >
                  <Icon type="edit" color={colors.font25} />
                </button>
                )}
                {filterName === 'keyword' && !!filter?.is_locked && (
                <div className={cn(classes.btn, classes.btnEdit)}>
                  <Icon
                    type="support"
                    role="button"
                    tooltipText={t('kSTegFilter', 'KW_EDITED', language)}
                    color={colors.font25}
                  />
                </div>
                )}
                {(filterName === 'tag' || filterName === 'tagPopoup') && (
                <button
                  type="button"
                  onClick={() => {
                    sendFeedEvent({ category: 'Delete tag', event: 'Clicked_Trash_Icon' });
                    onRemove(filter);
                  }}
                  className={cn(classes.btn, classes.btnDelete)}
                >
                  <Icon
                    type="delete"
                    width={13}
                    height={13}
                    color={colors.font25}
                    btnType={classes.iconEdit}
                  />
                </button>
                )}
              </div>
            )}
          </li>
        );
      })}
    </ul>

  );
};

FilterList.propTypes = {
  className: string,
  filterSearch: string,
  selectData: arrayOf(shape({ id: oneOfType([string, number]) })),
  onEdit: func,
  onRemove: func,
  fixedHeight: bool,
  dataFilters: arrayOf(
    shape({
      id: oneOfType([string, number]),
      name: string
    })
  ),
  addItem: func,
  removeItem: func,
  filterType: string,
  setSelectData: func,
  filterName: string,
  language: string
};

export default FilterList;
