import { string, number } from 'prop-types';

export function BlogMediaType({ width = 14, height = 14, color = '#2627D2', className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none">
      <svg className={className} width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1833 8.31661L7.68327 5.79994L9.53327 3.96661L9.04994 3.48327L5.86661 6.66661C5.73327 6.79994 5.57772 6.86661 5.39994 6.86661C5.22216 6.86661 5.06661 6.79994 4.93327 6.66661C4.79994 6.53327 4.73327 6.37494 4.73327 6.19161C4.73327 6.00827 4.79994 5.84994 4.93327 5.71661L8.09994 2.54994C8.36661 2.28327 8.68049 2.14994 9.04161 2.14994C9.40272 2.14994 9.71661 2.28327 9.98327 2.54994L10.4666 3.03327L11.2999 2.19994C11.4333 2.06661 11.5916 1.99994 11.7749 1.99994C11.9583 1.99994 12.1166 2.06661 12.2499 2.19994L13.7999 3.74994C13.9333 3.88327 13.9999 4.04161 13.9999 4.22494C13.9999 4.40827 13.9333 4.56661 13.7999 4.69994L10.1833 8.31661ZM2.66661 13.9999C2.47772 13.9999 2.31938 13.9361 2.19161 13.8083C2.06383 13.6805 1.99994 13.5222 1.99994 13.3333V12.0499C1.99994 11.8722 2.03327 11.7027 2.09994 11.5416C2.16661 11.3805 2.26105 11.2388 2.38327 11.1166L6.73327 6.74994L9.24994 9.24994L4.88327 13.6166C4.76105 13.7388 4.61938 13.8333 4.45827 13.8999C4.29716 13.9666 4.12772 13.9999 3.94994 13.9999H2.66661Z" fill={color || '#2627D2'} />
      </svg>
    </svg>
  );
}

BlogMediaType.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
