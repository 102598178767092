import { useMemo, memo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'storage/actions';
import { sendFeedEvent } from 'services/amplitude/events';
import cn from 'classnames';
import { string, shape } from 'prop-types';
import { isShowMetric } from 'data/metricsConfig';
import t from 'utils/translateFunc';
import like from 'assets/png/reactions/like_small.png';
import love from 'assets/png/reactions/love_small.png';
import funny from 'assets/png/reactions/funny_small.png';
import support from 'assets/png/reactions/support_small.png';
import wow from 'assets/png/reactions/wow_small.png';
import sad from 'assets/png/reactions/sad_small.png';
import angry from 'assets/png/reactions/angry_small.png';
import colors from 'styles/_colors.scss';
import classes from './IconsListMediaDetails.module.scss';
import IconMedia from './IconMedia';

const reactions = { like, love, funny, support, wow, sad, angry };

const IconsListMediaDetails = memo(({ language = 'uk', info = null }) => {
  const dispatch = useDispatch();

  function openTree(types = 'comments') {
    if (types === 'comments' && info?.post_type === 'post' && info?.comment_count === 0) return;
    if (types === 'reposts' && info?.post_type === 'post' && info?.repost_count === 0) return;

    if (types === 'comments') sendFeedEvent({ category: 'Full text', event: 'Clicked_Comments_Button_To_Open_Publication_context_Modal' });
    if (types === 'reposts') sendFeedEvent({ category: 'Full text', event: 'Clicked_Reposts_Button_To_Open_Publication_context_Modal' });

    const source_name = info?.source?.name || info?.source || '';
    const source_url = info?.socialnetworkarticle?.url || info?.forumarticle?.url || info?.blogarticle?.url || info?.url || '';

    const author_name = info?.creators?.length ? info?.creators[0]?.name : '';
    const author_url = source_url || info?.url || '';

    const publication_place_name = info?.publication_place_name || info?.publication_place?.name || '';
    const publication_place_url = info?.publication_place?.url || info?.url || '';

    dispatch(openModal({
      action: 'descendantsTree',
      info: {
        id: info?.id,
        title: info?.title || '',
        source_name: info?.source?.name || info?.source || '',
        media_type: info?.media_type,
        post_type: info?.post_type,
        article_id: info?.looqme_sn_id,
        date: info?.date,
        like_count: info?.like_count || 0,
        comment_count: info?.comment_count || 0,
        repost_count: info?.repost_count || 0,
        views_count: info?.views_count || 0,
        duplicates_count: info?.duplicates_count || 0,
        potential_reach: info?.potential_reach || 0,
        source: { name: source_name, url: source_url },
        author: { name: author_name, url: author_url },
        publication_place: { name: publication_place_name, url: publication_place_url },
        types
      }
    }));
  }

  const engagements = [
    { image: 'like', count: info?.reaction_like_count || 0 },
    { image: 'love', count: info?.reaction_love_count || 0 },
    { image: 'funny', count: info?.reaction_haha_count || 0 },
    { image: 'support', count: info?.reaction_support_count || 0 },
    { image: 'wow', count: info?.reaction_wow_count || 0 },
    { image: 'sad', count: info?.reaction_sorry_count || 0 },
    { image: 'angry', count: info?.reaction_anger_count || 0 }
  ];
  const sortEngagments = [...engagements].filter(item => !!item?.count)?.sort((a, b) => (a.count > b.count ? -1 : 1));

  const iconsList = useMemo(() => {
    if (info?.media_type === 'socialnetwork') {
      return [
        { type: 'has_likes', text: t('tooltip', 'LIKES', language), count: `${info?.like_count || 0}`, size: 15 },
        { type: 'has_comments', text: t('tooltip', 'COMMENTS', language), count: `${info?.comment_count || 0}`, size: 15, has_button: !!Number(info?.comment_count || 0) || info?.post_type !== 'post', handleButton: () => openTree('comments') },
        isShowMetric(info, 'reposts') && { type: 'reposts', text: t('tooltip', 'REPOSTS', language), count: `${info?.repost_count || 0}`, size: 15, has_button: !!Number(info?.repost_count || 0) || info?.post_type !== 'post', handleButton: () => openTree('reposts') }
      ];
    }
    if (info?.media_type === 'review') {
      return [
        { type: 'likes', text: t('tooltip', 'LIKES', language), count: `${info?.like_count || 0}`, size: 15 },
        { type: 'comments', text: t('tooltip', 'COMMENTS', language), count: `${info?.comment_count || 0}`, size: 15 }
      ];
    }
    if (info?.media_type === 'blog') {
      return [
        { type: 'likes', text: t('tooltip', 'LIKES', language), count: `${info?.like_count || 0}`, size: 15 },
        { type: 'comments', text: t('tooltip', 'COMMENTS', language), count: `${info?.comment_count || 0}`, size: 15 }
      ];
    }
    if (info?.media_type === 'forums') {
      return [];
    }
    return [
      { type: 'audience', text: t('tooltip', 'AUDIENCE', language), count: `${info?.source?.attendance || info?.attendance || 0}`, size: 18 },
      { type: 'ave', text: 'AVE', count: `${info?.ave || 0}`, size: 18 }
    ];
  }, [info?.media_type]);
  const showViews = info?.media_type === 'internet' || info?.media_type === 'socialnetwork' || info?.media_type === 'agency' || info?.media_type === 'press';
  return (
    <>
      {info?.media_type === 'review' && info?.post_type !== 'comment' && (
        <IconMedia
          type="kid_star"
          text={t('tooltip', 'RATE', language)}
          color={colors.grey450}
          count={info?.reviewarticle?.rate || 0}
          size={20}
          rate
        />
      )}
      {info?.media_type === 'socialnetwork' && <IconMedia type="group" text={t('tooltip', 'POTENTIAL_REACH', language)} count={info?.potential_reach || 0} size={18} />}
      {info?.media_type !== 'socialnetwork' && info?.media_type !== 'review' && iconsList.map((icon, i) => icon && (<IconMedia key={i} {...icon} />))}
      {info?.media_type !== 'review' && isShowMetric(info, 'doubles') && <IconMedia type="has_doubles" text={t('tooltip', 'DOUBLES', language)} count={info?.duplicates_count || 0} />}
      {showViews && isShowMetric(info, 'views') && (
      <IconMedia
        type="view"
        text={t('tooltip', info?.media_type === 'socialnetwork' ? 'VIEW' : 'VIEWING', language)}
        count={info?.media_type === 'socialnetwork' ? info?.views_count : (info?.source?.average_attendance || info?.average_attendance) || '0'}
      />
      )}
      {info?.media_type === 'socialnetwork' && (
      <div className={classes.socialnetworkWrap}>
        <IconMedia type="engagement" text={t('tooltip', 'ENGAGEMENT', language)} count={info?.engagement || 0} size={18} />
        <div className={cn(classes.iconGroupWrap)}>
          {iconsList.map((icon, i) => icon && (icon?.type === 'has_likes' && !!sortEngagments?.length ? (
            <Fragment key={i}>
              <IconMedia type="has_likes" text={t('tooltip', 'REACTIONS', language)} count={info?.like_count || 0} size={16} />
              {!!sortEngagments?.length && (
              <div className={classes.reactions}>
                { sortEngagments.map(({ image, count }) => (
                  !!count && (
                  <div key={image} className={classes.reaction}>
                    <img alt={image} src={reactions[image]} />
                    <span>{count}</span>
                  </div>
                  )
                ))}
              </div>
              )}
            </Fragment>
          ) : (<IconMedia key={i} {...icon} />)
          ))}
        </div>
      </div>
      )}
      {info?.media_type === 'review' && (
      <div className={classes.socialnetworkWrap}>
        <IconMedia type="engagement" text={t('tooltip', 'ENGAGEMENT', language)} count={info?.engagement || 0} size={18} />
        <div className={cn(classes.iconGroupWrap)}>
          {iconsList.map((icon, i) => <IconMedia key={i} {...icon} />)}
        </div>
        </div>
      )}
    </>
  );
});

IconsListMediaDetails.propTypes = {
  language: string,
  info: shape({})
};

export default IconsListMediaDetails;
