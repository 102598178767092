import { useCallback, useRef, memo } from 'react';
import { func, bool } from 'prop-types';
import cn from 'classnames';
import { Icon, SkeletonMention, SkeletonArticle } from 'Components';
import t from 'utils/translateFunc';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMoreArticles, useGetNewsListOffset } from 'storage/queryHooks.js';
import { toggleArticle, toggleOpenNewsList } from 'storage/actions';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './MentionsList.module.scss';
import ArticlesList from '../ArticlesList';
import KeywordMentionHeader from './KeywordMentionHeader';
import 'react-loading-skeleton/dist/skeleton.css';

const MentionsList = memo(({ onVisible = () => null, onRemoveTag = () => null, onSubmit = () => null, filtersPanelVisible = false }) => {
  const contentRef = useRef(null);
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  const { newsList, chekedArticles, articleDetails, openedNewsList, keywords_offset } = useSelector(state => state?.news);
  const { tabItem } = useSelector(state => state?.filters);

  const { mutateAsync: getMoreArticles, isLoading: articlesLoading, variables } = useGetMoreArticles();
  const { mutateAsync: getMoreNewsListOffset, isLoading: newsOffsetLoading } = useGetNewsListOffset();

  function onChangeArticle({ action, info }) {
    if (action === 'toggle') {
      dispatch(toggleArticle(info));
    }
  }
  function onShowMoreArticles({ keyword_id, next_split, start_split }) {
    getMoreArticles({ keyword_id, next_split, start_split });
  }

  const isSelectArticle = ({ id }) => !!chekedArticles.length && chekedArticles?.some(el => Number(el.article_id) === Number(id));
  const onToggle = (id, isOpened) => {
    const tooggleValue = isOpened ? openedNewsList?.filter(itemId => itemId !== id) : [...openedNewsList, id];
    dispatch(toggleOpenNewsList(tooggleValue));
  };

  function onScroll(e) {
    const scrollHeight = e?.target?.scrollHeight ? Math.ceil(e.target.scrollHeight) : 0;
    const clientHeight = e?.target?.scrollHeight ? Math.ceil(e.target.clientHeight) : 0;
    const maxTopScroll = scrollHeight - clientHeight;
    const currentTopScroll = e?.target?.scrollTop ? Math.ceil(e.target.scrollTop) : 0;
    if (currentTopScroll >= maxTopScroll && !newsOffsetLoading && !!keywords_offset) {
      getMoreNewsListOffset();
    }
  }

  const debouncedClickMentions = useCallback(debounce(onClickMentions, 300), [keywords_offset]);

  function onClickMentions() {
    const contentWrap = contentRef?.current?.clientHeight;
    const contentList = listRef?.current?.clientHeight;
    if (contentWrap >= contentList && !!keywords_offset) {
      getMoreNewsListOffset();
    }
  }

  return (
    <div
      id="mentions-content"
      ref={contentRef}
      className={cn(classes.content, filtersPanelVisible && classes.content_filtersPanelOn)}
      onScroll={onScroll}
      onClickCapture={debouncedClickMentions}
    >
      {!!tabItem?.action && (
        <div className={cn(
          classes.articles,
          classes.articles_tabs,
          (tabItem?.action === 'processed' || tabItem?.action === 'bookmarked') && classes.articles_tabs_green
        )}
        >
          <Icon
            role="icon"
            type={tabItem?.icon}
            className={classes.iconContainerBtn}
            color={colors.font35}
          />
          <div className={cn(classes.iconText)}>{tabItem?.name}</div>
        </div>
      )}
      {!!newsList?.length && (
        <div ref={listRef}>
          {newsList.map(({ articles, keyword, next_split, start_split }) => {
            const isOpened = openedNewsList?.some(itemId => itemId === keyword.id);
            return (
              <div key={keyword.id} className={cn(classes.articles, newsOffsetLoading && classes.articles_loading)}>
                <KeywordMentionHeader
                  keyword={keyword}
                  language={language}
                  onToggle={onToggle}
                  isOpened={isOpened}
                />
                {isOpened && (
                  <ArticlesList
                    articles={articles}
                    detailsId={articleDetails?.id}
                    isSelect={isSelectArticle}
                    onChange={onChangeArticle}
                    onSubmit={onSubmit}
                    onRemoveTag={onRemoveTag}
                    keyword={keyword}
                    onVisible={onVisible}
                    language={language}
                  />
                )}
                { isOpened && next_split && (!articlesLoading || keyword.id !== variables?.keyword_id) && (
                  <div
                    className={classes.showMoreBtn}
                    onClick={() => {
                      sendFeedEvent({ category: 'Article', event: 'Show_More_Button' });
                      onShowMoreArticles({ keyword_id: keyword?.id, next_split, start_split });
                    }}
                  >
                    {t('mentions', 'SHOW_MORE', language)}
                  </div>
                )}
                {articlesLoading && keyword?.id === variables?.keyword_id && (
                <div className={classes.articlesListSkeleton}>
                  {Array(5).fill(null).map((_, i) => <SkeletonArticle key={i} baseColor={colors.fontBody} highlightColor={colors.purpleA100} />)}
                </div>
                )}
              </div>
            );
          })}
          {' '}

        </div>
      )}
      <SkeletonMention fetching={newsOffsetLoading} />
    </div>
  );
}, (prevProps, nextProps) => isEqual(prevProps, nextProps));

MentionsList.propTypes = {
  onVisible: func,
  onRemoveTag: func,
  onSubmit: func,
  filtersPanelVisible: bool
};
export default Sentry.withProfiler(MentionsList);
