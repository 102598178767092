import { string } from 'prop-types';
import colors from 'styles/_colors.scss';

export function AddCircle({ color = colors.font70 }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.1665 13.1667H9.83317V9.83341H13.1665V8.16675H9.83317V4.83341H8.1665V8.16675H4.83317V9.83341H8.1665V13.1667ZM8.99984 17.3334C7.84706 17.3334 6.76373 17.1147 5.74984 16.6772C4.73595 16.2397 3.854 15.6459 3.104 14.8959C2.354 14.1459 1.76025 13.264 1.32275 12.2501C0.885254 11.2362 0.666504 10.1529 0.666504 9.00008C0.666504 7.8473 0.885254 6.76397 1.32275 5.75008C1.76025 4.73619 2.354 3.85425 3.104 3.10425C3.854 2.35425 4.73595 1.7605 5.74984 1.323C6.76373 0.885498 7.84706 0.666748 8.99984 0.666748C10.1526 0.666748 11.2359 0.885498 12.2498 1.323C13.2637 1.7605 14.1457 2.35425 14.8957 3.10425C15.6457 3.85425 16.2394 4.73619 16.6769 5.75008C17.1144 6.76397 17.3332 7.8473 17.3332 9.00008C17.3332 10.1529 17.1144 11.2362 16.6769 12.2501C16.2394 13.264 15.6457 14.1459 14.8957 14.8959C14.1457 15.6459 13.2637 16.2397 12.2498 16.6772C11.2359 17.1147 10.1526 17.3334 8.99984 17.3334ZM8.99984 15.6667C10.8609 15.6667 12.4373 15.0209 13.729 13.7292C15.0207 12.4376 15.6665 10.8612 15.6665 9.00008C15.6665 7.13897 15.0207 5.56258 13.729 4.27091C12.4373 2.97925 10.8609 2.33341 8.99984 2.33341C7.13873 2.33341 5.56234 2.97925 4.27067 4.27091C2.979 5.56258 2.33317 7.13897 2.33317 9.00008C2.33317 10.8612 2.979 12.4376 4.27067 13.7292C5.56234 15.0209 7.13873 15.6667 8.99984 15.6667Z" fill={color} />
    </svg>
  );
}
AddCircle.propTypes = {
  color: string
};
