import { string, func, bool } from 'prop-types';
import cn from 'classnames';
import { Icons } from 'Components';
import classes from './Button.module.scss';

const Button = ({
  textButton = '',
  onClick = () => null,
  className = '',
  type = 'button',
  disabled = false,
  green32 = false,
  greenBig = false,
  pink32 = false,
  purpleTransparent32 = false,
  noBorder = false,
  greyIconText24 = false,
  icon = ''
}) => (
  <button
    type={type}
    onClick={onClick}
    className={cn(
      classes.btn,
      green32 && classes.green32,
      greenBig && classes.greenBig,
      purpleTransparent32 && classes.purpleTransparent32,
      greyIconText24 && !textButton && classes.greyIconText24_circle,
      pink32 && classes.pink32,
      noBorder && classes.noBorder,
      className
    )}
    disabled={disabled}
  >
    {icon && <Icons icon={icon} className={classes.icon} />}
    {textButton && <p>{textButton}</p>}
  </button>
);

Button.propTypes = {
  textButton: string,
  onClick: func,
  className: string,
  type: string,
  disabled: bool,
  green32: bool,
  greenBig: bool,
  pink32: bool,
  noBorder: bool,
  purpleTransparent32: bool,
  greyIconText24: bool,
  icon: string
};

export default Button;
