import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function SearchChecked({ width = 18, height = 18, color = colors.font70, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 18} height={height || 18} viewBox="0 0 18 18" fill="none">
      <mask id="mask0_1624_20084" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_1624_20084)">
        <path d="M7.5 12C8.75 12 9.8125 11.5625 10.6875 10.6875C11.5625 9.8125 12 8.75 12 7.5C12 6.25 11.5625 5.1875 10.6875 4.3125C9.8125 3.4375 8.75 3 7.5 3C6.25 3 5.1875 3.4375 4.3125 4.3125C3.4375 5.1875 3 6.25 3 7.5C3 8.75 3.4375 9.8125 4.3125 10.6875C5.1875 11.5625 6.25 12 7.5 12ZM6.7125 10.1625L10.95 5.90625L9.88125 4.8375L6.7125 8.025L5.11875 6.45L4.05 7.5L6.7125 10.1625ZM7.5 13.5C5.825 13.5 4.40625 12.9187 3.24375 11.7562C2.08125 10.5937 1.5 9.175 1.5 7.5C1.5 5.825 2.08125 4.40625 3.24375 3.24375C4.40625 2.08125 5.825 1.5 7.5 1.5C9.175 1.5 10.5937 2.08125 11.7562 3.24375C12.9187 4.40625 13.5 5.825 13.5 7.5C13.5 8.2 13.3906 8.85937 13.1719 9.47812C12.9531 10.0969 12.6437 10.6625 12.2437 11.175L16.5 15.45L15.45 16.5L11.175 12.2437C10.6625 12.6437 10.0969 12.9531 9.47812 13.1719C8.85937 13.3906 8.2 13.5 7.5 13.5Z" fill={color || colors.font70} />
      </g>
    </svg>
  );
}

SearchChecked.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
