import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Calendar({ width = 18, height = 18, color = colors.font70, className = '' }) {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 18} height={height || 18} viewBox="0 0 18 18" fill="none">
      <mask id="mask0_1671_13540" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_1671_13540)">
        <path d="M3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39688 15.7656 2.25 15.4125 2.25 15V4.5C2.25 4.0875 2.39688 3.73438 2.69063 3.44063C2.98438 3.14688 3.3375 3 3.75 3H4.5V1.5H6V3H12V1.5H13.5V3H14.25C14.6625 3 15.0156 3.14688 15.3094 3.44063C15.6031 3.73438 15.75 4.0875 15.75 4.5V15C15.75 15.4125 15.6031 15.7656 15.3094 16.0594C15.0156 16.3531 14.6625 16.5 14.25 16.5H3.75ZM3.75 15H14.25V7.5H3.75V15ZM3.75 6H14.25V4.5H3.75V6Z" fill={color || colors.font70} />
      </g>
    </svg>

  );
}

Calendar.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
