import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment';
import { bool, func, string, shape } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, ErrorBoundarySentry } from 'Components';
import { DateRange } from 'react-date-range';
import { ru, enGB, uk } from 'react-date-range/dist/locale';
import { sendDownloadsEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import 'react-date-range/dist/styles.css';
import classes from './TableFilterDatepicker.module.scss';
import './DatePicker.scss';

const TableFilterDatepicker = forwardRef(({ setDatePicker = () => null, onReset = () => null, periodRange = { start: '', end: '' }, disabledPeriod = false, language = 'uk' }, ref) => {
  const lang = { uk: uk?.uk, ru: ru?.ru, en: enGB?.enGB };

  useImperativeHandle(ref, () => ({ onResetPeriod }));

  const periodData = [
    { value: 'today', label: t('datepicker', 'TODAY', language) },
    { value: 'yesterday', label: t('datepicker', 'YESTERDAY', language) },
    { value: 'week', label: t('datepicker', 'WEEK', language) },
    { value: 'month', label: t('datepicker', 'MONTH', language) },
    { value: 'quarter', label: t('datepicker', 'QUARTER', language) }
  ];
  const [period, setPeriod] = useState('');
  const [start, set_start] = useState('');
  const [end, set_end] = useState('');

  function onResetPeriod(e) {
    e?.target && sendDownloadsEvent({ category: 'Date Picker', event: 'Clicked_Reset_The_Period_Button_Date_Picker' });
    periodRange && onReset();
    start && set_start('');
    end && set_end('');
    period && setPeriod('');
  }

  useEffect(() => {
    if (periodRange?.start && periodRange?.end) {
      const diffDays = moment(periodRange?.end).diff(moment(periodRange?.start), 'days');
      const startPeriod = moment(periodRange?.start).format('YYYY-MM-DD');
      set_start(periodRange?.start);
      set_end(periodRange?.end);
      if (diffDays === 0 && startPeriod === moment().format('YYYY-MM-DD')) {
        setPeriod('today');
      } else if (diffDays === 0 && startPeriod === moment().subtract(1, 'day').format('YYYY-MM-DD')) {
        setPeriod('yesterday');
      } else if (diffDays === 7 && startPeriod === moment().subtract(1, 'week').format('YYYY-MM-DD')) {
        setPeriod('week');
      } else if (diffDays >= 28 && diffDays <= 31 && startPeriod === moment().subtract(1, 'month').format('YYYY-MM-DD')) {
        setPeriod('month');
      } else if (diffDays >= 88 && diffDays <= 93 && startPeriod === moment().subtract(3, 'month').format('YYYY-MM-DD')) {
        setPeriod('quarter');
      } else {
        setPeriod('');
      }
    }
  }, [periodRange]);

  useEffect(() => {
    switch (period) {
      case 'quarter':
        applyPeriod(moment().startOf('day').subtract(3, 'month'), moment().endOf('minute'));
        break;
      case 'month':
        applyPeriod(moment().startOf('day').subtract(1, 'month'), moment().endOf('minute'));
        break;
      case 'week':
        applyPeriod(moment().startOf('day').subtract(1, 'week'), moment().endOf('minute'));
        break;
      case 'yesterday':
        applyPeriod(moment().startOf('day').subtract(1, 'day'), moment().endOf('day').subtract(1, 'day'));
        break;
      case 'today':
        applyPeriod(moment().startOf('day'), moment().endOf('minute'));
        break;
      default:
        break;
    }
  }, [period]);

  const hadleRangeSelection = ({ selection }) => {
    setPeriod('');
    if (selection) {
      const startValue = moment(selection.startDate);
      const endValue = moment(selection.endDate).endOf('day');
      set_start(startValue.format('YYYY-MM-DD HH:mm'));
      set_end(endValue.format('YYYY-MM-DD HH:mm'));
    }
  };

  const handlePeriod = (e) => {
    if (e.target.value !== period) {
      setPeriod(e?.target?.value);
    }
  };

  function applyPeriod(startPeriod, endPeriod) {
    set_start(startPeriod?.format('YYYY-MM-DD HH:mm'));
    set_end(endPeriod?.format('YYYY-MM-DD HH:mm'));
  }

  const onSubmit = (e) => {
    e.preventDefault();
    sendDownloadsEvent({
      category: 'Date Picker',
      event: 'Clicked_Apply_Button_Date_Picker',
      properties: { start, end }
    });
    setDatePicker({ start, end });
  };

  const color = start || end ? (PROJECT === 'metricom' ? colors.green10 : colors.purple10) : 'transparent';

  const ranges = [{
    startDate: start ? moment(start).toDate() : undefined,
    endDate: end ? moment(end).toDate() : undefined,
    key: 'selection'
  }];

  return (
    <div className={classes.datepickerWrap}>
      <div className={classes.leftPart}>
        <div className={classes.infoSection}>
          <div className={classes.periodBox}>
            {periodData?.map(periodItem => (
              <label
                key={periodItem.value}
                className={cn(
                  classes.period,
                  period === periodItem.value && classes.period_checked,
                  disabledPeriod && classes.period_disabled
                )}
              >
                <input
                  className={classes.visually_hidden}
                  type="radio"
                  name="periodName"
                  value={periodItem.value}
                  checked={period === periodItem.value}
                  onChange={handlePeriod}
                  disabled={disabledPeriod}
                />
                {periodItem.label}
              </label>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.rightPart}>
        <ErrorBoundarySentry>
          <DateRange
            locale={lang[language]}
            className={cn('calendarDownloads', PROJECT === 'metricom' ? 'calendarMetricom' : 'calendarOriginal')}
            onChange={(item) => { hadleRangeSelection(item); }}
            weekdayDisplayFormat="EEEEEE"
            monthDisplayFormat="MMMM yyyy"
            maxDate={new Date()}
            months={1}
            direction="horizontal"
            ranges={ranges}
            rangeColors={[color]}
          // showMonthAndYearPickers={false}
            showMonthAndYearPickers
            showDateDisplay={false}
          />
        </ErrorBoundarySentry>
      </div>
      <div className={classes.btnSection}>
        <Button onClick={onResetPeriod} textButton={t('btn', 'RESET_PERIOD', language)} purpleTransparent32 />
        <Button
          onClick={onSubmit}
          textButton={t('btn', 'APPLY', language)}
          disabled={!start && !end}
          green32
        />
      </div>
    </div>
  );
});

TableFilterDatepicker.propTypes = {
  disabledPeriod: bool,
  periodRange: shape({ start: string, end: string }),
  setDatePicker: func,
  onReset: func,
  language: string
};

export default TableFilterDatepicker;
