import { string, number } from 'prop-types';
import cn from 'classnames';
import colors from 'styles/_colors.scss';

export const ArrowRight = ({ width = 24, height = 24, className = '' }) => (

  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    className={cn(className, 'icon icon-arrow-right')}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.99 13L20 13V11L7.99 11L7.99 8L4 12L7.99 16V13Z" fill={colors.green50} />
  </svg>

);

ArrowRight.propTypes = {
  width: number,
  height: number,
  className: string
};
