import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Table, Popover } from 'antd';
import { Button, SearchField, SelectCustom, Icon, AnimatePage } from 'Components';
import { useNavigate } from 'react-router-dom';
import Modals from 'Containers/Modals';
import { useSelector } from 'react-redux';
import t from 'utils/translateFunc';
import cn from 'classnames';
import moment from 'moment';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import Highlighter from 'react-highlight-words';
import { CalendarOutlined } from '@ant-design/icons';
import { useGetDownloadReports } from 'storage/queryHooks';
import { Trans } from 'react-i18next';
import useDebounceValue from 'hooks/useDebounce';
import useWindowSize from 'hooks/use-window-size';
import { sendDownloadsEvent } from 'services/amplitude/events';
import { isEmpty, isEqual } from 'lodash';
import { PROJECT } from 'config';
import colors from 'styles/_colors.scss';
import classes from './Downloads.module.scss';
import './downloadsTable.scss';
import TableFilterDatepicker from './TableFilterDatepicker';
import StatusOption from './StatusOption';
import { stylesFormat } from './selectStyles';

const { Column } = Table;

export default function Downloads() {
  const history = useNavigate();
  const tableRef = useRef();
  const tableDateRef = useRef();
  const [, height] = useWindowSize();
  const language = useSelector(state => state?.userInfo?.language);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [periodRange, setPeriodRange] = useState(null);
  const [name, setName] = useState('');
  const [fileTypeOption, setFileTypeOption] = useState({ label: t('downloads', 'ALL'), value: '' });
  const [statusOption, setStatusOption] = useState({ label: t('downloads', 'ALL'), value: '' });
  const [sortedInfo, setSortedInfo] = useState({});
  const [limit, setLimit] = useState(10);
  const debouncedName = useDebounceValue(name, 1000);
  const { isLoading } = useGetDownloadReports({
    name: debouncedName,
    sent_at_from: periodRange ? periodRange?.start : null,
    sent_at_to: periodRange ? periodRange?.end : null,
    file_type: fileTypeOption.value,
    status: statusOption.value,
    order: sortedInfo?.column !== undefined ? `${sortedInfo?.order === 'ascend' ? '' : '-'}${sortedInfo?.field}` : null,
    limit,
    offset: (currentPage - 1) * limit
  });
  const { tableDownloadReports, downloadReportsCount, hasDownloadReports } = useSelector(state => state?.downloads);
  const isMobile = useCheckMobileScreen();
  const isFiltersOn = useMemo(() => !!name.length || !!fileTypeOption.value || !!statusOption.value || !!periodRange, [fileTypeOption, name, statusOption, periodRange]);
  const optionsFileType = [
    { label: t('downloads', 'ALL'), value: '' },
    { label: 'PDF', value: 'pdf' },
    { label: 'HTML', value: 'html' },
    { label: 'DOCX', value: 'docx' },
    { label: 'XLS', value: 'xls' }
  ];
  const optionsStatus = [
    { label: t('downloads', 'ALL'), value: '' },
    { label: t('downloads', 'DONE'), value: 'finished' },
    { label: t('downloads', 'ERROR'), value: 'failed' },
    { label: t('downloads', 'PENDING'), value: 'in queue' },
    { label: t('downloads', 'FORMING'), value: 'processing' }];

  const filtersStatus = {
    finished: t('downloads', 'DONE'),
    failed: t('downloads', 'ERROR'),
    'in queue': t('downloads', 'PENDING'),
    processing: t('downloads', 'FORMING'),
    cancelled: t('downloads', 'CANCELLED'),
    archived: t('downloads', 'ARCHIVED'),
    default: ''
  };
  useEffect(() => {
    const newLimit = Math.floor((height - 230) / 60);
    limit !== newLimit && setLimit(newLimit);
  }, [height]);

  const onReset = () => {
    setPeriodRange(null);
    setOpen(false);
    onChangePaginationPage(1);
  };
  const handleOpenChange = (newOpen) => {
    sendDownloadsEvent({ category: 'Date Picker', event: 'Clicked_Date_Picker_Button' });
    setOpen(newOpen);
  };
  const handleChangePeriod = (newPeriod) => {
    setPeriodRange(newPeriod);
    setOpen(false);
    onChangePaginationPage(1);
  };

  function onChangePaginationPage(page, isSendEvent = false) {
    isSendEvent && sendDownloadsEvent({ category: 'Pagination', event: 'Clicked_Change_Page_Button' });
    setCurrentPage(page);
  }

  function onResetFilters() {
    sendDownloadsEvent({ category: 'Filters', event: 'Clicked_Reset_All_Filters_Button' });
    setFileTypeOption({ label: t('downloads', 'ALL'), value: '' });
    setStatusOption({ label: t('downloads', 'ALL'), value: '' });
    setName('');
    onChangePaginationPage(1);
    setPeriodRange(null);
    setSortedInfo({});
    tableDateRef?.current?.onResetPeriod();
  }
  const showTotal = useCallback((total, range) => (
    <Trans
      i18nKey="showTotalReports"
      defaults="{{range1}} – {{range2}} of {{total}} report(s)"
      values={{ range1: range[0], range2: range[1], total }}
      components={{ span: <span style={{ color: colors.font70 }} /> }}
    />
  ), []);

  function onChangeText(text) {
    onChangePaginationPage(1);
    setName(text);
  }

  function onChangeFileType(option) {
    onChangePaginationPage(1);
    sendDownloadsEvent({ category: 'Filters', event: `filter_file_${option?.value}` });
    setFileTypeOption(option);
  }

  function onChangeStatus(option) {
    onChangePaginationPage(1);
    sendDownloadsEvent({ category: 'Filters', event: `filter_status_${option?.value}` });
    setStatusOption(option);
  }

  return (
    <AnimatePage>
      <div className={classes.downloadsWrap} id="downloads-table">
        <div className={classes.filtersWrap}>
          <SearchField
            className={cn(classes.searchFilter, name?.length && classes.searchFilter_filled)}
            placeholder={t('downloads', 'SEARCH_BY_NAME', language)}
            value={name}
            setValue={onChangeText}
          />
          {isFiltersOn && (
          <Icon
            type="restart"
            role="button"
            className={classes.resetButton}
            tooltipText={t('downloads', 'RESET_FILTERS')}
            onClick={onResetFilters}
          />
          )}
          <SelectCustom
            value={!fileTypeOption?.value ? { label: t('downloads', 'FORMAT'), value: '' } : fileTypeOption}
            onChange={onChangeFileType}
            options={optionsFileType}
            className={cn(classes.selectFormat, !!fileTypeOption?.value && classes.selectFormat_selected)}
            styles={stylesFormat({ isMobile, selected: !!fileTypeOption?.value })}
          />
          <Popover
            content={(
              <TableFilterDatepicker
                ref={tableDateRef}
                setDatePicker={handleChangePeriod}
                onReset={onReset}
                periodRange={periodRange}
                language={language}
              />
          )}
            trigger="click"
            placement="bottom"
            arrow={false}
            open={open}
            onOpenChange={handleOpenChange}
          >
            <div className={cn(classes.periodWrap, periodRange && classes.periodWrap_selected)}>
              {!periodRange ? (
                <div>{t('downloads', 'DATE_RECEIPT')}</div>
              ) : (
                <div>
                  {moment(periodRange?.start).format('DD.MM.YY')}
                  {' - '}
                  {moment(periodRange?.end).format('DD.MM.YY')}
                </div>
              )}
              <CalendarOutlined
                style={{
                  color: periodRange ? (PROJECT === 'metricom' ? colors.green600m : colors.purple500) : undefined,
                  marginLeft: 'auto'
                }}
              />
            </div>
          </Popover>
          <SelectCustom
            value={!statusOption?.value ? { label: t('downloads', 'STATUS'), value: '' } : statusOption}
            onChange={onChangeStatus}
            options={optionsStatus}
            className={cn(classes.selectFormat, !!statusOption?.value && classes.selectFormat_selected)}
            styles={stylesFormat({ isMobile, selected: !!statusOption?.value })}
          />
        </div>
        <Table
          dataSource={tableDownloadReports}
          rowKey={record => record.key}
          rowClassName={classes.tableRow}
          ref={tableRef}
          scroll={{ y: `calc(100vh - ${230}px)`, x: '1000px' }}
          loading={isLoading}
          pagination={{
            defaultCurrent: 1,
            pageSize: limit,
            current: currentPage,
            total: downloadReportsCount,
            showLessItems: true,
            showSizeChanger: false,
            onChange: page => onChangePaginationPage(page, true),
            showTotal,
            position: ['bottomCenter'],
            hideOnSinglePage: !tableDownloadReports?.length
          }}
          locale={{
            emptyText: (
              <span className={classes.noDataWrap}>
                <span className={classes.noDataImage} />
                <span className={classes.noDataText}>{t('downloads', hasDownloadReports ? 'NODOWNLOADS_BY_FILTER' : 'NODOWNLOADS')}</span>
                <span className={classes.noDataSubText}>{t('downloads', hasDownloadReports ? 'CHECK_FILTERS' : 'NODOWNLOADS_DESCRIPTION')}</span>
                {hasDownloadReports && (
                <Button
                  onClick={onResetFilters}
                  className={cn(classes.button, classes.resetButton)}
                  textButton={t('btn', 'RESET_TABLE', language)}
                  green32
                />
                )}
                {!hasDownloadReports && (
                <Button
                  onClick={() => { history('/alerts/'); }}
                  className={cn(classes.button)}
                  textButton={t('btn', 'CREATE_ALERT', language)}
                  green32
                />
                )}
                {!hasDownloadReports && (
                <Button
                  onClick={() => {}}
                  className={cn(classes.button)}
                  textButton={t('btn', 'LEARN_MORE', language)}
                  purpleTransparent32
                />
                )}
              </span>)
          }}
          onChange={(pagination, filters, sorter) => {
            pagination && currentPage === pagination?.current && !isEmpty(sorter) && !isEqual(sorter, sortedInfo) && sendDownloadsEvent({ category: 'Sorting', event: `sort_by_${sorter?.field}` });
            setSortedInfo(sorter);
          }}
        >
          <Column
            dataIndex="sent_at"
            title={t('downloads', 'RECEIVED', language)}
            key="sent_at"
            width="10%"
            sortOrder={sortedInfo.columnKey === 'sent_at' ? sortedInfo.order : null}
            sorter
            render={text => (
              <div className={classes.dateWrap}>
                {text ? (
                  <>
                    <div>{moment(text).format('DD MMMM YY')}</div>
                    <div>{moment(text).format('HH:mm')}</div>
                  </>
                ) : '--:--'}
              </div>
            )}
          />
          <Column
            title={t('downloads', 'REPORT_NAME', language)}
            dataIndex="name"
            key="name"
            width="33%"
            ellipsis
            render={text => (
              <Highlighter
                highlightStyle={{
                  backgroundColor: 'transparent',
                  color: colors.purple450,
                  padding: 0,
                  textDecorationLine: 'underline'
                }}
                searchWords={[name]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            )}
          />
          <Column
            title={t('downloads', 'FORMAT')}
            dataIndex="file_type"
            key="file_type"
            width="10%"
            sortOrder={sortedInfo.columnKey === 'file_type' ? sortedInfo.order : null}
            sorter
            render={item => (
              <span className={cn(
                classes.format,
                item === 'docx' && classes.format_docx,
                item === 'pdf' && classes.format_pdf,
                item === 'html' && classes.format_html,
                (item === 'xls' || item === 'xlsex') && classes.format_xls
              )}
              >
                {item}
              </span>
            )}
          />
          <Column
            title={t('downloads', 'PERIOD')}
            dataIndex="period"
            key="period"
            width="20%"
            render={period => (
              <div className={classes.periodWrapper}>
                <div className={classes.dateWrap}>
                  <div>{moment(period.start).format('DD MMMM YY')}</div>
                  <div>{moment(period.start).format('HH:mm')}</div>
                </div>
                <div>—</div>
                <div className={classes.dateWrap}>
                  <div>{moment(period.end).format('DD MMMM YY')}</div>
                  <div>{moment(period.end).format('HH:mm')}</div>
                </div>
              </div>
            )}
          />
          <Column
            title={t('downloads', 'STATUS')}
            dataIndex="status"
            key="status"
            width="15%"
            sortOrder={sortedInfo.columnKey === 'status' ? sortedInfo.order : null}
            sorter
            render={(status, item) => (<StatusOption status={status} id={item?.key} filtersStatus={filtersStatus} classes={classes} />)}
          />
          <Column
            title=""
            dataIndex="url"
            key="url"
            width="150px"
            fixed="right"
            render={link => (
              link ? (
                <div
                  onClick={() => {
                    sendDownloadsEvent({ category: 'Download', event: 'Clicked_Download_Button' });
                    link && window.open(link, '_blank');
                  }}
                  className={classes.downloadBtn}
                >
                  {t('btn', 'DOWNLOAD')}
                </div>
              ) : ''
            )}
          />
        </Table>
        <Modals />
      </div>
    </AnimatePage>
  );
}
