import t from 'utils/translateFunc';
import { PROJECT } from 'config';

export const mediaTypesOptions = language => [
  { value: 'tv', label: t('mediaTypesFilter', 'TV', language), icon: 'tv' },
  { value: 'radio', label: t('mediaTypesFilter', 'RADIO', language), icon: 'radio' },
  { value: 'press', label: t('mediaTypesFilter', 'PRESS', language), icon: 'press' },
  { value: 'internet', label: t('mediaTypesFilter', 'INTERNET', language), icon: 'internet' },
  { value: 'socialnetwork', label: t('mediaTypesFilter', 'SOCIALNETWORK', language), icon: 'socialnetwork' },
  { value: 'agency', label: t('mediaTypesFilter', 'AGENCY', language), icon: 'agency' },
  { value: 'forum', label: t('mediaTypesFilter', 'FORUM', language), icon: 'forum' },
  { value: 'review', label: t('mediaTypesFilter', 'REVIEW', language), icon: 'review' },
  { value: 'blog', label: t('mediaTypesFilter', 'BLOG', language), icon: 'blog' }
].filter(({ value }) => (PROJECT === 'metricom' ? (value === 'internet' || value === 'socialnetwork' || value === 'forum' || value === 'review' || value === 'blog') : true));

export const mediaTypeOption = (type) => {
  if (type === 'tv') return { value: 'tv', label: t('mediaTypesFilter', 'TV'), icon: 'tv' };
  if (type === 'radio') return { value: 'radio', label: t('mediaTypesFilter', 'RADIO'), icon: 'radio' };
  if (type === 'press') return { value: 'press', label: t('mediaTypesFilter', 'PRESS'), icon: 'press' };
  if (type === 'internet') return { value: 'internet', label: t('mediaTypesFilter', 'INTERNET'), icon: 'internet' };
  if (type === 'socialnetwork') return { value: 'socialnetwork', label: t('mediaTypesFilter', 'SOCIALNETWORK'), icon: 'socialnetwork' };
  if (type === 'agency') return { value: 'agency', label: t('mediaTypesFilter', 'AGENCY'), icon: 'agency' };
  if (type === 'forum') return { value: 'forum', label: t('mediaTypesFilter', 'FORUM'), icon: 'forum' };
  if (type === 'review') return { value: 'review', label: t('mediaTypesFilter', 'REVIEW'), icon: 'review' };
  if (type === 'blog') return { value: 'blog', label: t('mediaTypesFilter', 'BLOG'), icon: 'blog' };
  if (type === 'total') return { value: 'total', label: t('mediaTypesFilter', 'SUM'), icon: 'plus' };
  return { };
};

export const postTypesOptions = language => [
  { value: 'post', label: t('postTypes', 'post', language), icon: 'post' },
  { value: 'repost', label: t('postTypes', 'repost', language), icon: 'reposts' },
  { value: 'extendedRepost', label: t('postTypes', 'extendedRepost', language), icon: 'extended_repost' },
  { value: 'comment', label: t('postTypes', 'comment', language), icon: 'comments' },
  { value: 'replyComment', label: t('postTypes', 'replyComment', language), icon: 'reply_comment' }];

export const postTypeOption = (type) => {
  if (type === 'post') return { value: 'post', label: t('postTypes', 'post'), icon: 'post' };
  if (type === 'repost') return { value: 'repost', label: t('postTypes', 'repost'), icon: 'reposts' };
  if (type === 'extendedRepost') return { value: 'extendedRepost', label: t('postTypes', 'extendedRepost'), icon: 'extended_repost' };
  if (type === 'comment') return { value: 'comment', label: t('postTypes', 'comment'), icon: 'comments' };
  if (type === 'replyComment') return { value: 'replyComment', label: t('postTypes', 'replyComment'), icon: 'reply_comment' };
  return { };
};
