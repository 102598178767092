import { memo } from 'react';
import { BtnDropDown } from 'Components';
import { string, func, shape, arrayOf, number } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from './portalsDropDown.module.scss';

const PortalsDropDown = memo(({ active = '', portals = [], onChange = () => null }) => (
  <div className={classes.portal}>
    {portals?.length > 1
      ? (
        <BtnDropDown
          icon="arrow_down"
          offset
          text={active}
          menuConfig={portals}
          selectOption={onChange}
          bodyClassName={classes.btnMoreBody}
          className={classes.btnMore}
          active={active}
          color={colors.font90}
          amplitudeCategory="Header"
          amplitudeEvent="Clicked_On_Dropdown_Menu_Button"
        />
      ) : active}
  </div>
));

PortalsDropDown.propTypes = {
  onChange: func,
  active: string,
  portals: arrayOf(shape({ id: number, name: string, slug: string }))
};

export default PortalsDropDown;
