import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function CopyAll({ width = 24, height = 25, color = colors.font70, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 24} height={height || 25} viewBox="0 0 24 25" fill="none">
      <mask id="mask0_1671_13648" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
        <rect y="0.5" width="24" height="24" fill={color || colors.font70} />
      </mask>
      <g mask="url(#mask0_1671_13648)">
        <path d="M3 18.9995V16.9995H5V18.9995H3ZM3 15.4995V13.4995H5V15.4995H3ZM3 11.9995V9.99951H5V11.9995H3ZM6.5 22.4995V20.4995H8.5V22.4995H6.5ZM9 18.4995C8.45 18.4995 7.97917 18.3037 7.5875 17.912C7.19583 17.5203 7 17.0495 7 16.4995V4.49951C7 3.94951 7.19583 3.47868 7.5875 3.08701C7.97917 2.69535 8.45 2.49951 9 2.49951H18C18.55 2.49951 19.0208 2.69535 19.4125 3.08701C19.8042 3.47868 20 3.94951 20 4.49951V16.4995C20 17.0495 19.8042 17.5203 19.4125 17.912C19.0208 18.3037 18.55 18.4995 18 18.4995H9ZM10 22.4995V20.4995H12V22.4995H10ZM5 22.4995C4.45 22.4995 3.97917 22.3037 3.5875 21.912C3.19583 21.5203 3 21.0495 3 20.4995H5V22.4995ZM13.5 22.4995V20.4995H15.5C15.5 21.0495 15.3042 21.5203 14.9125 21.912C14.5208 22.3037 14.05 22.4995 13.5 22.4995ZM3 8.49951C3 7.94951 3.19583 7.47868 3.5875 7.08701C3.97917 6.69534 4.45 6.49951 5 6.49951V8.49951H3Z" fill={color || colors.font70} />
      </g>
    </svg>

  );
}

CopyAll.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
