import { string, bool, func, oneOfType, arrayOf, shape } from 'prop-types';
import { Icon } from 'Components';
import cn from 'classnames';
import colors from 'styles/_colors.scss';
import classes from './BodyBookmarkMenu.module.scss';

const BodyBookmarkMenu = ({
  activeId = '',
  selectOption = () => null,
  onOpenModal = () => null,
  menuConfig = [],
  className = ''
}) => (
  <ul className={cn(classes.bodyBookmarkMenuChips, className)}>
    {!!menuConfig?.length && menuConfig.map(({ id, name, order }) => (
      <li
        className={cn(
          classes.chipsWrap,
          (id === Number(activeId) || (activeId === 'all' && id === 'all')) && classes.chipsWrap_active
        )}
        key={id}
      >
        <button
          className={classes.chips}
          onClick={() => selectOption(id)}
        >
          <div className={classes.text}>
            {name}
          </div>
        </button>
        {id !== 'all' && (
        <div className={classes.btnWrapper}>
          <button
            type="button"
            onClick={() => { onOpenModal({ action: 'delPage', info: { id, name } }); }}
            className={cn(classes.btn, classes.btnDelete)}
          >
            <Icon
              type="delete"
              width={14}
              height={14}
              color={colors.font25}
              btnType={classes.iconEdit}
            />
          </button>
          <button
            type="button"
            onClick={() => { onOpenModal({ action: 'renamePage', info: { id, name, order: order || 0 } }); }}
            className={cn(classes.btn, classes.btnEdit)}
          >
            <Icon
              type="edit"
              width={14}
              height={14}
              color={colors.font25}
              btnType={classes.iconEdit}
            />
          </button>
        </div>
        )}

      </li>
    ))}
  </ul>
);

BodyBookmarkMenu.propTypes = {
  menuConfig: arrayOf(shape({ name: string, icon: string, info: oneOfType([bool, string]) })),
  className: string,
  selectOption: func,
  onOpenModal: func,
  activeId: string
};

export default BodyBookmarkMenu;
