import colors from 'styles/_colors.scss';

export const stylesFormat = ({ isMobile, selected }) => ({
  control: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '128px',
    minHeight: '24px',
    maxHeight: '24px',
    backgroundColor: colors.white,
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '30px',
    opacity: state.isDisabled ? '0.8' : '1',
    cursor: 'pointer',
    margin: 0,
    border: 'none'

  }),
  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: colors.boxShadow,
    width: '140px',
    height: '205px',
    zIndex: 9999,
    ...(isMobile ? { left: 0 } : { right: 0 })
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px',
    maxHeight: '335px'
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    color: selected ? colors.textPrimary : colors.grey600
  }),
  indicatorsContainer: provided => ({
    ...provided,
    svg: {
      fill: selected ? `${colors.textPrimary} !important` : `${colors.grey600} !important'`
    }
  }),
  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '0 16px',
    height: '39px',
    fontSize: '14px',
    lineHeight: '39px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: colors.font200,
    borderBottom: '1px solid transparent',
    backgroundColor:
      state.isFocused || state.isSelected ? colors.purpleA10 : colors.white,
    ':active': {
      backgroundColor: colors.purpleA10
    }
  })
});
