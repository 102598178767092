import { string, number, bool } from 'prop-types';
import colors from 'styles/_colors.scss';

export function MinusRound({ width = 16, height = 16, color = colors.red600, className = '', isSelected = false }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g>
        {isSelected
          ? <path fillRule="evenodd" clipRule="evenodd" d="M5.39967 14.1423C6.21079 14.4923 7.07745 14.6673 7.99967 14.6673C8.9219 14.6673 9.78856 14.4923 10.5997 14.1423C11.4108 13.7923 12.1163 13.3173 12.7163 12.7173C13.3163 12.1173 13.7913 11.4118 14.1413 10.6007C14.4913 9.78954 14.6663 8.92287 14.6663 8.00065C14.6663 7.07843 14.4913 6.21176 14.1413 5.40065C13.7913 4.58954 13.3163 3.88398 12.7163 3.28398C12.1163 2.68398 11.4108 2.20898 10.5997 1.85898C9.78856 1.50898 8.9219 1.33398 7.99967 1.33398C7.07745 1.33398 6.21079 1.50898 5.39967 1.85898C4.58856 2.20898 3.88301 2.68398 3.28301 3.28398C2.68301 3.88398 2.20801 4.58954 1.85801 5.40065C1.50801 6.21176 1.33301 7.07843 1.33301 8.00065C1.33301 8.92287 1.50801 9.78954 1.85801 10.6007C2.20801 11.4118 2.68301 12.1173 3.28301 12.7173C3.88301 13.3173 4.58856 13.7923 5.39967 14.1423ZM8.66602 8.66732H11.3327V7.33398H8.66602H4.66602V8.66732H8.66602Z" fill={color || colors.red600} />
          : <path fillRule="evenodd" clipRule="evenodd" d="M5.39967 14.1423C6.21079 14.4923 7.07745 14.6673 7.99967 14.6673C8.9219 14.6673 9.78856 14.4923 10.5997 14.1423C11.4108 13.7923 12.1163 13.3173 12.7163 12.7173C13.3163 12.1173 13.7913 11.4118 14.1413 10.6007C14.4913 9.78954 14.6663 8.92287 14.6663 8.00065C14.6663 7.07843 14.4913 6.21176 14.1413 5.40065C13.7913 4.58954 13.3163 3.88398 12.7163 3.28398C12.1163 2.68398 11.4108 2.20898 10.5997 1.85898C9.78856 1.50898 8.9219 1.33398 7.99967 1.33398C7.07745 1.33398 6.21079 1.50898 5.39967 1.85898C4.58856 2.20898 3.88301 2.68398 3.28301 3.28398C2.68301 3.88398 2.20801 4.58954 1.85801 5.40065C1.50801 6.21176 1.33301 7.07843 1.33301 8.00065C1.33301 8.92287 1.50801 9.78954 1.85801 10.6007C2.20801 11.4118 2.68301 12.1173 3.28301 12.7173C3.88301 13.3173 4.58856 13.7923 5.39967 14.1423ZM11.783 11.784C10.7497 12.8173 9.48856 13.334 7.99967 13.334C6.51079 13.334 5.24968 12.8173 4.21634 11.784C3.18301 10.7507 2.66634 9.48954 2.66634 8.00065C2.66634 6.51176 3.18301 5.25065 4.21634 4.21732C5.24968 3.18398 6.51079 2.66732 7.99967 2.66732C9.48856 2.66732 10.7497 3.18398 11.783 4.21732C12.8163 5.25065 13.333 6.51176 13.333 8.00065C13.333 9.48954 12.8163 10.7507 11.783 11.784ZM8.66626 8.66732H11.3329V7.33398H8.66626H4.66626V8.66732H8.66626Z" fill={color || colors.red600} />}
      </g>
    </svg>
  );
}

MinusRound.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string,
  isSelected: bool
};
