import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';

export const Profile = ({ width = 32, height = 32, color = PROJECT === 'metricom' ? colors.green475 : colors.purple200, borderColor = PROJECT === 'metricom' ? colors.green500m : colors.purple500 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} strokeWidth="0.5" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="15.5" stroke={borderColor} />
    <path d="M12.4979 12.5C12.4979 11.8078 12.7032 11.1311 13.0877 10.5555C13.4723 9.97993 14.0189 9.53133 14.6585 9.26642C15.298 9.00152 16.0018 8.9322 16.6807 9.06725C17.3596 9.2023 17.9833 9.53564 18.4728 10.0251C18.9622 10.5146 19.2956 11.1383 19.4306 11.8172C19.5657 12.4961 19.4964 13.1999 19.2315 13.8394C18.9666 14.4789 18.5179 15.0256 17.9424 15.4101C17.3668 15.7947 16.6901 16 15.9979 16C15.0696 16 14.1794 15.6313 13.523 14.9749C12.8666 14.3185 12.4979 13.4283 12.4979 12.5ZM22.9209 21.985L21.5979 19.332C21.3069 18.7514 20.8602 18.2631 20.3076 17.9219C19.755 17.5807 19.1183 17.4 18.4689 17.4H13.5269C12.8774 17.4 12.2408 17.5807 11.6882 17.9219C11.1356 18.2631 10.6888 18.7514 10.3979 19.332L9.07488 21.985C9.02118 22.0916 8.99562 22.2101 9.00061 22.3293C9.00561 22.4485 9.041 22.5645 9.10342 22.6662C9.16584 22.7679 9.25322 22.8519 9.35726 22.9104C9.46131 22.9688 9.57856 22.9996 9.69788 23H22.2979C22.4172 22.9996 22.5345 22.9688 22.6385 22.9104C22.7425 22.8519 22.8299 22.7679 22.8923 22.6662C22.9548 22.5645 22.9902 22.4485 22.9951 22.3293C23.0001 22.2101 22.9746 22.0916 22.9209 21.985Z" fill={color} />
  </svg>
);

Profile.propTypes = {
  width: number,
  height: number,
  borderColor: string,
  color: string
};
