import { string } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Bolt({ color = colors.font70 }) {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 16L4 10.5H0L6.75 0H8.25L7.5 6.5H12L4.5 16H3Z" fill={color} />
    </svg>
  );
}
Bolt.propTypes = {
  color: string
};
