import { useState, useEffect } from 'react';
import { wordToHexColor } from 'utils/common';
import cn from 'classnames';
import { Icon, AnimatedTab } from 'Components';
import { validationEmail } from 'data/validation';
import t from 'utils/translateFunc';
import isEqual from 'lodash/isEqual';
import { oneOfType, func, shape, bool, string, arrayOf } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from '../ReportDetails.module.scss';

function EmailsComponent({ dataEmails = null, error = false, onChangeError = () => null, onChange = () => null }) {
  const [emails, setEmails] = useState(dataEmails?.length ? dataEmails : ['']);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorEmailsIndexes, setErrorEmailsIndexes] = useState([]);

  useEffect(() => {
    !isEqual(emails.filter(Boolean), dataEmails) && setEmails(dataEmails?.length ? dataEmails : ['']);
    clearErrors();
  }, [dataEmails]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const filterEmail = emails.filter(Boolean);
      const emailsArr = filterEmail?.length ? filterEmail : null;
      !isEqual(emailsArr, dataEmails) && onChange(filterEmail);
    }, 1000);
    return () => { clearTimeout(handler); };
  }, [emails]);

  function clearErrors() {
    errorEmailsIndexes?.length && setErrorEmailsIndexes([]);
    errorEmail && setErrorEmail('');
    error && onChangeError('');
  }

  useEffect(() => {
    if (error && dataEmails?.length) {
      const filterEmail = emails.filter(Boolean);
      !isEqual(filterEmail, emails) && setEmails(filterEmail?.length ? filterEmail : ['']);
      const errorsList = dataEmails?.reduce((acc, curr, index) => (!validationEmail(curr) ? acc.concat(index) : acc), []);
      setErrorEmailsIndexes(errorsList?.length ? errorsList : []);
      const errorString = typeof error === 'string' ? error : t('error', 'ERR_EMAIL');
      setErrorEmail(errorsList?.length ? errorString : '');
    }
    if (error && !dataEmails?.length) {
      setErrorEmailsIndexes([0]);
      setErrorEmail(t('error', 'ERR_EMAIL_REQUIRED'));
    }
    if (!error && !!(errorEmail || errorEmailsIndexes?.length)) {
      setErrorEmailsIndexes([]);
      setErrorEmail('');
    }
  }, [error, dataEmails]);

  const editItem = (index, newValue) => {
    clearErrors();
    !!errorEmailsIndexes?.length && setErrorEmailsIndexes(errorEmailsIndexes.filter(errIndex => errIndex !== index));
    const newItems = [...emails];
    newItems[index] = newValue;
    setEmails(newItems);
  };
  const removeItem = (index) => {
    clearErrors();
    sendAlertsEvent({ category: 'Recipient Email', event: 'Clicked_Delete_Recipient' });
    const newItems = emails.filter((_, i) => i !== index);
    setEmails(newItems);
  };

  const onAddReciever = () => {
    sendAlertsEvent({ category: 'Recipient Email', event: 'Clicked_Add_Recipient_Button' });
    if (!!emails?.length && !!emails[emails.length - 1]) {
      clearErrors();
      setEmails(emails.concat(''));
    }
  };

  return (
    <AnimatedTab>
      <div className={classes?.deliveryEmail}>
        {!!emails?.length && emails?.map((item, index) => (
          <div className={classes.emailWrap} key={index}>
            <span className={cn(classes.avatarChart)} style={{ backgroundColor: wordToHexColor(item.slice(0, 3)) }}>
              {!!item && item.charAt(0).toUpperCase()}
            </span>
            <input
              type="text"
              placeholder="example@mail.com"
              className={cn(classes.inputText, !!errorEmailsIndexes?.length && errorEmailsIndexes?.includes(index) && classes.inputText_error)}
              value={item}
              onChange={e => editItem(index, e.target.value)}
            />
            {emails?.length > 1 && (
            <div
              className={classes.deleteIcon}
              onClick={() => removeItem(index)}
            >
              <Icon type="delete" width={16} height={18} color={colors.font70} />
            </div>
            )}
          </div>
        ))}
        {(errorEmail || !!errorEmailsIndexes?.length) && (
        <div className={classes.errorText}>
          {errorEmail}
        </div>
        )}
        <div className={classes.purpleButton} onClick={onAddReciever}>{t('btn', 'ADD_RECIPIENT')}</div>
      </div>
    </AnimatedTab>
  );
}

EmailsComponent.propTypes = {
  dataEmails: oneOfType([arrayOf(string), shape({})]),
  error: oneOfType([bool, string]),
  onChangeError: func,
  onChange: func
};

export default EmailsComponent;
