import { number, string } from 'prop-types';
import colors from 'styles/_colors.scss';

export function ArrowDropDown({ width = 6, height = 6, color = colors.purple400 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 6 4" fill={color}>
      <path d="M0.116251 0.116621C-0.0387502 0.271585 -0.0387502 0.521424 0.116251 0.676387L2.77659 3.33607C2.89996 3.45941 3.09925 3.45941 3.22262 3.33607L5.88612 0.673225C6.03796 0.521424 6.03796 0.268422 5.88612 0.116621C5.73112 -0.0383426 5.48122 -0.0383426 5.32622 0.116621L3.00119 2.43791L0.676157 0.113459C0.521155 -0.0383425 0.271254 -0.0383426 0.116251 0.116621Z" fill={color} />
    </svg>
  );
}

ArrowDropDown.propTypes = {
  color: string,
  width: number,
  height: number
};
