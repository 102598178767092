import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export const KidStar = ({ width = 20, height = 20, color = colors.iconMainColor, className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
    <mask id="mask0_427_367" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill={color || colors.iconMainColor} />
    </mask>
    <g>
      <path d="M6.354 5.33341L8.68734 2.31258C8.854 2.09036 9.05192 1.92716 9.28109 1.823C9.51025 1.71883 9.74984 1.66675 9.99984 1.66675C10.2498 1.66675 10.4894 1.71883 10.7186 1.823C10.9478 1.92716 11.1457 2.09036 11.3123 2.31258L13.6457 5.33341L17.1873 6.52092C17.5484 6.63203 17.8332 6.83689 18.0415 7.1355C18.2498 7.43411 18.354 7.76397 18.354 8.12508C18.354 8.29175 18.3297 8.45842 18.2811 8.62508C18.2325 8.79175 18.1526 8.95147 18.0415 9.10425L15.7498 12.3542L15.8332 15.7709C15.8471 16.257 15.6873 16.6667 15.354 17.0001C15.0207 17.3334 14.6318 17.5001 14.1873 17.5001C14.1596 17.5001 14.0068 17.4792 13.729 17.4376L9.99984 16.3959L6.27067 17.4376C6.20123 17.4654 6.12484 17.4827 6.0415 17.4897C5.95817 17.4966 5.88178 17.5001 5.81234 17.5001C5.36789 17.5001 4.979 17.3334 4.64567 17.0001C4.31234 16.6667 4.15262 16.257 4.1665 15.7709L4.24984 12.3334L1.979 9.10425C1.86789 8.95147 1.78803 8.79175 1.73942 8.62508C1.69081 8.45842 1.6665 8.29175 1.6665 8.12508C1.6665 7.77786 1.7672 7.45494 1.96859 7.15633C2.16998 6.85772 2.45123 6.64592 2.81234 6.52092L6.354 5.33341Z" fill={color || 'fff'} />
    </g>
  </svg>
);
KidStar.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
