import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useWootric = () => {
  const user = useSelector(state => state?.userInfo?.user);
  const openWootric = useCallback(() => {
    if (user?.id && window.location.hostname !== 'app.looqme.io') {
      const setupScript = document.createElement('script');
      const noSurveyedCookie = false;
      const surveyImmediately = false;
      const wootricAccountToken = 'NPS-407f64ef';
      const joined = new Date(user?.date_joined);
      const createdDate = Date.parse(joined) * 0.001;
      setupScript.type = 'text/javascript';
      setupScript.id = 'wootric-settings';
      setupScript.async = true;
      setupScript.innerHTML = `
      wootric_no_surveyed_cookie = ${noSurveyedCookie};
      wootric_survey_immediately = ${surveyImmediately};
      window.wootricSettings = {
        email: "${user?.username || ''}",
        scale_color: 'gradient',
        first_name: "${user?.first_name || ''}",
        last_name: "${user?.last_name || ''}",
        id: "${user?.id || ''}",
        company: "${user?.company || 'LOOQME'}",
        created_at: "${createdDate || ''}",
        account_token: "${wootricAccountToken}"
      };
    `;
      document.getElementById('wootric-settings') === null && document.body.appendChild(setupScript);
      const beacon = document.createElement('script');
      beacon.type = 'text/javascript';
      beacon.id = 'wootric-beacon';
      beacon.async = true;
      beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
      beacon.onload = function () { window.wootric('run'); };
      document.getElementById('wootric-beacon') === null && document.body.appendChild(beacon);
    }
  }, [user?.id]);
  return { openWootric };
};
