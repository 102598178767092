import { useState, useRef, useMemo, useEffect, memo, Fragment } from 'react';
import cn from 'classnames';
import { wordToHexColor } from 'utils/common';
import t from 'utils/translateFunc';
import { Tooltip, Icon } from 'Components';
import { shape, func, bool } from 'prop-types';
import { Trans } from 'react-i18next';
import { sendAlertsEvent } from 'services/amplitude/events';
import { PROJECT } from 'config';
import useResizeObserver from 'hooks/useResizeObserver';
import { Popover } from 'antd';
import classes from './AlertCard.module.scss';

const AlertCard = memo(({ data = {}, isSelected = false, isEnabled = true, onEditAlert = () => null, userContacts = null }) => {
  const [groupRecievers, setGroupRecievers] = useState(false);
  const recieversRef = useRef();
  const editable = !data?.editable && !!data?.id;
  const userEmail = userContacts?.email;
  const userTelegramAccounts = userContacts.telegram_accounts;
  const userWhatsapAccounts = userContacts.whatsapp_accounts;
  const includEmail = useMemo(() => ((userEmail && data?.emails?.length && data?.emails.includes(userEmail)) ? [userEmail] : []), [data?.emails, userEmail]);
  const includTelegramAccount = useMemo(() => ((data?.telegram_users?.length && userTelegramAccounts.length) ? data.telegram_users.reduce((acc, curr) => {
    const includeAccount = userTelegramAccounts?.find(el => el?.telegram_username === curr?.telegram_username);
    includeAccount && acc.push(includeAccount);
    return acc;
  }, []) : []), [data?.telegram_users, userTelegramAccounts]);
  const includWhatsappAccount = useMemo(() => ((data?.whatsapp_accounts?.length && userWhatsapAccounts.length) ? data.whatsapp_accounts.reduce((acc, curr) => {
    const includeAccount = userWhatsapAccounts?.find(el => el?.id === curr?.id);
    includeAccount && acc.push(includeAccount);
    return acc;
  }, []) : []), [data?.whatsapp_accounts, userWhatsapAccounts]);
  const emails = editable ? includEmail : (data?.emails || []);
  const telegrams = editable ? includTelegramAccount : (data?.telegram_users || []);
  const whatsapps = editable ? includWhatsappAccount : (data?.whatsapp_accounts || []);
  const senders = [...telegrams, ...whatsapps, ...emails];
  const senderEmailsSymbolsLenght = useMemo(() => (emails?.length ? emails?.reduce((acc, curr) => acc += curr.trim().length, 0) : 0), [emails]);
  const senderTelegramSymbolsLenght = useMemo(
    () => (telegrams?.length ? telegrams?.reduce((acc, curr) => acc += curr.telegram_username.trim().length, 0) : 0),
    [telegrams]
  );
  const senderWhatsappSymbolsLenght = useMemo(
    () => (whatsapps?.length ? whatsapps?.reduce((acc, curr) => acc += curr.phone.length, 0) : 0),
    [whatsapps]
  );
  const senderTotalSymbols = senderEmailsSymbolsLenght + senderTelegramSymbolsLenght + senderWhatsappSymbolsLenght;
  const queryName = data?.query || data?.query_builder?.keyword;
  const name = data?.keyword?.name || queryName || '';
  const searchByKeyword = data?.searchByTab ? data?.searchByTab === 'keyword' : data?.keyword?.name;
  const supportEmail = PROJECT === 'metricom' ? 'support@metricom' : 'support@looqme.ua';
  const cardOwner = data?.user?.username || supportEmail;

  const { width, targetRef } = useResizeObserver();

  useEffect(() => {
    if (recieversRef?.current) {
      const footerWidth = recieversRef.current?.offsetWidth;
      width < footerWidth && !groupRecievers && setGroupRecievers(true);
      width >= footerWidth && groupRecievers && setGroupRecievers(false);
    }
  }, [width, senders?.length]);

  return (
    <div
      className={cn(classes.alertsCard, isSelected && classes.alertsCard_selected, !isEnabled && classes.alertsCard_disabled)}
      onClick={(e) => {
        if (e.target.dataset.checkbox !== 'switcher') {
          e.stopPropagation();
          sendAlertsEvent({ category: 'Manage alert', event: 'Clicked_Alert_Card' });
          !!data?.editable && onEditAlert({ alert_data: data, isToggle: false });
        }
      }}
    >
      <Tooltip
        text={data?.editable === false ? (<Trans i18nKey="notEditableAlert" values={{ email: cardOwner }} />) : ''}
        trigger="click"
        className={classes.alertsCard_tooltip}
      >
        <div className={classes.alertBody}>
          <div
            className={classes.textWrap}
          >
            <div className={classes.textTitle}>
              {editable && <div className={classes.supportImage} />}
              <Tooltip
                text={data?.name?.length > 20 ? data?.name : ''}
                truncate
              >
                {data?.name || t('alerts', 'ALERT_NAME')}
              </Tooltip>
            </div>
            <div className={classes.textSmallSubTitle}>
              {t('alerts', searchByKeyword ? t('alerts', 'BY_KEYWORD') : t('alerts', 'BY_QUERY'))}
            </div>
            <div className={cn(classes.textSubTitle, !searchByKeyword && queryName && classes.textSubTitle_query)}>
              <Tooltip
                text={name?.length > 35 ? name : ''}
                truncate
              >
                {searchByKeyword ? (data?.keyword?.name || t('alerts', 'CHOOSE_KEYWORD')) : (queryName || t('alerts', 'SET_QUERY'))}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={classes.alertFooter} ref={targetRef}>
          <div className={cn(classes.recieversWrap, groupRecievers && classes.recieversWrap_hide)} ref={recieversRef}>
            {!!senders?.length && senders.map(item => (
              <div key={item?.id || item?.phone || item} className={classes.postItem}>
                <Tooltip
                  text={senderTotalSymbols > 35 ? item?.telegram_username || item?.phone || item : ''}
                  className={classes.postItem}
                >
                  {item?.telegram_username && <div className={classes.telegramImage} />}
                  {item?.phone && <div className={classes.whatsappImage} />}
                  {typeof item === 'string' && (
                  <span className={cn(classes.postAvar)} style={{ backgroundColor: wordToHexColor(item) }}>
                    {item.trim().charAt(0).toUpperCase()}
                  </span>
                  )}
                </Tooltip>
                {senderTotalSymbols <= 35 && (
                <div>
                  <span className={classes.postText}>{item?.telegram_username?.trim() || item?.phone || item?.trim()}</span>
                </div>
                )}
              </div>
            ))}
          </div>
          {!senders?.length && (
          <div className={classes.recieversWrap}>
            <div className={classes.postItem}>
              <span className={classes.postText} />
            </div>
          </div>
          )}
          {!!senders?.length && groupRecievers && (
          <Popover
            destroyTooltipOnHide
            content={(
              <>
                {senders.map((sender, i) => (<p key={i}>{sender?.telegram_username || sender?.phone || sender?.trim()}</p>))}
              </>
)}
          >
            <div className={classes.recieversCommon}>
              <Icon type="mail" />
              <span className={classes.text}>
                {`${senders?.length} ${t('alerts', 'RECIPIENTS')}`}
              </span>
            </div>
          </Popover>
          )}
        </div>
      </Tooltip>
      <div className={cn(classes.switchWrapper, editable && classes.switchWrapper_support)}>
        <label className={cn(classes.switch)}>
          <input
            data-checkbox="switcher"
            type="checkbox"
            onChange={() => {
              data?.id && (data?.emails?.length || data?.telegram_users?.length || data?.whatsapp_accounts?.length) && onEditAlert({ alert_data: data, isToggle: true });
            }}
            checked={isEnabled}
          />
          <span data-checkbox="switcher" className={cn(classes.slider, classes.round)} />
        </label>
      </div>
    </div>
  );
});

AlertCard.propTypes = {
  data: shape({}),
  userContacts: shape({}),
  isEnabled: bool,
  isSelected: bool,
  onEditAlert: func
};

export default AlertCard;
