import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from 'storage';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntercomProvider } from 'react-use-intercom';
import { initializeHotjar } from 'utils/hotjar';
import { PROJECT } from 'config';
import App from './App';
import './lang/i18n';
import './utils/sentry';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      retryDelay: 0
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <IntercomProvider appId={process.env.REACT_INTERCOM_APP_ID || 'lleudin2'} autoBoot={PROJECT !== 'metricom'}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </IntercomProvider>
  </StrictMode>
);
initializeHotjar();
