import { useMemo, forwardRef } from 'react';
import { TextField, Button, Icon, Icons, PopoverColorPicker, SelectCustom, CodeMirror, ErrorBoundarySentry } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { bool, string, func, shape, oneOfType, number } from 'prop-types';
import { sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './QuerySearch.module.scss';

const QuerySearch = forwardRef(({
  preview = false,
  language = 'uk',
  isLoading = true,
  onChangeEditor = () => null,
  onCopyText = () => null,
  onPreview = () => null,
  onChangeName = () => null,
  onSubmit = () => null,
  onConfirmEditig = () => null,
  onChangeColor = () => null,
  onChangeSelected = () => null,
  onChangeAssign = () => null,
  onConfirmDel = () => null,
  editable = true,
  selected = false,
  color = '',
  assignFrom = { value: '', label: '' },
  data = null,
  name = '',
  query = '',
  errorQuery = '',
  errorName = '',
  filterName = '',
  errorValidate = null
}, ref) => {
  const setFor = useMemo(() => [
    { value: '', label: t('sourceAssignTeg', 'CURRENT_MOMENT', language) },
    { value: 'today', label: t('sourceAssignTeg', 'TODAY', language) },
    { value: 'yesterday', label: t('sourceAssignTeg', 'YESTERDAY', language) },
    { value: 'week', label: t('sourceAssignTeg', 'LAST_WEEK', language) },
    { value: 'month', label: t('sourceAssignTeg', 'LAST_MONTH', language) },
    { value: '3month', label: t('sourceAssignTeg', 'LAST_3MONTH', language) },
    { value: '6month', label: t('sourceAssignTeg', 'LAST_6MONTH', language) },
    { value: 'year', label: t('sourceAssignTeg', 'LAST_YEAR', language) },
    { value: '2year', label: t('sourceAssignTeg', 'LAST_2YEARS', language) }
  ], []);

  const labelText = {
    keyword: t('advancedBuilder', 'KEYWORD_NAME', language),
    tag: t('advancedBuilder', 'TAG_NAME', language),
    alert: t('advancedBuilder', 'ALERT_NAME', language),
    default: t('advancedBuilder', 'KEYWORD_NAME', language)
  };
  const labelPlaceholder = {
    keyword: t('advancedBuilder', 'ENTER_NAME_KEYWORD', language),
    tag: t('advancedBuilder', 'ENTER_NAME_TAG', language),
    alert: t('advancedBuilder', 'ENTER_NAME_ALERT', language),
    default: t('advancedBuilder', 'ENTER_NAME_KEYWORD', language)
  };

  return (
    <div className={classes.querySearch}>
      <div className={cn(classes.inputBoxWrapp, filterName === 'tag' && assignFrom?.value && classes.inputBoxWrapp_inform)}>
        <TextField
          labelText={labelText[filterName] || labelText.default}
          value={name}
          onChange={onChangeName}
          placeholder={labelPlaceholder[filterName] || labelPlaceholder.default}
          errorText={errorName}
          classNameForInput={classes.nameInputField}
          classNameLabelText={classes.nameLabel}
          className={classes.nameWrap}
        />
        {filterName === 'tag' && (
          <>
            <div className={classes.selectCustom}>
              <SelectCustom
                selectTitle={t('bodyAddTagModal', 'ASSIGN', language)}
                value={assignFrom}
                placeholder={t('bodyAddTagModal', 'ASSIGN', language)}
                onChange={onChangeAssign}
                options={setFor}
                menuPlacement="bottom"
                styles={{ control: provided => ({ ...provided, backgroundColor: colors.fontBody, border: 'none' }) }}
              />
            </div>
            <div className={classes.colorWrap}>
              <div className={classes.colorsText}>{t('bodyAddTagModal', 'COLOR', language)}</div>
              <PopoverColorPicker
                color={color}
                onChange={onChangeColor}
                className={classes.colorPickerPopover}
              />
            </div>

          </>
        )}
      </div>
      {filterName === 'tag' && assignFrom?.value && (
      <label htmlFor="enable_filters" className={classes.checkboxLabel}>
        <input
          type="checkbox"
          className={classes.visually_hidden}
          onChange={onChangeSelected}
          value={!!selected}
          id="enable_filters"
        />
        <div className={cn(classes.checkbox, selected && classes.checkbox_selected)}>
          {selected && (<Icons icon="check" className={classes.iconCheck} />)}
        </div>
        <div className={classes.checkedText}>{t('bodyAddTagModal', 'INFORM', language)}</div>
      </label>
      )}
      <div className={classes.queryWrap}>
        <div className={classes.queryLabel}>{t('advancedBuilder', 'QUERY_SEARCH', language)}</div>
        <ErrorBoundarySentry language={language}>
          <CodeMirror
            code={query}
            onChange={onChangeEditor}
            onConfirmEditig={onConfirmEditig}
            editable={editable}
            errorValidate={errorValidate}
            errorQuery={errorQuery}
            exampleTitle={t('error', 'EXAMPLE', language)}
            assignValue={assignFrom?.value}
            ref={ref}
          />
        </ErrorBoundarySentry>
        <div
          className={classes.examplesIcon}
          onClick={() => { onCopyText(query); }}
        >
          <Icon type="copy" />
        </div>
        {(!!errorValidate || errorQuery) && (
        <div className={classes.errorMessage}>
            {errorQuery && !errorValidate ? errorQuery : ''}
            {errorValidate && t('advancedBuilder', 'QUERY_ERRORS', language)}
        </div>
        )}
        <div className={cn(classes.infoText)}>
          {t('advancedBuilder', 'USED', language)}
          <b>{` ${query?.length} / 4000`}</b>
        </div>
      </div>

      <div className={classes.buttonBox}>
        {data?.id && filterName === 'keyword' && (
        <div
          onClick={() => {
            sendFeedEvent({ category: 'Delete keyword Advanced QB', event: 'Clicked_Delete_Button_In_Advanced_Query_Builder' });
            onConfirmDel({ keyword_id: data?.id, name });
          }}
          className={classes.buttonDelete}
        >
          {t('btn', 'DELETE', language)}
        </div>
        )}
        {!preview && (
          <Button
            onClick={onPreview}
            textButton={t('btn', 'NEXT', language)}
            green32
            className={classes.nextBtn}
          />
        )}
        {preview && (
          <>
            <Button
              onClick={() => onPreview({ onBack: true })}
              textButton={t('btn', 'BACK', language)}
              purpleTransparent32
            />
            <Button
              type="submit"
              onClick={onSubmit}
              disabled={isLoading}
              textButton={t('btn', data?.isEditAlert || data?.id ? 'SAVE' : 'ADD', language)}
              green32
            />
          </>

        )}
      </div>
    </div>
  );
});

QuerySearch.propTypes = {
  data: shape({}),
  preview: bool,
  language: string,
  isLoading: bool,
  onChangeEditor: func,
  onCopyText: func,
  onPreview: func,
  onChangeName: func,
  onSubmit: func,
  onConfirmEditig: func,
  onChangeColor: func,
  onConfirmDel: func,
  onChangeSelected: func,
  onChangeAssign: func,
  editable: bool,
  name: string,
  query: string,
  errorName: string,
  errorQuery: string,
  selected: bool,
  color: string,
  errorValidate: oneOfType([shape({}), number]),
  assignFrom: shape({ value: string, label: string }),
  filterName: string
};

export default QuerySearch;
