/* eslint-disable react/jsx-one-expression-per-line */
import { useMemo } from 'react';
import { Icon, TagsPopover, EditMentionsPopover } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { string, func, shape, number, arrayOf } from 'prop-types';
import { useEditManyArticles, useDeleteManyArticles } from 'storage/queryHooks';
import { PROJECT } from 'config';
import { sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './IconsBlockFooter.module.scss';

function IconsBlockFooter({ tabItem = '', onClose = () => null, onChangeTab = () => null, language = 'uk', chekedArticles = null }) {
  const isFavoriteTab = tabItem === 'bookmarked';
  const isDeletedTab = tabItem === 'deleted';
  const isMetricom = PROJECT === 'metricom';
  const { mutateAsync: onEditManyArticles } = useEditManyArticles();
  const { mutateAsync: onDeleteManyArticles } = useDeleteManyArticles();
  const chekedArticlesIds = useMemo(() => chekedArticles?.map(({ article_id }) => article_id, []), [chekedArticles.length]);

  const checkedTagsWithCount = useMemo(
    () => chekedArticles
      ?.reduce((acc, curr) => acc.concat(curr?.tags), [])
      ?.reduce((acc, curr) => {
        const includeItem = acc?.length && acc?.find(item => item?.id === curr?.id);
        includeItem
          ? (includeItem.count += 1)
          : acc.push({ ...curr, filterName: 'tagPopoup', type: t('filtrationPanel', 'TAG', language), count: 1 });
        return acc;
      }, []),
    [chekedArticles?.length]
  );
  const someUnprocessed = chekedArticles.some(({ processed }) => !processed);
  const isUnprocessed = tabItem === 'unprocessed' || someUnprocessed;

  const article_ids = useMemo(
    () => chekedArticles?.reduce((acc, { keyword_id, keyword_name, article_id }) => {
      const includ = !!acc.length && acc.some(el => el.keyword_id === keyword_id);
      acc = includ ? acc.map(el => (el?.keyword_id === keyword_id ? { ...el, article_ids: el.article_ids.concat(article_id) } : el)) :
        acc.concat({ keyword_id, keyword_name, article_ids: [article_id] });
      return acc;
    }, []),
    [chekedArticles.length]
  );

  function undoEditAction() {
    sendFeedEvent({ category: 'News_Control_Panel', event: 'Cancel_Add_To_Favorites_Button_Clicked' });
    onEditManyArticles({ article_ids, value: { bookmarked: isFavoriteTab }, notification: { message: t('notif', 'CANCELED', language) } });
  }

  function createUndoEditNotification() {
    const text = t('tabsMenu', 'FAVORITE', language);
    const messageText = `${t('notif', 'ALL', language)} ${chekedArticles?.length} ${t('notif', isFavoriteTab ? 'MENTION_REMOVED' : 'MENTION_ADDED', language)}`;
    const notification = {
      message: messageText,
      redirectText: isFavoriteTab ? <b>{text}</b> : <a onClick={() => { onChangeTab({ name: text, icon: 'favorites', action: 'bookmarked' }); }}>{text}</a>,
      cancelBtn: <span onClick={undoEditAction}><Icon type="undo" />{t('btn', 'CANCEL', language)}</span>
    };
    return notification;
  }

  function createUndoDeleteNotification() {
    const text = t('tabsMenu', 'DELETED', language);
    const messageText = `${t('notif', 'ALL', language)} ${chekedArticles?.length} ${t('notif', isDeletedTab ? 'MENTION_REMOVED' : 'MENTION_ADDED', language)}`;
    const notification = {
      message: messageText,
      redirectText: isDeletedTab ? <b>{text}</b> : <a onClick={() => { onChangeTab({ name: text, icon: 'delete', action: 'deleted' }); }}>{text}</a>,
      cancelBtn: <span onClick={undoDelete}><Icon type="undo" />{t('btn', 'CANCEL', language)}</span>
    };
    return notification;
  }
  function onMoveFavorite() {
    sendFeedEvent({
      category: 'News_Control_Panel',
      event: isFavoriteTab ? 'Remove_Add_To_Favorites_Button_Clicked' : 'Add_To_Favorites_Button_Clicked_In_Newsfeed'
    });
    onEditManyArticles({ article_ids, value: { bookmarked: !isFavoriteTab }, notification: createUndoEditNotification() });
  }
  function undoDelete() {
    onDeleteManyArticles({ is_recovery: true });
  }

  function onDelete(value) {
    sendFeedEvent({ category: 'News_Control_Panel', event: 'Delete_Button_In_Newsfeed' });
    onDeleteManyArticles(value);
  }

  function onOpenInBrowserBlank() {
    sendFeedEvent({ category: 'News_Control_Panel', event: 'Clicked_Open_In_Browser_Button' });
    if (chekedArticles.length) {
      for (let i = 1; i <= chekedArticles.length; i += 1) {
        setTimeout(() => {
          window.open(chekedArticles[i - 1].url, '_blank');
        }, i * 1000);
      }
    }
    onClose();
  }
  function onAddProcessed(value) {
    sendFeedEvent({
      category: 'News_Control_Panel',
      event: value?.processed ? 'Add_To_Processed_Button_Clicked_In_News_Control_Panel' : 'Add_To_Unprocessed_Button_Clicked_In_News_Control_Panel'
    });
    const notification = { message: t('notif', (value?.processed || someUnprocessed) ? 'MENTIONS_ADD_PROCESSED' : 'MENTIONS_ADD_UNPROCESSED', language) };
    onEditManyArticles({ article_ids, value, notification });
  }

  return (
    <div className={classes.iconsFooter}>
      {
        tabItem !== 'deleted' && (
        <>
          <Icon
            type="favorite"
            role="button"
            {...(isFavoriteTab ? { color: isMetricom ? colors.blueA450 : colors.green400 } : null)}
            className={classes.iconContainer}
            btnType={classes.icon}
            tooltipText={t('footer', isFavoriteTab ? 'REMOVE_FAVORITE' : 'ADD_FAVORITE', language)}
            onClick={onMoveFavorite}
          />
          <TagsPopover
            chekedArticlesArr={chekedArticlesIds}
            checkedTags={checkedTagsWithCount}
            language={language}
            idBind="footer"
            assignId="assign_tag"
          />
          <Icon
            type="delete"
            role="button"
            className={cn(classes.iconContainer, classes.iconContainer_edited)}
            btnType={classes.icon}
            tooltipText={t('footer', 'DELETE', language)}
            onClick={() => onDelete({ is_recovery: false, notification: createUndoDeleteNotification() })}
          />
          <Icon
            type="open_browser"
            role="button"
            className={classes.iconContainer}
            btnType={classes.icon}
            tooltipText={t('footer', 'OPEN_BROWSER_BLANK', language)}
            onClick={onOpenInBrowserBlank}
          />
          <Icon
            type={isUnprocessed ? 'processed' : 'unprocessed'}
            color={isUnprocessed ? (isMetricom ? colors.blueA450 : colors.green400) : colors.font35}
            role="button"
            className={cn(classes.iconContainer)}
            btnType={classes.icon}
            tooltipText={t('footer', isUnprocessed ? 'ADD_PROCESSED' : 'ADD_UNPROCESSED', language)}
            onClick={() => onAddProcessed({ processed: !!isUnprocessed })}
          />
          <EditMentionsPopover
            article_ids={article_ids}
            language={language}
            idBind="footer"
          />
        </>
        )
}
      {tabItem === 'deleted' && (
      <Icon
        type="restore"
        color={colors.purple300}
        className={classes.iconContainerBtn}
        textClass={classes.iconText}
        text={t('footer', 'RESTORE', language)}
        onClick={() => onDelete({ is_recovery: true })}
      />
      )}

    </div>
  );
}

IconsBlockFooter.propTypes = {
  language: string,
  onClose: func,
  onChangeTab: func,
  tabItem: string,
  chekedArticles: arrayOf(shape({ id: number, keyword_id: number }))
};

export default IconsBlockFooter;
