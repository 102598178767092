import { useState, useCallback, useEffect } from 'react';
import { Icon, TimeInput, AnimatedTab } from 'Components';
import t from 'utils/translateFunc';
import isEqual from 'lodash/isEqual';
import { oneOfType, func, shape, bool, string, number, arrayOf } from 'prop-types';
import { customAlphabet } from 'nanoid';
import colors from 'styles/_colors.scss';
import classes from '../ReportDetails.module.scss';

function TimesComponent({ timeList = null, error = false, dayId = null, onChange = () => null }) {
  const nanoid = customAlphabet('0123456789', 5);
  const emptyTimeItem = { id: nanoid(), time: '', ...(dayId ? { day: dayId } : {}) };
  const [times, setTimes] = useState(timeList?.length ? timeList : [emptyTimeItem]);
  const [errorTimeIds, setErrorTimeIds] = useState([]);

  useEffect(() => {
    !isEqual(times, timeList) && setTimes(timeList?.length ? timeList : [emptyTimeItem]);
  }, [timeList]);

  const checkErrors = useCallback(
    () => {
      const emptyFirst = times?.length === 1 && !times[0].time;
      const errorsList = (!emptyFirst || error) && times?.reduce((acc, curr) => (curr?.time?.length < 5 ? acc.concat(curr.id) : acc), []);

      setErrorTimeIds(errorsList?.length ? errorsList : []);
    },
    [times, dayId, error]
  );

  function onBlur() {
    checkErrors();
    !isEqual(times, timeList) && onChange({ times });
  }

  useEffect(() => {
    checkErrors();
  }, [times?.length, error]);

  const editItem = (id, newTime) => {
    !!errorTimeIds?.length && setErrorTimeIds(errorTimeIds.filter(errId => errId !== id));
    const newItems = times.map(item => (item?.id === id ? { id, time: newTime } : item));
    setTimes(newItems);
  };
  const removeItem = (id) => {
    const newItems = times.filter(item => item?.id !== id);
    setTimes(newItems);
    !isEqual(newItems, timeList) && onChange({ times: newItems });
  };

  const onAddTime = () => {
    if (!!times?.length && times.length < 12) {
      setTimes(times.concat(emptyTimeItem));
    }
  };

  return (
    <AnimatedTab>
      <div className={classes.timesComponent}>
        <div className={classes.timesListWrap}>
          {!!times?.length && times?.map((item, index) => (
            <div className={classes.timeWrap} key={index}>
              <TimeInput
                value={item?.time || ''}
                hasError={!!errorTimeIds?.length && errorTimeIds?.includes(item?.id)}
                onChange={value => editItem(item.id, value)}
                onBlur={onBlur}
              />
              {times?.length > 1 && (
              <div
                className={classes.deleteIcon}
                onClick={() => removeItem(item.id)}
              >
                <Icon type="delete" width={12} height={14} color={colors.font70} />
              </div>
              )}
            </div>
          ))}
        </div>
        <div className={classes.purpleButton} onClick={onAddTime}>{t('btn', 'ADD_TIME')}</div>
      </div>
    </AnimatedTab>
  );
}

TimesComponent.propTypes = {
  timeList: oneOfType([arrayOf(shape({})), shape({})]),
  dayId: number,
  error: oneOfType([bool, string]),
  onChange: func
};

export default TimesComponent;
