import { DateRange } from 'react-date-range';
import { ru, enGB, uk } from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import { arrayOf, func, shape, string, instanceOf, oneOfType } from 'prop-types';
import cn from 'classnames';
import { PROJECT } from 'config';
import { colorsRange } from '../../helpers';
import './Calendar.scss';

const Calendar = ({ state, hadleRangeSelection, language }) => {
  const { color, colorComparison } = colorsRange;
  const lang = { uk: uk?.uk, ru: ru?.ru, en: enGB?.enGB };

  return (
    <DateRange
      locale={lang[language]}
      className={cn('calendarMain', PROJECT === 'metricom' ? 'calendarMetricom' : 'calendarOriginal')}
      onChange={(item) => {
        hadleRangeSelection(item);
      }}
      weekdayDisplayFormat="EEEEEE"
      months={2}
      direction="horizontal"
      ranges={state}
      rangeColors={[color, colorComparison]}
      showMonthAndYearPickers={false}
      showDateDisplay={false}
    />
  );
};

Calendar.propTypes = {
  state: oneOfType([arrayOf(
    shape({
      endDate: instanceOf(Date),
      key: string,
      startDate: instanceOf(Date)
    })
  ), shape({
    selection: shape({
      endDate: instanceOf(Date),
      key: string,
      startDate: instanceOf(Date)
    })
  })]).isRequired,
  hadleRangeSelection: func.isRequired,
  language: string.isRequired
};

export default Calendar;
