import moment from 'moment';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';

export const hourPars = () => {
  const hours = [];
  for (let i = 0; i <= 23; i += 1) {
    hours.push(String(i).padStart(2, '0'));
  }
  return hours;
};

export const minutePars = () => {
  const minutes = [];
  for (let i = 0; i <= 59; i += 1) {
    minutes.push(String(i).padStart(2, '0'));
  }
  return minutes;
};

export const getYearsArray = function () {
  const today = new Date().getFullYear();
  return new Array(10).fill(today).map((el, i) => el - i).reverse();
};

export const getDaysArray = function (value) {
  const year = new Date().getFullYear();
  const daysInMonth = new Date(year, value, 0).getDate();
  return Array.from(Array(daysInMonth).keys()).map(el => (`0${el + 1}`).slice(-2));
};

export const countDaysDiff = (start, end) => {
  const days = moment(end).diff(moment(start), 'days') + 1;

  return days;
};

export const colorsRange = {
  color: PROJECT === 'metricom' ? colors?.rangeColorsMainMetricom : colors?.rangeColorsMain,
  colorComparison: PROJECT === 'metricom' ? colors?.rangeColorComparisonMetricom : colors?.rangeColorComparison
};
