import { useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { Icon, BodyHtml, CodeMirror } from 'Components';
import { CSSTransition } from 'react-transition-group';
import { bool, func, string } from 'prop-types';
import { textReplaced } from 'data/replacedFunc';
import { sendEvent } from 'services/amplitude/events';
import { PROJECT } from 'config';
import transitionFiltrationPanel from './transitionFiltrationPanel.module.scss';
import classes from './LeftSide.module.scss';
import { templateCompanyKeyword, templateCompanyPerson, templateCompanyTag, templatePersonTag } from '../helpers/teplates.js';

function LeftSide({
  onCopyText = () => null,
  onAdd = () => null,
  filterName = '',
  eventType = '',
  visible = true
}) {
  const nodeRef = useRef(null);
  const { filterId } = useParams();
  const language = useSelector(state => state?.userInfo?.language);
  const [tab, setTab] = useState('operators');
  const [operatorsTab, setOperatorsTab] = useState('operators');
  const [hintsTab, setHintsTab] = useState('examples');
  const [openedTemlates, setOpenedTemlates] = useState([]);
  const isAddOrEdit = eventType || (filterId ? 'Edit' : 'Add');

  const operatorsList = useMemo(() => [{
    buttonText: 'OR ',
    description: t('operatorExamples', 'OR', language),
    example: t('operatorExamples', 'OR_EXAMPLE', language),
    example_description: t('operatorExamples', 'OR_DESCRIPTION', language)
  },
  {
    buttonText: 'AND',
    description: t('operatorExamples', 'AND', language),
    example: t('operatorExamples', 'AND_EXAMPLE', language),
    example_description: t('operatorExamples', 'AND_DESCRIPTION', language)
  },
  {
    buttonText: 'AND NOT',
    description: t('operatorExamples', 'AND_NOT', language),
    example: t('operatorExamples', 'AND_NOT_EXAMPLE', language),
    example_description: t('operatorExamples', 'AND_NOT_DESCRIPTION', language)
  },
  {
    buttonText: '" "',
    description: t('operatorExamples', 'QUOTES', language),
    example: t('operatorExamples', 'QUOTES_EXAMPLE', language),
    example_description: t('operatorExamples', 'QUOTES_DESCRIPTION', language)
  },
  {
    buttonText: '~10',
    description: t('operatorExamples', 'AMOUNT', language),
    example: t('operatorExamples', 'AMOUNT_EXAMPLE', language),
    example_description: t('operatorExamples', 'AMOUNT_DESCRIPTION', language)
  },
  {
    buttonText: '( )',
    description: t('operatorExamples', 'BRACKETS', language),
    example: t('operatorExamples', 'BRACKETS_EXAMPLE', language),
    example_description: t('operatorExamples', 'BRACKETS_DESCRIPTION', language)
  },
  {
    buttonText: '/ /',
    description: t('operatorExamples', 'SLASHES', language),
    example: t('operatorExamples', 'SLASHES_EXAMPLE', language)
  },
  {
    buttonText: '[ ]',
    description: t('operatorExamples', 'SQUARE_QUOTES', language),
    example: t('operatorExamples', 'SQUARE_QUOTES_EXAMPLE', language),
    example_description: t('operatorExamples', 'SQUARE_QUOTES_DESCRIPTION', language)
  },
  {
    buttonText: '.{a,b}',
    description: t('operatorExamples', 'WORD_END', language),
    example: t('operatorExamples', 'WORD_END_EXAMPLE', language),
    example_description: t('operatorExamples', 'WORD_END_DESCRIPTION', language)
  },
  {
    buttonText: '?',
    description: t('operatorExamples', 'QUESTION_SIGN', language),
    example: t('operatorExamples', 'QUESTION_SIGN_EXAMPLE', language),
    example_description: t('operatorExamples', 'QUESTION_SIGN_DESCRIPTION', language)
  },
  {
    buttonText: '.',
    description: t('operatorExamples', 'DOT_SIGN', language),
    example: t('operatorExamples', 'DOT_SIGN_EXAMPLE', language),
    example_description: t('operatorExamples', 'DOT_SIGN_DESCRIPTION', language)
  }
  ], [language]);

  const fieldsList = useMemo(() => [{
    buttonText: 'title:',
    description: t('filtersExamples', 'TITLE', language),
    example: t('filtersExamples', 'TITLE_EXAMPLE', language)
  },
  {
    buttonText: 'text:',
    description: t('filtersExamples', 'TEXT', language),
    example: t('filtersExamples', 'TEXT_EXAMPLE', language)
  },
  {
    buttonText: 'publication_place_name:',
    description: t('filtersExamples', 'PUBLICATION_PLACE_NAME', language),
    example: t('filtersExamples', 'PUBLICATION_PLACE_NAME_EXAMPLE', language),
    example_description: t('filtersExamples', 'PUBLICATION_PLACE_NAME_DESCRIPTION', language)
  },
  {
    buttonText: 'post_type:',
    description: t('filtersExamples', 'POST_TYPE', language),
    example: t('filtersExamples', 'POST_TYPE_EXAMPLE', language),
    example_description: t('filtersExamples', 'POST_TYPE_DESCRIPTION', language)
  },
  {
    buttonText: 'source_type:',
    description: t('filtersExamples', 'SOURCE_TYPE', language),
    example: t('filtersExamples', 'SOURCE_TYPE_EXAMPLE', language),
    example_description: t('filtersExamples', 'SOURCE_TYPE_DESCRIPTION', language)
  },
  {
    buttonText: 'language_code:',
    description: t('filtersExamples', 'LANG_CODE', language),
    example: t('filtersExamples', 'LANG_CODE_EXAMPLE', language),
    example_description: t('filtersExamples', 'LANG_CODE_DESCRIPTION', language)
  }
  // {
  //   buttonText: 'author_name:',
  //   description: t('filtersExamples', 'AUTHOR_NAME', language)
  // },
  // {
  //   buttonText: 'author_url:',
  //   description: t('filtersExamples', 'AUTHOR_URL', language)
  // },
  // {
  //   buttonText: 'author_gender:',
  //   description: t('filtersExamples', 'AUTHOR_GENDER', language)
  // },
  // {
  //   buttonText: 'author_subscribers:',
  //   description: t('filtersExamples', 'AUTHOR_SUBSCRIBERS', language)
  // },

    // {
    //   buttonText: 'publication_place_url:',
    //   description: t('filtersExamples', 'PUBLICATION_PLACE_URL', language)
    // },
    // {
    //   buttonText: 'publication_place_subscribers:',
    //   description: t('filtersExamples', 'PUBLICATION_PLACE_SUBSCRIBERS', language)
    // },

    // {
    //   buttonText: 'likes_count:',
    //   description: t('filtersExamples', 'LIKES_COUNT', language)
    // },
    // {
    //   buttonText: 'comments_count:',
    //   description: t('filtersExamples', 'COMMENTS_COUNT', language)
    // },
    // {
    //   buttonText: 'shares_count:',
    //   description: t('filtersExamples', 'SHARES_COUNT', language)
    // },
    // {
    //   buttonText: 'views_count:',
    //   description: t('filtersExamples', 'VIEWS_COUNT', language)
    // },

  // {
  //   buttonText: 'url:',
  //   description: t('filtersExamples', 'URL', language)
  // }
  ], [language]);

  const hintsListKeywords = useMemo(() => [{
    label: t('hintsList', 'LIST_ALL_KW', language),
    content: 'AppleWatch OR "Apple Watch" OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand'
  },
  {
    label: t('hintsList', 'USE_CONDITIONS', language),
    content: '(AppleWatch OR "Apple Watch" OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand) AND source_type:(tv radio press internet agency)'
  },
  {
    label: t('hintsList', 'USE_PREVIEW', language),
    content: '(AppleWatch OR "Apple Watch" OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand) AND source_type:(tv radio press internet agency) AND NOT (Android OR Nokia)'
  },
  {
    label: t('hintsList', 'CLARIFY_CONTEXT', language),
    content: '((AppleWatch OR "Apple Watch" OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand) AND (technologies OR innovations) AND source_type:(tv radio press internet agency)) AND NOT (Android OR Nokia)'
  },
  {
    label: t('hintsList', 'SPECIFY_WORDS', language),
    content: '(("Apple Watch" OR “AppleWatch”~3 OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand) AND (technologies OR innovations) AND source_type:(tv radio press internet agency)) AND NOT (Android OR Nokia)'
  }
  ], [filterName, language]);

  const hintsListTags = useMemo(() => [{
    label: t('hintsList', 'LIST_ALL_TAGS', language),
    content: 'AppleWatch OR "Apple Watch" OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand'
  },
  {
    label: t('hintsList', 'USE_CONDITIONS', language),
    content: '(AppleWatch OR "Apple Watch" OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand) AND (technologies OR innovations)'
  },
  {
    label: t('hintsList', 'USE_PREVIEW', language),
    content: '(AppleWatch OR "Apple Watch" OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand) AND (technologies OR innovations) AND NOT (Android OR Nokia)'
  },
  {
    label: t('hintsList', 'SPECIFY_WORDS', language),
    content: '("Apple Watch" OR “AppleWatch”~3 OR "Apl Watch" OR AplWatch OR ApleWatch OR AppleWatchUK OR AppleWatchbrand) AND (technologies OR innovations) AND NOT (Android OR Nokia)'
  }
  ], [filterName, language]);

  const hintsList = filterName !== 'tag' ? hintsListKeywords : hintsListTags;

  const templatesList = useMemo(() => [{
    id: 'company_template',
    label: t('templatesList', 'COMPANY_LABEL', language),
    subtext: t('templatesList', 'COMPANY_CONTENT', language),
    content: filterName !== 'tag' ? templateCompanyKeyword(language) : templateCompanyTag(language)
  },
  {
    id: 'person_template',
    label: t('templatesList', 'PERSON_LABEL', language),
    subtext: t('templatesList', 'PERSON_CONTENT', language),
    content: filterName !== 'tag' ? templateCompanyPerson(language) : templatePersonTag(language)
  }
  // {
  //   id: 'phrase_template',
  //   label: t('templatesList', 'PHRASE_LABEL', language),
  //   subtext: t('templatesList', 'PHRASE_CONTENT', language),
  //   content: exampleText
  // }
  ], [filterName, language]);

  function onOpenCloseTemplate(id) {
    const openedId = openedTemlates.includes(id)
      ? openedTemlates.filter(el => el !== id)
      : openedTemlates.concat([id]);
    setOpenedTemlates(openedId);
  }

  function onClickTab(tabName) {
    tab !== tabName && setTab(tabName);
  }

  function onClickOperatorsSubTab(subTabName) {
    operatorsTab !== subTabName && setOperatorsTab(subTabName);
  }

  function onClickHintsSubTab(subTabName) {
    hintsTab !== subTabName && setHintsTab(subTabName);
  }

  return (
    <CSSTransition
      in={visible}
      timeout={350}
      classNames={transitionFiltrationPanel}
      unmountOnExit
      nodeRef={nodeRef}
    >
      <div className={cn(classes.leftSideWrap)} ref={nodeRef}>
        <div className={classes.tabWrapper}>
          <div
            className={cn(classes.tab, tab === 'operators' && classes.tab_active)}
            onClick={() => onClickTab('operators')}
          >
            {t('advancedBuilder', 'OPERATORS', language)}
          </div>
          <div
            className={cn(classes.tab, tab === 'hints' && classes.tab_active)}
            onClick={() => onClickTab('hints')}
          >
            {t('advancedBuilder', 'HINTS', language)}
          </div>
          { PROJECT !== 'metricom' && (
          <div
            className={cn(classes.tab, tab === 'support' && classes.tab_active)}
            onClick={() => onClickTab('support')}
          >
            {t('advancedBuilder', 'SUPPORT', language)}
          </div>
          )}
        </div>
        {tab === 'operators' && (
        <div className={classes.operatorsWrapper}>
          {filterName !== 'tag' && (
          <div className={classes.operatorsTabWrapper}>
            <div
              className={cn(classes.operatorsTab, operatorsTab === 'operators' && classes.operatorsTab_active)}
              onClick={() => onClickOperatorsSubTab('operators')}
            >
              {t('advancedBuilder', 'OPERATORS', language)}
            </div>
            <div
              className={cn(classes.operatorsTab, operatorsTab === 'fields' && classes.operatorsTab_active)}
              onClick={() => onClickOperatorsSubTab('fields')}
            >
              {t('advancedBuilder', 'FIELDS', language)}
            </div>
          </div>
          )}
          {operatorsTab === 'operators' && (
          <div className={classes.operatorsList}>
            {operatorsList.map((item, i) => (
              <div key={i} className={classes.operatorsListItem}>
                <div
                  className={cn(classes.operatorsListItemButton)}
                  onClick={() => { onAdd(` ${item.buttonText} `); }}
                >
                  <BodyHtml html={textReplaced(item.buttonText)} className={classes.operatorsItemText} />
                </div>
                <div className={classes.operatorsListItemText}>
                  {item.description}
                </div>
                <div className={classes.operatorsListItemExample}>
                  {item?.example && <BodyHtml html={textReplaced(item.example)} />}
                  <div>{item?.example_description || ''}</div>
                </div>
              </div>
            ))}
          </div>
          )}
          {operatorsTab === 'fields' && (
          <div className={classes.fieldsList}>
            {fieldsList.map((item, i) => (
              <div key={i} className={classes.fieldsListItem}>
                <div
                  className={cn(classes.fieldsListItemButton)}
                  onClick={() => { onAdd(` ${item.buttonText}`); }}
                >
                  <BodyHtml html={textReplaced(item.buttonText)} className={classes.operatorsItemText} />
                </div>
                <div className={classes.fieldsListItemText}>
                  {item.description}
                </div>
                <div className={classes.fieldsListItemExample}>
                  {item?.example && <BodyHtml html={textReplaced(item.example)} />}
                  <div>{item?.example_description || ''}</div>
                </div>
              </div>
            ))}
          </div>
          )}
        </div>
        )}
        {tab === 'hints' && (
          <div className={classes.hintsWrapper}>
            <div className={classes.hintsTabWrapper}>
              <div
                className={cn(classes.hintsTab, hintsTab === 'examples' && classes.hintsTab_active)}
                onClick={() => onClickHintsSubTab('examples')}
              >
                {t('advancedBuilder', 'HINTS', language)}
              </div>
              <div
                className={cn(classes.hintsTab, hintsTab === 'templates' && classes.hintsTab_active)}
                onClick={() => onClickHintsSubTab('templates')}
              >
                {t('advancedBuilder', 'TEMPLATES', language)}
              </div>
            </div>
            {hintsTab === 'examples' && (
            <div className={classes.examplesList}>
              {hintsList.map((item, i) => (
                <div className={classes.examplesListItem} key={i}>
                  <div className={classes.examplesListItem_label}>{ item.label}</div>
                  <div className={classes.examplesListItem_content}>
                    <BodyHtml html={textReplaced(item.content)} className={classes.examplesListItem_content_text} />
                    <div
                      className={classes.examplesIcon}
                      onClick={() => { onCopyText(item.content); }}
                    >
                      <Icon type="copy" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            )}
            {hintsTab === 'templates' && (
            <div className={classes.hintsBlock}>
              <div className={classes.hintsText}>
                {t('advancedBuilder', 'HINTS_TEXT', language)}
              </div>
              <div className={classes.templatesList}>
                {templatesList.map(item => (
                  <div key={item?.id} className={cn(classes.teplateListItem, openedTemlates.includes(item?.id) && classes.teplateListItem_opened)}>
                    <div
                      className={classes.teplateListItem_label}
                      onClick={() => { onOpenCloseTemplate(item?.id); }}
                    >
                      <Icon
                        type="arrow_outline"
                        className={cn(classes.iconArrow, openedTemlates.includes(item?.id) && classes.iconArrow_opened)}
                      />
                      {item.label}
                    </div>
                    <div className={classes.teplateListItem_subtext}>{item.subtext}</div>
                    <div className={cn(
                      classes.teplateListItem_content,
                      openedTemlates.includes(item?.id) && classes.teplateListItem_content_opened
                    )}
                    >
                      {openedTemlates.includes(item?.id) && (
                        <>
                          <CodeMirror
                            code={item.content}
                            editable={false}
                            customProps={{ height: '100%', maxHeight: 'none' }}
                          />
                          <div
                            className={classes.examplesIcon}
                            onClick={() => { onCopyText(item.content); }}
                          >
                            <Icon type="copy" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}

              </div>
            </div>
            )}
          </div>
        )}
        {tab === 'support' && (
        <div className={classes.supportWrapper}>
          <div
            className={classes.imageWrapper}
            onClick={() => {
              sendEvent({ category: `${isAddOrEdit} ${filterName} Advanced QB`, event: 'Clicked_Knowledge_Base', type: filterName });
              const helpLinkLooqme = 'http://help.looqme.io/uk/articles/8804681-%D1%8F%D0%BA-%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D1%82%D0%B8%D1%81%D1%8F-%D1%80%D0%BE%D0%B7%D1%88%D0%B8%D1%80%D0%B5%D0%BD%D0%B8%D0%BC-%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%BE%D0%BC-%D0%B7%D0%B0%D0%BF%D0%B8%D1%82%D1%96%D0%B2';
              const helpLinkMetricom = 'https://app.metricom.io/instructions/';
              const helpLink = PROJECT === 'metricom' ? helpLinkMetricom : helpLinkLooqme;
              window.open(helpLink, '_blank');
            }}
          >
            <div className={classes.image} />
            <div className={classes.text}>{t('advancedBuilder', 'BASE_KNOLEDGES', language)}</div>
          </div>
        </div>
        )}

      </div>
    </CSSTransition>
  );
}

LeftSide.propTypes = {
  onCopyText: func,
  onAdd: func,
  visible: bool,
  filterName: string,
  eventType: string
};

export default LeftSide;
