import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Xls({ className = '', width = 16, height = 16, color = colors.green800 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none">
      <mask id="mask0_1442_20641" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_1442_20641)">
        <path d="M4 7.66667V4H7.66667V7.66667H4ZM4 12V8.33333H7.66667V12H4ZM8.33333 7.66667V4H12V7.66667H8.33333ZM8.33333 12V8.33333H12V12H8.33333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM3.33333 12.6667H12.6667V3.33333H3.33333V12.6667Z" fill={color || colors.green800} />
      </g>
    </svg>
  );
}

Xls.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
