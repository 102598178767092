/* eslint-disable consistent-return */
import { useEffect, useState, useCallback, useRef } from 'react';

const useResizeObserver = () => {
  const elRef = useRef(null);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const observerRef = useRef(new ResizeObserver((entries) => {
    if (!Array.isArray(entries) || !entries.length) return;
    const entry = entries[0];
    setWidth(Math.round(entry?.contentRect?.width || 0));
    setHeight(Math.round(entry?.contentRect?.height || 0));
  }));

  useEffect(() => {
    const element = elRef.current;
    if (!element) return;
    observerRef.current.observe(element);
    // eslint-disable-next-line consistent-return
    return () => {
      observerRef.current.unobserve(element);
      observerRef.current.disconnect();
    };
  }, []);

  const targetRef = useCallback((element) => {
    if (!element) return;
    elRef.current = element;
  }, []);

  return { targetRef, width, height };
};
// const useResizeObserver = (targetRef) => {
//   const [size, setSize] = useState({ width: 0, height: 0 });

//   useEffect(() => {
//     const target = targetRef?.current;
//     if (!target) return;
//     const handleResize = (entries) => {
//       if (!Array.isArray(entries) || !entries.length) return;
//       const entry = entries[0];
//       setSize({ width: Math.round(entry.contentRect.width), height: Math.round(entry.contentRect.height) });
//     };
//     const resizeObserver = new ResizeObserver(handleResize);
//     resizeObserver.observe(target);
//     return () => { resizeObserver && !!target && resizeObserver.unobserve(target); };
//   }, [targetRef]);

//   return size;
// };

export default useResizeObserver;
