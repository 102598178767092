import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cn from 'classnames';
import { Icon } from 'Components';
import { string, func, number } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from './DrugBlocks.module.scss';

const Block = ({ blockValue = '', blockLabel = '', moveBlock = () => null, index = 0 }) => {
  const ref = useRef();
  const [, drop] = useDrop({
    accept: 'blocks_order',
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      moveBlock(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'blocks_order',
    item: { blockValue, index },
    collect: monitor => ({ isDragging: monitor.isDragging() })
  });
  drag(drop(ref));

  return (
    <div ref={ref} className={cn(classes.drugBlock, isDragging && classes.drugBlock_isDragging)}>
      <div className={cn(classes.contentPart)}>
        <Icon type="drug" />
        <div className={classes.blockLabel}>{blockLabel}</div>
      </div>
      <div className={cn(classes.contentPart, classes.contentPart_click)}>
        <button onClick={() => { moveBlock(index, index !== 0 ? index - 1 : 2); }} type="button">
          <Icon type="arrow_up" color={colors.font70} />
        </button>
        <button onClick={() => { moveBlock(index, index !== 2 ? index + 1 : 0); }} type="button">
          <Icon type="arrow_up" color={colors.font70} className={classes.arrowDown} />
        </button>
      </div>

    </div>
  );
};

Block.propTypes = {
  blockValue: string,
  blockLabel: string,
  moveBlock: func,
  index: number

};

export default Block;
