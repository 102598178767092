export const AMPLITUDE_URL = 'https://api2.amplitude.com';

const BASE_AMPLITUDE_FEED_API_KEY = '1b86069bc16faebea86f735dd4b7884f';
const BASE_AMPLITUDE_ALERTS_API_KEY = 'd991c0c4f53fded845797ceb529a915f';
const BASE_AMPLITUDE_DOWNLOADS_API_KEY = 'db1cc56a73542cdffd0e2ba5b4f11281';
const BASE_AMPLITUDE_SEARCH_API_KEY = 'f933c752217d1f7fef52797bfd1c962e';

const hostnameConfig = {
  localhost: {
    API_URL: 'http://localhost:9000',
    DASHBOARD_URL: 'http://localhost:5003',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: 'local',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: '',
    COPILOT: true
  },
  'test-newsfeed.looqme.io': {
    API_URL: 'https://test-hub.looqme.io',
    DASHBOARD_URL: 'https://test-dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: 'test',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: '',
    COPILOT: true
  },
  'dev-newsfeed.looqme.io': {
    API_URL: 'https://test-hub.looqme.io',
    DASHBOARD_URL: 'https://test-dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: 'test',
    PROJECT: 'metricom',
    AMPLITUDE_SEARCH_API_KEY: '',
    COPILOT: true
  },
  'feed.looqme.io': {
    API_URL: 'https://hub.looqme.io',
    DASHBOARD_URL: 'https://dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: BASE_AMPLITUDE_FEED_API_KEY,
    AMPLITUDE_ALERTS_API_KEY: BASE_AMPLITUDE_ALERTS_API_KEY,
    AMPLITUDE_DOWNLOADS_API_KEY: BASE_AMPLITUDE_DOWNLOADS_API_KEY,
    AMPLITUDE_SLUG: 'feed',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: BASE_AMPLITUDE_SEARCH_API_KEY,
    COPILOT: true
  },
  'trial-feed.looqme.io': {
    API_URL: 'https://trial-hub.looqme.io',
    DASHBOARD_URL: 'https://trial-dashboards.looqme.io',
    AMPLITUDE_FEED_API_KEY: BASE_AMPLITUDE_FEED_API_KEY,
    AMPLITUDE_ALERTS_API_KEY: BASE_AMPLITUDE_ALERTS_API_KEY,
    AMPLITUDE_DOWNLOADS_API_KEY: BASE_AMPLITUDE_DOWNLOADS_API_KEY,
    AMPLITUDE_SLUG: 'trial',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: BASE_AMPLITUDE_SEARCH_API_KEY,
    COPILOT: true
  },
  'feed-special.looqme.io': {
    API_URL: 'https://special.looqme.io',
    DASHBOARD_URL: 'https://dashboard-special.looqme.io',
    AMPLITUDE_FEED_API_KEY: BASE_AMPLITUDE_FEED_API_KEY,
    AMPLITUDE_ALERTS_API_KEY: BASE_AMPLITUDE_ALERTS_API_KEY,
    AMPLITUDE_DOWNLOADS_API_KEY: BASE_AMPLITUDE_DOWNLOADS_API_KEY,
    AMPLITUDE_SLUG: 'special',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: BASE_AMPLITUDE_SEARCH_API_KEY,
    COPILOT: true
  },
  'feed-alinea.looqme.io': {
    API_URL: 'https://alinea.looqme.io',
    DASHBOARD_URL: 'https://dashboard-alinea.looqme.io',
    AMPLITUDE_FEED_API_KEY: BASE_AMPLITUDE_FEED_API_KEY,
    AMPLITUDE_ALERTS_API_KEY: BASE_AMPLITUDE_ALERTS_API_KEY,
    AMPLITUDE_DOWNLOADS_API_KEY: BASE_AMPLITUDE_DOWNLOADS_API_KEY,
    AMPLITUDE_SLUG: 'alinea',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: BASE_AMPLITUDE_SEARCH_API_KEY,
    COPILOT: true
  },
  'feed-scm.looqme.io': {
    API_URL: 'https://scm.looqme.io',
    DASHBOARD_URL: 'https://scm-dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: BASE_AMPLITUDE_FEED_API_KEY,
    AMPLITUDE_ALERTS_API_KEY: BASE_AMPLITUDE_ALERTS_API_KEY,
    AMPLITUDE_DOWNLOADS_API_KEY: BASE_AMPLITUDE_DOWNLOADS_API_KEY,
    AMPLITUDE_SLUG: 'scm',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: BASE_AMPLITUDE_SEARCH_API_KEY,
    COPILOT: true
  },
  'feed-mfa.looqme.io': {
    API_URL: 'https://mfa.looqme.io',
    DASHBOARD_URL: 'https://mfa-dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: BASE_AMPLITUDE_FEED_API_KEY,
    AMPLITUDE_ALERTS_API_KEY: BASE_AMPLITUDE_ALERTS_API_KEY,
    AMPLITUDE_DOWNLOADS_API_KEY: BASE_AMPLITUDE_DOWNLOADS_API_KEY,
    AMPLITUDE_SLUG: 'mfa',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: BASE_AMPLITUDE_SEARCH_API_KEY,
    COPILOT: true
  },
  'feed.metricom.io': {
    API_URL: 'https://app.metricom.io',
    DASHBOARD_URL: 'https://dashboard.metricom.io',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SEARCH_API_KEY: '',
    AMPLITUDE_SLUG: 'metricom',
    PROJECT: 'metricom',
    COPILOT: true
  },
  'newsfeed.looqme.io': {
    API_URL: 'https://test-hub.looqme.io',
    DASHBOARD_URL: 'https://test-dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: '',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: '',
    COPILOT: true
  },
  default: {
    API_URL: 'https://test-hub.looqme.io',
    DASHBOARD_URL: 'https://test-dashboard.looqme.io',
    AMPLITUDE_FEED_API_KEY: '',
    AMPLITUDE_ALERTS_API_KEY: '',
    AMPLITUDE_DOWNLOADS_API_KEY: '',
    AMPLITUDE_SLUG: '',
    PROJECT: '',
    AMPLITUDE_SEARCH_API_KEY: '',
    COPILOT: true
  }
};

export const {
  API_URL,
  DASHBOARD_URL,
  AMPLITUDE_FEED_API_KEY,
  AMPLITUDE_ALERTS_API_KEY,
  AMPLITUDE_DOWNLOADS_API_KEY,
  AMPLITUDE_SLUG,
  PROJECT,
  COPILOT,
  AMPLITUDE_SEARCH_API_KEY
} = hostnameConfig?.[window.location.hostname] || hostnameConfig.default;
