import t from 'utils/translateFunc';

const templateCompanyKeyword = language => `<<<${t('templateCompanyKeyword', 'ADD_NAME', language)}>>>
  (AppleWatch "Apple Watch"

<<<${t('templateCompanyKeyword', 'ADD_ERRORS', language)}>>>
  "Apl Watch" AplWatch ApleWatch AppleWatchUK AppleWatchbrand)

<<<${t('templateCompanyKeyword', 'ADD_URL', language)}>>>
  "applebrand"

<<<${t('templateCompanyKeyword', 'ADD_MEDIA', language)}>>>
  ) AND source_type:(tv radio press internet agency socialnetwork forum review blog)

<<<${t('templateCompanyKeyword', 'ADD_EXEP', language)}>>>
  AND NOT (Android Nokia)
`;

const templateCompanyPerson = language => `<<<${t('templatePersonKeyword', 'ADD_NAME', language)}>>>
  ("James Cheynie" JamesCheynie "J.Cheynie"

<<<${t('templatePersonKeyword', 'ADD_ERRORS', language)}>>>
  "James Cheyni" JimCheynie JCheynie


<<<${t('templatePersonKeyword', 'ADD_MEDIA', language)}>>>
  ) AND source_type:(tv radio press internet agency socialnetwork forum review blog)

<<<${t('templatePersonKeyword', 'ADD_EXEP', language)}>>>
  AND NOT ("Jamez Cheynie" "John Cheynie")
`;

const templateCompanyTag = language => `<<<${t('templateCompanyTag', 'ADD_NAME', language)}>>>
  (AppleWatch "Apple Watch"

<<<${t('templateCompanyTag', 'ADD_ERRORS', language)}>>>
  "Apl Watch" AplWatch ApleWatch

<<<${t('templateCompanyTag', 'ADD_MEDIA', language)}>>>
  AppleWatchUK AppleWatchbrand)

<<<${t('templateCompanyTag', 'ADD_EXEP', language)}>>>
  AND NOT (Android Nokia)
`;

const templatePersonTag = language => `<<<${t('templateCompanyTag', 'ADD_NAME', language)}>>>
  ("James Cheynie" JamesCheynie "J.Cheynie"

<<<${t('templateCompanyTag', 'ADD_ERRORS', language)}>>>
  "James Cheyni" JimCheynie JCheynie)

<<<${t('templateCompanyTag', 'ADD_EXEP', language)}>>>
  AND NOT ("Jamez Cheynie" "John Cheynie")
`;

export { templateCompanyKeyword, templateCompanyPerson, templateCompanyTag, templatePersonTag };
