import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { validateQuery } from 'data/validateQuery';
import { Icon, Loader, SkeletonSearchCharts, NoDataSearch, SkeletonSearchList } from 'Components';
import cn from 'classnames';
import { openModal, toggleSearchDuplicates, changeSearchPageSize, changeSearchOrdering, changeCheckedSearchArcticles,
  editSearchQuery, toggleShowInstructions, editArticlesTab, addSearchFilterQuery, addSearchCharts, addMediaArticles, addSnArticles, addFavoriteSnArticles,
  addFavoriteMediaArticles, editSearchCounts, fitArticles, clearArticles } from 'storage/actions';
import { useGetMediaArticles, useGetSocialnetworkArticles, useGetSearchAnalyticsCharts, useGetSearchCounts,
  useGetFavoriteSearchCounts, useGetFavoriteMediaArticles, useGetFavoriteSocialnetworkArticles, useDowloadSearchReport,
  useToggleFavoriteManyArticle
} from 'storage/queryHooks';
import t from 'utils/translateFunc';
import { useQueryClient } from 'react-query';
import { searchQuerySelector } from 'storage/selectors';
import omit from 'lodash/omit';
import colors from 'styles/_colors.scss';
import { sendSearchEvent } from 'services/amplitude/events';
import classes from './SearchFeed.module.scss';
import SearchQuery from './SearchQuery';
import Instructions from './Instructions';
import SearchResultsHeader from './SearchResultsHeader';
import SearchResultsArticles from './SearchResultsArticles';
import SearchResultsCharts from './SearchResultsCharts';

function SearchFeed() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { searchCharts, media_articles, sn_articles, media_favorite_articles, sn_favorite_articles, searchTab, articlesTab, queryTemp, counts,
    page_size, ordering, duplicates, checkedArticles, filters, selectedSearchFilter, show_instructions } = useSelector(state => state?.search);
  const filtersQuery = useSelector(searchQuerySelector);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const { language, user } = useSelector(state => state?.userInfo);
  const [focusEditor, setFocusEditor] = useState(false);
  const [errorQuery, setErrorQuery] = useState('');
  const [errorValidate, setErrorValidate] = useState(null);
  const [showArrowUp, setShowArrowUp] = useState(false);
  const [scrollMedia, setScrollMedia] = useState({ shift: 20, cursor: {} });
  const [scrollSN, setScrollSN] = useState({ shift: 20, cursor: {} });
  const lastScrollPositionRef = useRef(0);
  const scrollsNumRef = useRef(0);
  const [isShowResultHeader, setIsShowResultHeader] = useState(false);
  const ref = useRef();
  const pageRef = useRef();

  const { isError: chartsIsError, isLoading: chartsLoading, isRefetching: chartsRefetching, refetch: refetchCharts } = useGetSearchAnalyticsCharts();
  const { isError: mediaIsError, isLoading: mediaLoading, isRefetching: mediaRefetching, refetch: refetchMedia } = useGetMediaArticles(scrollMedia);
  const { isError: socialIsError, isLoading: socialLoading, isRefetching: socialRefetching, refetch: refetchSocial } = useGetSocialnetworkArticles(scrollSN);
  const { isLoading: countLoading, isRefetching: countRefetching } = useGetSearchCounts();

  const { isLoading: favoriteMediaLoading, isRefetching: favoriteMediaRefetching } = useGetFavoriteMediaArticles(scrollMedia);
  const { isLoading: favoriteSocialLoading, isRefetching: favoriteSocialRefetching } = useGetFavoriteSocialnetworkArticles(scrollSN);
  const { isLoading: favoriteCountLoading, isRefetching: favoriteCountRefetching } = useGetFavoriteSearchCounts();

  const { isLoading: reportLoading, mutateAsync: downloadReport } = useDowloadSearchReport();
  const { mutateAsync: toggleFavoriteMany } = useToggleFavoriteManyArticle();
  const isFetchingCounts = favoriteCountLoading || countLoading || countRefetching || favoriteCountRefetching;
  const isFetchingArticles = mediaLoading || mediaRefetching || socialLoading || socialRefetching || favoriteMediaLoading || favoriteSocialLoading || favoriteSocialRefetching
  || favoriteMediaRefetching;
  const isFetchingCharts = chartsLoading || chartsRefetching;
  const isFetching = isFetchingCharts || isFetchingArticles;
  const filtersQueryFavorite = omit(filters, ['query']);

  const articles = useMemo(() => {
    if (articlesTab === 'media') return searchTab === 'favorite' ? media_favorite_articles : media_articles;
    if (articlesTab === 'socialnetwork') return searchTab === 'favorite' ? sn_favorite_articles : sn_articles;
    return null;
  }, [searchTab, articlesTab, media_favorite_articles, media_articles, sn_favorite_articles, sn_articles]);

  const hasData = !!(searchCharts || articles);

  useEffect(() => {
    dispatch(clearArticles());
    setScrollMedia({ shift: Number(page_size), cursor: {} });
    setScrollSN({ shift: Number(page_size), cursor: {} });
  }, [searchTab, page_size, filtersQuery, ordering, duplicates, date]);

  useEffect(() => {
    pageRef?.current.scrollTo({ top: 15 });
  }, [searchTab, articlesTab]);

  useEffect(() => {
    const pageContent = pageRef?.current;
    if (articles?.length && pageContent) {
      pageContent.clientHeight < pageContent.scrollHeight && !showArrowUp && setShowArrowUp(true);
      pageContent.clientHeight >= pageContent.scrollHeight && showArrowUp && setShowArrowUp(false);
    }
    !articles?.length && showArrowUp && setShowArrowUp(false);
  }, [articles?.length]);

  useEffect(() => {
    restoreCachedChartsAndCounts();
  }, [filtersQuery, duplicates, date]);

  useEffect(() => {
    restoreCachedSNArticles();
  }, [filtersQuery, page_size, ordering, duplicates, date, scrollSN]);

  useEffect(() => {
    restoreCachedMediaArticles();
  }, [filtersQuery, page_size, ordering, duplicates, date, scrollMedia]);

  useEffect(() => {
    if (selectedSearchFilter?.id) {
      !!errorQuery && setErrorQuery('');
      !!errorValidate && setErrorValidate(null);
    }
  }, [selectedSearchFilter?.id]);

  useEffect(() => {
    if (show_instructions && (hasData || isFetching)) {
      dispatch(toggleShowInstructions(false));
    }
  }, []);

  function isScrolledDown() {
    if (
      (searchTab === 'search' && articlesTab === 'media' && media_articles?.length && scrollMedia?.shift > 0) ||
      (searchTab === 'search' && articlesTab === 'socialnetwork' && sn_articles?.length && scrollSN?.shift > 0) ||
      (searchTab === 'favorite' && articlesTab === 'media' && media_favorite_articles?.length && scrollMedia?.shift > 0) ||
      (searchTab === 'favorite' && articlesTab === 'socialnetwork' && sn_favorite_articles?.length && scrollSN?.shift > 0)
    ) return true;
    return false;
  }

  function restoreCachedChartsAndCounts() {
    const cachedCharts = queryClient.getQueryData(['getSearchAnalyticsCharts', filtersQuery, duplicates, date]);
    const cachedCounts = queryClient.getQueryData(['getSearchCounts', filtersQuery, duplicates, date]);
    cachedCharts && dispatch(addSearchCharts(cachedCharts));
    cachedCounts && dispatch(editSearchCounts(cachedCounts));
    // queryClient.invalidateQueries({ queryKey: ['getSearchAnalyticsCharts', filtersQuery, duplicates, date] });
  }

  function restoreCachedSNArticles() {
    const cachedSnArticles = queryClient.getQueryData(['getSocialnetworkArticles', filtersQuery, page_size, ordering, duplicates, date, scrollSN]);
    const cachedSnFavoritesArticles = queryClient.getQueryData(['getFavoriteSocialnetworkArticles', filtersQueryFavorite, page_size, ordering, scrollSN]);
    cachedSnArticles && dispatch(addSnArticles(cachedSnArticles));
    cachedSnFavoritesArticles && dispatch(addFavoriteSnArticles(cachedSnFavoritesArticles));
    // cachedSnArticles ? dispatch(addSnArticles(cachedSnArticles)) : queryClient.invalidateQueries({ queryKey: ['getSocialnetworkArticles', filtersQuery, page_size, ordering, duplicates, date, scroll_sn || scrollSN] });
    // cachedSnFavoritesArticles ? dispatch(addFavoriteSnArticles(cachedSnFavoritesArticles)) : queryClient.invalidateQueries({ queryKey: ['getFavoriteSocialnetworkArticles', filtersQueryFavorite, page_size, ordering, scroll_sn || scrollSN] });
  }

  function restoreCachedMediaArticles() {
    const cachedMediaArticles = queryClient.getQueryData(['getMediaArticles', filtersQuery, page_size, ordering, duplicates, date, scrollMedia]);
    const cachedMediaFavoritesArticles = queryClient.getQueryData(['getFavoriteMediaArticles', filtersQueryFavorite, page_size, ordering, scrollMedia]);
    cachedMediaArticles && dispatch(addMediaArticles(cachedMediaArticles));
    cachedMediaFavoritesArticles && dispatch(addFavoriteMediaArticles(cachedMediaFavoritesArticles));
    // cachedMediaArticles ? dispatch(addMediaArticles(cachedMediaArticles)) : queryClient.invalidateQueries({ queryKey: ['getMediaArticles', filtersQuery, page_size, ordering, duplicates, date, scroll_media || scrollMedia] });
    // cachedMediaFavoritesArticles ? dispatch(addFavoriteMediaArticles(cachedMediaFavoritesArticles)) : queryClient.invalidateQueries({ queryKey: ['getFavoriteMediaArticles', filtersQueryFavorite, page_size, ordering, scroll_media || scrollMedia] });
  }

  const onChangeEditor = (text) => {
    errorQuery && setErrorQuery('');
    onFocus();
    dispatch(editSearchQuery(text));
    onCheckValidateErrors(text);
  };

  function onCheckValidateErrors(text) {
    if (text?.length) {
      const err = validateQuery({ query: text, language });
      !isEqual(err, errorValidate) && setErrorValidate(err);
    } else {
      !text.length && setErrorValidate(null);
    }
  }

  function onCheckErrors() {
    queryTemp.length < 1 && setErrorQuery(t('error', 'MIN_SYMBOLS'));
    !queryTemp.length && setErrorQuery(t('error', 'ERR_REQUIRED'));
    queryTemp.length > 4000 && setErrorQuery(t('error', 'MAX_SYMBOLS'));
    return !queryTemp.length || queryTemp.length < 1 || queryTemp.length > 4000 || errorValidate;
  }

  function onFocus() {
    !focusEditor && setFocusEditor(true);
    ref?.current?.view?.contentDOM.focus();
  }

  function onBlur() {
    !!focusEditor && setFocusEditor(false);
    ref.current.view?.scrollDOM?.scrollTo(0, 0);
  }

  function toggleInstructions() {
    const alwaysShow = searchTab === 'search'
    && ((articlesTab === 'analitycs' && !searchCharts) || (articlesTab === 'media' && !media_articles)
    || (articlesTab === 'socialnetwork' && !sn_articles));
    if (!alwaysShow) {
      dispatch(toggleShowInstructions(!show_instructions));
      sendSearchEvent({ category: 'Search_Instructions', event: !show_instructions ? 'Open_Instructions' : 'Hide_Instructions' });
    }
  }

  function refetchOnError() {
    articlesTab === 'analitycs' && chartsIsError && refetchCharts();
    articlesTab === 'media' && mediaIsError && refetchMedia();
    articlesTab === 'socialnetwork' && socialIsError && refetchSocial();
  }

  function onSearch() {
    const hasErrors = onCheckErrors();
    if (!hasErrors && queryTemp !== filters?.query) {
      let queryTempClear = queryTemp.replace(/\r?\n|\r/g, '');
      queryTempClear = queryTempClear.replace(/<<<[\w\W]*?>>>/g, '');
      dispatch(editSearchQuery(queryTempClear));
      dispatch(addSearchFilterQuery(queryTempClear));
      sendSearchEvent({ category: 'Search_Query', event: 'Enter_Search', properties: { query: queryTempClear } });
      !!show_instructions && dispatch(toggleShowInstructions(false));
      refetchOnError();
    }
  }

  function onOpenHistory() {
    dispatch(openModal({ action: 'searchHistory', info: null }));
    sendSearchEvent({ category: 'Search_History', event: 'Open_Search_History' });
  }

  function handleScrollToTop() {
    pageRef?.current.scrollTo({ top: 10 });
    if (articlesTab === 'media') {
      dispatch(clearArticles({ type: 'media_articles' }));
      dispatch(clearArticles({ type: 'media_favorite_articles' }));
      setScrollMedia({ shift: Number(page_size), cursor: {} });
    }
    if (articlesTab === 'socialnetwork') {
      dispatch(clearArticles({ type: 'sn_articles' }));
      dispatch(clearArticles({ type: 'sn_favorite_articles' }));
      setScrollSN({ shift: Number(page_size), cursor: {} });
    }
    pageRef?.current.scrollTo({ top: 10 });
  }

  function onChangeArticleTab(value) {
    if (value !== articlesTab) {
      dispatch(editArticlesTab(value));
    }
  }

  function insertIntoQuery({ operator = '', start = '(', end = ')' }) {
    if (!ref?.current) return;

    let text = queryTemp;
    const view = ref.current.view;
    const state = view.viewState.state;
    const range = state.selection.ranges[0];

    if (range.from === range.to) {
      text = `${text.slice(0, range.from)}${operator || start + end}${text.slice(range.to)}`;
    } else {
      text = `${text.slice(0, range.from)}${start}${text.slice(range.from, range.to)}${end}${operator}${text.slice(range.to)}`;
    }
    text = text.replace(/\s+/g, ' ');
    onChangeEditor(text);
  }

  function articlesScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (scrollTop < 5) {
      // scrolled UP
      pageRef.current.scroll(0, 10);
      if (searchTab === 'search' && articlesTab === 'media' && media_articles?.length && !isFetchingArticles && media_articles[0].cursor?.cm_position !== 0) {
        media_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'media_articles', direction: 'up' }))
          : setScrollMedia({ shift: -Number(page_size), cursor: media_articles[0].cursor });
      } else if (searchTab === 'search' && articlesTab === 'socialnetwork' && sn_articles?.length && !isFetchingArticles && sn_articles[0].cursor?.sn_position !== 0) {
        sn_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'sn_articles', direction: 'up' }))
          : setScrollSN({ shift: -Number(page_size), cursor: sn_articles[0].cursor });
      } else if (searchTab === 'favorite' && articlesTab === 'media' && media_favorite_articles?.length && !isFetchingArticles && media_favorite_articles[0].cursor?.cm_position !== 0) {
        media_favorite_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'media_favorite_articles', direction: 'up' }))
          : setScrollMedia({ shift: -Number(page_size), cursor: media_favorite_articles[0].cursor });
      } else if (searchTab === 'favorite' && articlesTab === 'socialnetwork' && sn_favorite_articles?.length && !isFetchingArticles && sn_favorite_articles[0].cursor?.sn_position !== 0) {
        sn_favorite_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'sn_favorite_articles', direction: 'up' }))
          : setScrollSN({ shift: -Number(page_size), cursor: sn_favorite_articles[0].cursor });
      }
    } else if (scrollHeight - scrollTop - clientHeight < 5) {
      // scrolled DOWN
      pageRef.current.scroll(0, scrollHeight - clientHeight - 15);
      if (searchTab === 'search' && articlesTab === 'media' && media_articles?.length && !isFetchingArticles) {
        media_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'media_articles', direction: 'down' }))
          : setScrollMedia({ shift: Number(page_size), cursor: media_articles[media_articles.length - 1].cursor });
      } else if (searchTab === 'search' && articlesTab === 'socialnetwork' && sn_articles?.length && !isFetchingArticles) {
        sn_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'sn_articles', direction: 'down' }))
          : setScrollSN({ shift: Number(page_size), cursor: sn_articles[sn_articles.length - 1].cursor });
      } else if (searchTab === 'favorite' && articlesTab === 'media' && media_favorite_articles?.length && !isFetchingArticles) {
        media_favorite_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'media_favorite_articles', direction: 'down' }))
          : setScrollMedia({ shift: Number(page_size), cursor: media_favorite_articles[media_favorite_articles.length - 1].cursor });
      } else if (searchTab === 'favorite' && articlesTab === 'socialnetwork' && sn_favorite_articles?.length && !isFetchingArticles) {
        sn_favorite_articles?.length > (2 * page_size)
          ? dispatch(fitArticles({ type: 'sn_favorite_articles', direction: 'down' }))
          : setScrollSN({ shift: Number(page_size), cursor: sn_favorite_articles[sn_favorite_articles.length - 1].cursor });
      }
    } else if (scrollTop > 20 && scrollHeight - scrollTop - clientHeight > 20) {
      if (lastScrollPositionRef.current > scrollTop) {
        (scrollsNumRef.current < 3) && (scrollsNumRef.current += 1);
        !isShowResultHeader && scrollsNumRef.current >= 0 && setIsShowResultHeader(true);
        lastScrollPositionRef.current = scrollTop;
      } else {
        (scrollsNumRef.current > -3) && (scrollsNumRef.current -= 1);
        isShowResultHeader && scrollsNumRef.current < 0 && setIsShowResultHeader(false);
        lastScrollPositionRef.current = scrollTop;
      }
    }
  }

  function showSearchQuery() {
    if (searchTab === 'search' && (articlesTab === 'socialnetwork' && sn_articles?.length)) {
      return sn_articles[0]?.cursor?.sn_position === 0;
    }
    if (searchTab === 'search' && articlesTab === 'media' && media_articles?.length) {
      return media_articles[0]?.cursor?.cm_position === 0;
    }
    if (searchTab === 'search') {
      return true;
    }
    return false;
  }

  return (
    <div className={cn(classes.searchContent, searchTab === 'favorite' && classes.searchContent_favorite)} ref={pageRef} onScroll={articlesScroll}>
      <SearchQuery
        ref={ref}
        query={queryTemp}
        showSearchQuery={showSearchQuery()}
        focusEditor={focusEditor}
        showInstructions={show_instructions}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        onOpenHistory={onOpenHistory}
        onChangeEditor={onChangeEditor}
        onChangeInstructions={toggleInstructions}
        errorValidate={errorValidate}
        errorQuery={errorQuery}
      />
      <div
        className={cn(
          classes.main,
          (focusEditor || errorValidate) && classes.main_focusEditor
        )}
        id="search-content-main"
      >
        <Instructions
          show={((show_instructions || !hasData) && !isFetching) && searchTab === 'search'}
          showClose={!!hasData}
          onHide={toggleInstructions}
          language={language}
          insertIntoQuery={insertIntoQuery}
          setTemplate={onChangeEditor}
          eventFunc={(event, props) => { sendSearchEvent({ category: 'Search_Instructions', event, properties: props }); }}
        />
        <SearchResultsHeader
          show={(!show_instructions) && !!(hasData || isFetching)}
          duplicates={duplicates}
          isFavorite={searchTab === 'favorite'}
          tab={articlesTab}
          onChangeTab={onChangeArticleTab}
          onToggleDuplicates={(value) => { dispatch(toggleSearchDuplicates(value)); }}
          fetching={isFetchingCounts || isFetching}
          onDownload={downloadReport}
          reportLoading={reportLoading}
          mediaCount={counts?.media || 0}
          socialCount={counts?.socialnetwork || 0}
          eventFunc={(event, props) => { sendSearchEvent({ category: 'Search_Articles_Header', event, properties: props }); }}
          isSticky={isShowResultHeader}
        />
        {articlesTab !== 'analitycs' && (
          <SkeletonSearchList fetching={isFetchingArticles && !articles?.length}>
            <NoDataSearch data={articles}>
              <SearchResultsArticles
                show={!show_instructions}
                isOperator={user?.permissions?.operator_permission}
                isSocial={articlesTab === 'socialnetwork'}
                isFavoriteTab={searchTab === 'favorite'}
                articles={articles}
                pageSize={Number(page_size)}
                ordering={ordering}
                checkedArticles={checkedArticles}
                onToggleFavoriteMany={toggleFavoriteMany}
                onChangeCheckedArticles={(value) => { dispatch(changeCheckedSearchArcticles(value)); }}
                onChangeOrdering={(value) => { dispatch(changeSearchOrdering(value)); }}
                onChangePageSize={(value) => { dispatch(changeSearchPageSize(value)); }}
                eventFunc={(event, props) => { sendSearchEvent({ category: 'Search_Articles', event, properties: props }); }}
                isFetching={isFetchingArticles}
                isScrollingUp={!isScrolledDown()}
              />
            </NoDataSearch>
          </SkeletonSearchList>
        )}
        {searchTab === 'search' && articlesTab === 'analitycs' && (
          <SkeletonSearchCharts fetching={isFetchingCharts}>
            <NoDataSearch data={searchCharts?.by_mention_dynamic}>
              <SearchResultsCharts
                show={!show_instructions}
                data={searchCharts}
                isLoading={isFetchingCharts}
              />
            </NoDataSearch>
          </SkeletonSearchCharts>
        )}
      </div>
      {!!reportLoading && (
      <div className={cn(classes.downloadsWrap)}>
        <div className={classes.iconContainer}>
          <Loader type="spin" size={40} fetching={reportLoading} color={colors.purple200} className={classes.loader} />
          <Icon
            type="download_icon"
            width={20}
            height={20}
            color={reportLoading ? colors.purple700 : colors.white}
            className={cn(classes.downloadsBtn, reportLoading && classes.downloadsBtn_active)}
          />
        </div>
      </div>
      )}
      {showArrowUp && <Icon type="arrow_forward" color={colors.grey450} onClick={handleScrollToTop} className={cn(classes.topButtonWrapper)} />}
    </div>
  );
}

SearchFeed.propTypes = {};

export default SearchFeed;
