import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import colors from 'styles/_colors.scss';
import classes from './SkeletonAlert.module.scss';

export default function SkeletonAlert() {
  return (
    <SkeletonTheme baseColor={colors.fontBody} highlightColor={colors.purpleA100}>
      <div className={cn(classes.alertsCard)}>
        <div className={classes.alertBody}>
          <div className={classes.textWrap}>
            <Skeleton className={classes.textTitle} containerClassName={classes.textTitleContainer} inline />
            <Skeleton className={classes.textSmallSubTitle} containerClassName={classes.textSmallSubTitleContainer} inline />
            <Skeleton className={classes.textSubTitle} containerClassName={classes.textSubTitleContainer} inline />
          </div>
          <div className={cn(classes.switchWrapper)}>
            <Skeleton className={classes.switch} containerClassName={classes.switcherContainer} inline />
          </div>
        </div>
        <div className={classes.alertFooter}>
          <div className={classes.postItem}>
            <Skeleton className={classes.postAvar} containerClassName={classes.avatarContainer} inline />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
