import { useEffect, useRef, useState } from 'react';
import { shape } from 'prop-types';
import cn from 'classnames';
import { sendFeedEvent } from 'services/amplitude/events';
import { Icon, SkeletonArticle, NoSearchData } from 'Components';
import t from 'utils/translateFunc';
import useClickOutside from 'hooks/clickOutside';
import { useGetDescendantsTree, useGetDescendants } from 'storage/queryHooks.js';
import ArticlesTree from 'Containers/Mentions/ArticlesTree';
import ArticleCard from 'Containers/Mentions/ArticlesTree/ArticleCard';
import colors from 'styles/_colors.scss';
import classes from './BodyDescendantsTree.module.scss';

function BodyDescendantsTree({ data = null }) {
  const [typesTab, setTypesTab] = useState(null);
  const [articleTree, setArticleTree] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const shift = 20;
  const comments_types = ['post', 'comment', 'replyComment', 'reply'];
  const reposts_types = ['post', 'repost', 'extendedRepost'];
  const dropMenuBtnRef = useRef(null);
  useClickOutside(dropMenuBtnRef, () => (showOrderMenu ? setShowOrderMenu(false) : null));

  const sortTypes = [
    { name: t('feed_ordering', 'DATE_NEWEST'), value: 'created_date' },
    { name: t('feed_ordering', 'DATE_OLDEST'), value: '-created_date' }
  ];

  const { data: articles, isLoading: isTreeLoading, isError: isTreeError } = useGetDescendantsTree({
    article_id: data?.id,
    article_sn_id: data?.article_id,
    date: data?.date,
    sort_by: orderBy?.value || sortTypes[0].value,
    types: typesTab,
    shift
  });

  const { mutate: loadDescendants } = useGetDescendants({
    article_id: data?.id,
    sort_by: orderBy?.value || sortTypes[0].value,
    shift
  });

  useEffect(() => {
    setOrderBy(sortTypes[0]);
    data?.types === 'reposts' ? changeTypesTab('reposts') : changeTypesTab('comments');
  }, []);

  useEffect(() => {
    if (articles?.id === data?.article_id && articles?.post_type === 'not_found') {
      const updated_article = { ...articles };
      updated_article.title = data?.title;
      updated_article.source_name = data?.source_name;
      updated_article.media_type = data?.media_type;
      updated_article.post_type = data?.post_type;
      updated_article.date = data?.date;
      updated_article.duplicates_count = data?.duplicates_count;
      updated_article.source = data?.source;
      updated_article.author = data?.author;
      updated_article.publication_place = data?.publication_place;
      updated_article.engagements = {
        like_count: data?.like_count,
        comment_count: data?.comment_count,
        repost_count: data?.repost_count,
        views_count: data?.views_count
      };
      updated_article.potential_reach = data?.potential_reach;
      setArticleTree(updated_article);
    } else {
      setArticleTree(articles);
    }
  }, [articles]);

  function changeTypesTab(types) {
    const post_type = data?.post_type;
    if (post_type === 'post') {
      const event_name = types === 'comments' ? 'Clicked_Comments_Tab_In_Publication_Context_Modal' : 'Clicked_Reposts_Tab_In_Publication_Context_Modal';
      sendFeedEvent({ category: 'descendants_tree', event: event_name });
      setTypesTab(types);
      return;
    }
    if (typesTab !== 'comments' && comments_types.includes(post_type)) {
      sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Comments_Tab_In_Publication_Context_Modal' });
      setTypesTab('comments');
      return;
    }
    if (typesTab !== 'reposts' && reposts_types.includes(post_type)) {
      sendFeedEvent({ category: 'descendants_tree', event: 'Clicked_Reposts_Tab_In_Publication_Context_Modal' });
      setTypesTab('reposts');
    }
  }

  function isTabAvailable(types) {
    if (types === 'comments' && data?.post_type === 'post' && data?.comment_count <= 0) return false;
    if (types === 'reposts' && data?.post_type === 'post' && data?.repost_count <= 0) return false;
    if (types === 'comments' && comments_types.includes(data?.post_type)) return true;
    if (types === 'reposts' && reposts_types.includes(data?.post_type)) return true;
    return false;
  }

  function updateOrderBy(sort_type) {
    if (sort_type?.value === 'created_date') sendFeedEvent({ category: 'descendants_tree', event: 'Sorted_By_Date_Newest_First' });
    if (sort_type?.value === '-created_date') sendFeedEvent({ category: 'descendants_tree', event: 'Sorted_By_Date_Oldest_First' });

    setOrderBy(sort_type);
    setShowOrderMenu(false);
  }

  return (
    <div id="descendance-tree" className={classes.fullScreen}>
      <div className={classes.mainHeader}>
        {t('details', 'PUBLICATION_CONTEXT')}
      </div>
      <div className={classes.fullScreenCard}>
        {articleTree ? (
          <ArticleCard
            article={articleTree}
            is_parent
          />
        ) : (
          <SkeletonArticle />
        )}

      </div>
      <header className={classes.header}>
        <div className={classes.leftSide}>
          <div className={classes.tabsGroup}>
            <div
              className={cn(classes.tabItem, typesTab === 'comments' && classes.tabItem_active, classes.tabItem_available)}
              hidden={!isTabAvailable('comments')}
              onClick={() => changeTypesTab('comments')}
            >
              <div className={classes.tabItem_text}>{t('tooltip', 'COMMENTS')}</div>
              <div className={cn(classes.line, typesTab === 'comments' && classes.line_active)} />
            </div>
            <div
              className={cn(classes.tabItem, typesTab === 'reposts' && classes.tabItem_active, classes.tabItem_available)}
              hidden={!isTabAvailable('reposts')}
              onClick={() => changeTypesTab('reposts')}
            >
              <div className={classes.tabItem_text}>{t('tooltip', 'REPOSTS')}</div>
              <div className={cn(classes.line, typesTab === 'reposts' && classes.line_active)} />
            </div>
          </div>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.orderGroup} ref={dropMenuBtnRef}>
            <div className={cn(classes.orderItem)} onClick={() => setShowOrderMenu(!showOrderMenu)}>
              <span>
                {orderBy?.name || sortTypes[0].name}
              </span>
              <Icon type="arrow_down" color={colors.font70} />
            </div>
            <div className={cn(classes.dropdownContent)} hidden={!showOrderMenu}>
              {sortTypes.map((item, num) => (
                <div className={classes.dropdownItem} onClick={() => updateOrderBy(item)} key={`tree_order_${num}`}>
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </header>
      <main className={classes.mainPart}>
        <div className={classes.mainPartContent}>
          {(isTreeLoading || !articleTree) && !isTreeError ? (
            <>
              {(new Array(3)).fill(null).map((_, i) => (
                <div key={i}><SkeletonArticle /></div>
              ))}
            </>
          ) : (articleTree ? (
            <ArticlesTree
              article={articleTree}
              load_descendants={loadDescendants}
              current_types={typesTab}
              current_shift={shift}
              is_parent
            />
          ) : <NoSearchData />)}
        </div>
      </main>
    </div>
  );
}

BodyDescendantsTree.propTypes = {
  data: shape({})
};

export default BodyDescendantsTree;
