import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Agency({ width = 14, height = 14, color = colors.green475, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none">
      <mask id="mask0_317_30812" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_317_30812)">
        <path d="M3.20972 11.377V6.71035H4.6666V11.377H3.20972ZM7.27349 11.377V6.71035H8.72639V11.377H7.27349ZM1.19775 14.1035V12.5868H14.8021V14.1035H1.19775ZM11.3333 11.377V6.71035H12.7902V11.377H11.3333ZM1.19777 5.4966V3.94405L7.99994 0.542969L14.8021 3.94405V5.4966H1.19777ZM4.50719 3.97992H11.4927L7.99994 2.25022L4.50719 3.97992Z" fill={color || colors.green475} />
      </g>
    </svg>
  );
}

Agency.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
