import { memo, useRef } from 'react';
import { string, func, bool, shape, oneOfType, arrayOf } from 'prop-types';
import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import t from 'utils/translateFunc';
import { ErrorBoundarySentry } from 'Components';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import DropdownIndicatorPart from './parts/DropdownIndicatorPart';
import OptionPart from './parts/OptionPart';
import OptionRadio from './parts/OptionRadio';
import MenuList from './parts/MenuList';
import classes from './SelectCustom.module.scss';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minHeight: '32px',
    backgroundColor: state?.sort ? colors.fontBody : colors.white,
    height: '40px',
    borderRadius: '4px',
    boxShadow: colors.boxShadow,
    border: 'none',
    outline: 'none',
    opacity: state.isDisabled ? '0.8' : '1'
  }),

  valueContainer: provided => ({
    ...provided,
    padding: '0 12px'
  }),

  singleValue: (provided, state) => ({
    ...provided,
    margin: '0',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    color: state.isDisabled ? `rgba(${colors.font400}, 0.4)` : colors.font400
  }),

  menu: provided => ({
    ...provided,
    borderRadius: '4px',
    boxShadow: colors.boxShadow,
    zIndex: 9999
  }),
  menuList: provided => ({
    ...provided,
    paddingBottom: '3px'
  }),

  placeholder: provided => ({
    ...provided,
    color: colors.grey600,
    fontSize: '14px',
    fontWeight: 400,
    cursor: 'pointer'
  }),

  option: (provided, state) => ({
    ...provided,
    marginBottom: '1px',
    padding: '10px 16px',
    height: '40px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: state.isSelected ? '500' : '400',
    cursor: 'pointer',
    color: colors.font200,
    backgroundColor: state.isSelected ? (PROJECT === 'metricom' ? colors.green50 : colors.purpleA10) : colors.white,
    ':active': {
      backgroundColor: PROJECT === 'metricom' ? colors.green50 : colors.purpleA10

    },
    '&:hover': {
      backgroundColor: PROJECT === 'metricom' ? colors.green50 : colors.purpleA10
    }
  })
};

const SelectCustom = memo(({
  selectTitle = '',
  value = { value: '', label: '' },
  isDisabled = false,
  onChange = () => null,
  isLoading = false,
  onMenuOpen = () => null,
  bigList = false,
  className = '',
  styles = {},
  placeholder = '',
  options = [],
  menuPlacement = 'auto',
  is_searchable = false,
  components = {},
  async = false,
  getSearchData = () => null,
  defaultOptions = false,
  isRadio = false,
  ...props
}) => {
  const ref = useRef(null);

  const SelectType = async ? AsyncSelect : Select;
  const asyncData = {
    ...(async
      ? {
        cacheOptions: true,
        defaultOptions,
        loadOptions: inputValue => getSearchData(inputValue).then(result => result?.filter(el => el.label?.toLowerCase().includes(inputValue?.toLowerCase())))
      }
      : null)
  };

  const allProps = { ...props, ...asyncData };

  return (
    <ErrorBoundarySentry>
      {selectTitle && <p className={classes.selectTitle}>{selectTitle}</p>}
      <SelectType
        ref={ref}
        value={value}
        onChange={(...args) => {
          onChange(...args);
          if (ref.current) ref.current.setState({ focusedOption: args[0] });
        }}
        isSearchable={is_searchable}
        className={className}
        classNamePrefix="select"
        options={options}
        noOptionsMessage={() => t('nodata', 'NOT_FOUND')}
        loadingMessage={() => t('search', 'LOADING')}
        styles={{ ...customStyles, ...styles }}
        isDisabled={isDisabled}
        placeholder={placeholder}
        onMenuOpen={onMenuOpen}
        menuPlacement={menuPlacement}
        isLoading={isLoading}
        isClearable={false}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{
          DropdownIndicator: DropdownIndicatorPart,
          IndicatorSeparator: null,
          Option: isRadio ? OptionRadio : OptionPart,
          ...(bigList && { MenuList }),
          ...components
        }}
        {...allProps}
      />
    </ErrorBoundarySentry>
  );
});

SelectCustom.propTypes = {
  selectTitle: string,
  value: oneOfType([func, shape({}), arrayOf(shape({}))]),
  onChange: func,
  onMenuOpen: func,
  isLoading: bool,
  options: arrayOf(shape({})),
  isDisabled: bool,
  bigList: bool,
  className: string,
  placeholder: string,
  styles: shape({}),
  menuPlacement: string,
  is_searchable: bool,
  async: bool,
  getSearchData: func,
  components: shape({}),
  defaultOptions: bool,
  isRadio: bool
};

export default SelectCustom;
