import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function ReplyComment({ width = 16, height = 16, color = colors.font70, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
      <mask id="mask0_1398_8674" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_1398_8674)">
        <path d="M5.33325 15.0003V11.317C4.21103 11.2281 3.26381 10.7725 2.49159 9.95033C1.71936 9.1281 1.33325 8.14477 1.33325 7.00033C1.33325 5.78921 1.7527 4.76421 2.59159 3.92533C3.43047 3.08644 4.45547 2.66699 5.66658 2.66699H6.11659L5.06659 1.60033L5.99992 0.666992L8.66658 3.33366L5.99992 6.00033L5.06659 5.06699L6.11659 4.00033H5.66658C4.83325 4.00033 4.12492 4.29199 3.54159 4.87533C2.95825 5.45866 2.66659 6.16699 2.66659 7.00033C2.66659 7.83366 2.95825 8.54199 3.54159 9.12533C4.12492 9.70866 4.83325 10.0003 5.66658 10.0003H6.66658V11.7837L8.44992 10.0003H10.3333C11.1666 10.0003 11.8749 9.70866 12.4583 9.12533C13.0416 8.54199 13.3333 7.83366 13.3333 7.00033C13.3333 6.16699 13.0416 5.45866 12.4583 4.87533C11.8749 4.29199 11.1666 4.00033 10.3333 4.00033H9.99992V2.66699H10.3333C11.5444 2.66699 12.5694 3.08644 13.4083 3.92533C14.2471 4.76421 14.6666 5.78921 14.6666 7.00033C14.6666 8.21144 14.2471 9.23644 13.4083 10.0753C12.5694 10.9142 11.5444 11.3337 10.3333 11.3337H8.99992L5.33325 15.0003Z" fill={color || colors.font70} />
      </g>
    </svg>
  );
}

ReplyComment.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
