import { string, func, shape, oneOfType } from 'prop-types';
import { Button } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { sendAlertsEvent } from 'services/amplitude/events';
import { PROJECT } from 'config';
import classes from './BodyConnectTelegram.module.scss';

const BodyConnectTelegram = ({
  data = { telegramBotLinkWeb: '', telegramBotLink: '' },
  onStartRefetchTelegram = () => null
}) => {
  function onOpenTelegramLink(link) {
    link && window.open(link, '_blank');
    onStartRefetchTelegram();
  }

  return (
    <div className={cn(classes.bodyConnectTelegram)}>
      <div className={classes.imageWrapp}>
        <div className={classes.imageMessage} />
        <div className={classes.imagePhone} />
      </div>
      <div className={classes.text}>
        {t('alerts', PROJECT === 'metricom' ? 'TELEGRAM_RECIVE_M' : 'TELEGRAM_RECIVE')}
      </div>
      <div className={classes.text}>
        {t('alerts', 'TELEGRAM_RECIVE_METHOD')}
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={() => {
            sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Private_Messages_Button' });
            onOpenTelegramLink(data?.telegramBotLink);
          }}
          textButton={t('btn', 'PRIVATE_MESSAGE')}
          purpleTransparent32
        />
        <Button
          onClick={() => {
            sendAlertsEvent({ category: 'Recipient Tg', event: 'Clicked_Group_Chats_Button' });
            onOpenTelegramLink(data?.telegramBotLinkGroup);
          }}
          textButton={t('btn', 'GROUP_MESSAGE')}
          purpleTransparent32
        />
      </div>
    </div>

  );
};

BodyConnectTelegram.propTypes = {
  data: oneOfType([shape({ telegramBotLinkWeb: string, telegramBotLink: string })]),
  onStartRefetchTelegram: func
};

export default BodyConnectTelegram;
