import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';

export function FavoriteBookmark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="37" viewBox="0 0 24 37" fill="none">
      <path d="M0 0H24V37L12 29.5L0 37V0Z" fill={PROJECT === 'metricom' ? colors.blueA350 : colors.green400} />
    </svg>
  );
}
