import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Tv({ width = 14, height = 14, color = colors.pink800, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none">
      <path d="M4.26152 13.1353V11.802H1.71442C1.29461 11.802 0.936876 11.6541 0.64122 11.3585C0.345576 11.0628 0.197754 10.7051 0.197754 10.2853V2.38094C0.197754 1.96113 0.345587 1.6034 0.641254 1.30774C0.936898 1.01209 1.29463 0.864258 1.71444 0.864258H12.2854C12.7052 0.864258 13.063 1.01209 13.3586 1.30774C13.6543 1.6034 13.8021 1.96113 13.8021 2.38094V10.2853C13.8021 10.7051 13.6543 11.0628 13.3586 11.3585C13.063 11.6541 12.7052 11.802 12.2854 11.802H9.73832V13.1353H4.26152ZM1.71442 10.2853H12.2854V2.38094H1.71442V10.2853Z" fill={color || colors.pink800} />
    </svg>
  );
}

Tv.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
