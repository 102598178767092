/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { func, string, shape, bool, oneOfType } from 'prop-types';
import { SelectCustom, RadioButton, AnimatedTab, Tooltip, Icon } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import colors from 'styles/_colors.scss';
import { optionsCommon, replaceWeekDays } from '../../options.js';
import classes from '../ReportDetails.module.scss';
import TimesComponent from './TimesComponent';

function PeriodictyComponent({ periodicty = { frequency: '', time_list: null, time: '' }, onChange = () => null, errorFrequency = '', errorTime = '' }) {
  const { frequency, frequency_specific, time_list, time_list_day, time_list_week, time_list_month } = periodicty;
  const multiHours = frequency === 'multiple_times_per_day';
  const specificHours = frequency === 'one_hour' || frequency === 'two_hours' || frequency === 'four_hours';
  const multiWeeks = frequency === 'multiple_per_week';
  const { periodicitiesOptions, someHoursOptions, periodicityFrequency, daysOfMonth, daysOfWeek } = useMemo(() => optionsCommon(), []);

  const multiMonth = frequency === 'multiple_per_month';
  const frequencyForDay = ['multiple_times_per_day', 'one_hour', 'two_hours', 'four_hours'];
  const periodicityValue = frequency ? periodicityFrequency[frequency] : null;
  const findnHours = useMemo(() => !!specificHours && someHoursOptions.find(item => item?.value === frequency), [frequency]);
  const shortFrequency = useMemo(() => (periodicityValue ? periodicitiesOptions.find(item => item?.value === periodicityValue) : null), [frequency]);

  const daysTimeList = useMemo(() => !!time_list?.length && (multiWeeks || multiMonth) &&
   time_list.reduce((acc, { id, time, day }) => {
     const existingDay = acc.find(item => item.day === day);
     existingDay ? existingDay.timeList.push({ id, time }) : acc.push({ day, timeList: [{ id, time }] });
     return acc;
   }, []), [time_list, frequency]);

  const styles = {
    container: provided => ({
      ...provided,
      border: colors.borderWhite,
      borderRadius: '4px',
      ...(errorFrequency ? {
        borderColor: colors.red500
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: errorFrequency ? colors.red500 : colors.grey600,
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    })
  };

  const sortTimesList = list => [...list].sort((a, b) => (a.time === '' ? 1 : b.time === '' ? -1 : a.day - b.day || a.time.localeCompare(b.time)));
  const daysList = multiMonth ? daysOfMonth : daysOfWeek;

  const daysTooltipList = useMemo(() => (
    (multiMonth || multiWeeks) && (
    <div className={cn(classes.dayMonthList, multiWeeks && classes.dayMonthList_week)}>
      {daysList.map(({ id, day, label }) => (
        <div
          key={`${id}-${day}`}
          className={cn(
            classes.dayItem,
            multiWeeks && classes.dayItem_week,
            time_list?.length && time_list.find(el => el?.day === day) && classes.dayItem_selected
          )}
          onClick={() => {
            const finDay = (time_list || []).some(el => el?.day === day);
            const filterTimeList = (time_list || []).filter(el => el?.day !== day);
            const addToTimeList = (time_list || []).concat([{ id, day, time: '' }]);
            const newTimeList = finDay ? filterTimeList : addToTimeList;
            const sortedTimeList = [...newTimeList].sort((a, b) => (a.day > b.day ? 1 : -1));
            onChange({ time_list: sortedTimeList });
          }}
        >
          {multiMonth ? day : label}
        </div>
      ))}
    </div>
    )
  ), [time_list, daysList, frequency]);

  return (
    <div className={classes.periodWrapp} id="periodicity">
      <SelectCustom
        value={shortFrequency}
        placeholder={t('reports', 'CHOOSE_PERIODICITY')}
        menuPlacement="top"
        onChange={({ value }) => {
          const newFrequncy = { frequency: value, time_list, time_list_day, time_list_week, time_list_month };
          if (frequency && value !== frequency) {
            if (frequencyForDay.includes(value)) {
              multiWeeks && (newFrequncy.time_list_week = time_list);
              multiMonth && (newFrequncy.time_list_month = time_list);
              newFrequncy.time_list = time_list_day;
            }
            if (value === 'multiple_per_week') {
              frequencyForDay.includes(frequency) && (newFrequncy.time_list_day = time_list);
              multiMonth && (newFrequncy.time_list_month = time_list);
              newFrequncy.time_list = time_list_week;
            }
            if (value === 'multiple_per_month') {
              frequencyForDay.includes(frequency) && (newFrequncy.time_list_day = time_list);
              multiWeeks && (newFrequncy.time_list_week = time_list);
              newFrequncy.time_list = time_list_month;
            }
            onChange(newFrequncy);
            return;
          }
          !frequency && onChange(newFrequncy);
        }}
        options={periodicitiesOptions}
        styles={styles}
      />
      {!!(specificHours || multiHours) && (
        <div className={classes.everyDay}>
          <div>
            <RadioButton
              value="multiple_times_per_day"
              label={t('reports', 'SPECIFIC_HOURS')}
              checked={multiHours}
              onChange={() => { !multiHours && onChange({ frequency: 'multiple_times_per_day', frequency_specific }); }}
            />
            <RadioButton
              value="one_hour"
              checked={specificHours}
              label={t('reports', 'EVERY_N_HOURS')}
              onChange={() => { onChange({ frequency: frequency_specific, frequency_specific }); }}
            />
          </div>
          <AnimatedTab>
            {multiHours && (
            <TimesComponent
              timeList={time_list}
              error={errorTime}
              onChange={({ times }) => {
                onChange({ time_list: sortTimesList(times) });
              }}
            />
            )}
            {specificHours && (
            <SelectCustom
              value={findnHours || someHoursOptions[0]}
              placeholder={t('reports', 'SELECT_INTERVAL')}
              onChange={({ value }) => { onChange({ frequency: value, frequency_specific: value }); }}
              options={someHoursOptions}
              styles={styles}
            />
            )}
          </AnimatedTab>
        </div>
      )}
      {(multiMonth || multiWeeks) && (
        <div className={classes.daysOfMonthList}>
          {daysTimeList?.length
            ? daysTimeList.map(dayItem => (
              <div key={dayItem?.day} className={classes.dayMonthItem}>
                <div className={classes.headerWrap}>
                  <div className={classes.dayMonthLabel}>
                    {multiWeeks ? replaceWeekDays(dayItem.day) : <Trans i18nKey="everyThDay" values={{ day: dayItem.day || 0 }} />}
                  </div>
                  <div
                    className={classes.deleteIcon}
                    onClick={() => {
                      onChange({ time_list: (time_list || []).filter(timeList => timeList?.day !== dayItem.day) });
                    }}
                  >
                    <Icon type="delete" width={12} height={14} color={colors.font70} />
                  </div>
                </div>
                <TimesComponent
                  timeList={(time_list || []).filter(list => list?.day === dayItem?.day) || null}
                  dayId={dayItem.id}
                  error={errorTime}
                  onChange={({ times }) => {
                    const dayTimeList = (times || []).map(item => ({ ...item, day: dayItem?.day }));
                    const newTimeList = (time_list || []).filter(timeList => timeList?.day !== dayItem.day).concat(dayTimeList);
                    const sortedTimeList = [...newTimeList].sort((a, b) => (a.time === '' ? 1 : b.time === '' ? -1 : a.day - b.day || a.time.localeCompare(b.time)));
                    onChange({ time_list: sortedTimeList });
                  }}
                />
              </div>
            ))
            : (
              <div className={classes.dayMonthItem}>
                <div className={cn(classes.dayMonthLabel, errorTime && classes.dayMonthLabel_error)}>
                  {t('reports', 'NO_DAY_ADDED')}
                </div>
              </div>
            )}
          <Tooltip
            text={daysTooltipList}
            placement="top"
            trigger="click"
            arrow={false}
            className={classes.buttonWrapp}
            color={colors.white}
            overlayInnerStyle={{ color: colors.font400, borderRadius: 0, width: '224px', height: multiWeeks ? '220px' : '276px' }}
          >
            <div className={classes.purpleButton}>{t('btn', 'ADD_DAY')}</div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

PeriodictyComponent.propTypes = {
  periodicty: shape({}),
  onChange: func,
  errorFrequency: oneOfType([string, bool]),
  errorTime: oneOfType([string, bool])
};

export default PeriodictyComponent;
