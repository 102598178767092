import { useSelector } from 'react-redux';
import { BtnDropDown } from 'Components';
import t from 'utils/translateFunc';
import { string, func, oneOfType, number } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from './HelpDropDown.module.scss';
import { helpMenu } from '../helpers/navigationConfig';

function HelpDropDown({ active = '', info = '', onChange = () => null }) {
  const language = useSelector(state => state?.userInfo?.language);
  const navMenu = !!helpMenu?.length && helpMenu?.map(el => ({ ...el, name: t('helpMenu', el.name, language) }));
  return (
    <div className={classes.helpWrapper}>
      {!!navMenu?.length && (
      <BtnDropDown
        icon="help"
        menuConfig={navMenu}
        selectOption={onChange}
        className={classes.btnMore}
        iconClass={classes.iconClass}
        selectClass={classes.selectClass}
        active={active}
        info={info}
        color={colors.font90}
        arrowRight
        amplitudeEvent="Clicked_Help_Button"
        amplitudeCategory="Header"
      />
      )}
      {info && <div className={classes.info}>{info}</div>}
    </div>
  );
}

HelpDropDown.propTypes = {
  onChange: func,
  active: string,
  info: oneOfType([number, string])
};

export default HelpDropDown;
