import { string } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Info({ color = colors.font70 }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.25 12H8.75V7H7.25V12ZM7.99563 5.5C8.20688 5.5 8.38542 5.42854 8.53125 5.28563C8.67708 5.14271 8.75 4.96563 8.75 4.75438C8.75 4.54313 8.67854 4.36458 8.53563 4.21875C8.39271 4.07292 8.21563 4 8.00438 4C7.79313 4 7.61458 4.07146 7.46875 4.21437C7.32292 4.35729 7.25 4.53437 7.25 4.74562C7.25 4.95687 7.32146 5.13542 7.46438 5.28125C7.60729 5.42708 7.78438 5.5 7.99563 5.5ZM8.00583 16C6.90472 16 5.86806 15.7917 4.89583 15.375C3.92361 14.9583 3.07292 14.3854 2.34375 13.6562C1.61458 12.9271 1.04167 12.0767 0.625 11.105C0.208333 10.1333 0 9.09514 0 7.99042C0 6.88569 0.208333 5.85069 0.625 4.88542C1.04167 3.92014 1.61458 3.07292 2.34375 2.34375C3.07292 1.61458 3.92333 1.04167 4.895 0.625C5.86667 0.208333 6.90486 0 8.00958 0C9.11431 0 10.1493 0.208333 11.1146 0.625C12.0799 1.04167 12.9271 1.61458 13.6562 2.34375C14.3854 3.07292 14.9583 3.92167 15.375 4.89C15.7917 5.85847 16 6.89319 16 7.99417C16 9.09528 15.7917 10.1319 15.375 11.1042C14.9583 12.0764 14.3854 12.9271 13.6562 13.6562C12.9271 14.3854 12.0783 14.9583 11.11 15.375C10.1415 15.7917 9.10681 16 8.00583 16Z" fill={color} />
    </svg>
  );
}
Info.propTypes = {
  color: string
};
