import { useState, useMemo, useRef } from 'react';
import { bool, func, shape } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Icon } from 'Components';
import { Popover, Input } from 'antd';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import classes from './FilterEditable.module.scss';

const { TextArea } = Input;

function FilterEditable({ filter = null, onRename = () => null, onRestoreDefault = () => null, onRemoveFilter = () => null, onSaveChanges = () => null,
  hasChanges = false }) {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('main');
  const ref = useRef();

  const id = filter?.id || null;

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    type !== 'main' && setType('main');
  };

  function saveChangesAsNew() {
    onSaveChanges({ name: `${filter?.name} copy` });
    setOpen(false);
  }
  function restoreDefault() {
    onRestoreDefault();
    setOpen(false);
  }
  function removeFilter() {
    filter?.id && onRemoveFilter(filter?.id);
    setOpen(false);
  }

  const popoverOptions = hasChanges ? [
    { icon: 'save_disk', title: t('search', 'SAVE_AS_NEW'), click: saveChangesAsNew },
    { icon: 'restart', title: t('search', 'RESTORE_DEFAULT'), click: restoreDefault },
    { icon: 'remove', title: t('search', 'DELETE_FILTER'), click: removeFilter }
  ] : [{ icon: 'remove', title: t('search', 'DELETE_FILTER'), click: removeFilter }];

  const popoverMenu = useMemo(() => (
    <div className={classes.searchFilterPpoverMenu}>
      {popoverOptions.map(({ title, icon, click }) => (
        <div
          key={title}
          className={cn(classes.menuItem, icon === 'remove' && classes.menuItemRemove)}
          onClick={click}
        >
          <Icon type={icon} role="icon" width={20} height={20} color={colors.font70} />
          <span>{title}</span>
        </div>
      )) }

    </div>
  ), [filter?.id, hasChanges]);

  return (
    <div className={classes.editableFilter}>
      <TextArea
        value={filter?.name}
        autoSize
        autoFocus
        onChange={({ target }) => { onRename({ id: filter?.id, name: target.value }); }}
        placeholder={t('search', 'ENTER_FILTER_NAME')}
        className={classes.inputText}
      />
      {hasChanges && (
        <Icon
          type="save_disk"
          role="button"
          width={24}
          height={24}
          color={PROJECT === 'metricom' ? colors.green800 : colors.purple450}
          className={classes.saveIcon}
          tooltipText={t('search', id ? 'SAVE_CHANGES_TOTHIS' : 'SAVE_FILTER')}
          onClick={() => onSaveChanges({ id, name: filter?.name })}
        />
      )}
      {filter?.id && (
        <Popover
          content={popoverMenu}
          onOpenChange={handleOpenChange}
          open={open}
          trigger="click"
          placement="bottom"
          arrow={false}
          getPopupContainer={() => document.getElementById('search-filters')}
          destroyTooltipOnHide
        >
          <span ref={ref}>
            <Icon type="more" color={colors.font60} className={cn(classes.popoverIcon, open && classes.popoverIconActive)} />
          </span>
        </Popover>
      )}
    </div>
  );
}
FilterEditable.propTypes = {
  filter: shape({}),
  onSaveChanges: func,
  onRestoreDefault: func,
  onRemoveFilter: func,
  onRename: func,
  hasChanges: bool
};
export default FilterEditable;
