/* eslint-disable no-unused-vars */

import { func, shape } from 'prop-types';
import { Button, Loader, SelectCustom, SearchOptionPart, SearchPlaceholderPart, SearchField, Icon } from 'Components';
import t from 'utils/translateFunc';
import { useGetPagginationSearchSources, useGetSearchSourcesLevel, useGetSearchSourcesSubject,
  useGetSearchSourcesLanguages, useGetSearchMediaTypes } from 'storage/queryHooks';
import { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import memoize from 'lodash/memoize';
import { Pagination } from 'antd';
import useDebounceValue from 'hooks/useDebounce';
import colors from 'styles/_colors.scss';
import classes from './BodySelectSources.module.scss';
import Option from './Option';
import getStyles from './styles';

const BodySelectSources = ({ data = null, onCancel = () => null, onApply = () => null }) => {
  const { mutateAsync: getLevel } = useGetSearchSourcesLevel();
  const { mutateAsync: getSubject } = useGetSearchSourcesSubject();
  const { mutateAsync: getLanguages } = useGetSearchSourcesLanguages();
  const { mutateAsync: getMediaTypes } = useGetSearchMediaTypes();
  const { customStyles } = getStyles();
  const [selectedSources, setSelectedSources] = useState(data || []);
  const [allSources, setAllSources] = useState('');
  const [allSelectedSources, setAllSelectedSources] = useState('');
  const [sourceSearch, setSourceSearch] = useState('');
  const [sourceLevel, setSourceLevel] = useState(null);
  const [sourceSubject, setSourceSubject] = useState(null);
  const [sourceLanguages, setSourceLanguages] = useState(null);
  const [mediaTypes, setMediaTypes] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const icontains = useDebounceValue(sourceSearch, 1000);

  const { data: sourceData, isLoading } = useGetPagginationSearchSources({
    query: {
      page_size: 15,
      ...(page > 1 ? { page } : {}),
      icontains,
      level: sourceLevel?.filter(({ isExclude }) => !isExclude).map(({ value }) => value),
      level_exclude: sourceLevel?.filter(({ isExclude }) => !!isExclude).map(({ value }) => value),
      subject: sourceSubject?.filter(({ isExclude }) => !isExclude).map(({ value }) => value),
      subject_exclude: sourceSubject?.filter(({ isExclude }) => !!isExclude).map(({ value }) => value),
      language: sourceLanguages?.filter(({ isExclude }) => !isExclude).map(({ value }) => value),
      language_exclude: sourceLanguages?.filter(({ isExclude }) => !!isExclude).map(({ value }) => value),
      media_type: mediaTypes?.filter(({ isExclude }) => !isExclude).map(({ value }) => value),
      media_type_exclude: mediaTypes?.filter(({ isExclude }) => !!isExclude).map(({ value }) => value)
    }
  });
  const sourcesOptions = !!sourceData?.results?.length && sourceData?.results.map(({ id, name, icon }) => ({ value: id, label: name, icon }));

  const checkSelected = memoize(value => selectedSources?.length && selectedSources.find(item => item?.value === value));

  useEffect(() => {
    if (allSources && sourcesOptions?.length) {
      setSelectedSources(sourcesOptions.map(item => ({ ...item, isExclude: allSources === 'exclude' })));
    }
  }, [allSources]);

  useEffect(() => {
    page !== 1 && setPage(1);
  }, [icontains, mediaTypes, sourceLanguages, sourceSubject, sourceLevel]);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onApply(selectedSources);
    onCancel();
  };
  const selectProps = {
    isMulti: true,
    components: { Option: SearchOptionPart, Placeholder: SearchPlaceholderPart },
    closeMenuOnSelect: false,
    styles: customStyles,
    hideSelectedOptions: false,
    controlShouldRenderValue: false,
    defaultOptions: true,
    async: true
  };

  function onChangeOption(optionValue) {
    const isSelected = !!selectedSources?.length && !!selectedSources.some(item => item?.value === optionValue?.value);
    const findOption = selectedSources?.find(item => item?.value === optionValue?.value);
    const optionHasValue = findOption && findOption.isExclude !== optionValue.isExclude;
    const addValue = selectedSources.concat(optionValue);
    const removeOption = optionHasValue
      ? selectedSources.filter(item => item?.value !== optionValue?.value).concat(optionValue)
      : selectedSources.filter(item => item?.value !== optionValue?.value);

    const newOptions = isSelected ? removeOption : addValue;
    setSelectedSources(newOptions);
  }
  const isLastPage = useMemo(() => !!sourceData?.count && page >= sourceData.count / 15, [page, sourceData?.count]);

  function itemRender(_, type, originalElement) {
    if (type === 'prev') {
      return (
        <span>
          <Icon
            type="arrow_forward"
            width={20}
            height={20}
            color={page === 1 ? colors.font50 : colors.grey600}
            className={cn(
              classes.iconPaggination,
              classes.iconForwardBack,
              page === 1 && classes.iconPaggination_disabled
            )}
          />
        </span>
      );
    }
    if (type === 'next') {
      return (
        <span>
          <Icon
            type="arrow_forward"
            width={20}
            height={20}
            color={isLastPage ? colors.font50 : colors.grey600}
            className={cn(
              classes.iconPaggination,
              isLastPage && classes.iconPaggination_disabled
            )}
          />
        </span>
      );
    }
    if (type === 'jump-prev' || type === 'jump-next') {
      return <strong className={classes.jumpDots}>...</strong>;
    }

    return originalElement;
  }

  return (
    <div className={classes.bodySelectSorces}>
      <div className={classes.sourcesWrapp}>
        <div className={classes.sourcesListWrap}>
          <div className={classes.sourcesListFilters}>
            <div className={classes.searchWrap}>
              <SearchField
                placeholder={t('search', 'Пошук')}
                value={sourceSearch}
                setValue={setSourceSearch}
              />
            </div>
            <div className={classes.selectWrapper}>
              <label className={classes.seletMenuTitle}>{t('sourceFilter', 'LEVEL')}</label>
              <SelectCustom
                value={sourceLevel}
                placeholder={t('search', 'Пошук рівня...')}
                onChange={(value) => { setSourceLevel(value); }}
                endingPlaceholderPart={t('search', sourceLevel?.length === 1 ? 'рівень' : 'рівня')}
                getSearchData={getLevel}
                isSearchable
                {...selectProps}
              />
            </div>
            <div className={classes.selectWrapper}>
              <label className={classes.seletMenuTitle}>{t('sourceFilter', 'SUBJECT')}</label>
              <SelectCustom
                value={sourceSubject}
                placeholder={t('search', 'Пошук тематики...')}
                onChange={(value) => { setSourceSubject(value); }}
                endingPlaceholderPart={t('search', sourceSubject?.length === 1 ? 'тематика' : 'тематики')}
                getSearchData={getSubject}
                isSearchable
                {...selectProps}
              />
            </div>
            <div className={classes.selectWrapper}>
              <label className={classes.seletMenuTitle}>{t('search', 'LANGUAGE')}</label>
              <SelectCustom
                value={sourceLanguages}
                placeholder={t('search', 'Пошук мови...')}
                onChange={(value) => { setSourceLanguages(value); }}
                endingPlaceholderPart={t('search', sourceLanguages?.length === 1 ? 'мова' : 'мови')}
                getSearchData={getLanguages}
                isSearchable
                {...selectProps}
              />
            </div>

            <div className={classes.selectWrapper}>
              <label className={classes.seletMenuTitle}>{t('search', 'TYPE_SOURCE')}</label>
              <SelectCustom
                value={mediaTypes}
                placeholder={t('search', 'ALL_SOURCES')}
                onChange={(value) => { setMediaTypes(value); }}
                endingPlaceholderPart={t('search', mediaTypes?.length === 1 ? 'джерело' : 'джерела')}
                getSearchData={getMediaTypes}
                {...selectProps}
              />
            </div>
          </div>
          <div className={classes.sourcesListAvailable}>
            <div className={classes.sourcesListHeader}>
              <div>
                {t('search', 'Доступні джерала')}
              </div>
              <div className={cn(classes.selectBox)}>
                {sourceData?.count || 0}
                <Icon
                  type="add_round"
                  width={16}
                  height={16}
                  isSelected={allSources === 'include'}
                  onClick={() => {
                    setAllSources(allSources !== 'include' ? 'include' : null);
                    allSources === 'include' && setSelectedSources(selectedSources.filter(item => item?.isExclude));
                  }}
                />
                <Icon
                  type="minus_round"
                  width={16}
                  height={16}
                  isSelected={allSources === 'exclude'}
                  onClick={() => {
                    setAllSources(allSources !== 'exclude' ? 'exclude' : null);
                    allSources === 'exclude' && setSelectedSources(selectedSources.filter(item => !item?.isExclude));
                  }}
                />
              </div>
            </div>
            <Loader size={65} fetching={isLoading} color={colors.font35}>
              <div className={classes.sourcesListOptions}>
                {!!sourcesOptions?.length && sourcesOptions.map(({ value, label, icon }) => (
                  <Option
                    key={value}
                    value={value}
                    label={label}
                    icon={icon}
                    allSources={allSources}
                    isSelected={checkSelected(value) || !!allSources}
                    onClick={(val) => { onChangeOption(val); }}
                  />
                ))}
              </div>
            </Loader>
            {!!sourcesOptions?.length && (
              <div className={classes.sourcesListFooter}>
                <Pagination
                  className={classes.sourcesListPaggination}
                  showSizeChanger={false}
                  defaultPageSize={15}
                  defaultCurrent={page}
                  size="small"
                  total={sourceData?.count}
                  showLessItems
                  showQuickJumper={false}
                  itemRender={itemRender}
                  onChange={(value) => { setPage(value); }}
                />
              </div>
            )}
          </div>
        </div>
        <Icon type="arrow_forward" />
        <div className={classes.sourcesListSelected}>
          <div className={classes.sourcesListHeader}>
            <div>
              {t('search', 'Додані джерала')}
            </div>
            <div className={cn(classes.selectBox)}>
              {selectedSources?.length || 0}
              <Icon
                type="add_round"
                width={16}
                height={16}
                isSelected={allSelectedSources === 'include'}
                onClick={() => {
                  setAllSelectedSources(allSelectedSources !== 'include' ? 'include' : null);
                  setSelectedSources(selectedSources.map(item => ({ ...item, isExclude: false })));
                }}
              />
              <Icon
                type="minus_round"
                width={16}
                height={16}
                isSelected={allSelectedSources === 'exclude'}
                onClick={() => {
                  setAllSelectedSources(allSelectedSources !== 'exclude' ? 'exclude' : null);
                  setSelectedSources(selectedSources.map(item => ({ ...item, isExclude: true })));
                }}
              />
            </div>
          </div>
          <div className={classes.sourcesListOptions}>
            {selectedSources?.length ? [...selectedSources].slice(pageSelected > 1 ? (pageSelected - 1) * 15 : 0, pageSelected * 15).map(({ value, label, icon }) => (
              <Option
                key={value}
                value={value}
                label={label}
                icon={icon}
                allSources={allSources}
                isSelected={checkSelected(value) || !!allSources}
                onClick={(val) => { onChangeOption(val); }}
              />
            )) : (
              <div className={classes.noDataWrapp}>
                <div className={classes.noDataImage} />
                <div className={classes.noDataText}>{t('search', 'Немає вибраних джерел')}</div>
              </div>
            )}
          </div>
          {!!selectedSources?.length && (
          <div className={classes.sourcesListFooter}>
            <Pagination
              className={classes.sourcesListPaggination}
              showSizeChanger={false}
              defaultPageSize={15}
              defaultCurrent={pageSelected}
              size="small"
              total={selectedSources?.length}
              showLessItems
              showQuickJumper={false}
              onChange={(value) => { setPageSelected(value); }}
            />
          </div>
          )}
        </div>
      </div>

      <div className={classes.buttonBox}>
        <Button
          onClick={onCancel}
          textButton={t('btn', 'CANCEL')}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Button
          type="button"
          className={classes.button}
          onClick={onSubmit}
          textButton={t('btn', 'APPLY')}
          green32
        />
      </div>
    </div>
  );
};
BodySelectSources.propTypes = {
  data: shape({}),
  onCancel: func,
  onApply: func
};
export default BodySelectSources;
