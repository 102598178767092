import { string, func, shape, bool, oneOfType } from 'prop-types';
import { useState, useEffect } from 'react';
import { Icon, Loader } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './UserAvatar.module.scss';

function UserAvatar({ image = '', avatar = '', language = 'uk', isLoading = false, onChange = () => null }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [loadingErr, setLoadingErr] = useState('');

  useEffect(() => {
    if (!image) {
      setImageUrl('');
      return;
    }
    image && handleCreateImageURL(image);
  }, [image]);

  useEffect(() => {
    if (loadingErr) {
      setTimeout(() => {
        setLoadingErr('');
      }, 3000);
    }
  }, [loadingErr]);

  const beforeUpload = (file) => {
    if (file?.type && file?.size) {
      const isJpgOrPng = file.type === 'image/jpeg' || file?.type === 'image/png';
      if (!isJpgOrPng) {
        setLoadingErr(t('error', 'ERR_FORMAT_PHOTO', language));
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setLoadingErr(t('error', 'ERR_BIG_PHOTO', language));
      }
      return isJpgOrPng && isLt2M;
    }
    return false;
  };

  function handleCreateImageURL(file) {
    const newImageUrl = URL.createObjectURL(file);
    setImageUrl(newImageUrl);
  }

  function onImageChange(event) {
    const file = event?.target?.files[0];
    beforeUpload(file) && onChange(file);
  }
  const hasImage = imageUrl || avatar;

  return (
    <div className={classes.avatarWrapper}>
      <div className={cn(classes.profile, (hasImage || isLoading) && classes.profile_image)}>
        <Loader size={45} fetching={isLoading} color={colors.font35}>
          {hasImage
            ? <img src={hasImage} alt="avatar" className={classes.image} />
            : <Icon type="profile" width={24} height={24} color={colors.font35} role="icon" /> }
        </Loader>
      </div>

      {loadingErr && <div className={classes.loadingErr}>{loadingErr}</div>}
      <div className={classes.photoLabel}>
        <div className={classes.photoLabel_label}>{t('profilePhoto', 'PHOTO', language)}</div>
        <label htmlFor="inputAvatar">
          <div className={classes.photoLabel_button}>
            {t('profilePhoto', 'CHOOSE_PHOTO', language)}
            <input
              type="file"
              id="inputAvatar"
              multiple={false}
              accept="image/*"
              onChange={onImageChange}
              onClick={() => { sendFeedEvent({ category: 'Profile Settings', event: 'Clicked_Choose_Photo_Button_Avatar' }); }}
            />
          </div>
        </label>
      </div>
      {hasImage && (
      <div
        className={classes.delete}
        onClick={() => {
          document.getElementById('inputAvatar').value = '';
          onChange(null);
        }}
      >
        <Icon type="delete" />
      </div>
      )}
    </div>
  );
}

UserAvatar.propTypes = {
  image: oneOfType([string, shape({})]),
  avatar: string,
  language: string,
  isLoading: bool,
  onChange: func
};
export default UserAvatar;
