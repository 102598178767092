import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import colors from 'styles/_colors.scss';
import classes from './SkeletonSearchArticle.module.scss';

export default function SkeletonSearchArticle() {
  return (
    <SkeletonTheme baseColor={colors.fontBody} highlightColor={colors.purpleA100}>
      <div className={cn(classes.article)}>
        <div className={cn(classes.headerWrapper)}>
          <div className={cn(classes.titleWrapper)}>
            <Skeleton height={18} width={18} inline />
            <Skeleton count={2} height={18} className={classes.text} containerClassName={classes.textWrapper} inline />
          </div>
          <Skeleton className={classes.date} containerClassName={classes.dateWrapper} inline />
        </div>
        <Skeleton count={2} height={24} className={classes.footer} containerClassName={classes.footerWrapper} inline />
      </div>
    </SkeletonTheme>
  );
}
