import { useMemo } from 'react';
import { oneOfType, func, bool, number, string, shape, arrayOf } from 'prop-types';
import { SelectCustom, AnimatedTab } from 'Components';
import t from 'utils/translateFunc';
import colors from 'styles/_colors.scss';

const TemplateComponent = ({ dataTemplate = null, templates = [], error = false, onChange = () => null }) => {
  const templatesOptions = useMemo(() => (templates?.length ? templates?.map(tp => ({ value: tp.id, label: tp.title })) : null), [templates]);
  const styles = {
    container: provided => ({
      ...provided,
      border: colors.borderWhite,
      borderRadius: '4px',
      ...(error ? {
        borderColor: colors.red500
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: error ? colors.red500 : colors.grey600,
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    })
  };

  return (
    <AnimatedTab>
      <SelectCustom
        value={dataTemplate ? { value: dataTemplate?.id, label: dataTemplate?.title || dataTemplate?.name } : null}
        placeholder={t('reports', 'CHOOSE_TEMPLATE')}
        onChange={({ value, label }) => { onChange({ id: value, name: label }); }}
        options={templatesOptions}
        styles={styles}
      />
    </AnimatedTab>
  );
};

TemplateComponent.propTypes = {
  dataTemplate: oneOfType([number, shape({})]),
  templates: arrayOf(shape({})),
  error: oneOfType([bool, string]),
  onChange: func
};

export default TemplateComponent;
