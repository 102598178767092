import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import { string, node, bool } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from './SkeletonFilterList.module.scss';

export function SkeletonFilterList({ fetching = false, children = null, className = '' }) {
  return (fetching ? (
    <div className={cn(classes.filterList, className)}>
      <SkeletonTheme baseColor={colors.fontBody} highlightColor={colors.purpleA100}>
        {new Array(6).fill(null).map((_, i) => <Skeleton key={i} count={2} className={classes.filterListItem} containerClassName={classes.filterListItemWrapper} inline />)}
      </SkeletonTheme>
    </div>
  ) : children);
}

SkeletonFilterList.propTypes = {
  fetching: bool,
  children: node,
  className: string
};
export default SkeletonFilterList;
