import colors from 'styles/_colors.scss';

export const customStyles = {
  control: (styles, state) => ({
    ...styles,
    width: '334px',
    minHeight: '37px',
    backgroundColor: colors.white,
    borderRadius: '4px',
    outline: 'none',
    boxShadow: 'none !important',
    opacity: state.isDisabled ? '0.8' : '1',
    borderColor: 'transparent !important'
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '4px 8px'
  }),
  input: styles => ({
    ...styles,
    color: colors.font400,
    lineHeight: '19px',
    fontSize: '14px'
  }),
  placeholder: styles => ({
    ...styles,
    color: colors.purpleA100,
    marginTop: '4px',
    marginBottom: '4px',
    fontSize: '14px'
  }),
  multiValue: (styles, state) => ({
    ...styles,
    height: '29px',
    borderRadius: '4px',
    backgroundColor: state.data.value === 'addWord' ? 'transparent' : colors.purpleA100
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: colors.font400,
    lineHeight: '23px',
    fontSize: '14px'
  }),
  option: styles => ({
    ...styles,
    cursor: 'pointer',
    fontSize: '14px'
  }),
  clearIndicator: styles => ({
    ...styles,
    cursor: 'pointer',
    color: colors.font400,
    display: 'none',
    ':hover': {
      color: 'red'
    }
  }),
  indicatorsContainer: styles => ({
    ...styles,
    cursor: 'pointer',
    color: colors.font400
  }),
  multiValueRemove: (styles, state) => ({
    ...styles,
    color: colors.font200,
    cursor: 'pointer',
    transform: 'scale(1.2)',
    display: state.data.value === 'addWord' ? 'none' : 'flex',
    ':hover': {
      color: colors.font400,
      transform: 'scale(1.3)'
    }
  })
};

export const customSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    width: '408px',
    minHeight: '37px',
    backgroundColor: colors.white,
    borderRadius: '4px',
    outline: 'none',
    boxShadow: 'none !important',
    opacity: state.isDisabled ? '0.8' : '1',
    borderColor: 'transparent !important'
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '4px 8px'
  }),
  input: styles => ({
    ...styles,
    color: colors.font400,
    lineHeight: '19px',
    fontSize: '14px'
  }),
  placeholder: styles => ({
    ...styles,
    color: colors.purpleA100,
    marginTop: '4px',
    marginBottom: '4px',
    fontSize: '14px'
  }),
  multiValue: styles => ({
    ...styles,
    height: '29px',
    borderRadius: '4px',
    backgroundColor: colors.purpleA100
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: colors.font400,
    lineHeight: '23px',
    fontSize: '14px'
  }),
  option: styles => ({
    ...styles,
    cursor: 'pointer',
    fontSize: '14px'
  }),
  clearIndicator: styles => ({
    ...styles,
    cursor: 'pointer',
    color: colors.font400,
    display: 'none',
    ':hover': {
      color: 'red'
    }
  }),
  indicatorsContainer: styles => ({ ...styles }),
  multiValueRemove: (styles, state) => ({
    ...styles,
    color: colors.font200,
    cursor: 'pointer',
    display: state.data.value === 'all' ? 'none' : 'flex',
    transform: 'scale(1.2)',
    ':hover': {
      color: colors.font400,
      transform: 'scale(1.3)'
    }
  })
};
