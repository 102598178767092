import { string, number, bool } from 'prop-types';
import colors from 'styles/_colors.scss';

export function AddRound({ width = 20, height = 20, color = colors.green700, className = '', isSelected = false }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '24'} height={height || '24'} viewBox="0 0 20 20" fill="none">
      <mask id={isSelected ? 'mask0_317_31301' : 'mask0_317_31120'} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill={colors.iconMainColor} />
      </mask>
      <g mask={`url(#${isSelected ? 'mask0_317_31301' : 'mask0_317_31120'})`}>
        {isSelected
          ? <path d="M9.16669 14.1665H10.8334V10.8332H14.1667V9.1665H10.8334V5.83317H9.16669V9.1665H5.83335V10.8332H9.16669V14.1665ZM10 18.3332C8.84724 18.3332 7.76391 18.1144 6.75002 17.6769C5.73613 17.2394 4.85419 16.6457 4.10419 15.8957C3.35419 15.1457 2.76044 14.2637 2.32294 13.2498C1.88544 12.2359 1.66669 11.1526 1.66669 9.99984C1.66669 8.84706 1.88544 7.76373 2.32294 6.74984C2.76044 5.73595 3.35419 4.854 4.10419 4.104C4.85419 3.354 5.73613 2.76025 6.75002 2.32275C7.76391 1.88525 8.84724 1.6665 10 1.6665C11.1528 1.6665 12.2361 1.88525 13.25 2.32275C14.2639 2.76025 15.1459 3.354 15.8959 4.104C16.6459 4.854 17.2396 5.73595 17.6771 6.74984C18.1146 7.76373 18.3334 8.84706 18.3334 9.99984C18.3334 11.1526 18.1146 12.2359 17.6771 13.2498C17.2396 14.2637 16.6459 15.1457 15.8959 15.8957C15.1459 16.6457 14.2639 17.2394 13.25 17.6769C12.2361 18.1144 11.1528 18.3332 10 18.3332Z" fill={color || colors.green700} />
          : <path d="M9.16669 14.1665H10.8334V10.8332H14.1667V9.1665H10.8334V5.83317H9.16669V9.1665H5.83335V10.8332H9.16669V14.1665ZM10 18.3332C8.84724 18.3332 7.76391 18.1144 6.75002 17.6769C5.73613 17.2394 4.85419 16.6457 4.10419 15.8957C3.35419 15.1457 2.76044 14.2637 2.32294 13.2498C1.88544 12.2359 1.66669 11.1526 1.66669 9.99984C1.66669 8.84706 1.88544 7.76373 2.32294 6.74984C2.76044 5.73595 3.35419 4.854 4.10419 4.104C4.85419 3.354 5.73613 2.76025 6.75002 2.32275C7.76391 1.88525 8.84724 1.6665 10 1.6665C11.1528 1.6665 12.2361 1.88525 13.25 2.32275C14.2639 2.76025 15.1459 3.354 15.8959 4.104C16.6459 4.854 17.2396 5.73595 17.6771 6.74984C18.1146 7.76373 18.3334 8.84706 18.3334 9.99984C18.3334 11.1526 18.1146 12.2359 17.6771 13.2498C17.2396 14.2637 16.6459 15.1457 15.8959 15.8957C15.1459 16.6457 14.2639 17.2394 13.25 17.6769C12.2361 18.1144 11.1528 18.3332 10 18.3332ZM10 16.6665C11.8611 16.6665 13.4375 16.0207 14.7292 14.729C16.0209 13.4373 16.6667 11.8609 16.6667 9.99984C16.6667 8.13873 16.0209 6.56234 14.7292 5.27067C13.4375 3.979 11.8611 3.33317 10 3.33317C8.13891 3.33317 6.56252 3.979 5.27085 5.27067C3.97919 6.56234 3.33335 8.13873 3.33335 9.99984C3.33335 11.8609 3.97919 13.4373 5.27085 14.729C6.56252 16.0207 8.13891 16.6665 10 16.6665Z" fill={color || colors.green700} />}
      </g>
    </svg>
  );
}

AddRound.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string,
  isSelected: bool
};
