import { useState } from 'react';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { bool, func, string } from 'prop-types';
import { Icon, BodyHtml, Animated } from 'Components';
import { textReplaced, getBgColor } from 'data/replacedFunc';
import colors from 'styles/_colors.scss';
import classes from './Instructions.module.scss';
import AdviceCard from './AdviceCard';
import instructionLists from './instructionsLists';

function Instructions({ show = true, language = 'en', showClose = true, eventFunc = () => null, onHide = () => null, insertIntoQuery = () => null, setTemplate = () => null }) {
  const [tab, setTab] = useState('all');
  const { advicesList, templatesCards, fieldsList, fastSearch, operatorsList, adviceExamples } = instructionLists(language);

  function onChangeTab(value) {
    if (value !== tab) {
      eventFunc('Toggle_Instructions_Tab', { tab: value });
      setTab(value);
    }
  }

  const tabStyles = {
    entering: { opacity: 1, transform: 'translateY(0)' },
    entered: { opacity: 1, transform: 'translateY(0)' },
    exiting: { opacity: 0, transform: 'translateY(20vh)' },
    exited: { opacity: 0, transform: 'translateY(20vh)' }
  };

  return (
    <Animated
      visible={show}
      duration={350}
      className={classes.instructions}
    >
      <div className={classes.tabs}>
        <div
          className={cn(classes.tab, tab === 'all' && classes.tab_active)}
          onClick={() => { onChangeTab('all'); }}
        >
          {t('search', 'ALL_TIPS')}
        </div>
        <div
          className={cn(classes.tab, tab === 'templates' && classes.tab_active)}
          onClick={() => { onChangeTab('templates'); }}
        >
          {t('search', 'TEMPLATES')}
        </div>
        <div
          className={cn(classes.tab, tab === 'operators' && classes.tab_active)}
          onClick={() => { onChangeTab('operators'); }}
        >
          {t('search', 'EXAMPLES_OF_OPERATORS')}
        </div>
        <div
          className={cn(classes.tab, tab === 'fields' && classes.tab_active)}
          onClick={() => { onChangeTab('fields'); }}
        >
          {t('search', 'FIELDS')}
        </div>
        <div
          className={cn(classes.tab, tab === 'advices' && classes.tab_active)}
          onClick={() => { onChangeTab('advices'); }}
        >
          {t('search', 'TIPS')}
        </div>
        {!!showClose && (
        <Icon
          type="close_arrow"
          color={colors.white}
          width={12}
          height={12}
          className={classes.btnClose}
          onClick={onHide}
        />
        )}
      </div>
      <Animated visible={tab === 'all' || tab === 'templates'} className={classes.content} duration={300} styles={tabStyles}>
        <div className={cn(classes.content_header)}>{t('search', 'SELECT_QUERY_TEMPLATE')}</div>
        <div className={cn(classes.content_cards)}>
          {templatesCards?.map(tepl => (
            <div
              key={tepl.id}
              className={cn(classes.card, classes.tipBtn)}
              onClick={() => {
                setTemplate(tepl.tag);
                eventFunc('Use_Template', { template: tepl.id });
              }}
            >
              <div className={classes.cardDescription}>
                <div className={classes.cardHeader}>{tepl.label}</div>
                <div className={classes.cardText}>{tepl.subtext}</div>
              </div>

              <div className={classes.cardImage}>
                <div className={tepl.imgClass} />
              </div>

            </div>
          ))}
        </div>
      </Animated>
      <Animated visible={tab === 'all' || tab === 'operators'} className={classes.content} duration={300} styles={tabStyles}>
        <div className={cn(classes.content_header)}>{t('search', 'EXAMPLES_OF_OPERATORS')}</div>
        <div className={cn(classes.content_cards)}>
          {operatorsList?.map((operatorItem, i) => (
            <div key={i} className={cn(classes.card, classes.card_operator)}>
              <div
                className={cn(classes.operator, classes.tipBtn)}
                onClick={() => {
                  insertIntoQuery(operatorItem.insertion);
                  eventFunc('Use_Operator', { operator: operatorItem?.buttonText });
                }}
              >
                {operatorItem?.colorBg
                  ? <div className={classes.operatorBtn} style={{ backgroundColor: getBgColor(operatorItem.buttonText) }}>{operatorItem.buttonText}</div>
                  : <BodyHtml html={textReplaced(operatorItem.buttonText)} className={classes.operatorBtn} />}

              </div>
              <div className={classes.description}>
                {operatorItem.description}
              </div>
            </div>
          ))}
        </div>
      </Animated>
      <Animated visible={tab === 'all' || tab === 'fields'} className={classes.content} duration={300} styles={tabStyles}>
        <div className={cn(classes.content_header)}>{t('search', 'FIELDS')}</div>
        <div className={cn(classes.content_cards)}>
          {fieldsList?.map((operatorItem, i) => (
            <div key={i} className={cn(classes.card, classes.card_operator)}>
              <div
                className={cn(classes.operator, classes.tipBtn)}
                onClick={() => {
                  insertIntoQuery(operatorItem.insertion);
                  eventFunc('Use_Field', { field: operatorItem?.buttonText });
                }}
              >
                {operatorItem?.colorBg
                  ? <div className={classes.operatorBtn} style={{ backgroundColor: getBgColor(operatorItem.buttonText) }}>{operatorItem.buttonText}</div>
                  : <BodyHtml html={textReplaced(operatorItem.buttonText)} className={classes.operatorBtn} />}
              </div>
              <div className={classes.description}>
                {operatorItem.description}
              </div>
            </div>
          ))}
        </div>
      </Animated>
      <Animated visible={tab === 'all' || tab === 'advices'} className={classes.content_advices} duration={300} styles={tabStyles}>
        <div className={cn(classes.size22)}>{t('search', 'SEARCH_QUERY_INSTRUCTIONS')}</div>
        <div className={cn(classes.size14)}>{t('search', 'SEARCH_QUERY_GUIDE')}</div>
        <div className={cn(classes.size18)}>{t('search', 'FIND_OBJECTS_QUICKLY')}</div>
        <AdviceCard
          operators={fastSearch?.operators}
          example={fastSearch?.example || ''}
          exampleAdditional={fastSearch?.exampleAdditional || ''}
          additional={fastSearch?.additional || ''}
        />
        <hr />
        <div className={cn(classes.size18)}>{t('search', 'CREATE_COMPLEX_QUERY')}</div>
        {advicesList?.map((advice, i) => (
          <div key={i} className={classes.adviceGroup}>
            <div className={cn(classes.size14)}>{advice.headerText}</div>
            {advice?.subText && <div className={cn(classes.size14)}><i>{advice?.subText}</i></div>}
            {advice?.cards?.map((card, ind) => (
              <AdviceCard
                key={ind}
                operator={card?.operator}
                example={card?.example || ''}
              />
            ))}
            <hr />
          </div>
        ))}
        <div className={cn(classes.size18)}>{t('search', 'EXAMPLES_OF_COMPLEX_QUERY')}</div>
        {adviceExamples?.map((item, i) => (
          <div key={i} className={cn(classes.card, classes.card_examples)}>
            <div className={classes.size14}>
              <strong>{item?.header}</strong>
              <span>
                &nbsp;&ndash;&nbsp;
                {item?.header_example}
                {item?.header_link && (
                <a target="_blank" rel="noopener noreferrer" href={item?.header_link?.url}>
                  &nbsp;&ndash;&nbsp;
                  {item?.header_link?.text}
                </a>
                )}
              </span>

            </div>
            <BodyHtml html={textReplaced(item?.example)} className={classes.exampleContent} />
          </div>
        ))}
        <div className={cn(classes.size14)}>
          <i>{t('search', 'ADDITIONAL_HELP')}</i>
        </div>
      </Animated>
    </Animated>
  );
}

Instructions.propTypes = {
  show: bool,
  showClose: bool,
  onHide: func,
  language: string,
  insertIntoQuery: func,
  setTemplate: func,
  eventFunc: func
};

export default Instructions;
