import { number, string } from 'prop-types';
import colors from 'styles/_colors.scss';

export const Delete = ({ width = 12, height = 12, color = colors.font90 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 15" fill="none">
    <path d="M0.857143 13.3333C0.857143 14.25 1.62857 15 2.57143 15H9.42857C10.3714 15 11.1429 14.25 11.1429 13.3333V3.33333H0.857143V13.3333ZM2.96571 7.4L4.17429 6.225L6 7.99167L7.81714 6.225L9.02571 7.4L7.20857 9.16667L9.02571 10.9333L7.81714 12.1083L6 10.3417L4.18286 12.1083L2.97429 10.9333L4.79143 9.16667L2.96571 7.4ZM9 0.833333L8.14286 0H3.85714L3 0.833333H0V2.5H12V0.833333H9Z" fill={color} />
  </svg>
);

Delete.propTypes = {
  width: number,
  height: number,
  color: string
};
