import { memo, useState } from 'react';
import { string, func, bool, number, oneOfType, shape, node, object } from 'prop-types';
import cn from 'classnames';
import { toPascalCase } from 'utils/common';
import * as svg from 'icons';
import { Tooltip, BodyHtml } from 'Components';
import classes from './icon.module.scss';
import './icon.scss';

export const Icon = memo(({ btnType = '',
  id = '',
  onClick = () => null,
  color = '',
  opacity = 1,
  tooltipText = '',
  className = '',
  href = '',
  width = 0,
  height = 0,
  style = () => null,
  wrapStyles = {},
  role = '',
  text = '',
  textClass = '',
  type,
  ...rest
}) => {
  const [state, setState] = useState({ btnClass: '' });

  const IconComp = svg[toPascalCase(type)];

  if (role === 'button') {
    return (
      <Tooltip
        text={typeof tooltipText === 'string' ? <BodyHtml html={tooltipText} /> : tooltipText}
        className={cn(className, classes.icon_btn)}
      >
        <div {...(id ? { id } : null)} {...(onClick ? { onClick } : null)} className={cn(btnType && btnType)}>
          {IconComp && (
          <IconComp
            className={cn(classes.icon, className)}
            opacity={opacity}
            {...(color ? { color } : null)}
            {...(width ? { width } : null)}
            {...(height ? { height } : null)}
          />
          )}
          {text && <div style={{ ...(color ? { color } : null) }} className={textClass}>{text}</div>}
        </div>
      </Tooltip>
    );
  }
  if (role === 'icon') {
    return (
      <IconComp
        className={className}
        onClick={onClick}
        opacity={opacity}
        color={color}
        type={type}
        width={width}
        height={height}
        style={style}
        {...rest}
      />
    );
  }

  return (
    <a
      {...(id ? { id } : null)}
      {...(href ? { href } : null)}
      {...(onClick ? { onClick } : null)}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'flex',
        alignItems: 'center',
        ...wrapStyles
      }}
      className={className}
      onMouseEnter={() => setState({ btnClass: 'hover-btn' })}
      onMouseLeave={() => setState({ btnClass: '' })}
    >
      {IconComp && (
      <IconComp
        className={state.btnClass}
        {...(onClick ? { onClick } : null)}
        {...(opacity ? { opacity } : null)}
        {...(color ? { color } : null)}
        {...(onClick ? { onClick } : null)}
        {...(width ? { width } : null)}
        {...(height ? { height } : null)}
        type={type}
        {...rest}
      />
      )}
      {text && <div style={{ ...(color ? { color } : null) }} className={textClass}>{text}</div>}
    </a>
  );
});

Icon.propTypes = {
  id: string,
  onClick: oneOfType([func, bool]),
  color: string,
  opacity: number,
  tooltipText: oneOfType([number, string, node]),
  className: string,
  href: string,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  style: oneOfType([func, shape({})]),
  wrapStyles: object,
  fill: string,
  tooltipProps: oneOfType([func, shape({})]),
  type: string.isRequired,
  role: string,
  btnType: string,
  text: oneOfType([string, number, node, object]),
  textClass: string
};
