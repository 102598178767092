import { useState, useEffect } from 'react';
import { array, bool, func } from 'prop-types';
import { useSelector } from 'react-redux';
import { sendFeedEvent } from 'services/amplitude/events';
import t from 'utils/translateFunc';
import { useCopilotHistoryNextPage } from 'storage/copilot/requestsHooks';
import classes from './CopilotHistory.module.scss';

function History({ chats_list = [], select_chat = () => null, is_full_screen = false }) {
  const [chats, setChats] = useState([]);
  const { language } = useSelector(state => state?.userInfo);
  const { mutate: loadNextPage } = useCopilotHistoryNextPage();

  useEffect(() => {
    setChats(chats_list);
  }, [chats_list]);

  function formatDate(q_date) {
    const date = new Date(q_date);
    const now = new Date();
    const delta_days = (now - date) / (1000 * 60 * 60 * 24);
    if (delta_days > 365) return date.getFullYear();
    if (delta_days > 30) return `${date.toLocaleString('default', { day: '2-digit' })}.${date.toLocaleString('default', { month: '2-digit' })}.${date.toLocaleString('default', { year: 'numeric' })}`;
    if (delta_days > 7) return t('COPILOT', 'PREVIOUS_30_DAYS', language);

    if (date.getDate() === now.getDate()) return t('COPILOT', 'TODAY', language);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.getDate() === yesterday.getDate()) return t('COPILOT', 'YESTERDAY', language);

    return t('COPILOT', 'PREVIOUS_7_DAYS', language);
  }

  function handleScroll(e) {
    const scrollHeight = e?.target?.scrollHeight || 0;
    const clientHeight = e?.target?.clientHeight || 0;
    const scrollTop = e?.target?.scrollTop || 0;
    if ((clientHeight + scrollTop - scrollHeight) === 0) {
      loadNextPage();
    }
  }

  function getFilters(item) {
    if (item?.filter) {
      const filter = item.filter;
      const active_filter = Object.keys(filter).map((key) => {
        if (typeof filter[key] === 'object' && filter[key]?.length) {
          return filter[key].map(criteria => ({ key: t('filtrationPanel', key.toUpperCase(), language), value: criteria?.name || '', id: criteria?.id || null }));
        }
        return null;
      }).filter(val => !!val).flat();
      return active_filter.map((filter_item, i) => (
        <div className={classes.filterItem} key={i}>
          <span className={classes.filterName}>
            { filter_item.key }
            :&nbsp;
          </span>
          <span className={classes.filterValue}>{ filter_item.value }</span>
        </div>
      ));
    }
    return (<span />);
  }

  return (
    <main className={classes.history} onScroll={handleScroll}>
      {chats.length === 0 && <div className={classes.empty}>{t('COPILOT', 'NO_CHATS', language)}</div>}
      {chats.map((item, i) => (
        <div
          className={classes.chatItem}
          onClick={() => {
            if (is_full_screen) {
              sendFeedEvent({ category: 'copilot', event: 'Selected_Existed_Copilot_Chat_In_Full_Screen_Mode' });
            } else {
              sendFeedEvent({ category: 'copilot', event: 'Selected_Existed_Copilot_Chat' });
            }
            select_chat(item.id);
          }}
          key={i}
        >
          <div className={classes.question} key={item.id}>
            <div className={classes.quetText}>{item.name}</div>
            <div className={classes.questDate}>{formatDate(item.created_at)}</div>
          </div>
          <div className={classes.filters}>
            {getFilters(item)}
          </div>
        </div>
      ))}
    </main>
  );
}

History.propTypes = {
  chats_list: array,
  select_chat: func,
  is_full_screen: bool
};

export default History;
