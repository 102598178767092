import t from 'utils/translateFunc';
import { TextField, Icon } from 'Components';
import { func, number, oneOfType, string } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from '../FiltersList.module.scss';

function CustomRage({ filterName = '', eventFunc = () => null, range = '', label = '', onChange = () => null }) {
  return (
    <div className={classes.selectWrapper}>
      <TextField
        labelText={label}
        value={range}
        classNameLabelText={classes.seletMenuTitle}
        onChange={({ target }) => {
          const pattern = /^\s*\d*$/;
          !target.value && onChange('');
          if (target.value && pattern.test(target.value)) {
            onChange(target.value);
            eventFunc(filterName, { value: target.value });
          }
        }}
        classNameForInput={classes.inputNumber}
        placeholder={t('search', 'FROM')}
        customIcon={(
          <span className={classes.arrowsBox}>
            <Icon
              type="arrow_left"
              width={20}
              height={20}
              color={colors.font70}
              onClick={() => {
                onChange(+range >= 1000 ? +range - 1000 : 0);
                eventFunc(filterName, { value: +range >= 1000 ? +range - 1000 : 0 });
              }}
            />
            <Icon
              type="arrow_left"
              width={20}
              height={20}
              className={classes.iconRight}
              color={colors.font70}
              onClick={() => {
                onChange(range ? +range + 1000 : 1000);
                eventFunc(filterName, { value: range ? +range + 1000 : 1000 });
              }}
            />
          </span>
      )}
      />
    </div>
  );
}

CustomRage.propTypes = { filterName: string, eventFunc: func, range: oneOfType([number, string]), label: string, onChange: func };

export default CustomRage;
