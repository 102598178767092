import cn from 'classnames';
import t from 'utils/translateFunc';
import { bool, func } from 'prop-types';
import classes from './ExampleCard.module.scss';

function ExampleCard({ isBlur = false, onClick = () => null }) {
  return (
    <div className={cn(classes.alertsCard, isBlur && classes.alertsCard_blur)} onClick={onClick}>
      <div className={classes.alertBody}>
        <div className={classes.textWrap}>
          <div className={classes.textTitle}>{t('alerts', 'EXAMPLE_ALERTS')}</div>
          <div className={classes.textSmallSubTitle}>
            {t('alerts', 'BY_KEYWORD')}
          </div>
          <div className={classes.textSubTitle}>
            &lt;
            {t('alerts', 'COMPANY_NAME')}
            &gt;
          </div>
        </div>
        <div className={classes.descriptions}>
          <div className={classes.textWrap}>
            {t('alerts', 'SEE_RECIVER_MAIL')}
          </div>
          <div className={classes.image} />
        </div>
      </div>

      <div className={classes.alertFooter}>
        <div className={classes.postItem}>
          <span className={cn(classes.postAvar)}>Q</span>
          <span className={classes.postText}>{t('alerts', 'COLLEAGUE_MAIL')}</span>
        </div>
        <div className={classes.postItem}>
          <span className={cn(classes.postAvar, classes.postAvar_orange)}>Q</span>
          <span className={classes.postText}>{t('alerts', 'YOUR_MAIL')}</span>
        </div>
      </div>
    </div>
  );
}

ExampleCard.propTypes = {
  isBlur: bool,
  onClick: func
};

export default ExampleCard;
