import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { bool, node, oneOfType, shape } from 'prop-types';
import colors from 'styles/_colors.scss';
import classes from './SkeletonSearchCharts.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';

function SkeletonSearchCharts({ fetching = false, children = null }) {
  return (fetching ? (
    <SkeletonTheme baseColor={colors.white} highlightColor={colors.purpleA100}>
      <div className={classes.chartWrapp}>
        <Skeleton height={276} borderRadius={8} inline containerClassName={classes.chart} />
        <Skeleton count={3} height={20} width={154} borderRadius={30} inline containerClassName={classes.legend} />
      </div>
    </SkeletonTheme>
  ) : children);
}

SkeletonSearchCharts.propTypes = { fetching: bool, children: oneOfType([node, shape({})]) };

export default SkeletonSearchCharts;
