import { string, func, bool, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import { Icons } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './FilterOption.module.scss';

const FilterOption = ({
  icon = false,
  onChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
  name = '',
  optionIconRight = '',
  text = '',
  mode32 = false,
  checked = false,
  amplitudeCategory = '',
  amplitudeEvent = ''
}) => {
  function sendAmplitudeInfo() {
    amplitudeCategory && amplitudeEvent && sendFeedEvent({
      category: amplitudeCategory,
      event: amplitudeEvent
    });
  }

  return (
    <label
      className={cn(
        classes.filterOption,
        icon && classes.filterOption_icon,
        checked && classes.filterOption_isChecked,
        mode32 && classes.filterOption_mode32
      )}
    >
      <input
        className={classes.visually_hidden}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={sendAmplitudeInfo}
      />
      {icon && (
        <Icons
          icon={icon}
          className={cn(classes.icon, mode32 && classes.icon_mode32)}
        />
      )}
      <p className={classes.text}>
        {text}
        {optionIconRight && (<Icons icon={optionIconRight} className={cn(classes.iconRight)} />)}
      </p>
    </label>
  );
};

FilterOption.propTypes = {
  text: oneOfType([string, number]),
  icon: oneOfType([string, bool]),
  mode32: bool,
  onChange: func,
  checked: bool,
  onFocus: func,
  onBlur: func,
  name: string,
  optionIconRight: string,
  amplitudeCategory: string,
  amplitudeEvent: string
};

export default FilterOption;
