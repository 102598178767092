import { useState, useEffect, useMemo } from 'react';
import t from 'utils/translateFunc';
import { FilterList, SkeletonFilterList, SearchField, NoSearchData } from 'Components';
import { useGetCreators } from 'storage/queryHooks';
import { useDispatch, useSelector } from 'react-redux';
import { removeFilterItem, addFilterItem, addCreators } from 'storage/actions';
import useDebounceValue from 'hooks/useDebounce';
import colors from 'styles/_colors.scss';
import classes from './FilterCreator.module.scss';

const FilterCreator = () => {
  const dispatch = useDispatch();
  const { filterList, creators } = useSelector(state => state?.filters);
  const initCreators = useSelector(state => state?.news?.creators);
  const { language } = useSelector(state => state?.userInfo);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterSearchOnServer, setFilterSearchOnServer] = useState(false);
  const icontains = useDebounceValue(filterSearch, 1000);
  const { isLoading: creatorsLoading } = useGetCreators({ enabled: (!!icontains && filterSearchOnServer), query: { icontains } });
  const addItem = ({ id, name, type, filterName }) => { dispatch(addFilterItem({ id, name, type, filterName })); };
  const removeItem = ({ id, filterName }) => { dispatch(removeFilterItem({ id, filterName })); };
  const filterType = t('filtrationPanel', 'CREATOR', language);

  useEffect(() => {
    if (!filterSearch?.length) {
      dispatch(addCreators([]));
    }
  }, [filterSearch?.length]);

  const selectedData = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'creator'), [filterList?.length]);
  const searchData = useMemo(() => {
    const name_filter = ({ name }) => (name?.length ? name.toLowerCase()?.includes(filterSearch?.toLowerCase()) : '');
    const init_data = initCreators?.filter(name_filter).slice(0, 20);
    init_data?.length === 0 ? setFilterSearchOnServer(true) : setFilterSearchOnServer(false);
    const data = creators?.filter(name_filter).filter(item => !init_data.find(v => v?.id === item?.id));
    const filtered_data = init_data?.length ? init_data : data;
    // filter duplicates
    const uniq_ids = Array.from(new Set(filtered_data.map(item => item.id)));
    return uniq_ids.map(id => filtered_data.find(item => item.id === id));
  }, [creators?.length, initCreators?.length, filterSearch]);

  return (
    <div className={classes.filterCreator}>
      <div className={classes.container}>
        <div className={classes.searchWrap}>
          <SearchField
            placeholder={t('creatorLngFilter', 'ENTER_CREATOR', language)}
            value={filterSearch}
            setValue={setFilterSearch}
          />
        </div>
        <SkeletonFilterList type="bubbles" fetching={creatorsLoading} color={colors.font35}>
          {searchData?.length ? (
            <FilterList
              dataFilters={searchData}
              filterSearch={filterSearch}
              selectData={selectedData}
              filterName="creator"
              addItem={addItem}
              removeItem={removeItem}
              filterType={filterType}
              language={language}
            />
          ) : !!filterSearch && <NoSearchData language={language} /> }
        </SkeletonFilterList>
      </div>
    </div>
  );
};

export default FilterCreator;
