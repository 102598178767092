import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export const ArrowOutline = ({ width = 5, height = 10, className = '' }) => (
  <svg width={width} height={height} viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M0.5 9.3002C0.366667 9.3002 0.25 9.25436 0.15 9.1627C0.05 9.07103 0 8.9502 0 8.8002V1.2002C0 1.0502 0.05 0.929362 0.15 0.837695C0.25 0.746029 0.366667 0.700195 0.5 0.700195C0.533333 0.700195 0.65 0.750195 0.85 0.850195L4.475 4.4752C4.55833 4.55853 4.61667 4.64186 4.65 4.7252C4.68333 4.80853 4.7 4.9002 4.7 5.0002C4.7 5.1002 4.68333 5.19186 4.65 5.2752C4.61667 5.35853 4.55833 5.44186 4.475 5.5252L0.85 9.1502C0.8 9.2002 0.745833 9.2377 0.6875 9.2627C0.629167 9.28769 0.566667 9.3002 0.5 9.3002Z" fill={colors.font70} />
  </svg>

);

ArrowOutline.propTypes = {
  width: number,
  height: number,
  className: string
};
