import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export const Copy = ({ width = 16, height = 16, color = colors.font70 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <mask id="mask0_1122_858" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill={colors.iconMainColor} />
    </mask>
    <g mask="url(#mask0_1122_858)">
      <path d="M3.33333 14.6667C2.96667 14.6667 2.65278 14.5361 2.39167 14.275C2.13056 14.0139 2 13.7 2 13.3333V4.66668C2 4.47779 2.06389 4.31945 2.19167 4.19168C2.31944 4.0639 2.47778 4.00001 2.66667 4.00001C2.85556 4.00001 3.01389 4.0639 3.14167 4.19168C3.26944 4.31945 3.33333 4.47779 3.33333 4.66668V13.3333H10C10.1889 13.3333 10.3472 13.3972 10.475 13.525C10.6028 13.6528 10.6667 13.8111 10.6667 14C10.6667 14.1889 10.6028 14.3472 10.475 14.475C10.3472 14.6028 10.1889 14.6667 10 14.6667H3.33333ZM6 12C5.63333 12 5.31944 11.8695 5.05833 11.6083C4.79722 11.3472 4.66667 11.0333 4.66667 10.6667V2.66668C4.66667 2.30001 4.79722 1.98612 5.05833 1.72501C5.31944 1.4639 5.63333 1.33334 6 1.33334H12C12.3667 1.33334 12.6806 1.4639 12.9417 1.72501C13.2028 1.98612 13.3333 2.30001 13.3333 2.66668V10.6667C13.3333 11.0333 13.2028 11.3472 12.9417 11.6083C12.6806 11.8695 12.3667 12 12 12H6ZM6 10.6667H12V2.66668H6V10.6667Z" fill={color} />
    </g>
  </svg>
);

Copy.propTypes = {
  width: number,
  height: number,
  color: string
};
