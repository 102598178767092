import { number, string } from 'prop-types';
import colors from 'styles/_colors.scss';

export function FilterPink({ width = 24, height = 24, color = colors.pink400 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill={color} />
      <path d="M11.3844 18C11.1719 18 10.9938 17.9281 10.85 17.7844C10.7063 17.6406 10.6344 17.4625 10.6344 17.25V12.75L6.15312 7.03125C5.97812 6.81875 5.95312 6.59375 6.07812 6.35625C6.20312 6.11875 6.39688 6 6.65938 6H17.6094C17.8719 6 18.0656 6.11875 18.1906 6.35625C18.3156 6.59375 18.2906 6.81875 18.1156 7.03125L13.6344 12.75V17.25C13.6344 17.4625 13.5625 17.6406 13.4188 17.7844C13.275 17.9281 13.0969 18 12.8844 18H11.3844ZM12.1344 12.825L16.6344 7.125H7.63437L12.1344 12.825Z" fill="white" />
    </svg>
  );
}

FilterPink.propTypes = {
  width: number,
  height: number,
  color: string
};
