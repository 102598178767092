import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import { sendFeedEvent } from 'services/amplitude/events';
import { Bolt, Vector } from 'icons';
import { Button } from 'Components';
import { useCopilotIncreaseLimits } from 'storage/copilot/requestsHooks';
import t from 'utils/translateFunc';
import colors from 'styles/_colors.scss';
import classes from './AIUsing.module.scss';

function AIUsing({ load_limits = () => null, is_loading_limits = false, is_full_screen = false }) {
  const [isShowIncreaseAnswer, setIsShowIncreaseAnswer] = useState(false);
  const { usage, limit } = useSelector(state => state?.copilot?.limits);
  const { language } = useSelector(state => state?.userInfo);
  const { mutate: increaseLimits } = useCopilotIncreaseLimits();

  useEffect(() => {
    !is_loading_limits && load_limits();
  }, []);

  const usagePercent = useMemo(() => {
    if (!limit) return 0;
    const percent = Math.ceil((usage * 100) / limit);
    return percent > 100 ? 100 : 100 - percent;
  }, [usage, limit]);

  function increaseCopilotLimit() {
    if (is_full_screen) {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Increase_Limit_In_Full_Screen_Mode' });
    } else {
      sendFeedEvent({ category: 'copilot', event: 'Clicked_Copilot_Increase_Limit' });
    }
    increaseLimits(null, {
      onSuccess: () => setIsShowIncreaseAnswer(true)
    });
  }

  return (
    <main>
      <div className={classes.part}>
        <div className={classes.usageCard}>
          <div className={classes.usageText}>
            <Bolt color={colors.purple350} />
            {t('COPILOT', 'AI_USING', language)}
          </div>
          <div className={classes.usageScale}>
            <div className={classes.scalePointer} style={{ width: `${usagePercent}%` }} />
          </div>
          <div className={classes.usageLimits}>
            <div>
              {limit ? limit - (usage || 0) : 0}
              &nbsp;
              {t('COPILOT', 'AI_USING_AVAILABLE', language)}
            </div>
            <div>
              {t('COPILOT', 'AI_USING_OUT_OF', language)}
              &nbsp;
              {limit || 0}
              &nbsp;
              {t('COPILOT', 'AI_USING_REQUESTS', language)}
            </div>
          </div>
        </div>
      </div>
      {usage >= limit && (
        <div className={classes.part}>
          <div className={classes.limitsUp}>
            <div className={classes.limitsImg} />
            <div className={classes.titleText}>
              {t('COPILOT', 'AI_USING_UP_TITLE', language)}
            </div>
            <div className={classes.mainText}>
              {t('COPILOT', 'AI_USING_UP_TEXT', language)}
            </div>
            <Button
              textButton={t('COPILOT', 'AI_USING_UP_BTN', language)}
              onClick={() => increaseCopilotLimit()}
              green32
            />
          </div>
        </div>
      )}
      {isShowIncreaseAnswer && (
        <div className={classes.partMsg}>
          <div className={classes.MsgWrap}>
            <div className={classes.copilotUser}>
              <Vector />
              <span>Q Insight</span>
            </div>
            <div className={classes.increaseLimitMsg}>
              {t('COPILOT', 'INCREASE_LIMITS_ANSWER')}
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

AIUsing.propTypes = {
  load_limits: func,
  is_loading_limits: bool,
  is_full_screen: bool
};

export default AIUsing;
