import { string, number, oneOfType } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Remove({ width = 20, height = 20, color = colors.font70, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '20'} height={height || '20'} viewBox="0 0 20 20" fill="none">
      <mask id="mask0_393_95418" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_393_95418)">
        <path d="M5.83331 17.5C5.37498 17.5 4.98262 17.3368 4.65623 17.0104C4.32984 16.684 4.16665 16.2917 4.16665 15.8333V5H3.33331V3.33333H7.49998V2.5H12.5V3.33333H16.6666V5H15.8333V15.8333C15.8333 16.2917 15.6701 16.684 15.3437 17.0104C15.0173 17.3368 14.625 17.5 14.1666 17.5H5.83331ZM14.1666 5H5.83331V15.8333H14.1666V5ZM7.49998 14.1667H9.16665V6.66667H7.49998V14.1667ZM10.8333 14.1667H12.5V6.66667H10.8333V14.1667Z" fill={color || colors.font70} />
      </g>
    </svg>
  );
}

Remove.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  color: string,
  className: string
};
