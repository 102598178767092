import { memo } from 'react';
import { shape, oneOfType, number, string, bool, func } from 'prop-types';
import cn from 'classnames';
import { Icon } from 'Components';
import colors from 'styles/_colors.scss';
import classes from './BodySelectSources.module.scss';

const Option = memo(({ label = '', icon = '', value = '', isSelected = null, onClick = () => null }) => (
  <div className={classes.optionPart}>
    <p className={classes.labelText}>
      {!!icon && (
      <img
        alt="icon"
        height="18px"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.style.display = 'none';
        }}
        importance="high"
        loading="eager"
        referrerPolicy="no-referrer"
        className={classes.image}
        src={icon}
      />
      )}
      <span>{label}</span>
    </p>
    <div className={cn(classes.selectBox)}>
      <Icon
        type="add_round"
        width={16}
        height={16}
        color={!!isSelected && !isSelected?.isExclude ? colors.green700 : colors.iconMainColor}
        isSelected={!!isSelected && !isSelected?.isExclude}
        onClick={() => { onClick({ value, label, icon, isExclude: false }); }}
      />
      <Icon
        type="minus_round"
        width={16}
        height={16}
        color={!!isSelected && isSelected?.isExclude ? colors.red600 : colors.iconMainColor}
        isSelected={!!isSelected && !!isSelected?.isExclude}
        onClick={() => { onClick({ value, label, icon, isExclude: true }); }}
      />
    </div>
  </div>
));

Option.propTypes = { label: string, icon: string, value: oneOfType([string, number]), isSelected: oneOfType([bool, shape({})]), onClick: func };

export default Option;
