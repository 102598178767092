import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function Flash({ width = 32, height = 32, color = colors.font70, className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width || '32'} height={height || '32'} viewBox="0 0 32 32" fill="none">
      <mask id="mask0_1210_40420" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_1210_40420)">
        <path d="M16.0007 20.8003L20.2673 14.667H16.4673L19.134 5.33366H12.0007V16.0003H16.0007V20.8003ZM23.1673 15.1337L15.1673 26.7003C15.034 26.9003 14.8618 27.0337 14.6507 27.1003C14.4395 27.167 14.234 27.167 14.034 27.1003C13.834 27.0337 13.6673 26.917 13.534 26.7503C13.4007 26.5837 13.334 26.3781 13.334 26.1337V18.667H12.0007C11.2673 18.667 10.6395 18.4059 10.1173 17.8837C9.5951 17.3614 9.33398 16.7337 9.33398 16.0003V5.33366C9.33398 4.60033 9.5951 3.97255 10.1173 3.45033C10.6395 2.9281 11.2673 2.66699 12.0007 2.66699H19.8007C20.5118 2.66699 21.084 2.94477 21.5173 3.50033C21.9507 4.05588 22.0784 4.66699 21.9007 5.33366L20.0007 12.0003H21.5007C22.3007 12.0003 22.8951 12.3559 23.284 13.067C23.6729 13.7781 23.634 14.467 23.1673 15.1337Z" fill={color || colors.font70} />
      </g>
    </svg>
  );
}

Flash.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
