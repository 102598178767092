import { useState, useRef, useEffect } from 'react';
import { Icons, InfoBtn, Button } from 'Components';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { PROJECT } from 'config';
import classes from './SearchWindow.module.scss';

const SearchWindow = ({
  onCancel = () => null,
  onClickOk = () => null,
  language = 'uk',
  visible = false,
  onClear = () => null,
  search = '',
  detailsVisible = false
}) => {
  const [editName, setEditName] = useState(search);
  const [instruction, setInstruction] = useState(false);
  const [show, setShow] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleClick);
      document.addEventListener('keydown', escAndTabFunction);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', escAndTabFunction);
    };
  }, [visible]);

  useEffect(() => {
    instruction ? setShow(true) : setTimeout(() => setShow(false), 300);
  }, [instruction]);

  function escAndTabFunction({ code }) {
    if (code === 'Escape' || code === 'Tab') {
      onCancel();
    }
  }

  function handleClick({ target }) {
    if (ref.current.contains(target)) return;
    onCancel();
  }

  const onChangeName = (e) => {
    e.preventDefault();
    setEditName(e.target.value);
  };

  const onClickKey = (e) => {
    (e.code === 'Enter' || e.key === 'Enter') && onSubmit(e);
  };

  function onSubmit(e) {
    e.preventDefault();
    onClickOk({ id: 'icontains', name: editName, type: t('filtrationPanel', 'ICONTAINS', language), filterName: 'icontains' });
  }

  const handleClear = () => {
    setEditName('');
    onClear();
  };
  const disabled = !editName?.length;
  const instructionLooqme = language === 'ru'
    ? 'https://hub.looqme.io/instructions/'
    : 'http://help.looqme.io/uk/articles/6337096-%D1%96%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D1%96%D1%8F-%D1%96%D0%B7-%D0%BD%D0%B0%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%BD%D1%8F-%D0%BF%D0%BE%D1%88%D1%83%D0%BA%D0%BE%D0%B2%D0%B8%D1%85-%D0%B7%D0%B0%D0%BF%D0%B8%D1%82%D1%96%D0%B2';
  const instructionMetricom = 'https://app.metricom.io/instructions/';
  const instructionLink = PROJECT === 'metricom' ? instructionMetricom : instructionLooqme;
  return (
    <div className={cn(classes.search, !detailsVisible && classes.search_down)} ref={ref}>
      <div className={cn(classes.search_wrapper, instruction && classes.search_wrapper_active)}>
        <button
          className={classes.exitBtn}
          onClick={() => onCancel()}
          title="close"
          type="button"
        >
          <Icons icon="Icn_Clear" className={cn(classes.icon)} />
        </button>
        <div className={cn(classes.title)}>
          {t('searchWindow', 'SEARCH_PUBLICATIONS', language)}
        </div>
        <div className={cn(classes.body)}>
          <textarea
            placeholder={t('searchWindow', 'ENTER_WORDS', language)}
            className={classes.body_text}
            value={editName}
            onChange={onChangeName}
            onKeyDown={onClickKey}
          />
          <div className={classes.buttonBox}>
            {PROJECT !== 'metricom' && (
            <InfoBtn
              className={cn(classes.infoButton, show && classes.infoButton_active)}
              text={t('searchWindow', 'INSTRUCTION', language)}
              icon="error_outline"
              onClick={() => setInstruction(!instruction)}
            />
            )}
            <Button
              onClick={handleClear}
              textButton={t('btn', 'CLEAR', language)}
              className={classes.buttonCancel}
              purpleTransparent32
            />
            <Button
              disabled={disabled}
              onClick={onSubmit}
              textButton={t('btn', 'SEARCH', language)}
              green32
            />
          </div>
        </div>

        {show && (
          <div className={cn(classes.instruction, instruction && classes.instruction_active)}>
            {t('searchWindow', 'TAB', language)}
            <p><b>приватбанк privatbank</b></p>
            <p>{t('searchWindow', 'QUOTES', language)}</p>
            <p><b>“приват банк” “privat bank”</b></p>
            <br />
            <p>{t('searchWindow', 'STARS', language)}</p>
            <p><b>“приват банк*” приватбанк*</b></p>
            <br />
            <p>{t('searchWindow', 'AND', language)}</p>
            <p><b>приватбанк AND “курс валют”</b></p>
            <br />
            <p>
              <a
                href={instructionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('searchWindow', 'MORE', language)}
              </a>

            </p>
          </div>
        )}

      </div>
    </div>

  );
};

SearchWindow.propTypes = {
  onCancel: func,
  onClickOk: func,
  language: string,
  visible: bool,
  onClear: func,
  search: string,
  detailsVisible: bool
};

export default SearchWindow;
