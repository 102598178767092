import { useEffect, useState } from 'react';
import { oneOfType, func, bool, string, shape, arrayOf } from 'prop-types';
import { SelectCustom, AnimatedTab } from 'Components';
import t from 'utils/translateFunc';
import colors from 'styles/_colors.scss';

const KeywordComponent = ({ dataKeyword = null, keywords = [], keywordsNextPage = () => null, hasKeywordsNextPage = false, error = false, onChange = () => null }) => {
  const [keywordsOptions, setKeywordsOptions] = useState(null);
  const styles = {
    container: provided => ({
      ...provided,
      border: colors.borderWhite,
      borderRadius: '4px',
      ...(error ? {
        borderColor: colors.red500
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: error ? colors.red500 : colors.grey600,
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    })
  };

  useEffect(() => {
    if (keywords?.length) setKeywordsOptions(keywords?.map(kw => ({ value: kw.id, label: kw.name })));
    if (hasKeywordsNextPage) keywordsNextPage({ cancelRefetch: false });
  }, [keywords]);

  return (
    <AnimatedTab>
      <SelectCustom
        value={dataKeyword ? { value: dataKeyword?.id, label: dataKeyword?.name } : null}
        placeholder={t('alerts', 'CHOOSE_KEYWORD')}
        onChange={({ value, label }) => { onChange({ id: value, name: label }); }}
        options={keywordsOptions}
        styles={styles}
        onMenuScrollToBottom={() => { if (hasKeywordsNextPage) keywordsNextPage(); }}
        onMenuOpen={() => null}
        is_searchable
      />
    </AnimatedTab>
  );
};

KeywordComponent.propTypes = {
  dataKeyword: shape({}),
  keywords: arrayOf(shape({})),
  keywordsNextPage: func,
  hasKeywordsNextPage: bool,
  error: oneOfType([bool, string]),
  onChange: func
};

export default KeywordComponent;
