import colors from 'styles/_colors.scss';

export default function getStyles() {
  const stylesMulti = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      minHeight: '32px',
      backgroundColor: state?.sort ? colors.fontBody : colors.white,
      borderRadius: '4px',
      boxShadow: colors.boxShadow,
      border: 'none',
      outline: 'none',
      opacity: state.isDisabled ? '0.8' : '1'
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '6px',
      paddingLeft: '12px'
    }),

    multiValue: provided => ({
      ...provided,
      margin: 0,
      padding: '4px 0',
      backgroundColor: colors.white
    }),
    multiValueLabel: provided => ({
      ...provided,
      padding: '0 2px',
      paddingLeft: '2px',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: colors.textPrimary,
      cursor: 'pointer',
      transition: 'all 150ms linear',
      '&:hover + div[role="button"]': {
        opacity: 1
      }
    }),
    multiValueRemove: provided => ({
      ...provided,
      padding: 0,
      cursor: 'pointer',
      opacity: 0,
      backgroundColor: colors.white,
      color: colors.font50,
      transition: 'all 150ms linear',
      '&:hover': {
        opacity: 1,
        backgroundColor: colors.white,
        color: colors.font50
      }
    })
  };
  const customStyles = {
    control: provided => ({
      ...provided,
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      backgroundColor: colors.white,
      borderRadius: '4px',
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    }),

    valueContainer: provided => ({
      ...provided,
      display: 'flex',
      flexWrap: 'no-wrap',
      padding: '0 16px'
    }),

    menu: provided => ({
      ...provided,
      margin: '2px 0 0 0',
      maxHeight: '253px',
      border: 'none',
      outline: 'none',
      borderRadius: '4px',
      boxShadow: colors.boxShadow
    }),

    menuList: provided => ({
      ...provided,
      paddingTop: '4px',
      paddingBottom: '3px',
      maxHeight: '200px',
      '::-webkit-scrollbar': {
        width: '11px',
        height: '12px'
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: colors.grey300,
        border: '3px solid transparent',
        borderRadius: '20px',
        backgroundClip: 'content-box'
      }
    }),

    option: (provided, state) => ({
      ...provided,
      marginBottom: '1px',
      padding: '6px 12px',
      display: 'flex',
      alignItems: 'center',
      height: '32px',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: '400',
      cursor: 'pointer',
      color: colors.font400,
      backgroundColor: state.isFocused ? colors.purpleA10 : colors.white,
      ':active': { backgroundColor: colors.white }
    }),

    placeholder: provided => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: colors.font70
    }),

    input: provided => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: colors.font70
    }),

    noOptionsMessage: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1px',
      height: '24px',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: '400',
      color: colors.font90
    })
  };
  return { stylesMulti, customStyles };
}
