import { useMemo, useEffect, useCallback, useRef, memo } from 'react';
import t from 'utils/translateFunc';
import { Row, Col } from 'antd';
import { Button, Icon, SelectCustom, SkeletonReportsList, ErrorBoundarySentry } from 'Components';
import cn from 'classnames';
import { bool, shape, func, array } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './ReportsTable.module.scss';
import ReportCard from '../ReportCard';
import { colSizes } from './colSizes';
import CustomCard from '../OnboardingCards/CustomCard';
import ExampleCard from '../OnboardingCards/ExampleCard';
import SetReportCard from '../OnboardingCards/SetReportCard';
import OtherChannelCard from '../OnboardingCards/OtherChannelCard';

const ReportsTable = memo(({ reports = [], portalsOptions = [], reportsPortal = null, userContacts = null, detailsVisible = false,
  portalError = false, reportData = null, fetchings = null, onEditReport = () => null, onChangePortal = () => null }) => {
  const { sizes, sizeOrder1, sizeOrder2, sizeOrder3, sizeOrder4, sizeOrder5, sizeOrder6 } = colSizes(detailsVisible);
  const tableRef = useRef();
  const hasEmpty = useMemo(() => reports.some(item => !item?.id), [reports]);
  const hasReports = useMemo(() => reports.some(item => typeof item?.id === 'number'), [reports]);
  const sizeFunc = useCallback(
    (type, id) => {
      if (!id) return sizeOrder1;
      if (type === 'performace') return sizeOrder1;
      if (type === 'example') return sizeOrder2;
      if (type === 'try') return sizeOrder3;
      if (type === 'set_report') return sizeOrder4;
      if (type === 'full_image') return sizeOrder5;
      if (type === 'other_channel') return sizeOrder6;
      return sizes;
    },
    [detailsVisible]
  );

  useEffect(() => {
    scrollToTop();
  }, [portalError]);

  function scrollToTop() {
    portalError && tableRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const sendClickWidgetEvent = () => sendAlertsEvent({ category: 'Add report', event: 'Clicked_On_Widget_In_Reports' });

  const styles = {
    container: provided => ({
      ...provided,
      border: colors.borderWhite,
      borderRadius: '4px',
      ...(portalError ? {
        borderColor: colors.red500
      } : {})
    }),
    placeholder: provided => ({
      ...provided,
      color: portalError ? colors.red500 : colors.grey600,
      fontSize: '14px',
      fontWeight: 400,
      cursor: 'pointer'
    })
  };
  function sorted_portals_options() {
    if (portalsOptions?.length) {
      return [...portalsOptions].sort(
        (a, b) => {
          const label_a = a?.label.toUpperCase();
          const label_b = b?.label.toUpperCase();
          if (label_a > label_b) return 1;
          if (label_a < label_b) return -1;
          return 0;
        }
      );
    }
    return [];
  }

  return (
    <div className={classes.reportsTableWrap} ref={tableRef}>
      <div className={cn(classes.reportsWrapper, detailsVisible && classes.reportsWrapper_panelOn)}>
        <div className={classes.reportsHeader}>
          <div className={classes.reportsHeaderStart}>
            {portalsOptions?.length > 1 && (
            <SelectCustom
              className={classes.selectPortals}
              value={reportsPortal ? { value: reportsPortal?.id, label: reportsPortal?.name } : null}
              placeholder={t('reports', 'CHOOSE_PORTAL')}
              onChange={({ value, label, slug }) => { onChangePortal({ id: value, name: label, slug }); }}
              options={sorted_portals_options()}
              styles={styles}
              is_searchable
            />
            )}
            <div className={classes.headerText}>
              <Icon type="reports" role="icon" />
              {t('reports', 'REPORTS') }
            </div>
          </div>
          <Button
            onClick={() => {
              sendAlertsEvent({ category: 'Add report', event: 'Clicked_Add_Report_Button' });
              !fetchings && onEditReport({ report_data: null });
            }}
            textButton={t('btn', 'ADD')}
            className={classes.addButton}
            {...(hasReports ? { purpleTransparent32: true } : { green32: true })}
          />
        </div>
        <SkeletonReportsList sizes={sizes} fetching={fetchings}>
          <Row gutter={[16, 16]} align="start" className={classes.reportsList}>
            {!!reports?.length && reports.map((reportItem, i) => (
              <Col key={reportItem?.id || i} {...(hasEmpty ? sizes : sizeFunc(reportItem?.type, reportItem?.id))}>
                {reportItem?.type === 'performace' && (
                <CustomCard
                  isBlur={detailsVisible}
                  className={classes.performaceCard}
                  data={{
                    title: t('reports', 'CAMPAIGN_PERFORMANCE_REPORT'),
                    subtitle: t('reports', 'DETAILED_REPORTS')
                  }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings && onEditReport({ report_data: null });
                  }}
                />
                )}
                { reportItem?.type === 'example' && (
                <ExampleCard
                  isBlur={detailsVisible}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings && onEditReport({ report_data: null });
                  }}
                />
                )}
                {reportItem?.type === 'try' && (
                <CustomCard
                  isBlur={detailsVisible}
                  className={classes.tryCard}
                  data={{ title: t('alerts', 'LETS_TRY'), subtitle: t('alerts', 'FEW_MINUTES') }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings && onEditReport({ report_data: null });
                  }}
                />
                )}
                {reportItem?.type === 'set_report' && (
                <SetReportCard
                  isBlur={detailsVisible}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings && onEditReport({ report_data: null });
                  }}
                />
                )}
                {reportItem?.type === 'full_image' && (
                <CustomCard
                  isBlur={detailsVisible}
                  className={classes.seeFullImageCard}
                  data={{
                    title: t('reports', 'FULL_PICTURE'),
                    subtitle: t('reports', 'FULL_ANALYSIS_AND_VISUALIZATION')
                  }}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings && onEditReport({ report_data: null });
                  }}
                />
                )}
                {reportItem?.type === 'other_channel' && (
                <OtherChannelCard
                  isBlur={detailsVisible}
                  onClick={() => {
                    sendClickWidgetEvent();
                    !fetchings && onEditReport({ report_data: null });
                  }}
                />
                )}
                {!reportItem?.type && (
                  <ErrorBoundarySentry>
                    <ReportCard
                      data={reportData && reportData?.id === reportItem?.id ? reportData : reportItem}
                      isEnabled={reportItem?.is_enabled}
                      onEditReport={({ report_data, isToggle }) => {
                        scrollToTop();
                        onEditReport({ report_data: isToggle ? reportItem : report_data, isToggle });
                      }}
                      userContacts={userContacts}
                      isSelected={detailsVisible && reportData?.id === reportItem?.id}
                    />
                  </ErrorBoundarySentry>
                )}
              </Col>
            )) }
          </Row>
        </SkeletonReportsList>
      </div>
    </div>
  );
});

ReportsTable.propTypes = {
  portalsOptions: array,
  detailsVisible: bool,
  portalError: bool,
  userContacts: shape({}),
  onEditReport: func,
  onChangePortal: func,
  reportData: shape({}),
  reportsPortal: shape({}),
  fetchings: bool,
  reports: array
};

export default ReportsTable;
